import Enum from './enums';

class TransactionStatuses extends Enum {
  static UPLOADED = 'uploaded';
  static PROCESSED = 'processed';
  static CATEGORIZED = 'categorized';
  static ARCHIVED = 'archived';
}

export const TransactionStatusTypes = Object.freeze(TransactionStatuses);
