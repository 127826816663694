import { useState, useMemo, useEffect } from 'react';
import { useReactTable } from '@tanstack/react-table';
import { Pagination, Text } from '@koinx/xui';
import styles from './CustomTable.module.css';
import TransactionDetailsDrawer from '@/Components/Routes/TransactionsPage/TransactionsDetailsDrawer';
import { useDispatch, useSelector } from 'react-redux';
import useTransaction from '@/Hooks/useTransaction';
import { commonHeaders } from './constants';
import DateAndTypeCell from './components/TableCells/DateAndTypeCell';
import SourceCell from './components/TableCells/SourceCell';
import AddressCell from './components/TableCells/AddressCell';
import AmountCell from './components/TableCells/AmountCell';
import CategoryCell from './components/TableCells/CategoryCell';
import IndeterminateCheckbox from './components/TableCells/IndeterminateCheckbox';
import TableHeader from './components/TableHeader';
import TableBody from './components/TableBody';
import { getTableConfig } from './tableConfig';
import { setSelectedTableRows } from '@/Redux/slices/transactionSlice';
import { getTxnsObjectsById } from './helper';

const CustomTable = ({
  tableData,
  customLastColumnName,
  customLastColumnLabel,
  customLastColumnBody,
  isTableDataLoading,
  selectableRows = false,
  EmptyStateComponent,
  tableName,
}) => {
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);
  const [rowDataForDetailsDrawer, setRowDataForDetailsDrawer] = useState(null);
  const { sourceNameToSourceDataMap } = useSelector((state) => state.sources);
  const {
    totalTransactionsCount,
    cursor,
    rowsPerPage,
    categorizeTransactionSuccessData,
    archiveTransactionSuccessData,
  } = useSelector((state) => state.transactions);

  const { handleSetTransactionsPageCursorAndRowPerPage } = useTransaction();

  const tableDataIsNotLoadingAndExists =
    !isTableDataLoading && tableData.length > 0;

  const columns = useMemo(() => {
    const baseColumns = [
      {
        header: () => (
          <div className={styles.DateAndTypeHeaderCell}>
            <Text variant="subtitle-2" textColor="gray-11">
              Type
            </Text>
            <Text variant="body-3" textColor="gray-9">
              {commonHeaders[0].label}
            </Text>
          </div>
        ),

        accessorKey: commonHeaders[0].id,
        cell: ({ getValue, row }) => (
          <DateAndTypeCell getValue={getValue} row={row} />
        ),
      },
      {
        header: commonHeaders[1].label,
        accessorKey: commonHeaders[1].id,
        cell: ({ getValue, row, column }) => {
          return <SourceCell row={row} column={column} getValue={getValue} />;
        },
      },
      {
        header: commonHeaders[2].label,
        accessorKey: commonHeaders[2].id,
        cell: ({ getValue, row, column }) => {
          return <SourceCell row={row} column={column} getValue={getValue} />;
        },
      },
      {
        header: commonHeaders[3].label,
        accessorKey: commonHeaders[3].id,
        cell: ({ getValue, row }) => (
          <AddressCell
            getValue={getValue}
            row={row}
            sourceNameToSourceDataMap={sourceNameToSourceDataMap}
          />
        ),
      },
      {
        header: commonHeaders[4].label,
        accessorKey: commonHeaders[4].id,
        cell: ({ getValue, row }) => (
          <AddressCell
            getValue={getValue}
            row={row}
            sourceNameToSourceDataMap={sourceNameToSourceDataMap}
          />
        ),
      },
      {
        header: commonHeaders[5].label,
        align: 'end',
        accessorKey: commonHeaders[5].id,
        cell: ({ getValue, row }) => (
          <AmountCell
            getValue={getValue}
            row={row}
            coinKey={'inCoin'}
            priceKey="receivedValue"
          />
        ),
      },
      {
        header: commonHeaders[6].label,
        align: 'end',
        accessorKey: commonHeaders[6].id,
        cell: ({ getValue, row }) => (
          <AmountCell
            getValue={getValue}
            row={row}
            coinKey={'outCoin'}
            priceKey="outCoinTotalCostOfAcquisition"
          />
        ),
      },
      {
        header: commonHeaders[7].label,
        accessorKey: commonHeaders[7].id,
        align: 'end',
        cell: ({ getValue, row }) => (
          <AmountCell
            getValue={getValue}
            row={row}
            coinKey={'feeCoin'}
            priceKey="feeCoinMarketPrice"
          />
        ),
      },
      {
        header: customLastColumnLabel || 'Category',
        accessorKey: customLastColumnName || 'category',
        cell: ({ getValue, row }) => (
          <CategoryCell
            getValue={getValue}
            row={row}
            customLastColumnBody={customLastColumnBody}
          />
        ),
      },
    ];
    if (selectableRows) {
      return [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              className={styles.Checkbox}
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <div className="px-1" onClick={(e) => e.stopPropagation()}>
              <IndeterminateCheckbox
                className={styles.Checkbox}
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
        },
        ...baseColumns,
      ];
    }
    return baseColumns;
  }, [
    customLastColumnLabel,
    customLastColumnName,
    selectableRows,
    sourceNameToSourceDataMap,
    customLastColumnBody,
  ]);

  const [prevCache, setPrevCache] = useState([]);

  const dispatch = useDispatch();

  // onRowSelectionChange receives a function that when executed returns all
  // the selected transactions.
  const onRowSelectionChange = (all) => {
    // just a state to hold all previous as well as currently selected rows.
    setPrevCache(all);
    if (typeof all === 'function') {
      // data given by all(prevCache) function is in the form of {6720aa44b6735c00071d1d7e: true, ..., ..., },
      // so we convert it to our exisiting selected rows state structure, which is an array of transaction objects.
      const arrayOfTxnObjects = getTxnsObjectsById(all(prevCache));
      dispatch(setSelectedTableRows(arrayOfTxnObjects));
    }
  };

  const tableConfig = getTableConfig({
    tableData,
    columns,
    totalTransactionsCount,
    rowsPerPage,
    selectableRows,
    cursor,
    handleSetTransactionsPageCursorAndRowPerPage,
    onRowSelectionChange,
    prevCache,
  });

  const table = useReactTable(tableConfig);

  const handleRowClick = (row) => {
    setIsDetailsDrawerOpen(true);
    setRowDataForDetailsDrawer(row.original);
  };

  // this was needed to clear the internal state of header checkbox
  // after doing an operation that refreshes the transactions data and table.
  useEffect(() => {
    if (categorizeTransactionSuccessData) table.resetRowSelection();
    if (archiveTransactionSuccessData) table.resetRowSelection();
  }, [categorizeTransactionSuccessData, archiveTransactionSuccessData, table]);

  return (
    <div className={styles.TableContainer}>
      <div className={styles.TableScrollContainer}>
        <table className={styles.Table}>
          <TableHeader headerGroups={table.getHeaderGroups()} />
          <TableBody
            isTableDataLoading={isTableDataLoading}
            tableName={tableName}
            tableData={tableData}
            columns={columns}
            EmptyStateComponent={EmptyStateComponent}
            table={table}
            handleRowClick={handleRowClick}
          />
        </table>
      </div>
      {tableDataIsNotLoadingAndExists && (
        <div className={styles.TableFooter}>
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            rowOptionsGap={10}
            rowOptionsLength={100}
            setCurrentPage={(page) => table.setPageIndex(page - 1)}
            rowsPerPage={table.getState().pagination.pageSize}
            setRowsPerPage={table.setPageSize}
            paginationWrapperClass={styles.PaginationWrapperClass}
          />
        </div>
      )}
      {isDetailsDrawerOpen && (
        <TransactionDetailsDrawer
          rowData={rowDataForDetailsDrawer}
          isOpen={isDetailsDrawerOpen}
          handleClose={() => setIsDetailsDrawerOpen(false)}
        />
      )}
    </div>
  );
};

export default CustomTable;
