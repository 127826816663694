import { UPDATE_USER_WALLET_NAME_URL } from '@/Utils/constants';

const updateUserWalletName = (axiosInstance, walletId, customWalletName) => {
  return axiosInstance.put(UPDATE_USER_WALLET_NAME_URL, {
    walletId,
    customWalletName,
  });
};

export default updateUserWalletName;
