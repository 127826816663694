export const journalTableHeaders = [
  { id: 'timestamp', label: 'Date and Time' },
  { id: 'amount', label: 'Amount' },
  { id: 'createType', label: 'Type' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'description', label: 'Description' },
  { id: '_id', label: 'Journal ID' },
];

export const commonHeaderProps = () => ({
  style: {
    fontSize: 'var(--font-14)',
    fontWeight: 500,
    minWidth: 'max-content',
    color: 'var(--gray-12)',
    background: '#f1f5fe',
    whiteSpace: 'nowrap',
  },
});

export const JournaltableInfoCompData = {
  title: 'No journals found.',
  imageAlt: 'No journals found.',
  subtitle: 'Looks like no journals are added',
  btnText: 'Add Journal',
};

export const SystemCreatedJournal = 'system';
