import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  defaultTaxSettings: null,
  taxSettingsData: null,
  baseCurrency: null,
  isSettingLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setWorkspaceSettings: (state) => {
      state.isSettingLoading = true;
    },
    setWorkspaceSettingsSuccess: (state, action) => {
      state.isWorkspacesLoading = false;
      state.taxSettingsData = action.payload.taxSettings;
      state.defaultTaxSettings = action.payload.defaultSettings;
      state.baseCurrency = action.payload.taxSettings.baseCurrency;
    },
    setWorkspaceSettingsError: (state, action) => {
      state.isSettingLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setWorkspaceSettings,
  setWorkspaceSettingsSuccess,
  setWorkspaceSettingsError,
} = userSlice.actions;

export default userSlice.reducer;
