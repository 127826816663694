import {
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
} from '@mui/material';

const TransactionsTableSkeleton = ({ rowCount, columnCount, columns }) => {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  maxWidth: column.maxWidth,
                  fontSize: 'var(--font-16)',
                  fontWeight: 500,
                  color: 'var(--gray-12)',
                  background: '#f1f5fe',
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: rowCount }).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {Array.from({ length: columnCount }).map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton height={30} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionsTableSkeleton;
