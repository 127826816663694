import { useState } from 'react';
import { useLocation } from 'react-router';
import {
  ExpandableNestedTab,
  NotNestedExpandableTab,
  NotNestedFixedTab,
  NestedFixedTab,
} from './States/SidebarCollapsed';
import ExpandableTab from './States/SidebarOpened/ExpandableTab';
import FixedTab from './States/SidebarOpened/FixedTab';

/**
 *
 * SidebarTab is a recursive component. It can render a tab (basically a link) along with its nested/child links.
 *
 * A tab can be expandable or fixed (not expandable). Expandable means it has some child links.
 * A tab can be nested or not nested. Nested means it is child link of some parent expandable tab.
 *
 * To know if the tab is expandable, we set `isExpandable: true` in its definition in constants.js file.
 * To know if the tab is nested, we pass `isNested={true}` in the recursive calls of the SidebarTab.
 *
 */
const SidebarTab = ({
  tab,
  isCollapsed,
  isNested = false,
  handlePopoverClose,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(location.pathname.includes(tab?.link));
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleTab = () => {
    setIsOpen(!isOpen);
    setIsPopoverOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  const handlePopoverOpen = (e) => {
    setIsPopoverOpen(true);
    if (e) {
      if (e.target) {
        setAnchorEl(e.target);
      }
    }
  };

  const isActiveTab = (tabLink) => {
    if (location.pathname.includes(tabLink) || location.pathname === tabLink) {
      return true;
    } else return false;
  };

  // a sidebar tab can be expandable or fixed (not expandable)
  // similarly it can be nested, or it can be not nested.
  // we know a tab is expandable if it has some child links
  // based on these we define these conditions:
  const tabIsExpandableAndIsNested = tab.isExpandable && isNested;
  const tabIsExpandableAndNotNested = tab.isExpandable && !isNested;
  const tabIsNotExpandableAndIsNested = !tab.isExpandable && isNested;
  const tabIsNotExpandableAndNotNested = !tab.isExpandable && !isNested;

  // when the sidebar is opened (not collapsed), these are the conditions
  // in which the sidebar tab can be rendered
  const isTabExpandable = tab.isExpandable;

  if (isCollapsed) {
    if (tabIsExpandableAndIsNested) {
      return (
        <ExpandableNestedTab
          anchorEl={anchorEl}
          tab={tab}
          handleClose={handleClose}
          handlePopoverOpen={handlePopoverOpen}
          isActiveTab={isActiveTab}
          isCollapsed={isCollapsed}
          isPopoverOpen={isPopoverOpen}
        />
      );
    } else if (tabIsExpandableAndNotNested) {
      return (
        <NotNestedExpandableTab
          anchorEl={anchorEl}
          tab={tab}
          handleClose={handleClose}
          handlePopoverOpen={handlePopoverOpen}
          isActiveTab={isActiveTab}
          isCollapsed={isCollapsed}
          isPopoverOpen={isPopoverOpen}
        />
      );
    } else if (tabIsNotExpandableAndIsNested) {
      return (
        <NestedFixedTab
          tab={tab}
          isActiveTab={isActiveTab}
          isNested={isNested}
          handlePopoverClose={handlePopoverClose}
        />
      );
    } else if (tabIsNotExpandableAndNotNested) {
      return <NotNestedFixedTab tab={tab} isActiveTab={isActiveTab} />;
    }
  } else {
    if (isTabExpandable) {
      return (
        <ExpandableTab
          tab={tab}
          isOpen={isOpen}
          isActiveTab={isActiveTab}
          isCollapsed={isCollapsed}
          handleToggleTab={handleToggleTab}
        />
      );
    } else {
      return <FixedTab isActiveTab={isActiveTab} tab={tab} />;
    }
  }
};

export default SidebarTab;
