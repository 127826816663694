import { Button, Drawer, Text } from '@koinx/xui';
import styles from './CategoriseTransactionsDrawer.module.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelectOptions } from '@/Utils/helper/createSelectOptions';
import useChartOfAccounts from '@/Hooks/useChartOfAccounts';
import { accountTypeEnums } from '@/Utils/enums/accountType';
import { transformAccounts } from '@/Components/Routes/ChartOfAccounts/CreateAccountDrawer/helpers';
import { Divider } from '@mui/material';
import {
  createAccountEntries,
  initializeSelectedValues,
  shouldRenderFees,
  shouldRenderGains,
  shouldRenderMainTransaction,
  shouldRenderTDS,
} from './helper';
import useTransaction from '@/Hooks/useTransaction';
import { categoriseTransactionsData, categorizeSections } from './constants';
import InputGroup from './components/InputGroup';

const CategoriseTransactionsDrawer = ({
  isOpen,
  handleOnOpenChange,
  handleClose,
  rowData,
  width = '45%',
  isMultiRow = false,
}) => {
  const userBaseCurrencyCode = useSelector(
    (state) => state.workspace.baseCurrency
  );

  const isCategorizeTransactionsLoading = useSelector(
    (state) => state.transactions.isCategorizeTransactionsLoading
  );
  const [selectedValues, setSelectedValues] = useState(
    initializeSelectedValues(rowData, isMultiRow, userBaseCurrencyCode)
  );

  const [accountOptions, setAccountOptions] = useState({
    mainTransaction: [],
    gains: [],
    fees: [],
    tds: [],
  });

  const [lastUpdatedSection, setLastUpdatedSection] = useState(null);

  const { parentAccounts } = useSelector((state) => state.chartOfAccounts);
  const { handleCategorizeTransactions } = useTransaction();
  const { handleGetParentAccountList } = useChartOfAccounts();

  const handleSelectChange = useCallback(
    (section, field, value) => {
      setSelectedValues((prev) => ({
        ...prev,
        [section]: { ...prev[section], [field]: value },
      }));

      if (field === 'category') {
        handleGetParentAccountList({ type: value });
        setLastUpdatedSection(section);
      }
    },
    [handleGetParentAccountList]
  );

  useEffect(() => {
    if (lastUpdatedSection && selectedValues[lastUpdatedSection].category) {
      setAccountOptions((prev) => ({
        ...prev,
        [lastUpdatedSection]: transformAccounts(parentAccounts?.accounts || []),
      }));
    }
  }, [parentAccounts, selectedValues, lastUpdatedSection]);

  const categoryOptions = useMemo(
    () => createSelectOptions(Object.values(accountTypeEnums)),
    []
  );

  const handleSubmit = useCallback(() => {
    const categorizations = (isMultiRow ? rowData : [rowData]).map((row) => ({
      transactionId: row._id,
      accounts: {
        mainTransaction:
          (row.inCoin && !row.outCoin) ||
            (!row.inCoin && row.outCoin) ||
            (row.inCoin && row.outCoin && row.isP2P)
            ? createAccountEntries(
              row,
              categorizeSections.mainTransaction,
              selectedValues
            )
            : [],
        gains:
          row.gain > 0
            ? createAccountEntries(
              row,
              categorizeSections.gains,
              selectedValues
            )
            : [],
        fee:
          row?.feeCoin && row?.feeCoinAmount > 0
            ? createAccountEntries(row, categorizeSections.fees, selectedValues)
            : [],
        tds:
          row?.tdsCoin && row?.tds > 0
            ? createAccountEntries(row, categorizeSections.tds, selectedValues)
            : [],
      },
    }));

    handleCategorizeTransactions(handleClose, categorizations);
  }, [
    selectedValues,
    rowData,
    handleClose,
    handleCategorizeTransactions,
    isMultiRow,
  ]);
  const sections = [
    {
      shouldRender: shouldRenderMainTransaction(rowData, isMultiRow),
      title: null,
      section: categorizeSections.mainTransaction,
    },
    {
      shouldRender: shouldRenderGains(rowData, isMultiRow),
      title: 'Gains',
      section: categorizeSections.gains,
    },
    {
      shouldRender: shouldRenderFees(rowData, isMultiRow),
      title: 'Fees',
      section: categorizeSections.fees,
    },
    {
      shouldRender: shouldRenderTDS(rowData, isMultiRow),
      title: 'TDS',
      section: categorizeSections.tds,
    },
  ];

  const renderSection = (shouldRender, title, section) => {
    if (!shouldRender) return null;
    return (
      <>
        {title && (
          <Text variant="heading-4" textColor="gray-12">
            {title}
          </Text>
        )}
        <InputGroup
          section={section}
          categoryOptions={categoryOptions}
          accountOptions={accountOptions[section]}
          selectedValues={selectedValues}
          handleSelectChange={handleSelectChange}
          userBaseCurrencyCode={userBaseCurrencyCode}
          rowData={rowData}
          isMultiRow={isMultiRow}
        />
      </>
    );
  };

  return (
    <Drawer
      variant="right_fixed_bottom_content"
      isOpen={isOpen}
      handleonOpenChange={handleOnOpenChange}
      handleClose={handleClose}
      width={width}
      bottomContent={
        <div className={styles.BottomContainer}>
          <Button
            onClick={handleClose}
            className={styles.Button}
            data-testid="categorise-transaction-cancel-button"
            variant="outline">
            {categoriseTransactionsData.leftButtonTxt}
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isCategorizeTransactionsLoading}
            data-testid="categorise-transaction-save-button"
            className={styles.Button}
            variant="primary">
            {categoriseTransactionsData.rightButtonTxt}
          </Button>
        </div>
      }
      data-testid="categorise-transaction-drawer"
      titleClass={styles.DrawerTitle}
      closeIconClass={styles.CloseIcon}
      title={categoriseTransactionsData.title}>
      <section className={styles.DrawerContentContainer}>
        {sections.reduce((acc, { shouldRender, title, section }, index) => {
          if (shouldRender) {
            if (acc.length > 0) {
              acc.push(<Divider key={`divider-${index}`} />);
            }
            acc.push(
              <div key={section}>
                {renderSection(shouldRender, title, section)}
              </div>
            );
          }
          return acc;
        }, [])}
      </section>
    </Drawer>
  );
};

export default CategoriseTransactionsDrawer;
