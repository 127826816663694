export const shortenString = (
  inputString,
  startCharsCount = 3,
  endCharsCount = 4
) => {
  if (inputString) {
    let lastFourCharacters = inputString.substring(
      inputString.length - endCharsCount
    );
    return inputString.length > startCharsCount
      ? `${inputString.substring(0, startCharsCount)}...${lastFourCharacters}`
      : inputString;
  }
  return '';
};
