import DefaultCoinIcon from '@/Assets/TransactionPage/TransactionCoins/DefaultCoin.svg';
import { fiatCoins } from '@/Utils/enums/fiatCoins';

export const getCryptoCoinDetails = (
  coin,
  contractAddress,
  userTransactionCoins,
  isFiatCoin = false
) => {
  if (contractAddress) {
    return {
      coinName:
        userTransactionCoins?.[coin + '@' + contractAddress]?.name ?? null,
      image:
        userTransactionCoins?.[coin + '@' + contractAddress]?.image ??
        DefaultCoinIcon,
    };
  } else if (userTransactionCoins?.[coin] && !isFiatCoin) {
    return {
      coinName: userTransactionCoins?.[coin]?.name ?? null,
      image: userTransactionCoins?.[coin]?.image ?? DefaultCoinIcon,
    };
  } else if (fiatCoins?.[coin?.trim()?.toUpperCase()]) {
    return {
      coinName: fiatCoins?.[coin?.toUpperCase()]?.name ?? null,
      image: fiatCoins?.[coin?.toUpperCase()]?.image ?? DefaultCoinIcon,
    };
  } else {
    return {
      coinName: coin,
      image: DefaultCoinIcon,
    };
  }
};
