import React from 'react';
import { Avatar } from '@koinx/xui';
import styles from './UserAvatar.module.css';
import { toTitleCase } from '@/Utils/toTitleCase';

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString(16)}`.slice(-2);
  }

  return color;
}

const UserAvatar = ({ name, className, size = 'large' }) => {
  let width = '';
  let height = '';
  let fontsize = '';
  switch (size) {
    case (size = 'small'): {
      width = '26px';
      height = '26px';
      fontsize = 'var(--font-12)';
      break;
    }
    case (size = 'medium'): {
      width = '30px';
      height = '30px';
      fontsize = 'var(--font-14)';
      break;
    }
    case (size = 'large'): {
      width = '34px';
      height = '34px';
      fontsize = 'var(--font-16)';
      break;
    }
  }

  function stringAvatar(name = '') {
    const tokens = name.split(' ');

    const firstTwoWords = tokens.length > 2 ? tokens.slice(0, 2) : tokens;

    const userInitials = `${firstTwoWords?.[0]?.[0]}${firstTwoWords?.[1]?.[0]}`;
    return {
      style: {
        width: width,
        height: height,
      },
      fallbackStyles: {
        backgroundColor: stringToColor(toTitleCase(name)),
      },
      fallbackContent: userInitials.toUpperCase(),
    };
  }
  return (
    <div className={styles.Wrapper}>
      <Avatar {...stringAvatar(name)} imageSrc="xyz" data-testid="userAvatar" />
      <p data-testid="userAvatarName" className={`${styles.Name} ${className}`}>
        {toTitleCase(name)}
      </p>
    </div>
  );
};

export default UserAvatar;
