import { Text } from '@koinx/xui';
import styles from './AmountGroup.module.css';
import AmountComponent from '@/Components/Ui/AmountComponent';
import { useSelector } from 'react-redux';

const AmountGroup = ({
  cryptoAmount,
  price,
  cryptoAmountClassName,
  priceClassName,
  coin,
}) => {
  const baseCurrency = useSelector((state) => state.workspace.baseCurrency);

  return (
    <div className={styles.Pricing} data-testid="amountGroup-wrapper">
      {price < 0 ? (
        <AmountComponent
          maximumFractionDigits={4}
          value={price}
          isColored={price < 0}
        />
      ) : (
        <AmountComponent
          maximumFractionDigits={4}
          value={price}
          className={priceClassName ? priceClassName : ''}
          customColor={{ color: 'var(--gray-12)' }}
        />
      )}
      {coin !== baseCurrency && (
        <Text
          data-testid="amount-crypto-price"
          className={
            cryptoAmountClassName ? cryptoAmountClassName : styles.CryptoAmount
          }
          textColor="gray-9"
          variant="body-3">
          {cryptoAmount?.toFixed(2)} {coin}
        </Text>
      )}
    </div>
  );
};

export default AmountGroup;
