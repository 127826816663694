import styles from './ArchivePopup.module.css';
import { Select, Close, Button, ArrowDropDown } from '@koinx/xui';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ArchivePopupContent } from './constants';
import { pluralize } from '@/Utils/helper/pluralize';
import { replaceTemplateString } from '@/Utils/helper/replaceTemplateString';
import { prepareArchiveTransactionsData } from './helper';
import useTransaction from '@/Hooks/useTransaction';

const { buttons, heading, select, subHeading } = ArchivePopupContent;

const ArchivePopup = ({ handleClose }) => {
  const { handleArchiveTransactions } = useTransaction();

  const { selectedTableRows, archiveLabels, transactionsSuccessData } =
    useSelector((state) => state.transactions);

  const [selectedLabel, setSelectedLabel] = useState('');

  const handleArchiveButtonClick = () => {
    let archiveTransactionsArray = prepareArchiveTransactionsData(
      transactionsSuccessData,
      selectedTableRows,
      selectedLabel
    );
    handleArchiveTransactions(archiveTransactionsArray, handleClose);
  };

  return (
    <div
      data-testid="archive-transactions-popup"
      className={styles.ArchivePopupContainer}
    >
      <div className={styles.CloseWrapper}>
        <IconButton data-testid="close-popup-icon" onClick={handleClose}>
          <Close className={styles.CloseIcon} />
        </IconButton>
      </div>
      <div className={styles.ArchivePopupWrapper}>
        <h1 className={styles.ArchivePopupHeading}>
          {replaceTemplateString(heading, [
            pluralize(
              selectedTableRows.length,
              ArchivePopupContent.pluralize.singular,
              ArchivePopupContent.pluralize.plural
            ),
          ])}
        </h1>
        <p className={styles.ArchivePopupSubHeading}>
          {replaceTemplateString(subHeading, [
            selectedTableRows.length,
            pluralize(
              selectedTableRows.length,
              ArchivePopupContent.pluralize.singular,
              ArchivePopupContent.pluralize.plural
            ),
          ])}
        </p>
        <div className={styles.ArchivePopupSelectWrapper}>
          <p className={styles.ArchivePopupSelectLabel}>{select.label}</p>
          <Select
            isCustomOptionCreationAllowed
            searchInput
            value={selectedLabel}
            onChange={(label) => setSelectedLabel(label)}
            iconRight={<ArrowDropDown />}
            width="100%"
            className={styles.ArchivePopupSelect}
            options={archiveLabels}
            placeholder={select.placeholder}
          />
        </div>
        <div className={styles.ButtonsWrapper}>
          <Button
            data-testid="archivePopup-cancelButton"
            onClick={handleClose}
            fullWidth
            variant="outline"
          >
            {buttons.cancel}
          </Button>
          <Button
            data-testid="archivePopup-confirmArchiveButton"
            disabled={!selectedLabel}
            onClick={handleArchiveButtonClick}
            fullWidth
            variant="primary"
          >
            {buttons.archive}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ArchivePopup;
