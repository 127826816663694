export const staticContent = {
  title: 'Enter OTP',
  email: 'amittiwari@abccompany.com',
  change: 'Change',
  subText: 'OTP has been sent to your email. It expires in 5 mins.',
  input: {
    label: 'Enter 6 Digit OTP',
    placeholder: 'Your 6 digit OTP',
    name: 'otp',
  },
  infoText: ['Already a member?', 'Sign In'],
  button: {
    signUp: 'Create Account',
    signIn: 'Sign In',
    loading: 'Please wait',
  },
  retry: {
    text: "Didn't receive? ",
    link: 'Resend OTP',
  },
};
