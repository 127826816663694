import axios from 'axios';
import { BEGIN_AUTH_URL } from '@/Utils/constants';

export const beginAuth = ({ email, recaptchaToken, firstName, lastName }) => {
  const body = {
    email,
    recaptchaToken,
    firstName,
    lastName,
  };
  Object.keys(body).forEach((value) => {
    if (body[value] === null || body[value] === undefined) {
      delete body[value];
    }
  });
  return axios.post(BEGIN_AUTH_URL, body);
};
