import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  message: null,
  error: null,
};

const blockchainIntegrationSlice = createSlice({
  name: 'blockchainIntegration',
  initialState,
  reducers: {
    setSyncBlockchainWallet: (state) => {
      state.loading = true;
    },
    setSyncBlockchainWalletSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    setSyncBlockchainWalletError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setSyncBlockchainWallet,
  setSyncBlockchainWalletError,
  setSyncBlockchainWalletSuccess,
} = blockchainIntegrationSlice.actions;

export default blockchainIntegrationSlice.reducer;
