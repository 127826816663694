import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUserWorkspaceMembersLoading: false,
  userWorkspaceMembers: [],
  error: null,
  isUpdatingMemberRoleLoading: false,
  message: null,
  isRevokeMemberLoading: false,
  workspaceRoles: [],
  workspaceRolesLoading: false,
};

const accountingMemberSlice = createSlice({
  name: 'accountingMember',
  initialState,
  reducers: {
    setWorkspaceMembers: (state) => {
      state.isUserWorkspaceMembersLoading = true;
    },
    setWorkspaceMembersSuccess: (state, action) => {
      state.isUserWorkspaceMembersLoading = false;
      state.userWorkspaceMembers = action.payload;
    },
    setWorkspaceMembersError: (state, action) => {
      state.isUserWorkspaceMembersLoading = false;
      state.error = action.payload;
    },
    setWorkspaceMemberRole: (state) => {
      state.isUpdatingMemberRoleLoading = true;
    },
    setUpdateWorkspaceMemberRoleSuccess: (state, action) => {
      state.isUpdatingMemberRoleLoading = false;
      state.userWorkspaceMembers[action.payload.index].role =
        action.payload.role;
    },
    setUpdateWorkspaceMemberRoleError: (state, action) => {
      state.isUpdatingMemberRoleLoading = false;
      state.error = action.payload;
    },
    setRevokeWorkspaceMember: (state) => {
      state.isRevokeMemberLoading = true;
    },
    setRevokeWorkspaceMemberSuccess: (state, action) => {
      state.isRevokeMemberLoading = false;
      state.message = action.payload;
    },
    setRevokeWorkspaceMemberError: (state, action) => {
      state.error = action.payload;
    },
    setFetchUserWorkspaceRoles: (state) => {
      state.workspaceRolesLoading = true;
    },
    setFetchUserWorkspaceRolesSuccess: (state, action) => {
      state.workspaceRolesLoading = false;
      state.workspaceRoles = action.payload;
    },
    setFetchUserWorkspaceRolesError: (state) => {
      state.workspaceRolesLoading = false;
    },
  },
});

export const {
  setWorkspaceMembers,
  setWorkspaceMembersSuccess,
  setWorkspaceMembersError,
  setWorkspaceMemberRole,
  setUpdateWorkspaceMemberRoleSuccess,
  setUpdateWorkspaceMemberRoleError,
  setFetchUserWorkspaceRoles,
  setFetchUserWorkspaceRolesSuccess,
  setFetchUserWorkspaceRolesError,
  setRevokeWorkspaceMember,
  setRevokeWorkspaceMemberSuccess,
  setRevokeWorkspaceMemberError,
} = accountingMemberSlice.actions;

export default accountingMemberSlice.reducer;
