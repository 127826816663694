import React from 'react';
import { flexRender } from '@tanstack/react-table';
import styles from '@/Components/Routes/TransactionsPage/CustomTable/CustomTable.module.css';
import TableSkeleton from '@/Components/Ui/TableSkeleton/TableSkeleton';

const TableBody = ({
  isTableDataLoading,
  tableName,
  tableData,
  columns,
  EmptyStateComponent,
  table,
  handleRowClick,
}) => {
  if (isTableDataLoading) {
    return (
      <TableSkeleton
        columnCount={tableName === 'unrecognizedTable' ? 10 : 9}
        rowCount={5}
      />
    );
  }

  if (tableData.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={columns.length}>
            <EmptyStateComponent />
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {table.getRowModel().rows.map((row) => (
        <tr
          key={row.id}
          onClick={() => handleRowClick(row)}
          className={styles.TableRow}>
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className={styles.TableCell}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
