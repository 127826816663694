import { useState } from 'react';
import { navbarContent } from './constants';
import styles from './Navbar.module.css';
import { routeEnums } from '@/Utils/enums/routes.js';
import UserAvatar from '@/Components/UserAvatar';
import { ArrowDropDown, ArrowDropUp, Select } from '@koinx/xui';
import { useSelector } from 'react-redux';
import { createWorkspaceSelectOptions } from './helpers';
import useAuth from '@/Hooks/useAuth';

export default function Navbar() {
  const { workspaces, currentWorkspaceId, userData } = useSelector(
    (state) => state.user
  );
  const [dropdown, setDropdown] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] =
    useState(currentWorkspaceId);

  const { handleUserSignout } = useAuth();

  return (
    <header className={styles.Navbar}>
      <nav className={styles.Wrapper}>
        <div className={styles.LogoCategoriesWrapper}>
          <a href={routeEnums.DASHBOARD}>
            <div className={styles.Logo}>
              <img
                src={navbarContent.logo.src}
                alt={navbarContent.logo.alt}
                className={styles.LogoImage}
              />
            </div>
          </a>
        </div>
        <div className={styles.rightSideWrapper}>
          {workspaces && (
            <Select
              options={createWorkspaceSelectOptions(workspaces)}
              className={styles.WorkspaceDropdown}
              value={selectedWorkspace}
              onChange={(workspaceId) => setSelectedWorkspace(workspaceId)}
            />
          )}
          {userData && (
            <div
              className={`${styles.ProfileWrapper} ${dropdown && styles.active}`}
              onClick={() => {
                setDropdown(!dropdown);
              }}
              tabIndex="0"
            >
              <div className={styles.ProfileContainer}>
                <UserAvatar
                  name={userData.name}
                  className={styles.ProfileName}
                />
                {dropdown ? (
                  <ArrowDropUp className={styles.DropdownArrow} />
                ) : (
                  <ArrowDropDown className={styles.DropdownArrow} />
                )}
              </div>
            </div>
          )}
          {dropdown && (
            <div className={styles.Dropdown}>
              {navbarContent.profileDropdown.dropdownList.map((item) =>
                item.isExternal ? (
                  <a
                    href={item.path}
                    className={styles.DropdownItems}
                    key={item.label}
                  >
                    {item.label}
                  </a>
                ) : (
                  <a
                    key={item.label}
                    href={`${item.path}`}
                    className={styles.DropdownItems}
                    onClick={() => setDropdown(false)}
                  >
                    {item.label}
                  </a>
                )
              )}
              <hr className={styles.Line} />
              <p
                tabIndex={'0'}
                className={styles.DropdownItems}
                onClick={() => handleUserSignout()}
              >
                {navbarContent.profileDropdown.signout.label}
              </p>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}
