export const ArchivedTableDummyData = [
  {
    label: ['spam'],
    timestamp: '2021-12-31T18:30:00.000Z',
    inCoin: 'MATIC',
    outCoin: 'ETH',
    inCoinAmount: 100,
    outCoinAmount: 1000,
    feeCoin: 'INR',
    feeCoinAmount: 10,
    feeCoinMarketPrice: 1000,
    type: 'Trade',
    source: 'Binance',
    inCoinMarketPrice: 101,
    outCoinTotalCostOfAcquisition: 102,
    fromAddress: '1HB5XMLmzFVj8ALj6mfBsbifRoD4miY36v',
    toAddress: '1HB5XMLmzFVj8ALj6mfBsbifRoD4miY36v',
    txnHash: '1HB5XMLmzFVj8ALj6mfBsbifRoD4miY36v',
    accounting: {
      status: 'uploaded',
      category: 'Sales Revenue',
    },
    journal: {
      id: '213123',
      link: 'https://www.example.com',
    },
    invoice: {
      id: 'INV-003284',
      link: 'https://www.example.com',
    },
  },
  {
    label: ['spam'],
    _id: '6683edadaced5b00131e1b36',
    accounting: {
      status: 'processed',
    },
    inCoinAmount: 0,
    outCoinAmount: 123,
    inCoinMarketPrice: 0,
    source: 'binance',
    walletId: '6683edadfb3a8d001236cab9',
    timestamp: '2022-05-29T11:59:57.000Z',
    inCoin: null,
    outCoin: 'BTC',
    feeCoin: null,
    feeCoinAmount: 0,
    type: 'airdrop',
    outCoinTotalCostOfAcquisition: 123,
  },
];

export const emptyStateContent = {
  title: 'No Transactions Found.',
  cta: 'Archive Transactions',
  imageAlt: 'No transactions found illustration',
};
