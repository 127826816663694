import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setValidateOTPError,
  setHandleUserTokens,
  setResendOTP,
  setResendOTPSuccess,
  setResendOTPError,
  setBeginAuth,
  setBeginAuthSuccess,
  setBeginAuthError,
  setHandleRefreshTokenError,
  setHandleRefreshTokenSuccess,
  setHandleRefreshToken,
  setValidateOTP,
  setValidateOTPSuccess,
  setUserSignout,
  setUserSignoutError,
  setUserSignOutSuccess,
} from '@/Redux/slices/authSlice';
import { validateOTP } from '@/Services/auth/validateOTP';
import { resendOTP } from '@/Services/auth/resendOTP';
import { beginAuth } from '@/Services/auth/beginAuth';
import notify from '@/Utils/notifyToast';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import useStorage from './useStorage';
import { routeEnums } from '@/Utils/enums/routes';
import { refreshToken } from '@/Services/auth/refreshToken';
import { useLocation, useNavigate } from 'react-router';
import { isValidEmail } from '@/Utils/helper/isValidEmail';
import { staticContent } from '@/Components/UserOnboarding/SignInComponent/constants';
import { userLogout } from '@/Services/auth/userLogout';

const unauthorizedRoutes = [
  routeEnums.SIGN_IN_PAGE,
  routeEnums.SIGN_UP_PAGE,
  routeEnums.VERIFY_OTP_PAGE,
];

function useAuth() {
  const dispatch = useDispatch();
  const { storeInLocalStorage } = useStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyText } = staticContent;

  const handleBeginAuth = useCallback(
    async (email, recaptchaToken, resetCaptcha, firstName, lastName) => {
      try {
        if (!isValidEmail(email)) {
          notify(notifyText.invalidEmail, 'error');
          return;
        }
        if (!recaptchaToken) {
          notify(notifyText.invalidRecaptcha, 'error');
          return;
        }

        dispatch(setBeginAuth({ email }));
        const response = await beginAuth({
          email,
          recaptchaToken,
          firstName,
          lastName,
        });
        dispatch(setBeginAuthSuccess());
        await resetCaptcha();
        if (response.data) {
          navigate(routeEnums.VERIFY_OTP_PAGE);
        }
        // Uncomment the below line only if isNewUser flag is needed at any point in the flow
        // sessionStorage.setItem('isNewUser', response.data.newUser);
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(setBeginAuthError(getErrorStatement(error)));
          await resetCaptcha();
          notify(getErrorStatement(error), 'error');
        }
      }
    },
    [dispatch, navigate, notifyText.invalidEmail, notifyText.invalidRecaptcha]
  );

  const handleValidateOTP = useCallback(
    async (otp, email, inviteId, onSuccess) => {
      try {
        dispatch(setValidateOTP());
        const response = await validateOTP(otp, email, inviteId);
        if (response.data) {
          dispatch(
            setValidateOTPSuccess({
              message: response.data.message,
              newUser: response.data.newUser,
              accessToken: response.data.accessToken,
            })
          );
          onSuccess();
        }
        // Uncomment the below line only if isNewUser flag is needed at any point in the flow
        // sessionStorage.setItem('isNewUser', response.data.newUser);
        notify(response.data.message, 'success');
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(setValidateOTPError(getErrorStatement(error)));
          notify(getErrorStatement(error), 'error');
        }
      }
    },
    [dispatch]
  );

  const handleResendOTP = useCallback(
    async (email, userId) => {
      try {
        dispatch(setResendOTP());
        const response = await resendOTP(email, userId);
        dispatch(setResendOTPSuccess());
        notify(response.data.message, 'success');
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(setResendOTPError(getErrorStatement(error)));
          notify(getErrorStatement(error), 'error');
        }
      }
    },
    [dispatch]
  );

  const handleRefreshToken = useCallback(async () => {
    try {
      dispatch(setHandleRefreshToken());
      const response = await refreshToken();
      dispatch(setHandleRefreshTokenSuccess(response.data.message));
      dispatch(setHandleUserTokens(response.data.accessToken));
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(setHandleRefreshTokenError(getErrorStatement(error)));
        if (!unauthorizedRoutes.includes(location.pathname)) {
          navigate(unauthorizedRoutes[0]);
        }
      }
    }
  }, [dispatch, navigate, location]);

  const handleUserSignout = useCallback(async () => {
    try {
      dispatch(setUserSignout());
      const response = await userLogout();
      dispatch(setUserSignOutSuccess());
      dispatch(setHandleUserTokens(null));
      notify(response.data.message, 'success');
      navigate(0);
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(setUserSignoutError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    }
  }, [dispatch, navigate]);

  return {
    handleBeginAuth,
    handleValidateOTP,
    handleResendOTP,
    handleUserSignout,
    handleRefreshToken,
  };
}

export default useAuth;
