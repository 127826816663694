'use client';
import React from 'react';
import PopUpCenter from './PopUpCenter';
import usePopup from '@/Hooks/usePopup';
import { useDispatch, useSelector } from 'react-redux';

const PopUpCenterWrapper = () => {
  const {
    popupCenterComponent: componentToShow,
    popupCenterOpen: popUpCenterState,
    popupCenterClosingFunction,
    isClosable,
  } = useSelector((state) => state.popup);
  const { handlePopupCenterOpen } = usePopup();
  return (
    <PopUpCenter
      ContentComp={componentToShow}
      isOpen={popUpCenterState}
      closeFun={() => {
        handlePopupCenterOpen(false);
        if (popupCenterClosingFunction) {
          popupCenterClosingFunction();
        }
      }}
      isClosable={isClosable}
    />
  );
};

export default PopUpCenterWrapper;
