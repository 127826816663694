import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import useAxiosPrivate from './useAxiosPrivate';
// import useUser from './useUser';

import notify from '@/Utils/notifyToast';
import { navigateToIntegrationLoading } from '@/Utils/helper/navigateToIntegrationLoading';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import { OAuthEnum } from '@/Utils/enums/OAuthEnum';
import { routeEnums } from '@/Utils/enums/routes';
import {
  BINANCE_AUTH_URL,
  COINJAR_AUTH_URL,
  MUDREX_AUTH_URL,
} from '@/Utils/config';
import { integrationMethodsEnum } from '@/Utils/enums/integrationMethods';
import {
  setOAuthIntegrationSyncTransactions,
  setOAuthIntegrationSyncTransactionsError,
  setOAuthIntegrationSyncTransactionsSuccess,
  setOAuthIntegrationTokens,
  setOAuthIntegrationTokensError,
  setOAuthIntegrationTokensSuccess,
} from '@/Redux/slices/oauthIntegrationSlice';
import { oauthIntegrationSyncTransactions } from '@/Services/oauth-integration/oauthIntegrationSyncTransaction';
import { oauthIntegrationTokens } from '@/Services/oauth-integration/oauthIntegrationTokens';

const INVALID_KEYS_ERROR = 'InvalidKeysError';
const integrationToOAuthDataMapping = {
  [OAuthEnum.BINANCE_OAUTH]: {
    syncTransactionErrorSource: 'binance',
    tokensError: 'InvalidBinanceKeys',
    authURL: BINANCE_AUTH_URL,
  },
  [OAuthEnum.COINJAR]: {
    syncTransactionErrorSource: 'coinjar',
    tokensError: 'InvalidCoinJarKeys',
    authURL: COINJAR_AUTH_URL,
  },
  [OAuthEnum.MUDREX]: {
    syncTransactionErrorSource: 'mudrex',
    tokensError: 'InvalidMudrexKeys',
    authURL: MUDREX_AUTH_URL,
  },
};

function useOAuthIntegration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  //   const { handleGetUserSpinWheelResult } = useUser();

  const sourceNameToSourceDataMap = useSelector(
    (state) => state.sources.sourceNameToSourceDataMap
  );

  let attemptedTimes = 0;

  const handleOAuthIntegrationAuth = useCallback((exchangeName) => {
    try {
      const exchangeAuthRedirectURL =
        integrationToOAuthDataMapping[exchangeName].authURL;
      window.location.href = exchangeAuthRedirectURL;
    } catch (error) {
      if (!axios.isCancel(error)) {
        notify(getErrorStatement(error), 'error');
      }
    }
  }, []);

  const handleOAuthIntegrationSyncTransactions = useCallback(
    (exchangeName, walletId, isResync) => {
      attemptedTimes++;
      dispatch(setOAuthIntegrationSyncTransactions());

      const sourceExchangeName =
        integrationToOAuthDataMapping[exchangeName].syncTransactionErrorSource;
      const exchangeSourceData = sourceNameToSourceDataMap[sourceExchangeName];
      const exchangeOAuthSyncEndpoint =
        exchangeSourceData?.endpoints?.[integrationMethodsEnum.OAUTH]?.sync;

      oauthIntegrationSyncTransactions(
        axiosPrivate,
        exchangeOAuthSyncEndpoint,
        walletId
          ? {
              walletId,
              exchangeName: sourceExchangeName,
            }
          : {}
      )
        .then((response) => {
          navigate(
            isResync
              ? routeEnums.INTEGRATION_LOADING_PAGE
              : `${routeEnums.ADD_WALLET_PAGE}?success=true`
          );
          dispatch(
            setOAuthIntegrationSyncTransactionsSuccess(response.data.message)
          );
          // dispatch(
          //   updateSocketStateSuccess({
          //     stateCount: 1,
          //     message: 'Integration data uploaded successfully to the servers',
          //     error: false,
          //   })
          // );
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            const responseError = error?.response?.data?.errors?.[0];

            if (
              attemptedTimes <= 1 &&
              responseError?.name === INVALID_KEYS_ERROR &&
              responseError?.source ===
                integrationToOAuthDataMapping[exchangeName]
                  .syncTransactionErrorSource
            ) {
              return handleOAuthIntegrationAuth();
            }

            const errorMessage = getErrorStatement(error);
            dispatch(setOAuthIntegrationSyncTransactionsError(errorMessage));
            notify(errorMessage, 'error');
          }
        });
    },
    [
      attemptedTimes,
      dispatch,
      sourceNameToSourceDataMap,
      axiosPrivate,
      navigate,
      handleOAuthIntegrationAuth,
    ]
  );

  const handleOAuthIntegrationTokens = useCallback(
    (exchangeName, code) => {
      dispatch(setOAuthIntegrationTokens());

      const exchangeSourceData =
        sourceNameToSourceDataMap[
          integrationToOAuthDataMapping[exchangeName].syncTransactionErrorSource
        ];
      const exchangeOAuthTokensEndpoint =
        exchangeSourceData?.endpoints?.[integrationMethodsEnum.OAUTH]?.tokens;

      oauthIntegrationTokens(axiosPrivate, exchangeOAuthTokensEndpoint, {
        code,
      })
        .then((response) => {
          dispatch(setOAuthIntegrationTokensSuccess(response.data.message));
          navigateToIntegrationLoading(navigate, dispatch);
          // handleGetUserSpinWheelResult();
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            const responseErrorName = error?.response?.data?.errors?.[0]?.name;

            if (
              attemptedTimes <= 1 &&
              responseErrorName ===
                integrationToOAuthDataMapping[exchangeName].tokensError
            ) {
              return handleOAuthIntegrationAuth();
            }

            const errorMessage = getErrorStatement(error);
            dispatch(setOAuthIntegrationTokensError(errorMessage));
            notify(errorMessage, 'error');
          }
        });
    },
    [
      attemptedTimes,
      axiosPrivate,
      dispatch,
      handleOAuthIntegrationAuth,
      navigate,
      sourceNameToSourceDataMap,
    ]
  );

  return {
    handleOAuthIntegrationSyncTransactions,
    handleOAuthIntegrationAuth,
    handleOAuthIntegrationTokens,
  };
}

export default useOAuthIntegration;
