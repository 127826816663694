import { sourceTypesEnum } from '@/Utils/enums/sourceTypes';
import { extractPopularIntegrations } from './extractPopularIntegrations';
import { supportedCountries } from '@/Utils/enums/supportedCountries';
import { extractPartneredIntegrations } from '@/Utils/helper/extractPartneredIntegrations.js';

export const FILTER_TYPES = {
  QUERY: 'query',
  CATEGORY: 'category',
};

export const filterIntegrationsData = ({
  sources,
  filterType,
  filterValue,
  userCountry = supportedCountries.INDIA,
}) => {
  if (!sources) {
    return [];
  }

  sources = [...sources]?.sort((s1, s2) =>
    s1.displayName < s2.displayName ? -1 : 1
  );
  const filerByQuery = (sources, filterValue) => {
    if (!filterValue) return sources;
    return sources.filter(
      (source) =>
        source.name.toLowerCase().includes(filterValue.toLowerCase()) ||
        source.displayName.toLowerCase().includes(filterValue.toLowerCase())
    );
  };

  const filterByCategory = (sources, sourceType) => {
    return sources.filter((source) => source.sourceType === sourceType);
  };

  const getCategoryWiseData = (sources) => {
    return {
      [sourceTypesEnum.PARTNERS]: extractPartneredIntegrations(
        sources,
        userCountry
      ),
      [sourceTypesEnum.POPULAR]: extractPopularIntegrations(
        sources,
        userCountry
      ),
      [sourceTypesEnum.EXCHANGE]: filterByCategory(
        sources,
        sourceTypesEnum.EXCHANGE
      ),
      [sourceTypesEnum.WALLET]: filterByCategory(
        sources,
        sourceTypesEnum.WALLET
      ),
      [sourceTypesEnum.CHAIN]: filterByCategory(sources, sourceTypesEnum.CHAIN),
      [sourceTypesEnum.DEX]: filterByCategory(sources, sourceTypesEnum.DEX),
      // [sourceTypesEnum.NFT]: filterByCategory(sources, sourceTypesEnum.NFT),
    };
  };

  if (filterType === FILTER_TYPES.QUERY) {
    const filteredSources = filerByQuery(sources, filterValue);
    return getCategoryWiseData(filteredSources);
  }

  return getCategoryWiseData(sources);
};
