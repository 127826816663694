import styles from './LeftComponent.module.css';
import { staticContent } from './constants';
const { logo, platformHeading, title, illustration, illustrationAlt, logoAlt } =
  staticContent;

const LeftComponent = () => {
  return (
    <section className={styles.LeftComponentContainer}>
      <div className={styles.LogoContainer}>
        <img className={styles.Logo} src={logo} alt={logoAlt} />
        <span className={styles.PlatformHeading}>{platformHeading}</span>
      </div>
      <div className={styles.MainContentWrapper}>
        <h1 className={styles.Title}>{title}</h1>
        <img
          className={styles.Illustration}
          src={illustration}
          alt={illustrationAlt}
        />
      </div>
    </section>
  );
};

export default LeftComponent;
