import {
  addEditTransactionDrawerStaticContent,
  labels,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/constants';
import styles from './ReceivedSection.module.css';
import SearchComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/SearchComponent';
import TextFieldComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/TextfieldComponent';
import { hideReceivedSection } from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/helper';
import { replaceTemplateString } from '@/Utils/helper/replaceTemplateString';
import { useSelector } from 'react-redux';

const {
  receivedSectionTitle,
  coinsPlaceholder,
  noOfCoins,
  amountInUserCurrency,
  marketPriceInUserCurrency,
} = addEditTransactionDrawerStaticContent;

const ReceivedSection = ({ transaction, handleChange }) => {
  const baseCurrency = useSelector((state) => state.workspace.baseCurrency);

  if (hideReceivedSection(transaction?.type, transaction?.label)) {
    return null;
  }

  return (
    <div
      className={styles.Wrapper}
      data-testid="add-edit-txn-popup-received-section">
      <div>
        <div className={styles.Title}>{receivedSectionTitle}</div>
        <div className={styles.HorizontalLine} />
      </div>
      <div className={styles.Row}>
        <SearchComponent
          label={labels.INCOIN}
          onChange={handleChange}
          value={transaction?.inCoin}
          isCustomOptionCreationAllowed={true}
          data-testid="add-edit-txn-popup-recv-curr-selector"
        />
        <TextFieldComponent
          label={labels.INCOINAMOUNT}
          numeric={true}
          onChange={handleChange}
          placeholder={coinsPlaceholder}
          title={noOfCoins}
          value={transaction?.inCoinAmount}
          data-testid="add-edit-txn-popup-recv-coin-count-input"
        />
      </div>
      <div className={styles.Row}>
        <TextFieldComponent
          label={labels.INCOINMARKETPRICE}
          numeric={true}
          onChange={handleChange}
          placeholder={coinsPlaceholder}
          title={replaceTemplateString(amountInUserCurrency, [baseCurrency])}
          value={transaction?.inCoinMarketPrice}
          data-testid="add-edit-txn-popup-recv-coin-amount-input"
        />
        <TextFieldComponent
          label={labels.NOTIONALINCOINMARKETPRICE}
          numeric={true}
          onChange={handleChange}
          placeholder={coinsPlaceholder}
          title={replaceTemplateString(marketPriceInUserCurrency, [
            baseCurrency,
          ])}
          value={transaction?.notionalInCoinMarketPrice}
          data-testid="add-edit-txn-popup-recv-coin-marketprice-input"
        />
      </div>
    </div>
  );
};

export default ReceivedSection;
