import { Badge, InfoComponent, ArchivedOutline } from '@koinx/xui';
import styles from './ArchivedTable.module.css';
import CustomTable from '@/Components/Routes/TransactionsPage/CustomTable';
import { archiveLablesColorMap } from '@/Utils/archiveLabelsColorMap';
import { useSelector } from 'react-redux';
import { emptyStateContent } from './constants';
import { routeEnums } from '@/Utils/enums/routes';
import { useNavigate } from 'react-router';
import EmptyDataImage from '@/Assets/General/EmptyDataImage.svg';
import { capitalise } from '@/Utils/capitalise';
import { generateAccountBadgeStyle } from '@/Components/Routes/TransactionsPage/TransactionsDetailsDrawer/helper';

const ArchivedTable = () => {
  const navigate = useNavigate();
  const { transactionsSuccessData, isTransactionsLoading } = useSelector(
    (state) => state.transactions
  );

  const customLastColumnBodyRenderer = (value) => {
    if (!value) return;

    const label = value[0];

    return (
      <div data-testid="archived-label-badge" className={styles.BadgeWrapper}>
        <Badge
          className={styles.CategoryBadge}
          style={generateAccountBadgeStyle(label)}>
          {capitalise(label)}
        </Badge>
      </div>
    );
  };

  const handleNavigateToIntegrationsPage = () => {
    navigate(routeEnums.TRANSACTIONS_PAGE);
  };

  // defined here for now because once everything is merged, we might create a global reusable empty state component.
  const EmptyStateInfoComponent = () => {
    return (
      <InfoComponent
        data-testid="infoComponent-archivedTable"
        title={emptyStateContent.title}
        enableButton={true}
        buttonText={emptyStateContent.cta}
        buttonIcon={<ArchivedOutline style={{ fill: 'white' }} />}
        image={EmptyDataImage}
        imageAlt={emptyStateContent.imageAlt}
        buttonClickHandler={handleNavigateToIntegrationsPage}
      />
    );
  };

  return (
    <CustomTable
      tableData={transactionsSuccessData}
      customLastColumnBody={customLastColumnBodyRenderer}
      customLastColumnLabel={'Label'}
      customLastColumnName={'labels'}
      isTableDataLoading={isTransactionsLoading}
      selectableRows={false}
      EmptyStateComponent={EmptyStateInfoComponent}
    />
  );
};

export default ArchivedTable;
