import styles from './Disconnected.module.css';
import { DisconnectedPageData } from './constants';

const content = DisconnectedPageData;
const Disconnected = () => {
  return (
    <div className={styles.Wrapper}>
      <svg
        width="222"
        height="183"
        viewBox="0 0 222 183"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_15965_193071)">
          <circle
            cx="109.5"
            cy="93.5"
            r="56.5"
            transform="rotate(-90 109.5 93.5)"
            fill="#E2ECFB"
          />
          <path
            d="M41.8542 125.115C41.433 126.615 40.4722 127.681 38.9849 128.326C40.4854 128.971 41.433 130.037 41.8542 131.538C42.2623 130.037 43.2231 128.971 44.7236 128.326C43.2231 127.695 42.2623 126.615 41.8542 125.115Z"
            fill="#BCD9FC"
          />
          <path
            d="M211.634 100.028C211.213 101.528 210.252 102.594 208.765 103.239C210.265 103.884 211.213 104.95 211.634 106.451C212.042 104.95 213.003 103.884 214.503 103.239C213.003 102.608 212.042 101.528 211.634 100.028Z"
            fill="#BCD9FC"
          />
          <path
            d="M174.556 75.5063C174.135 77.0068 173.174 78.0729 171.687 78.7179C173.187 79.3628 174.135 80.429 174.556 81.9295C174.964 80.429 175.925 79.3628 177.425 78.7179C175.925 78.0861 174.964 77.0068 174.556 75.5063Z"
            fill="#BCD9FC"
          />
          <path
            d="M87.0666 13.7255C86.6454 15.226 85.6846 16.2922 84.1973 16.9371C85.6978 17.5821 86.6454 18.6482 87.0666 20.1487V20.1882V20.1619C87.4747 18.6614 88.4355 17.5952 89.936 16.9503C88.4355 16.2922 87.4878 15.226 87.0666 13.7255Z"
            fill="#BCD9FC"
          />
          <path
            d="M208.765 57.0658V57C208.765 57.0132 208.765 57.0263 208.751 57.0263C208.751 57.0132 208.751 57 208.738 57V57.0658C207.909 60.0668 206 62.2122 203 63.5021C206 64.792 207.909 66.9375 208.738 69.9385V70.0043C208.738 69.9911 208.738 69.978 208.751 69.978C208.751 69.9911 208.751 70.0043 208.765 70.0043V69.9385C209.594 66.9375 211.502 64.792 214.503 63.5021C211.502 62.2122 209.594 60.0668 208.765 57.0658Z"
            fill="#BCD9FC"
          />
          <path
            d="M66.7572 28.0585V27.9927C66.7572 28.0058 66.7572 28.019 66.7441 28.019C66.7441 28.0058 66.7441 27.9927 66.7309 27.9927V28.0585C65.9017 31.0595 63.9932 33.2049 60.9922 34.4948C63.9932 35.7847 65.9017 37.9302 66.7309 40.9311V40.997C66.7309 40.9838 66.7309 40.9706 66.7441 40.9706C66.7441 40.9838 66.7441 40.997 66.7572 40.997V40.9311C67.5865 37.9302 69.495 35.7847 72.496 34.4948C69.495 33.2049 67.5865 31.0595 66.7572 28.0585Z"
            fill="#BCD9FC"
          />
          <path
            d="M114.718 114.814C114.718 117.298 112.666 119.242 110.29 119.242C107.806 119.242 105.862 117.19 105.862 114.814C105.862 112.33 107.914 110.386 110.29 110.386C112.774 110.278 114.718 112.33 114.718 114.814Z"
            fill="#258BFF"
          />
          <path
            d="M101.326 109.091C106.186 104.015 114.07 103.799 119.146 108.875C121.414 111.143 124.978 107.687 122.71 105.311C115.798 98.3986 104.566 98.3986 97.8697 105.527C95.6017 107.795 99.1657 111.359 101.326 109.091Z"
            fill="#258BFF"
          />
          <path
            d="M86.3138 88.3549C93.0098 82.5229 101.11 78.8509 110.182 78.8509C119.146 78.8509 127.894 81.9829 134.482 88.1389C136.858 90.2989 140.422 86.8429 138.046 84.5749C130.594 77.5549 120.442 73.7749 110.182 73.7749C99.8138 73.7749 90.5258 78.0949 82.8578 84.7909C80.3738 86.9509 83.8298 90.5149 86.3138 88.3549Z"
            fill="#258BFF"
          />
          <path
            d="M92.2539 99.8027C96.7899 94.9427 103.162 91.8108 109.858 91.7028C116.77 91.5948 123.358 94.4028 128.218 99.2627C130.486 101.531 134.05 98.0748 131.782 95.6988C126.166 89.8668 118.066 86.4108 109.966 86.6268C101.974 86.8428 94.3059 90.2988 88.9059 96.1308C86.5299 98.6148 90.0939 102.179 92.2539 99.8027Z"
            fill="#258BFF"
          />
          <path
            d="M150.059 81.1383C151.367 73.7378 146.429 66.6778 139.028 65.3694C131.628 64.0609 124.568 68.9995 123.259 76.4C121.951 83.8005 126.889 90.8605 134.29 92.169C141.69 93.4774 148.75 88.5388 150.059 81.1383Z"
            fill="#768396"
          />
          <path
            d="M142.184 74.9858L140.274 73.0767L131.645 81.7061L133.554 83.6153L142.184 74.9858Z"
            fill="white"
          />
          <path
            d="M133.562 73.1099L131.652 75.019L140.282 83.6485L142.191 81.7393L133.562 73.1099Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_15965_193071">
            <rect
              width="183"
              height="222"
              fill="white"
              transform="translate(0 183) rotate(-90)"
            />
          </clipPath>
        </defs>
      </svg>

      <h1 className={styles.Heading}> {content.heading} </h1>
      <p className={styles.SubHeading}>{content.subHeading}</p>
    </div>
  );
};

export default Disconnected;
