export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL;
export const WALLET_CONNECT_PROJECT_ID =
  process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;

export const PLATFORM_TYPE = 'accounting';

export const BASE_PLATFORM_URL = `${BASE_URL}/${PLATFORM_TYPE}`;
export const AUTH_PLATFORM_URL = `${BASE_PLATFORM_URL}/auth`;

//Auth routes
export const REFRESH_TOKEN_URL = `${AUTH_PLATFORM_URL}/token`;
export const BEGIN_AUTH_URL = `${AUTH_PLATFORM_URL}/begin-auth`;
export const VALIDATE_OTP_URL = `${AUTH_PLATFORM_URL}/validate-auth-otp`;
export const RESEND_OTP_URL = `${AUTH_PLATFORM_URL}/resend-auth-otp`;
export const USER_LOGOUT_URL = `${AUTH_PLATFORM_URL}/signout`;

// Workspace
export const WORKSPACE_URL = `${BASE_PLATFORM_URL}/workspace`;
export const USER_URL = `${WORKSPACE_URL}/user-data`;
export const WORKSPACE_USER_DATA_URL = `${WORKSPACE_URL}/workspace-user-data`;
export const USER_WORKSPACE_LIST_URL = `${WORKSPACE_URL}/list`;
export const USER_WORKSPACE_MEMBERS = `${WORKSPACE_URL}/members`;
export const USER_WORKSPACE_ROLES = `${WORKSPACE_URL}/user-roles`;
export const WORKSPACE_USER_ACCESS = `${WORKSPACE_URL}/user-access`;
export const USER_WORKSPACE_MEMBER_REVOKE_ACCESS = `${WORKSPACE_URL}/revoke-access`;
export const WORKSPACE_SETTINGS=`${WORKSPACE_URL}/settings`;

// Accounting - Invites
export const ACCOUNTING_INVITES_URL = `${BASE_PLATFORM_URL}/invites`;
export const INVITES_LIST_URL = `${ACCOUNTING_INVITES_URL}/workspace`;
export const REVOKE_INVITE_URL = `${ACCOUNTING_INVITES_URL}/:inviteId/revoke`;
export const SEND_INVITE_URL = `${ACCOUNTING_INVITES_URL}/mail`;

//Chart-Of-Accounts
export const CHART_OF_ACCOUNTS_URL = `${BASE_PLATFORM_URL}/accounts`;
export const CHART_OF_ACCOUNTS_LIST_URL = `${CHART_OF_ACCOUNTS_URL}/workspace`; // chart of accounts
export const CHART_OF_ACCOUNTS_DETAILS_URL = `${CHART_OF_ACCOUNTS_URL}/:accountId/details`;
export const CHART_OF_ACCOUNTS_LEDGER_ACCOUNTS_URL = `${CHART_OF_ACCOUNTS_URL}/:accountId/ledgers`;

//Journals
export const JOURNALS_URL = `${BASE_PLATFORM_URL}/journals/`;
export const JOURNALS_DETAILS_URL = `${JOURNALS_URL}details`;
//Journal-Templates
export const JOURNAL_TEMPLATES_URL = `${BASE_PLATFORM_URL}/journal-templates/`;

//Transactions
export const TRANSACTIONS_URL = `${BASE_PLATFORM_URL}/transactions`;
export const EDIT_TRANSACTIONS_URL = `${BASE_PLATFORM_URL}/transactions/:transactionId`;
export const GET_ARCHIVE_LABELS_URL = `${BASE_PLATFORM_URL}/labels`;
export const ARCHIVE_TRANSACTIONS_URL = `${TRANSACTIONS_URL}/archive`;
export const CATEGORIZE_TRANSACTIONS_URL = `${TRANSACTIONS_URL}/categorize`;
export const TRANSACTIONS_COINS_URL = `${TRANSACTIONS_URL}/coins`;
export const TRANSACTIONS_FILTERS_URL = `${TRANSACTIONS_URL}/filters`;

// Sources
export const SOURCES_URL = `${BASE_URL}/sources`;
export const WALLET_URL = `/accounting/wallets`;
export const UPLOAD_USER_TRANSACTIONS_URL = `/accounting/integrations/upload-transactions`;
export const SYNC_USER_TRANSACTIONS_URL = `/integrations/sync-transactions`;
export const GET_ONGOING_INTEGRATION_STATUS = `/integrations/get-ongoing-deep-integration-status`;
export const UPDATE_USER_WALLET_NAME_URL = `${WALLET_URL}/name`;
export const METADATA = `/metadata`;
export const DELETE_WALLET_URL = `${USER_URL}/wallet`;
export const BLOCKCHAIN_INTEGRATION_URL = `${BASE_URL}/accounting/integrations/sync-wallet`;

//Integrations
export const BITBNS_AUTH_URL =
  'https://oauth.bitbns.com/oauth/dialog/authorize?redirect_uri=';

//top 1000 coins
export const TOP_1000_COINS_URL = `${BASE_URL}/free-tools/get-top-1000-coins`;
