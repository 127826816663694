import { DateRangePicker } from '@koinx/xui';
import styles from '@/Components/Routes/ChartOfAccounts/Details/Details.module.css';
import { DetailsData } from '@/Components/Routes/ChartOfAccounts/Details/constants';
import AccountDetails from '@/Components/Routes/ChartOfAccounts/Details/components/AccountDetails';
import { useSelector } from 'react-redux';
import useChartOfAccounts from '@/Hooks/useChartOfAccounts';
import { useParams } from 'react-router';
import { useState } from 'react';

const TransactionDetailsSection = () => {
  const { accountId } = useParams();

  const {
    isLedgerAccountsLoading,
    ledgersData,
    ledgersTableCursor,
    ledgersTableRowsPerPage,
  } = useSelector((state) => state.chartOfAccounts);
  const { handleFetchLedgerAccounts } = useChartOfAccounts();

  const [dateRange, setDateRange] = useState({
    from: '',
    to: '',
  });

  const handleDateRangeChange = (from, to) => {
    const baseParams = {
      accountId,
      cursor: ledgersTableCursor,
      limit: ledgersTableRowsPerPage,
    };

    setDateRange({ from: from || '', to: to || '' });

    handleFetchLedgerAccounts({
      ...baseParams,
      ...(from && to && {
        fromTimestamp: new Date(from).toISOString(),
        toTimestamp: new Date(to).toISOString(),
      }),
    });
  };

  return (
    <div className={styles.MainBottomWrapper}>
      <div className={styles.BottomWrapper}>
        <h2
          data-testid="transactionDetailsSection-title"
          className={styles.TopTitle}>
          {DetailsData.transactionsSection.title}
        </h2>
        <DateRangePicker
          data-testid="transactionDetailsSection-dateRange"
          className={styles.DatePicker}
          onSelect={handleDateRangeChange}
          value={{
            from: dateRange.from,
            to: dateRange.to,
          }}
        />
      </div>
      <div className={styles.TransactionDetails}>
        {ledgersData &&
          DetailsData.transactionsSection.accountDetails.map(
            ({ label, dataTestId, isAmount, key, coinKey }) => (
              <AccountDetails
                dataTestId={dataTestId}
                key={key}
                label={label}
                isDataLoading={isLedgerAccountsLoading}
                isAmount={isAmount}
                value={ledgersData[key]}
                coin={ledgersData.coin ? ledgersData.coin : null}
                coinAmount={ledgersData.coin ? ledgersData[coinKey] : null}
              />
            )
          )}
      </div>
    </div>
  );
};

export default TransactionDetailsSection;
