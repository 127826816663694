import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  message: null,
  error: null,
};

const bitBnsSlice = createSlice({
  name: 'bitBns',
  initialState,
  reducers: {
    setBitbnsTokens: (state) => {
      state.loading = true;
    },
    setBitbnsTokensSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    setBitbnsTokensError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setBitbnsSyncTransactions: (state) => {
      state.loading = true;
    },
    setBitbnsSyncTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    setBitbnsSyncTransactionsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setBitbnsTokens,
  setBitbnsTokensSuccess,
  setBitbnsTokensError,
  setBitbnsSyncTransactions,
  setBitbnsSyncTransactionsError,
  setBitbnsSyncTransactionsSuccess,
} = bitBnsSlice.actions;

export default bitBnsSlice.reducer;
