import { TabTypesEnum } from '@/Utils/enums/tabTypes';
import { TransactionStatusTypes } from '@/Utils/enums/TransactionStatusTypes';
import { AddIntegration, Categorise, ArchivedOutline } from '@koinx/xui';

export const getEmptyStateContent = (tabFromParams) => {
  switch (tabFromParams) {
    case TabTypesEnum.UNCATEGORIZED:
      return {
        ctaText: 'Add an Integration',
        icon: AddIntegration,
      };

    case TabTypesEnum.CATEGORIZED:
      return {
        ctaText: 'Categorize Transactions',
        icon: Categorise,
      };
    case TabTypesEnum.ARCHIVED:
      return {
        ctaText: 'Archive Transactions',
        icon: ArchivedOutline,
      };
    default:
      break;
  }
};

export const getStatusFromTab = (tab) => {
  if (!tab) {
    return TransactionStatusTypes.PROCESSED;
  } else if (tab === TabTypesEnum.UNCATEGORIZED) {
    return TransactionStatusTypes.PROCESSED;
  } else return tab;
};

/**
 *
 * @param {object} selectedRowsObject the rows object supplied by tanstack table. it is in the form
 * `{6720aa44b6735c00071d1d7e: true, ..., ..., },`
 * @returns {object[]} array of transaction data objects that were selected.
 *
 */
export const getTxnsObjectsById = (selectedRowsObject) => {
  const selectedRowIds = Object.keys(selectedRowsObject);
  const cachedTxnsObject = JSON.parse(sessionStorage.getItem('cachedTxns'));
  const result = [];
  for (let i = 0; i < selectedRowIds.length; i++) {
    if (cachedTxnsObject[selectedRowIds[i]]) {
      result.push(cachedTxnsObject[selectedRowIds[i]]);
    }
  }
  return result;
};
