import styles from './FeesSection.module.css';
import {
  addEditTransactionDrawerStaticContent,
  labels,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/constants';
import SearchComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/SearchComponent';
import TextFieldComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/TextfieldComponent';

const { coinsPlaceholder, noOfCoins, feeTitle } =
  addEditTransactionDrawerStaticContent;

const FeesSection = ({ transaction, handleChange }) => {
  return (
    <div className={styles.Wrapper}>
      <div>
        <p className={styles.Title}>{feeTitle}</p>
        <div className={styles.HorizontalLine}></div>
      </div>
      <div className={styles.Row}>
        <SearchComponent
          value={transaction?.feeCoin}
          onChange={handleChange}
          label={labels.FEECOIN}
          isCustomOptionCreationAllowed={true}
          data-testid="add-edit-txn-popup-fee-curr-selector"
        />
        <TextFieldComponent
          title={noOfCoins}
          placeholder={coinsPlaceholder}
          onChange={handleChange}
          value={transaction?.feeCoinAmount}
          label={labels.FEECOINAMOUNT}
          numeric={true}
          data-testid="add-edit-txn-popup-fee-coins-count-input"
        />
      </div>
    </div>
  );
};

export default FeesSection;
