import {
  Button,
  Add,
  InfoComponent,
  AddIntegration,
  ToolTip,
} from '@koinx/xui';
import styles from './UnrecognizedTable.module.css';
import CustomTable from '@/Components/Routes/TransactionsPage/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTableRows,
  setIsCategorisationAllowed,
} from '@/Redux/slices/transactionSlice';
import { useEffect, useState } from 'react';
import CategoriseTransactionsDrawer from '@/Components/Routes/TransactionsPage/CategoriseTransactionsDrawer';
import EmptyDataImage from '@/Assets/General/EmptyDataImage.svg';
import { emptyStateContent } from './constants';
import { routeEnums } from '@/Utils/enums/routes';
import { permissionsCategoryEnums } from '@/Utils/enums/permissionsCategory';
import { permissionsEnums } from '@/Utils/enums/permissions';
import { useUserHasPermission } from '@/Hooks/useUserHasPermission';
import { useNavigate } from 'react-router';
import { validateMultipleTransactions } from './helpers';

const UnrecognizedTable = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const dispatch = useDispatch();
  const {
    transactionsSuccessData,
    isTransactionsLoading,
    selectedTableRows,
    isCategorisationAllowed,
  } = useSelector((state) => state.transactions);

  const userCanArchiveAndCategorizeTransactions = useUserHasPermission(
    permissionsCategoryEnums.TRANSACTIONS,
    permissionsEnums.UPDATE
  );

  useEffect(() => {
    if (selectedTableRows.length > 1) {
      const isAllowed = validateMultipleTransactions(selectedTableRows, 'INR'); // TODO: use currency from api after integrations.
      dispatch(setIsCategorisationAllowed(isAllowed));
    } else {
      dispatch(setIsCategorisationAllowed(true));
    }
  }, [dispatch, selectedTableRows]);

  const customLastColumnBodyRenderer = (value, index) => {
    if (!isCategorisationAllowed && selectedTableRows.length > 0) {
      return (
        <ToolTip
          content={
            <p style={{ textWrap: 'wrap' }}>{emptyStateContent.tooltip}</p>
          }
          side="left">
          <div>
            <Button
              disabled={true}
              data-testid="unrecognizedTable-addCategory-button"
              onClick={(e) => {
                e.stopPropagation();
                if (transactionsSuccessData.length > index)
                  setSelectedRowData(transactionsSuccessData[index]);
                setIsDrawerOpen(true);
              }}
              icon={
                <Add
                  className={
                    isCategorisationAllowed ? styles.CategoryButtonIcon : ''
                  }
                />
              }
              buttonType="icon_right"
              variant="ghost"
              className={styles.CategoryButton}>
              Add
            </Button>
          </div>
        </ToolTip>
      );
    } else if (isCategorisationAllowed && selectedTableRows.length > 0) {
      return (
        <Button
          disabled={true}
          data-testid="unrecognizedTable-addCategory-button"
          onClick={(e) => {
            e.stopPropagation();
            if (transactionsSuccessData.length > index)
              setSelectedRowData(transactionsSuccessData[index]);
            setIsDrawerOpen(true);
          }}
          icon={<Add />}
          buttonType="icon_right"
          variant="ghost"
          className={styles.CategoryButton}>
          Add
        </Button>
      );
    } else
      return (
        <Button
          data-testid="unrecognizedTable-addCategory-button"
          onClick={(e) => {
            e.stopPropagation();
            if (transactionsSuccessData.length > index)
              setSelectedRowData(transactionsSuccessData[index]);
            setIsDrawerOpen(true);
          }}
          icon={<Add className={styles.CategoryButtonIcon} />}
          buttonType="icon_right"
          variant="ghost"
          className={styles.CategoryButton}>
          Add
        </Button>
      );
  };

  const handleNavigateToIntegrationsPage = () => {
    navigate(routeEnums.ADD_INTEGRATION_PAGE);
  };

  const EmptyStateInfoComponent = () => {
    return (
      <InfoComponent
        data-testid="infoComponent-uncategorizedTable"
        title={emptyStateContent.title}
        enableButton={true}
        buttonText={emptyStateContent.cta}
        buttonIcon={<AddIntegration style={{ fill: 'white' }} />}
        image={EmptyDataImage}
        imageAlt={emptyStateContent.imageAlt}
        buttonClickHandler={handleNavigateToIntegrationsPage}
      />
    );
  };

  return (
    <>
      <CustomTable
        tableData={transactionsSuccessData}
        customLastColumnBody={customLastColumnBodyRenderer}
        tableName={'unrecognizedTable'}
        isTableDataLoading={isTransactionsLoading}
        selectableRows={userCanArchiveAndCategorizeTransactions ? true : false}
        EmptyStateComponent={EmptyStateInfoComponent}
      />
      {isDrawerOpen && (
        <CategoriseTransactionsDrawer
          isOpen={isDrawerOpen}
          rowData={selectedRowData}
          handleClose={() => setIsDrawerOpen(false)}
        />
      )}
    </>
  );
};

export default UnrecognizedTable;
