import { transactionLabelsEnum, transactionTypesEnum } from '@koinx/utils';
export const searchComponentStaticData = {
  currencyTitle: 'Currency',
  placeholder: 'Select a Coin',
};

function mapToKeyValue(obj) {
  const keyValuePairs = {};
  Object.keys(obj)
    .map((key) => key)
    .forEach((path) => {
      const splittedPath = path.split('/');
      const filename = splittedPath.pop().split('.')[0]; // Extract filename without extension
      const remainingPath = splittedPath.slice(2).join('/');

      //why not directly use path? https://github.com/vitejs/vite/issues/14581
      keyValuePairs[filename] = new URL(
        `../Assets/${remainingPath}/${filename}.svg`,
        import.meta.url
      ).href;
    });
  return keyValuePairs;
}

export const transactionTypeAssets = mapToKeyValue(
  import.meta.glob(
    '../Assets/TransactionPage/TradeIcons/*.{png,jpeg,jpg,svg}',
    { query: '?raw', import: 'default' }
  )
);

export const dateAndTimeStaticData = {
  timeTitle: 'Transaction Date and Time',
  textFieldStyles: {
    '&': {
      width: '100%',
    },

    '& .MuiInputLabel-root': {
      color: 'var(--sec-black)',
    },

    '& .MuiOutlinedInput-input': {
      fontSize: '1.4rem',
    },

    [`@media (max-width: 425px)`]: {
      '& .MuiOutlinedInput-input': {
        fontSize: '1.4rem',
      },
    },
  },
};

export const tdsSectionStaticData = {
  tdstitle: 'TDS',
};

export const labels = {
  TIMESTAMP: 'timestamp',
  TDSCOIN: 'tdsCoin',
  TDS: 'tds',
  DESCRIPTION: 'description',
  FEECOIN: 'feeCoin',
  FEECOINAMOUNT: 'feeCoinAmount',
  TYPE: 'type',
  LABEL: 'label',
  WALLETID: 'walletId',
  SOURCEID: 'sourceId',
  INCOIN: 'inCoin',
  INCOINAMOUNT: 'inCoinAmount',
  INCOINMARKETPRICE: 'inCoinMarketPrice',
  NOTIONALINCOINMARKETPRICE: 'notionalInCoinMarketPrice',
  OUTCOIN: 'outCoin',
  OUTCOINAMOUNT: 'outCoinAmount',
  TOADDRESS: 'toAddress',
  FROMADDRESS: 'fromAddress',
  TXNHASH: 'txnHash',
  TIMEZONE: 'timezone',
};

export const addEditTransactionDrawerStaticContent = {
  noOfCoins: 'No.of Coins',
  coinsPlaceholder: '0.0',
  transaction: 'Transaction',
  saveText: 'Save',
  transactionType: 'Transaction Type',
  transactionLabel: 'Transaction Label',
  chooseWallet: 'Choose a Wallet',
  selectNewWallet: 'Select a new wallet',
  receivedSectionTitle: 'Received',
  amountInUserCurrency: `Amount in {0}`,
  marketPriceInUserCurrency: `Market Price in {0}`,
  sent: 'Sent',
  feeTitle: 'Fees %',
  fromTitle: 'From',
  toTitle: 'To',
  hashTitle: 'Transaction Hash',
  descriptionPlaceholder: 'Enter a description',
  descriptionTitle: 'Description',
};

export const ADD_NEW_WALLET = 'Add New Wallet';

export const externalTypeToExternalLabelMapping = {
  [transactionTypesEnum.TRADE]: [
    transactionLabelsEnum.BUY,
    transactionLabelsEnum.SELL,
    transactionLabelsEnum.SWAP,
  ],
  [transactionTypesEnum.MARGIN_TRADE]: [
    transactionLabelsEnum.BUY,
    transactionLabelsEnum.SELL,
  ],
  [transactionTypesEnum.FUTURES_TRADE]: [
    transactionLabelsEnum.BUY,
    transactionLabelsEnum.SELL,
  ],
  [transactionTypesEnum.DEPOSIT]: [
    transactionLabelsEnum.EXTERNAL_DEPOSIT,
    transactionLabelsEnum.BORROW,
    transactionLabelsEnum.LEND_SETTLEMENT,
    transactionLabelsEnum.FIXED_DEPOSIT_UNLOCK,
    transactionLabelsEnum.RECEIVE_FROM_POOL,
    transactionLabelsEnum.REWARD,
    transactionLabelsEnum.REFUND,
    transactionLabelsEnum.STAKING_INTEREST,
    transactionLabelsEnum.AIRDROP,
    transactionLabelsEnum.RECEIVE_LOAN,
    transactionLabelsEnum.COLLATERAL_UNLOCK,
    transactionLabelsEnum.FUNDING_INTEREST,
    transactionLabelsEnum.FUTURES_REALISED_PROFIT,
    transactionLabelsEnum.OPTIONS_REALISED_PROFIT,
    transactionLabelsEnum.SALARY,
    transactionLabelsEnum.CONSULTANCY_INCOME,
    transactionLabelsEnum.MINING_INCOME,
  ],
  [transactionTypesEnum.WITHDRAWAL]: [
    transactionLabelsEnum.EXTERNAL_WITHDRAWAL,
    transactionLabelsEnum.LEND,
    transactionLabelsEnum.BORROW_SETTLEMENT,
    transactionLabelsEnum.FIXED_DEPOSIT_LOCK,
    transactionLabelsEnum.SEND_TO_POOL,
    transactionLabelsEnum.REPAY_LOAN,
    transactionLabelsEnum.COLLATERAL_LOCK,
    transactionLabelsEnum.FUNDING_FEE,
    transactionLabelsEnum.FUTURES_REALISED_LOSS,
    transactionLabelsEnum.OPTIONS_REALISED_LOSS,
    transactionLabelsEnum.DONATION,
  ],
  [transactionTypesEnum.EXPENSE]: [
    transactionLabelsEnum.FUTURES_FEE,
    transactionLabelsEnum.OPTIONS_FEE,
    transactionLabelsEnum.BROKERAGE_OR_GAS_FEE,
    transactionLabelsEnum.MARGIN_INTEREST_PAYMENT,
    transactionLabelsEnum.MINING_EXPENSE,
    transactionLabelsEnum.CONSULTANCY_EXPENSE,
  ],
};

export const externalTypeToLogoMapping = {
  [transactionTypesEnum.TRADE]: transactionTypeAssets['TradeIcon'],
  [transactionTypesEnum.MARGIN_TRADE]: transactionTypeAssets['TradeIcon'],
  [transactionTypesEnum.FUTURES_TRADE]: transactionTypeAssets['TradeIcon'],
  [transactionTypesEnum.DEPOSIT]: transactionTypeAssets['DepositIcon'],
  [transactionTypesEnum.WITHDRAWAL]: transactionTypeAssets['TransferIcon'],
  [transactionTypesEnum.EXPENSE]: transactionTypeAssets['WithdrawIcon'],
};

export const initialState = {
  type: transactionTypesEnum.TRADE,
  label: externalTypeToExternalLabelMapping[transactionTypesEnum.TRADE][0],
  timestamp: new Date(),
  walletId: '',
  sourceId: '',
  inCoin: '',
  inCoinMarketPrice: null,
  notionalInCoinMarketPrice: null,
  outCoin: '',
  inCoinAmount: null,
  outCoinAmount: null,
  feeCoin: '',
  feeCoinAmount: null,
  tdsCoin: '',
  tds: null,
  description: '',
  txnHash: '',
  fromAddress: '',
  toAddress: '',
  timezone: '',
};

export const numberDataTypes = new Set([
  labels.TIMESTAMP,
  labels.INCOINMARKETPRICE,
  labels.NOTIONALINCOINMARKETPRICE,
  labels.INCOINAMOUNT,
  labels.OUTCOINAMOUNT,
  labels.FEECOINAMOUNT,
  labels.TDS,
]);
