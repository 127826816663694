import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { Text } from '@koinx/xui';
import styles from '@/Components/Routes/TransactionsPage/CustomTable/CustomTable.module.css';

const TableHeader = ({ headerGroups }) => (
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => {
          return (
            <th key={header.id} className={styles.TableHeaderCell}>
              <Text
                style={{
                  textAlign: header.column.columnDef.align || 'start',
                }}
                variant="subtitle-2"
                textColor="gray-11">
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </Text>
            </th>
          );
        })}
      </tr>
    ))}
  </thead>
);

export default TableHeader;
