export const staticContent = {
  advancedTab: {
    tabHeading: 'Advanced',
    inputLabel: 'Import Transactions from',
  },

  button: 'Import Now',

  tooltipContent:
    'Transactions from this date forward will be included for analysis and tax calculations.',
};
