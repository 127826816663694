import { Tabs } from '@koinx/xui';
import styles from './TabsComponent.module.css';
import { useSearchParams } from 'react-router-dom';
import { TabTypesEnum } from '@/Utils/enums/tabTypes';
import { useDispatch, useSelector } from 'react-redux';
import useTransaction from '@/Hooks/useTransaction';
import { setTotalTransactionsCount } from '@/Redux/slices/transactionSlice';

const TabsComponent = ({ tabsArray, setSelectedTab }) => {
  const { transactionsSuccessData, isTransactionsLoading } = useSelector(
    (state) => state.transactions
  );

  const { handleSetTransactionsPageCursorAndRowPerPage } = useTransaction();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (tab) => {
    setSelectedTab(tab);
    setSearchParams({ tab: tab });
    handleSetTransactionsPageCursorAndRowPerPage({
      cursor: 0,
      rowsPerPage: 10,
    });
    // without this if condition, if you click on the tab, it will reset the count to 0
    if (transactionsSuccessData.length > 0 && tab !== searchParams.get('tab')) {
      dispatch(setTotalTransactionsCount(0));
    }
  };

  return (
    <Tabs
      data-testid="tabs"
      disabled={isTransactionsLoading}
      className={styles.Tabs}
      value={searchParams.get('tab') || TabTypesEnum.UNCATEGORIZED}
      onChange={handleChange}
      options={tabsArray}
    />
  );
};

export default TabsComponent;
