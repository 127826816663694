import { useSelector } from 'react-redux';
import styles from './SyncAllPopup.module.css';
import { Avatar, Close } from '@koinx/xui';
import { useMemo } from 'react';
import usePopup from '@/Hooks/usePopup';
import { extractSyncableWallets } from '@/Components/Routes/DataSources/UserIntegrations/helper';
import { syncAllPopupData } from '@/Components/Routes/DataSources/UserIntegrations/constants';
import SyncTransactionsButton, {
  syncTransactionButtonVariants,
} from '@/Components/Routes/DataSources/UserIntegrations/Components/SyncTransactionsButton';

const { description, note, title } = syncAllPopupData;

const SyncAllPopup = () => {
  const { handlePopupCenterComponentRender, handlePopupCenterOpen } =
    usePopup();
  const { wallets: walletData, walletToNamesMap } = useSelector(
    (state) => state.wallet
  );

  const handleClose = () => {
    handlePopupCenterOpen(false);
    handlePopupCenterComponentRender(null);
  };

  const syncableWallets = useMemo(
    () => extractSyncableWallets(walletData, walletToNamesMap),
    [walletData, walletToNamesMap]
  );

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <h1 className={styles.Title}>{title}</h1>
        <Close className={styles.Icon} onClick={handleClose} />
      </div>
      <p className={styles.Description}>{description}</p>
      <div className={styles.WalletsContainer}>
        {syncableWallets.length > 0 &&
          syncableWallets.map((item) => (
            <div key={item.name} className={styles.WalletRow}>
              <Avatar
                imageSrc={item.logo}
                fallbackContent={item.name.charAt(0)}
                className={styles.Avatar}
              />
              <h2 className={styles.WalletName}>{item.name}</h2>
            </div>
          ))}
      </div>
      <p className={styles.Note}>{note}</p>
      <SyncTransactionsButton
        variant={syncTransactionButtonVariants.SYNC_ALL}
      />
    </div>
  );
};

export default SyncAllPopup;
