import { ContentCopy, Button, Check, Avatar, ToolTip } from '@koinx/xui';
import styles from './TransactionDetail.module.css';
import { useState, useEffect } from 'react';
import { shortenString } from '@/Utils/helper/shortenString';
import AmountComponent from '@/Components/Ui/AmountComponent';

const TransactionDetail = ({
  currencyAmount,
  cryptoAmount,
  label,
  stringValue,
  Icon,
  fontClassName,
  iconFillColor,
  isAddressFromTable,
  valueToCopy,
  image,
  avatar,
  link,
  download = false,
  avatarClass = '',
  avatarTooltipContent = '',
}) => {
  const [copiedValue, setCopiedValue] = useState(null);
  const handleCopyValue = (e, value) => {
    e.stopPropagation();
    if (value) {
      navigator.clipboard.writeText(`${value}`);
      setCopiedValue(value);
    }
  };

  useEffect(() => {
    let timeout;
    if (copiedValue) {
      timeout = setTimeout(() => {
        setCopiedValue(null);
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [copiedValue]);

  return (
    <div className={styles.DetailContainer}>
      {label && (
        <p
          data-testid="transactionDetails-transactionLabel"
          className={styles.Label}>
          {label}
        </p>
      )}
      <div className={styles.ValueWrapper}>
        {Icon && (
          <Icon
            data-testid="transactionDetails-typeIcon"
            style={{ fill: `${iconFillColor}` }}
          />
        )}
        {avatar && avatarTooltipContent && (
          <ToolTip content={avatarTooltipContent}>
            <Avatar
              fallbackContent={avatarTooltipContent}
              imageSrc={image}
              className={avatarClass ? avatarClass : styles.DefaultAvatarStyles}
            />
          </ToolTip>
        )}
        {avatar && !avatarTooltipContent && (
          <Avatar
            imageSrc={image}
            className={avatarClass ? avatarClass : styles.DefaultAvatarStyles}
          />
        )}
        <div className={styles.IconOrAvatarWithValueWrapper}>
          {currencyAmount && (
            <AmountComponent
              dataTestId="transactionDetails-currencyAmount"
              value={currencyAmount}
              isPositiveNegativeSign={Number(currencyAmount) < 0}
              weight="normal"
            />
          )}
          {cryptoAmount && (
            <span
              data-testid="transactionDetails-cryptoAmount"
              className={styles.CryptoAmount}>
              ({cryptoAmount})
            </span>
          )}
          {!link && stringValue && (
            <span
              data-testid="transactionDetails-stringValue"
              className={fontClassName ? fontClassName : styles.StringValue}>
              {valueToCopy ? shortenString(stringValue) : stringValue}
            </span>
          )}
          {link && (
            <a download={download} className={styles.Link} href={'#'}>
              {/* TODO: replace # in href with link when link is available in data */}
              {stringValue}
            </a>
          )}
          {valueToCopy && (
            <Button
              data-testid="transactionDetails-copyButton"
              className={
                isAddressFromTable ? styles.TableCopyButton : styles.CopyButton
              }
              onClick={(e) => handleCopyValue(e, valueToCopy)}
              variant={'just_icon'}
              icon={
                copiedValue ? (
                  <Check
                    className={
                      isAddressFromTable
                        ? styles.TableCopyIcon
                        : styles.CopyIcon
                    }
                  />
                ) : (
                  <ContentCopy
                    className={
                      isAddressFromTable
                        ? styles.TableCopyIcon
                        : styles.CopyIcon
                    }
                  />
                )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionDetail;
