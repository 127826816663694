import { Badge, Text } from '@koinx/xui';
import styles from '@/Components/Routes/TransactionsPage/TransactionsDetailsDrawer/TransactionDetailsDrawer.module.css';
import { Divider } from '@mui/material';
import TransactionDetail from '@/Components/Routes/TransactionsPage/TransactionDetail/TransactionDetail';
import {
  getMainTransactionAmount,
  getMainTransactionLabel,
} from '@/Components/Routes/TransactionsPage/CategoriseTransactionsDrawer/helper';
import { generateAccountBadgeStyle } from '@/Components/Routes/TransactionsPage/TransactionsDetailsDrawer/helper';
import { transactionDetailsFields } from '@/Components/Routes/TransactionsPage/TransactionsDetailsDrawer/constants';

const CategorizedTransactionDetails = ({ rowData }) => {
  const renderSection = (title, content) => (
    <>
      <div className={styles.SectionWrapper}>
        {title && (
          <Text variant="heading-4" textColor="gray-12">
            {title}
          </Text>
        )}
        <div className={styles.DetailWrapper}>{content}</div>
      </div>
      <Divider />
    </>
  );

  const renderAccountBadge = (accountName) => (
    <div className={styles.BadgeWrapper}>
      <Text variant="label-1">{transactionDetailsFields.accountlable}</Text>
      <Badge
        className={styles.AccountBadge}
        style={generateAccountBadgeStyle(accountName)}>
        {accountName}
      </Badge>
    </div>
  );

  return (
    <>
      {rowData.accounting?.associatedAccounts?.mainTransaction?.length > 0 &&
        renderSection(
          null,
          <>
            {rowData.receivedValue > 0 && rowData.inCoin && (
              <TransactionDetail
                label={getMainTransactionLabel(rowData, false)}
                cryptoAmount={`${rowData.inCoinAmount} ${rowData.inCoin}`}
                currencyAmount={rowData.receivedValue}
              />
            )}
            {rowData.outCoinAmount > 0 && rowData.outCoin && (
              <TransactionDetail
                label={getMainTransactionLabel(rowData, false)}
                cryptoAmount={`${rowData.outCoinAmount} ${rowData.outCoin}`}
                currencyAmount={getMainTransactionAmount(rowData)}
              />
            )}
            {renderAccountBadge(
              rowData.accounting?.associatedAccounts?.mainTransaction[0]?.name
            )}
          </>
        )}

      {rowData.accounting?.associatedAccounts?.gains?.length > 0 &&
        renderSection(
          transactionDetailsFields.gainsTitle,
          <>
            {rowData.gain !== 0 && (
              <TransactionDetail
                label={transactionDetailsFields.gainsTitle}
                currencyAmount={rowData?.gain}
              />
            )}
            {renderAccountBadge(
              rowData.accounting?.associatedAccounts?.gains[0]?.name
            )}
          </>
        )}

      {rowData.accounting?.associatedAccounts?.fee?.length > 0 &&
        renderSection(
          transactionDetailsFields.feesTitle,
          <>
            {rowData.feeCoinAmount > 0 &&
              rowData.fees > 0 &&
              rowData.feeCoin && (
                <TransactionDetail
                  label={transactionDetailsFields.gainsTitle}
                  cryptoAmount={`${rowData.feeCoinAmount} ${rowData.feeCoin}`}
                  currencyAmount={rowData.fees}
                />
              )}
            {renderAccountBadge(
              rowData.accounting?.associatedAccounts?.fee[0]?.name
            )}
          </>
        )}

      {rowData.accounting?.associatedAccounts?.tds?.length > 0 &&
        renderSection(
          transactionDetailsFields.tdsTitle,
          <>
            {rowData.tdsValue > 0 && rowData.tds > 0 && rowData.tdsCoin && (
              <TransactionDetail
                label={transactionDetailsFields.tdsTitle}
                cryptoAmount={`${rowData.tdsValue} ${rowData.tdsCoin}`}
                currencyAmount={rowData.tds}
              />
            )}
            {renderAccountBadge(
              rowData.accounting?.associatedAccounts?.tds[0]?.name
            )}
          </>
        )}
    </>
  );
};

export default CategorizedTransactionDetails;
