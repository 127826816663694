import AmountGroup from '@/Components/Ui/AmountGroup';
import BlankCellValue from '@/Components/Ui/BlankCellValue/BlankCellValue';
import { TransactionNatureTypes } from '@/Utils/enums/TransactionNatureType';
import dateFormatter from '@/Utils/helper/formatDate';

export const getRows = (ledgerAccounts) => {
  if (ledgerAccounts && ledgerAccounts?.ledgers.length > 0) {
    const rows = ledgerAccounts?.ledgers?.map((item) => {
      const row = {
        timestamp: dateFormatter(item.timestamp, true),
        accountName: ledgerAccounts?.accountName,

        description: item.description ? (
          item.description
        ) : (
          <BlankCellValue align="left" />
        ),
        runningBalance:
          item.runningBalance && ledgerAccounts.coin ? (
            <AmountGroup
              price={item.runningBalance}
              cryptoAmount={item.coinAmountRunningBalance}
              coin={ledgerAccounts.coin}
            />
          ) : item.runningBalance ? (
            <AmountGroup price={item.runningBalance} />
          ) : (
            <BlankCellValue align="right" />
          ),
        runningDebits:
          item.amount &&
          item.transactionNature === TransactionNatureTypes.DEBIT &&
          ledgerAccounts.coin ? (
            <AmountGroup
              price={item.amount}
              cryptoAmount={item.coinAmount}
              coin={ledgerAccounts.coin}
            />
          ) : item.amount &&
            item.transactionNature === TransactionNatureTypes.DEBIT ? (
            <AmountGroup price={item.amount} />
          ) : (
            <BlankCellValue align="right" />
          ),
        runningCredits:
          item.amount &&
          item.transactionNature === TransactionNatureTypes.CREDIT &&
          ledgerAccounts.coin ? (
            <AmountGroup
              price={item.amount}
              cryptoAmount={item.coinAmount}
              coin={ledgerAccounts.coin}
            />
          ) : item.amount &&
            item.transactionNature === TransactionNatureTypes.CREDIT ? (
            <AmountGroup price={item.amount} />
          ) : (
            <BlankCellValue align="right" />
          ),
      };
      return row;
    });
    return rows;
  } else return [];
};
