export const staticContent = {
  heading: 'Sign in',
  input: {
    name: 'email',
    label: 'Email Address',
    placeholder: 'john.doe@gmail.com',
  },
  button: {
    default: 'Proceed',
    loading: 'Please wait',
  },

  notifyText: {
    invalidRecaptcha: 'Invalid ReCaptcha Token',
    invalidEmail: 'Invalid Email',
  },
};
