import { transactionFilterKeys } from './constants';
import { getCryptoCoinDetails } from '@/Utils/helper/getCryptoCoinDetails';
import {
  transactionLabelsToIconMapping,
  transactionTypesToIconMapping,
  truncateString,
} from '@koinx/utils';

const createWalletFilterOptions = (walletFilter, walletToNamesMap) => {
  return walletFilter.map((wallet) => ({
    label:
      wallet?.customWalletName ||
      wallet?.name.charAt(0).toUpperCase() + wallet?.name.slice(1),
    value: wallet?._id,
    itemLogo: walletToNamesMap[wallet?._id]?.logo,
  }));
};

const createCoinFilterOptions = (coinFilter, userTransactionCoins) => {
  return coinFilter.reduce((coinOptions, coin) => {
    if (coin) {
      let coinName = coin;
      if (coinName?.includes('@')) {
        coinName = coinName.split('@')[0] + ' (' + coinName.split('@')[1];
        coinName = truncateString(coinName, 30) + ')';
      }
      coinOptions.push({
        label: coinName,
        value: coin,
        itemLogo: getCryptoCoinDetails(coin, null, userTransactionCoins).image,
      });
    }
    return coinOptions;
  }, []);
};

const createTypeFilterOptions = (typeFilter) => {
  return typeFilter.map((type) => {
    const TypeIcon = transactionTypesToIconMapping[type];
    return {
      label: type,
      value: type,
      itemLogo: <TypeIcon fill="var(--sec-blue)" />,
    };
  });
};

const createLabelFilterOptions = (labelFilter) => {
  return labelFilter.map((label) => {
    const LabelIcon = transactionLabelsToIconMapping[label];
    return {
      label: label,
      value: label,
      itemLogo: <LabelIcon fill="var(--sec-blue)" />,
    };
  });
};

export const createDynamicFilterOptions = (
  dynamicFilters,
  walletToNamesMap,
  userTransactionCoins
) => {
  const dynamicFilterOptions = {};
  for (const key in dynamicFilters) {
    const dynamicFilter = dynamicFilters[key];
    let generatedOptions;

    if (key === transactionFilterKeys.WALLETS)
      generatedOptions = createWalletFilterOptions(
        dynamicFilter,
        walletToNamesMap
      );
    else if (key === transactionFilterKeys.COINS)
      generatedOptions = createCoinFilterOptions(
        dynamicFilter,
        userTransactionCoins
      );
    else if (key === transactionFilterKeys.LABELS)
      generatedOptions = createLabelFilterOptions(dynamicFilter);
    else if (key === transactionFilterKeys.TYPES)
      generatedOptions = createTypeFilterOptions(dynamicFilter);
    dynamicFilterOptions[key] = generatedOptions;
  }

  return dynamicFilterOptions;
};

export const createDateRangeFilterShortcuts = (financialYears, resetButton) => {
  const shortcutOptions = financialYears.map((year) => ({
    label: year.label,
    value: {
      from: year.startTimestamp,
      to: year.endTimestamp,
    },
  }));

  shortcutOptions.push({
    label: resetButton.label,
    value: { from: null, to: null },
  });

  return shortcutOptions;
};

export const getTransactionFilterValue = (key, filters, options) => {
  if (key === transactionFilterKeys.DATE_RANGE)
    return {
      from: filters[transactionFilterKeys.START_DATE],
      to: filters[transactionFilterKeys.END_DATE],
    };
  else if (key === transactionFilterKeys.WARNINGS)
    return options.filter((warningOption) => filters[warningOption.value]);
  else return filters[key];
};

export const generateTransactionAPIQueryParams = (filters) => {
  if (!filters) return {};

  const {
    WALLETS,
    TYPES,
    LABELS,
    COINS,
    TAGS,
    START_DATE,
    END_DATE,
    MISSING_COST_ERRORS,
    MISSING_PRICE_ERRORS,
    SORT_BY,
    CURSOR,
  } = transactionFilterKeys;

  const apiSpecificQueryParams = {};

  apiSpecificQueryParams.tags = filters[TAGS]?.map((tag) => tag.value);
  apiSpecificQueryParams.externalLabel = filters[LABELS]?.map(
    (label) => label.value
  );
  if (filters[MISSING_COST_ERRORS])
    apiSpecificQueryParams.missingCostErrors = filters[MISSING_COST_ERRORS];
  if (filters[MISSING_PRICE_ERRORS])
    apiSpecificQueryParams.missingPriceErrors = filters[MISSING_PRICE_ERRORS];

  return {
    walletId: filters[WALLETS]?.map((wallet) => wallet.value),
    type: filters[TYPES]?.map((type) => type.value),
    coin: filters[COINS]?.map((coin) => coin.value),
    ...(filters[START_DATE] && {
      startTimestamp: new Date(filters[START_DATE]).getTime(),
    }),
    ...(filters[END_DATE] && {
      endTimestamp: new Date(filters[END_DATE]).setHours(23, 59, 59),
    }),
    ...(filters[SORT_BY] && { sortBy: filters[SORT_BY] }),
    ...(filters[CURSOR] && { cursor: filters[CURSOR] }),
    ...apiSpecificQueryParams,
  };
};
