import { Button } from '@koinx/xui';
import { Close } from '@koinx/xui';
import { Avatar, Chip, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usePopup from '@/Hooks/usePopup';
import useWallet from '@/Hooks/useWallet';
import { toTitleCase } from '@/Utils/helper/toTitleCase';
import styles from './ExistingWalletPopup.module.css';
const ExistingWalletPopup = ({
  existingWallets,
  customWalletName,
  currentWallet,
  selectedMethod,
  formData,
  handleSetUploadedFiles,
}) => {
  const { handlePopupCenterOpen } = usePopup();
  const { handleUploadUserTransactions } = useWallet();
  const { walletToNamesMap } = useSelector((state) => state.wallet);
  const [walletId, setWalletId] = useState(existingWallets[0]._id);
  const [selectedChip, setSelectedChip] = useState(-1);
  const [isChipSelected, setIsChipSelected] = useState(true);
  const [selectedChipIndex, setSelectedChipIndex] = useState(0);
  const newWalletLabel = customWalletName
    ? customWalletName
    : `${currentWallet.displayName}-${existingWallets.length + 1}`;
  const handleClose = () => {
    handlePopupCenterOpen(false);
  };

  const handleUploadFilesToNewWallet = () => {
    if (currentWallet) {
      formData.set('customWalletName', newWalletLabel);
      handleUploadUserTransactions(
        currentWallet.name,
        formData,
        currentWallet,
        selectedMethod
      );
      handleSetUploadedFiles();
      handlePopupCenterOpen(false);
    }
  };

  const handleMapFileToExistingWallet = () => {
    if (isChipSelected) {
      formData.set('walletId', walletId);
      handleUploadUserTransactions(
        currentWallet.name,
        formData,
        currentWallet,
        selectedMethod
      );
      handleSetUploadedFiles();
      handlePopupCenterOpen(false);
    }
  };

  const handleChipClick = (selectedWalletId, chipNo) => {
    setWalletId(selectedWalletId);
    setSelectedChipIndex(chipNo);
    setIsChipSelected(isChipSelected ? false : true);
  };

  useEffect(() => {
    setSelectedChip(isChipSelected ? selectedChipIndex : -1);
  }, [isChipSelected, selectedChipIndex]);

  useEffect(() => {
    if (walletId !== '') {
      setIsChipSelected(true);
    }
  }, [walletId]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.CloseWrapper}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </div>
      <div className={styles.InnerWrapper}>
        <h1 className={styles.Title}>{'Attention!'}</h1>
        <p className={styles.Text}>
          {existingWallets.length === 1
            ? `It looks like you've already set up a ${toTitleCase(
                existingWallets[0]?.walletSource
              )} integration. \n Do you want to update your existing integration or start fresh with a new integration?`
            : `It looks like you've already set up multiple ${toTitleCase(
                existingWallets[0]?.walletSource
              )} integrations. \n Do you want to update any of your existing integrations or start fresh with a new integration?`}
        </p>
        <p className={styles.SelectWalletText}>
          {'Select integration to update'}
        </p>
        <div className={styles.ChipsWrapper}>
          {existingWallets.map((wallet, index) => {
            const walletData = walletToNamesMap?.[wallet?._id];
            return (
              <Chip
                key={wallet?._id}
                label={walletData?.customName}
                variant={selectedChip === index ? 'filled' : 'outlined'}
                color="primary"
                avatar={<Avatar src={walletData?.logo} />}
                clickable
                onClick={() => handleChipClick(wallet?._id, index)}
              />
            );
          })}
        </div>
        <Button
          variant={isChipSelected ? 'primary' : 'outline'}
          onClick={handleMapFileToExistingWallet}
          isDisabled={!isChipSelected}
          fullWidth
        >
          Update Existing Integration
        </Button>
        <div className={styles.MiddleComponent}>
          <div className={styles.Divider}></div>
          <div className={styles.Circle}>
            <p className={styles.Or}>Or</p>
          </div>
        </div>
        <Button
          className={styles.SecondaryButton}
          onClick={handleUploadFilesToNewWallet}
          variant="outline"
          fullWidth
        >
          Create New Integration
        </Button>
      </div>
    </div>
  );
};

export default ExistingWalletPopup;
