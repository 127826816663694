import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserIntegrationsDesktop from './Components/UserIntegrationsDesktop';
import styles from './UserIntegrations.module.css';
import { setSelectedIntegration } from '@/Redux/slices/sourcesSlice';

const UserIntegrationsComp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedIntegration(null));
  }, [dispatch]);

  return (
    <div className={styles.OverallWrapper}>
      <UserIntegrationsDesktop />
    </div>
  );
};

const UserIntegration = memo(UserIntegrationsComp);
export default UserIntegration;
