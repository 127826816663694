import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sourcesLoading: false,
  sources: [],
  sourcesError: null,
  selectedIntegration: null,
  enabledIntegrationMethods: [],
  sourceNameToSourceDataMap: null,
};

const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  reducers: {
    setSources: (state) => {
      state.sourcesLoading = true;
    },
    setSourcesSuccess: (state, action) => {
      state.sourcesLoading = false;
      state.sources = action.payload;
    },
    setSourcesError: (state, action) => {
      state.sourcesLoading = false;
      state.sourcesError = action.payload;
    },
    setSourceNameToSourceDataMap: (state, action) => {
      state.sourceNameToSourceDataMap = action.payload;
    },
    setIntegrationMethods: (state, action) => {
      state.enabledIntegrationMethods = action.payload;
    },
    setSelectedIntegration: (state, action) => {
      state.selectedIntegration = action.payload;
    },
  },
});

export const {
  setSources,
  setSourcesSuccess,
  setSourcesError,
  setSourceNameToSourceDataMap,
  setIntegrationMethods,
  setSelectedIntegration,
} = sourcesSlice.actions;

export default sourcesSlice.reducer;
