import React from 'react';
import { Select, Text } from '@koinx/xui';
import styles from '@/Components/Routes/TransactionsPage/CategoriseTransactionsDrawer/CategoriseTransactionsDrawer.module.css';

const SelectField = ({
  section,
  field,
  options,
  label,
  value,
  onChange,
  disabled,
}) => (
  <div className={styles.SelectWrapper}>
    <Text variant="button-2" textColor="gray-12">
      {label}
    </Text>
    <Select
      options={options}
      onChange={(value) => onChange(section, field, value)}
      value={value}
      className={styles.Select}
      disabled={disabled}
      width="100%"
    />
  </div>
);
export default SelectField;
