import Enum from './enums';

class PermissionsCategory extends Enum {
  static ADMIN = 'ADMIN';
  static ACCOUNTS = 'ACCOUNTS';
  static TRANSACTIONS = 'TRANSACTIONS';
  static INTEGRATIONS = 'INTEGRATIONS';
  static JOURNALS = 'JOURNALS';
  static REPORTS = 'REPORTS';
}

export const permissionsCategoryEnums = Object.freeze(PermissionsCategory);
