import { CHART_OF_ACCOUNTS_LEDGER_ACCOUNTS_URL } from '@/Utils/constants';

export const fetchLedgerAccounts = (axiosInstance, accountId, params) => {
  const URL = CHART_OF_ACCOUNTS_LEDGER_ACCOUNTS_URL.replace(
    ':accountId',
    accountId
  );

  return axiosInstance.get(URL, {
    params,
    paramsSerializer: (params) => params?.toString(),
  });
};
