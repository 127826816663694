import { useCallback } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { useDispatch } from 'react-redux';

import getErrorStatement from '@/Utils/helper/getErrorStatement';
import notify from '@/Utils/notifyToast';
import {
  setIntegrationMethods,
  setSelectedIntegration,
  setSourceNameToSourceDataMap,
  setSources,
  setSourcesError,
  setSourcesSuccess,
} from '@/Redux/slices/sourcesSlice';
import { fetchSources } from '@/Services/sources/fetchSources';
import { integrationMethodsEnum } from '@/Utils/enums/integrationMethods';

function useSources() {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const handleSources = useCallback(async () => {
    dispatch(setSources());
    try {
      const response = await fetchSources(axiosPrivate);
      dispatch(setSourcesSuccess(response.data));
      const sourceNameToSourceDataMapping = {};
      for (const source of response.data) {
        sourceNameToSourceDataMapping[source.name] = { source };
      }
      sourceNameToSourceDataMapping['defaultCoin'] = {
        displayName: 'Default Logo',
        logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/sources/Ethereum.png',
      };
      dispatch(setSourceNameToSourceDataMap(sourceNameToSourceDataMapping));
    } catch (error) {
      dispatch(setSourcesError(getErrorStatement(error)));
      notify(getErrorStatement(error), 'error');
    }
  }, [axiosPrivate, dispatch]);

  const handleSetSelectedIntegration = useCallback(
    (integration) => {
      if (integration) {
        const integrationMethods = integration.integrationMethods?.filter(
          (method) =>
            integration.enabled[method] &&
            method !== integrationMethodsEnum.DEEP_INTEGRATION &&
            method !== integrationMethodsEnum.OAUTH
        );
        dispatch(setIntegrationMethods(integrationMethods));
      } else {
        dispatch(setIntegrationMethods([]));
      }
      dispatch(setSelectedIntegration(integration));
    },
    [dispatch]
  );

  return {
    handleSources,
    handleSetSelectedIntegration,
  };
}

export default useSources;
