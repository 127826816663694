import Enum from './enums';

class RouteEnums extends Enum {
  // internal routes
  static ROOT = '/';
  static SIGN_IN_PAGE = '/sign-in';
  static SIGN_UP_PAGE = '/sign-up';
  static VERIFY_OTP_PAGE = '/verify-otp';
  static DASHBOARD = '/dashboard';

  static BASE_URL = '';
  static HOME_PAGE = '/home';
  static DATA_SOURCE_PAGE = `${this.BASE_URL}/data-source`;
  static TRANSACTIONS_PAGE = `${this.BASE_URL}/transactions`;
  static JOURNALS_PAGE = `${this.BASE_URL}/journals`;
  static ITEMS_PAGE = `${this.BASE_URL}/items`;
  static SALES_PAGE = `${this.BASE_URL}/sales`;
  static PURCHASES_PAGE = `${this.BASE_URL}/purchases`;
  static CHART_OF_ACCOUNTS_PAGE = `${this.BASE_URL}/chart-of-accounts`;
  static CHART_OF_ACCOUNTS_DETAILS_PAGE = `${this.CHART_OF_ACCOUNTS_PAGE}/details/:accountId`;
  static REPORTS_PAGE = `${this.BASE_URL}/reports`;
  static AUTOMATION_PAGE = `${this.BASE_URL}/automation`;
  static SETTINGS_PAGE = `${this.BASE_URL}/settings`;
  // Data sources subpages
  static TREASURY_ACCOUNTS_PAGE = `${this.DATA_SOURCE_PAGE}/treasury-accounts`;
  static INTEGRATIONS_PAGE = `${this.DATA_SOURCE_PAGE}/integrations`;
  static ADD_INTEGRATION_PAGE = `${this.DATA_SOURCE_PAGE}/add-integration`;
  static ADD_INTEGRATION_WITH_WALLET_ID = `${this.DATA_SOURCE_PAGE}/add-integration/:walletId`;
  static INTEGRATION_LOADING_PAGE = `${this.ADD_INTEGRATION_PAGE}/integration-loading`;
  static WALLET_DETAILS_ID_PAGE = '/wallet-details/:walletId';
  static TREASURY_ACCOUNTS_DETAILS_PAGE = `${this.TREASURY_ACCOUNTS_PAGE}/details/:accountId`;

  // journals subpages
  static JOURNAL_TABLE_PAGE = `${this.JOURNALS_PAGE}/journal-table`;
  static JOURNAL_TEMPLATES_PAGE = `${this.JOURNALS_PAGE}/journal-templates`;
  static JOURNALS_DETAILS_PAGE = `${this.JOURNALS_PAGE}/details/:journalId`;
  static ADD_JOURNAL_PAGE = `${this.JOURNALS_PAGE}/add-journal`;
  static EDIT_JOURNAL_PAGE = `${this.JOURNALS_PAGE}/edit-journal/:journalId`;

  // settings sub pages
  static SETTINGS_GENERAL_PAGE = `${this.BASE_URL}${this.SETTINGS_PAGE}/general`;
  static SETTINGS_TEAM_PAGE = `${this.BASE_URL}${this.SETTINGS_PAGE}/team`;
  static SETTINGS_CUSTOMERS_PAGE = `${this.BASE_URL}${this.SETTINGS_PAGE}/customers`;
  static SETTINGS_VENDOR_PAGE = `${this.BASE_URL}${this.SETTINGS_PAGE}/vendor`;

  //external routes
  static TERMS_AND_CONDITIONS = 'https://www.koinx.com/terms-conditions';
  static PRIVACY_POLICY = 'https://www.koinx.com/privacy-crypto-tax';
}

export const routeEnums = Object.freeze(RouteEnums);
