import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { extractFinancialYear } from '@/Utils/helper/extractFinancialYear';
import { toHumanReadableTime } from '@/Utils/helper/humanReadableTime';

/**
 * @param {string} financialYear
 * @returns {object} {startTimestamp, endTimestamp, readableFYRange}
 * @description This hook is used to get the start and end timestamps of the financial year.
 */
function useTimeStamp(financialYear) {
  const { taxSettingsData } = useSelector((state) => state.workspace);

  let year = extractFinancialYear(financialYear, true);
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [endTimestamp, setEndTimestamp] = useState(null);
  const [readableFYRange, setReadableFYRange] = useState(null);

  useEffect(() => {
    if (!year) {
      setStartTimestamp(null);
      setEndTimestamp(null);
    } else if (taxSettingsData?.beginningOfTaxYear) {
      const { day, month } = taxSettingsData.beginningOfTaxYear;
      if (day === 1 && month === 1) year++;

      let startDate = null;
      let endDate = null;
      startDate = new Date(
        year - 1,
        taxSettingsData?.beginningOfTaxYear.month - 1,
        taxSettingsData?.beginningOfTaxYear.day
      );
      endDate = new Date(startDate);
      endDate.setFullYear(year);
      endDate.setDate(startDate.getDate() - 1);
      endDate.setHours(23, 59, 59);
      setStartTimestamp(Date.parse(startDate));
      setEndTimestamp(Date.parse(endDate));
    }
  }, [financialYear, year, taxSettingsData]);

  useEffect(() => {
    if (startTimestamp && endTimestamp) {
      const startDateTimestamps = startTimestamp
        ? toHumanReadableTime(startTimestamp, false)
        : null;
      const endDateTimeStamps = endTimestamp
        ? toHumanReadableTime(endTimestamp, false)
        : null;

      setReadableFYRange(`${
        startDateTimestamps?.date
      } ${startDateTimestamps?.month?.slice(0, 3)} '${startDateTimestamps?.year
        ?.toString()
        .slice(2)} - ${endDateTimeStamps?.date} 
        ${endDateTimeStamps?.month?.slice(0, 3)} '${endDateTimeStamps?.year
          ?.toString()
          .slice(2)}`);
    }
  }, [startTimestamp, endTimestamp]);

  return { startTimestamp, endTimestamp, readableFYRange };
}

export default useTimeStamp;
