'use client';
import { useRef, useEffect, useCallback } from 'react';
import Styles from './PopUp.module.css';
import { Close } from '@koinx/xui';

function PopUp({ ContentComp, isOpen, closeFun, isClosable = true }) {
  const primaryWrapperRef = useRef(null);
  const overlayRef = useRef(null);

  const handleKeyDowns = useCallback(
    (e) => {
      if (isOpen) {
        if (e.key === 'Escape') {
          if (isClosable && closeFun) {
            closeFun();
          }
        }
      }
    },
    [closeFun, isClosable, isOpen]
  );

  const handleBgOnClick = (e) => {
    if (overlayRef.current === e.target) {
      if (isClosable && closeFun) {
        closeFun();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDowns);
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      document.removeEventListener('keydown', handleKeyDowns);
    };
  }, [isOpen, isClosable, handleKeyDowns]);

  return (
    <div
      ref={overlayRef}
      className={Styles.WrapperWrapper}
      onClick={handleBgOnClick}
      style={{
        background: isOpen ? 'rgba(0, 0, 0, 0.4)' : 'none',
        pointerEvents: isOpen ? 'all' : 'none',
      }}
    >
      <div
        className={Styles.Wrapper}
        ref={primaryWrapperRef}
        onClick={(e) => {
          if (
            isClosable &&
            e.target === primaryWrapperRef.current &&
            closeFun
          ) {
            closeFun();
          }
        }}
        style={{
          transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        {isClosable ? (
          <Close className={Styles.CloseIcon} onClick={closeFun} />
        ) : null}
        {ContentComp ? ContentComp : null}
      </div>
    </div>
  );
}

export default PopUp;
