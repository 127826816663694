import { ReactComponent as WalletIcon } from '@/Assets/Icons/WalletIcon.svg';
import { ReactComponent as DownArrow } from '@/Assets/General/DownArrow.svg';

import TradePlatformCoinSwitch from '@/Assets/WalletPage/TradePlatformCoinSwitch.png';
import AddIntegrationIcon from '@/Assets/WalletPage/WalletMobile.svg';
import { ReactComponent as GreenTickIcon } from '@/Assets/General/GreenTickIcon.svg';
function mapToKeyValue(obj) {
  const keyValuePairs = {};
  Object.keys(obj)
    .map((key) => key)
    .forEach((path) => {
      const splittedPath = path.split('/');
      const filename = splittedPath.pop().split('.')[0]; // Extract filename without extension
      const remainingPath = splittedPath.slice(2).join('/');

      //why not directly use path? https://github.com/vitejs/vite/issues/14581
      keyValuePairs[filename] = new URL(
        `../Assets/${remainingPath}/${filename}.svg`,
        import.meta.url
      ).href;
    });
  return keyValuePairs;
}

const exchangePlatformAssets = mapToKeyValue(
  import.meta.glob('../Assets/ExchangePlatform/*.{png,jpeg,jpg,svg}', {
    query: '?raw',
    import: 'default',
  })
);
export const walletPageData = {
  title: 'Add Integration',
  subTitle: 'Please add an integration to continue.',
  exchangeText: 'Choose an integration',
  requestWalletText: 'Can’t find yours?',
  filterButtons: [
    { btnText: 'All integrations', value: 'allIntegrations' },
    { btnText: 'Popular', value: 'popular' },
    { btnText: 'Exchanges', value: 'exchange' },
    { btnText: 'Wallets', value: 'wallet' },
    { btnText: 'Blockchains', value: 'chain' },
    // { btnText: "NFTs", value: "nft" },
    { btnText: 'Decentralized Exchanges', value: 'dex' },
  ],
  requestWalletLink: 'abc',
  emptyState: {
    heading: 'No Results Found',
    subHeading: 'Don’t worry, You can use our custom file format',
    btnText: 'Use KoinX Custom Format',
  },
  integrationTypes: {
    oAuth: 'OAuth',
    address: 'Address',
    csv: 'File Upload',
    api: 'API',
  },
  options: [{ name: 'Rename' }, { name: 'Unlink' }],
  optionsColor: ['#374151', '#F7324CCC'],
  // totalWorth: "Total Worth",
  transactionMade: 'Transactions',
  lastSync: 'Last synced:',
  lastTransaction: 'Last transaction:',
  upperSection: {
    title: 'Data Sources',
    searchBarPlaceholder: 'Search Wallet',
    addWalletButton: {
      text: 'Add Integration',
      icon: WalletIcon,
    },
    sortingOptions: [
      'Sort by',
      'Date Added',
      'Name A-Z',
      'Name Z-A',
      'Most Transactions',
    ],
    sortIcon: DownArrow,
  },
  mobileFlow: {
    GreenTickIcon: GreenTickIcon,
    AddIntegrationIcon: AddIntegrationIcon,
    tradePlatforms: [
      {
        name: 'Bitbns',
        icon: exchangePlatformAssets['Bitbns'],
      },
      {
        name: 'WazirX',
        icon: exchangePlatformAssets['WazirX'],
      },
      {
        name: 'Metamask',
        icon: exchangePlatformAssets['Metamask'],
      },
      {
        name: 'CoinSwitch',
        icon: TradePlatformCoinSwitch,
      },
      {
        name: 'Binance',
        icon: exchangePlatformAssets['Binance'],
      },
    ],
  },
};
