import styles from '@/Components/Ui/Sidebar/SidebarTab/SidebarTab.module.css';
import { ToolTip } from '@koinx/xui';
import PopoverContent from '@/Components/Ui/Sidebar/SidebarTab/PopoverContent';

const NotNestedExpandableTab = ({
  tab,
  isActiveTab,
  handlePopoverOpen,
  handleClose,
  isCollapsed,
  anchorEl,
  isPopoverOpen,
}) => {
  return (
    <ToolTip content={tab.name} side="right" sideOffset={5}>
      <div
        data-fixed-expandable-icon-active={isActiveTab(tab.link)}
        className={styles.ExpandableTabContainer}
      >
        <div
          tabIndex={0}
          // for keyboard accessibility
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handlePopoverOpen(e);
            }
          }}
          data-testid={tab.name}
          onClick={handlePopoverOpen}
          className={styles.ExpandableTabWrapper}
        >
          <div className={styles.NameAndIconWrapper}>
            {tab.icon ? <tab.icon className={styles.TabIcon} /> : <></>}
          </div>
        </div>
        <PopoverContent
          tab={tab}
          handleClose={handleClose}
          anchorEl={anchorEl}
          isCollapsed={isCollapsed}
          isPopoverOpen={isPopoverOpen}
        />
      </div>
    </ToolTip>
  );
};

export default NotNestedExpandableTab;
