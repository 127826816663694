import { Badge, InfoComponent, Categorise } from '@koinx/xui';
import styles from './CategorizedTable.module.css';
import CustomTable from '@/Components/Routes/TransactionsPage/CustomTable';
import { useSelector } from 'react-redux';
import { routeEnums } from '@/Utils/enums/routes';
import { useNavigate } from 'react-router';
import { emptyStateContent } from './constants';
import EmptyDataImage from '@/Assets/General/EmptyDataImage.svg';
import { generateAccountBadgeStyle } from '@/Components/Routes/TransactionsPage/TransactionsDetailsDrawer/helper';

const CategorizedTable = () => {
  const navigate = useNavigate();

  const { transactionsSuccessData, isTransactionsLoading } = useSelector(
    (state) => state.transactions
  );

  const customLastColumnBodyRenderer = (value) => {
    const category =
      value?.associatedAccounts?.mainTransaction?.[0]?.name ||
      value?.associatedAccounts?.gains?.[0]?.name;
    return (
      <div
        data-testid="categorizedTable-category-badge"
        className={styles.BadgeWrapper}>
        <Badge
          className={styles.CategoryBadge}
          style={generateAccountBadgeStyle(category ? category : 'Category')}>
          {category ? category : 'Categorized'}
        </Badge>
      </div>
    );
  };

  const handleNavigateToIntegrationsPage = () => {
    navigate(routeEnums.TRANSACTIONS_PAGE);
  };

  const EmptyStateInfoComponent = () => {
    return (
      <InfoComponent
        data-testid="infoComponent-categorizedTable"
        title={emptyStateContent.title}
        enableButton={true}
        buttonText={emptyStateContent.cta}
        buttonIcon={<Categorise style={{ fill: 'white' }} />}
        image={EmptyDataImage}
        imageAlt={emptyStateContent.imageAlt}
        buttonClickHandler={handleNavigateToIntegrationsPage}
      />
    );
  };

  return (
    <CustomTable
      tableData={transactionsSuccessData}
      customLastColumnBody={customLastColumnBodyRenderer}
      customLastColumnName={'accounting'}
      isTableDataLoading={isTransactionsLoading}
      EmptyStateComponent={EmptyStateInfoComponent}
    />
  );
};

export default CategorizedTable;
