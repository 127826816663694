export const timezones = {
  'GMT +00:00': 'UTC',
  'GMT +01:00': 'Europe/Paris GMT +01:00',
  'GMT -05:00': 'America/New_York GMT -05:00',
  'GMT +05:30': 'Asia/Kolkata GMT +05:30',
  'GMT +09:00': 'Asia/Tokyo GMT +09:00',
  'GMT +03:00': 'W-SU GMT +03:00',
  'GMT +02:00': 'Libya GMT +02:00',
  'GMT -10:00': 'US/Hawaii GMT -10:00',
  'GMT -09:00': 'US/Alaska GMT -09:00',
  'GMT -04:00': 'Etc/GMT+4 GMT -04:00',
  'GMT -03:00': 'Etc/GMT+3 GMT -03:00',
  'GMT -06:00': 'US/Indiana-Starke GMT -06:00',
  'GMT -07:00': 'US/Mountain GMT -07:00',
  'GMT -08:00': 'US/Pacific GMT -08:00',
  'GMT -02:00': 'Etc/GMT+2 GMT -02:00',
  'GMT -01:00': 'Etc/GMT+1 GMT -01:00',
  'GMT -03:30': 'Canada/Newfoundland GMT -03:30',
  'GMT +08:00': 'Singapore GMT +08:00',
  'GMT +07:00': 'Indian/Christmas GMT +07:00',
  'GMT +10:00': 'Pacific/Yap GMT +10:00',
  'GMT +11:00': 'Pacific/Ponape GMT +11:00',
  'GMT +05:00': 'Indian/Maldives GMT +05:00',
  'GMT +13:00': 'Pacific/Tongatapu GMT +13:00',
  'GMT +06:00': 'Indian/Chagos GMT +06:00',
  'GMT +12:00': 'Pacific/Wallis GMT +12:00',
  'GMT +04:00': 'Indian/Reunion GMT +04:00',
  'GMT +04:30': 'Asia/Kabul GMT +04:30',
  'GMT +05:45': 'Asia/Katmandu GMT +05:45',
  'GMT +06:30': 'Indian/Cocos GMT +06:30',
  'GMT +03:30': 'Iran GMT +03:30',
  'GMT +10:30': 'Australia/Yancowinna GMT +10:30',
  'GMT +09:30': 'Australia/North GMT +09:30',
  'GMT +08:45': 'Australia/Eucla GMT +08:45',
  'GMT -11:00': 'US/Samoa GMT -11:00',
  'GMT -12:00': 'Etc/GMT+12 GMT -12:00',
  'GMT +14:00': 'Pacific/Kiritimati GMT +14:00',
  'GMT +13:45': 'Pacific/Chatham GMT +13:45',
  'GMT -09:30': 'Pacific/Marquesas GMT -09:30',
};
