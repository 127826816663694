import { ToolTip } from '@koinx/xui';

const CustomTooltip = ({
  component,
  title,
  placement,
  className,
  width, // unused
  style,
  arrowColor,
  arrow = true,
  align,
  sideOffset,
}) => {
  return (
    <ToolTip
      sideOffset={sideOffset}
      side={placement}
      content={title}
      className={className}
      style={style}
      arrowColor={arrowColor}
      arrow={arrow}
      align={align}
    >
      {component}
    </ToolTip>
  );
};

export default CustomTooltip;
