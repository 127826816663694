import Enum from './enums';

class Permissions extends Enum {
  static CREATE = 'CREATE';
  static READ = 'READ';
  static UPDATE = 'UPDATE';
  static DELETE = 'DELETE';
}

export const permissionsEnums = Object.freeze(Permissions);
