import { routeEnums } from '@/Utils/enums/routes';

/**
 *
 * @param {string} paramId
 *
 * takes a `paramId`, and replaces it with the `:accountId`
 * in chart of accounts details page url then returns it.
 */
const replaceAccountIdParamWithId = (paramId, isTreasuryFlow) => {
  return isTreasuryFlow
    ? routeEnums.TREASURY_ACCOUNTS_DETAILS_PAGE.replace(':accountId', paramId)
    : routeEnums.CHART_OF_ACCOUNTS_DETAILS_PAGE.replace(':accountId', paramId);
};

export default replaceAccountIdParamWithId;
