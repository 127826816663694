/**
 * Returns a boolean for whether categorisation is allowed for multiple selected transactions.
 * @param {object[]} selectedTransactionsArray Array that contains selected transactions
 * @param {string} baseCurrency base currency of the user.
 * @returns {boolean} Returns a boolean for whether categorisation is allowed for multiple selected transactions.
 * @description Core logic for allowing categorisation of multiple selected transactions:
 */
export const validateMultipleTransactions = (
  selectedTransactionsArray,
  baseCurrency
) => {
  if (selectedTransactionsArray.length === 1) return true;

  if (selectedTransactionsArray.length > 0) {
    const baseTxn = selectedTransactionsArray[0];
    const baseLabel = baseTxn.externalLabel;
    const baseType = baseTxn.externalType;

    for (let i = 1; i < selectedTransactionsArray.length; i++) {
      const label = selectedTransactionsArray[i].externalLabel;
      const type = selectedTransactionsArray[i].externalType;

      if (label !== baseLabel) {
        return false;
      }

      if (type !== baseType) {
        return false;
      }
    }

    const firstObject = prepareValidationObject(baseTxn, baseCurrency);
    const isAllowed = validateTransactionsAgainstFirstObject(
      firstObject,
      selectedTransactionsArray,
      baseCurrency
    );

    return isAllowed;
  }
};

/**
 *
 * @param {object} txn a selected transaction object
 * @param {string} baseCurrency user base currency
 * @returns {object} validation object
 * @description Core logic for validation object creation:
 *
 * First get the baseCurrency of the user from the /workspace/settings API.
 * Now for a transaction:
 * * If inCoin exists and inCoin equals baseCurrency and inCoinAmount is non-zero, then baseCurrencyIn will be true.
 * * If inCoin exists and inCoin does not equal baseCurrency and inCoinAmount is non-zero, then cryptoIn will be true.
 * That means that if the coin is not base currency then it’s  a crypto.
 * Similarly for outCoin.
 * If feeCoin exists and feeCoinAmount is non-zero, then fee will be true.
 * Same as fee for tds.
 * isP2P is a transaction object’s property. If transation.isP2P is true, then isP2P will be true here too.
 * By default everything will be false.
 */
function prepareValidationObject(txn, baseCurrency) {
  let obj = {
    cryptoIn: false,
    cryptoOut: false,
    baseCurrencyIn: false,
    baseCurrencyOut: false,
    fee: false,
    tds: false,
    isP2P: false,
  };

  if (txn.inCoin && txn.inCoin === baseCurrency && txn.inCoinAmount > 0) {
    obj.baseCurrencyIn = true;
  }

  if (txn.inCoin && txn.inCoin !== baseCurrency && txn.inCoinAmount > 0) {
    obj.cryptoIn = true;
  }

  if (txn.outCoin && txn.outCoin === baseCurrency && txn.outCoinAmount > 0) {
    obj.baseCurrencyOut = true;
  }

  if (txn.outCoin && txn.outCoin !== baseCurrency && txn.outCoinAmount > 0) {
    obj.cryptoOut = true;
  }

  if (txn.feeCoin && txn.feeCoinAmount > 0) {
    obj.fee = true;
  }

  if (txn.tdsCoin && txn.tds > 0) {
    obj.tds = true;
  }

  if (txn.isP2P === true) {
    obj.isP2P = true;
  }

  return obj;
}

/**
 *
 * @param {object} firstObject The validation object that was created for the first selected transaction.
 * @param {object[]} allTransactions array of all selected transactions.
 * @param {string} baseCurrency user base currency
 * @returns {boolean} returns a boolean for whether all the transactions have matching validation object or not.
 */
function validateTransactionsAgainstFirstObject(
  firstObject,
  allTransactions,
  baseCurrency
) {
  for (let index = 1; index < allTransactions.length; index++) {
    let obj = prepareValidationObject(allTransactions[index], baseCurrency);

    const isObjectSame = matchObject(firstObject, obj);
    if (isObjectSame) {
      continue;
    } else return false;
  }

  return true;
}

/**
 *
 * @param {object} firstObject The validation object for the first selected transaction.
 * @param {object} obj The current validation object that should be matched with the first transaction object.
 * @returns {boolean} returns a boolean for whether the two objects have same keys and values or not.
 */
function matchObject(firstObject, obj) {
  for (let key in obj) {
    if (obj[key] !== firstObject[key]) {
      return false;
    }
  }
  return true;
}
