import SignUpComponent from '@/Components/UserOnboarding/SignUpComponent';
import styles from './SignUpPage.module.css';

const SignUpPage = () => {
  return (
    <section className={styles.Wrapper}>
      <SignUpComponent />
    </section>
  );
};

export default SignUpPage;
