export const createWorkspaceSelectOptions = (workspaces) => {
  const options = [];
  workspaces.forEach((workspace) => {
    options.push({
      label: workspace.name,
      value: workspace._id,
    });
  });
  return options;
};
