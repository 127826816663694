import styles from './ChainView.module.css';
import { getCustomNameIntegration } from '@/Utils/helper/getCustomNameIntegration';
import { useSelector } from 'react-redux';
import useBlockchainIntegration from '@/Hooks/useBlockchainIntegration';
import notify from '@/Utils/notifyToast';
import { useState } from 'react';
import { Button, Input } from '@koinx/xui';
import { chainViewData } from '@/Components/AddExchangesPage/constants';

const { button, label, toast } = chainViewData;

const ChainView = ({ customName }) => {
  const { wallets: userWallets } = useSelector((state) => state.wallet);
  const { selectedIntegration } = useSelector((state) => state.sources);
  const { loading } = useSelector((state) => state.blockchainIntegration);

  const { handleSyncBlockchainWallet } = useBlockchainIntegration();

  const [publicAddress, setPublicAddress] = useState('');

  const handleBlockchainIntegrationSubmit = () => {
    if (publicAddress) {
      const newWalletLabel = getCustomNameIntegration(
        userWallets,
        selectedIntegration,
        customName
      );
      handleSyncBlockchainWallet(
        newWalletLabel,
        selectedIntegration?.name,
        publicAddress,
        false
      );
    } else {
      notify(toast, 'error');
    }
  };

  const handlePublicAddressChange = (e) => {
    setPublicAddress(e.target.value);
  };
  return (
    <>
      <label className={styles.Label}>{label}</label>

      <Input
        inputClass={styles.CustomNameInput}
        type="text"
        onChange={handlePublicAddressChange}
        value={publicAddress}
      />
      <Button
        variant="primary"
        isLoading={loading}
        fullWidth
        onClick={handleBlockchainIntegrationSubmit}
      >
        {button}
      </Button>
    </>
  );
};

export default ChainView;
