import { routeEnums } from '@/Utils/enums/routes';
import styles from '@/Components/Routes/ChartOfAccounts/Details/Details.module.css';
import { Folder } from '@koinx/xui';

export const recursivelyCreateOptionsFromListOfAccounts = (
  hierarchyObject,
  optionsArray
) => {
  if (hierarchyObject) {
    optionsArray.push({
      label: hierarchyObject?.name,
      link:
        hierarchyObject.childAccount === undefined
          ? null
          : routeEnums.CHART_OF_ACCOUNTS_DETAILS_PAGE.replace(
              ':accountId',
              hierarchyObject?._id
            ),
      icon:
        hierarchyObject.childAccount === undefined ? null : (
          <Folder
            className={styles.Icon}
            data-testid={`breadcrumbs-folderIcon-${hierarchyObject._id}`}
          />
        ),
    });
    if (hierarchyObject?.childAccount) {
      recursivelyCreateOptionsFromListOfAccounts(
        hierarchyObject?.childAccount,
        optionsArray
      );
    }
  }
};

export const getLinks = (hierarchyObject) => {
  let optionsArray = [];
  recursivelyCreateOptionsFromListOfAccounts(hierarchyObject, optionsArray);
  return optionsArray;
};
