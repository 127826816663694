import Enum from './enums';

class IntegrationImportingStatus extends Enum {
  static LOADING = 'loading';
  static LOADED = 'loaded';
  static ERROR = 'error';
}

export const integrationImportingStatusEnums = Object.freeze(
  IntegrationImportingStatus
);
