import { accountTypeEnums } from '@/Utils/enums/accountType';

export function transformAccounts(accounts) {
  function transform(account) {
    const option = {
      label: account.name,
      value: account._id,
    };

    if (account.accounts && account.accounts.length > 0) {
      option.options = account.accounts.map(transform);
    }

    return option;
  }
  function transformRootAccount(account) {
    const option = {
      label: account.name,
      value: '',
      options: [],
    };

    const nestedOption = transform(account);
    option.options.push(nestedOption);

    return option;
  }

  return accounts.map((account) => {
    if (account.level === 0) {
      return transformRootAccount(account);
    } else {
      return transform(account);
    }
  });
}

export const getAccountTypeOptions = () => {
  let arrayOfAccountTypes = [];
  Object.values(accountTypeEnums).forEach((accountType) =>
    arrayOfAccountTypes.push(accountType)
  );
  let options = [];
  arrayOfAccountTypes.forEach((accountType) => {
    options.push({
      label: accountType,
      value: accountType,
    });
  });
  return options;
};
