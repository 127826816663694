import { useSelector } from 'react-redux';
import { Amount } from '@koinx/xui';

const AmountComponent = ({
  value,
  size = 'md',
  weight = 'normal',
  formattingEnabled = true,
  isColored = false,
  isCurrencySign = true,
  isPositiveNegativeSign = false,
  className = '',
  maxLength = 16,
  appendContent = '',
  currencyCode,
  customColor = '',
  maximumFractionDigits = 2,
  humanReadable = true,
  useGrouping = true,
  humanReadableLimit,
  dataTestId,
}) => {
  const userBaseCurrencyCode = useSelector(
    (state) => state.workspace.baseCurrency
  );
  return (
    <Amount
      value={value}
      size={size}
      weight={weight}
      formattingEnabled={formattingEnabled}
      isColored={isColored}
      isCurrencySign={isCurrencySign}
      isPositiveNegativeSign={isPositiveNegativeSign}
      maxLength={maxLength}
      appendContent={appendContent}
      currencyCode={currencyCode ?? userBaseCurrencyCode}
      customColor={customColor}
      maximumFractionDigits={maximumFractionDigits}
      humanReadable={humanReadable}
      useGrouping={useGrouping}
      className={className}
      humanReadableLimit={humanReadableLimit}
      data-testid={dataTestId}
    />
  );
};

export default AmountComponent;
