// import useScreenType from '@src/Hooks/useScreenType';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './HowToIntegrate.module.css';
import { Question, Document, Text } from '@koinx/xui';
import { generateDefaultNotes } from './helper';
import { staticData } from './constants';

const { desktopBlogTitle, mobileBlogTitle, title, desktopTitle } = staticData;

const HowToIntegrate = ({ selectedMethod, children }) => {
  const { selectedIntegration } = useSelector((state) => state.sources);
  // const isMobileScreen = useScreenType();

  const blogLink = useMemo(() => {
    return selectedIntegration?.blogLinks?.[selectedMethod];
  }, [selectedIntegration?.blogLinks, selectedMethod]);

  const videoLink = useMemo(() => {
    return selectedIntegration?.videoLinks?.[selectedMethod];
  }, [selectedIntegration?.videoLinks, selectedMethod]);

  const defaultNotes = useMemo(() => {
    return generateDefaultNotes(
      selectedMethod,
      selectedIntegration?.displayName
    );
  }, [selectedIntegration?.displayName, selectedMethod]);

  return (
    <div className={styles.Wrapper}>
      {children}
      {videoLink || blogLink ? (
        <>
          {videoLink && (
            <iframe
              src={videoLink}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={styles.Video}
            />
          )}
          {blogLink && (
            <a
              href={blogLink}
              target="_blank"
              rel="noopener noreferrer"
              className={
                // isMobileScreen ?
                // styles.BlogWrapper :
                styles.ReadBLogWrapper
              }
            >
              <Document className={styles.Icon} />
              <Text variant="button-2">
                {
                  // isMobileScreen ?
                  // mobileBlogTitle :
                  desktopBlogTitle
                }
              </Text>
              {/* {isMobileScreen
                &&
                <ArrowIncline fill="var(--sec-blue)" />} */}
            </a>
          )}
        </>
      ) : (
        <ol className={styles.List}>
          {defaultNotes.map((note, index) => (
            <li
              key={index}
              dangerouslySetInnerHTML={{ __html: note }}
              className={styles.ListItem}
            />
          ))}
        </ol>
      )}
    </div>
  );
};

HowToIntegrate.Title = function Title() {
  // const isMobileScreen = useScreenType();
  const { selectedIntegration } = useSelector((state) => state.sources);

  return (
    <div className={styles.TitleWrapper}>
      <Text variant="button-2">
        {desktopTitle(selectedIntegration?.displayName)}
      </Text>
    </div>
  );
};

export default HowToIntegrate;
