export const transactionsPageData = {
  pluralize: {
    singular: 'Transaction',
    plural: 'Transactions',
  },
  title: 'Transactions',
  button: {
    import: 'Import Transactions',
    manual: 'Add Manually',
    archive: 'Archive',
    spam: 'Archive as Spam',
    categorise: 'Categorise',
  },
  tooltip: 'You can only categorize similar transactions together',
};

export const headingWhenRowsSelected = `{0} {1} Selected`;
