import { CHART_OF_ACCOUNTS_DETAILS_URL } from '@/Utils/constants';

export const fetchAccountDetails = (
  axiosInstance,
  accountId,
  hierarchy,
  isTreasuryAccount
) => {
  const params = {
    hierarchy: hierarchy,
    isTreasuryAccount: isTreasuryAccount,
  };
  const DETAILS_URL = CHART_OF_ACCOUNTS_DETAILS_URL.replace(
    ':accountId',
    accountId
  );
  return axiosInstance.get(DETAILS_URL, {
    params,
  });
};
