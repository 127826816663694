const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const defaultMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'September',
  'October',
  'November',
  'December',
];

const getMonthName = (month, format) => {
  switch (format) {
    case 'short':
      return shortMonths[month - 1];
    default:
      return defaultMonths[month - 1];
  }
};

const nthNumber = (number) => {
  return number > 0
    ? ['th', 'st', 'nd', 'rd'][
        (number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10
      ]
    : '';
};

export const toHumanReadableTime = (timestamp, formatted = true) => {
  const time = new Date(timestamp);

  const date = time.getDate();
  const month = time.getMonth() + 1;
  const year = time.getFullYear();
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  if (formatted) {
    const humanReadableTime = `${date}/${month}/${year} ${hours}:${minutes}:${
      seconds.length === 2 ? seconds : `0${seconds}`
    }`;
    return humanReadableTime;
  } else {
    return {
      date: date,
      month: getMonthName(month, 'short'),
      fullMonth: getMonthName(month),
      year: year,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      nthDate: date + nthNumber(date),
    };
  }
};
