import { TransactionTypeIconMap } from '@/Utils/TransactionTypeIconMapping';
import { transactionSortOptions } from '@/Utils/enums/transactionSortOptions';
import { transactionTypesEnum } from '@koinx/utils';
import { FilterClear } from '@koinx/xui';

export const TransactionFilterData = {
  searchBarPlaceholder: 'Search',
  filterBtn: {
    text: 'Filters',
    onOpenText: 'Clear Filters',
  },
  uncategorizedType: {
    name: 'Uncategorized Type',
    key: 'uncategorizedType',
    items: [
      {
        label: 'Unrecognized',
        value: 'Unrecognized',
      },
      {
        label: 'Recognized',
        value: 'Recognized',
      },
    ],
    width: 140,
  },
  filterCategories: [
    {
      name: 'wallets',
      key: 'wallets',
      maxBadgeCount: 2,
      items: [],
      width: 200,
    },
    {
      label: 'Type',
      key: 'type',
      maxBadgeCount: 2,
      items: [
        { label: 'Trade', value: transactionTypesEnum.TRADE },
        { label: 'Deposit', value: transactionTypesEnum.DEPOSIT },
        { label: 'Withdrawal', value: transactionTypesEnum.WITHDRAWAL },
        { label: 'Margin Trade', value: transactionTypesEnum.MARGIN_TRADE },
        { label: 'Futures Trade', value: transactionTypesEnum.FUTURES_TRADE },
        { label: 'Expense', value: transactionTypesEnum.EXPENSE },
      ],
      width: 150,
    },
    {
      name: 'Labels',
      key: 'labels',
      maxBadgeCount: 2,
      items: [
        { label: 'abc', name: 'abc' },
        { label: 'def', name: 'def' },
        { label: 'ghi', name: 'ghi' },
      ],
      width: 110,
    },
    {
      name: 'Currencies',
      key: 'currencies',
      maxBadgeCount: 2,
      items: [
        { name: 'BTC', value: 'BTC' },
        { name: 'ETH', value: 'ETH' },
        { name: 'USDT', value: 'USDT' },
        { name: 'XRP', value: 'XRP' },
      ],
      width: 130,
    },
  ],
  dateSelectors: [
    {
      label: 'Start Date',
      filterCategory: 'startDate',
    },
    {
      label: 'End Date',
      filterCategory: 'endDate',
    },
  ],
  dateRangeSelectors: {
    label: 'Date Range',
  },
};

export const transactionFilterTypes = {
  SELECT: 'select',
  MULTI_SELECT: 'multi_select',
  DATE_RANGE: 'date_range',
};

export const transactionFilterKeys = {
  WALLETS: 'wallets',
  COINS: 'coins',
  TYPES: 'types',
  LABELS: 'labels',
  SORT_BY: 'sortBy',
  CURSOR: 'cursor',
  DATE_RANGE: 'dateRange',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

const sortByOptions = [
  {
    label: 'None',
    value: transactionSortOptions.NONE,
  },
  {
    label: 'Most Recent',
    value: transactionSortOptions.NEWEST,
  },
  {
    label: 'Oldest',
    value: transactionSortOptions.OLDEST,
  },
  
];


export const staticData = {
  clearFilterBtn: {
    btnText: 'Clear Filters',
    icon: <FilterClear fill="var(--sec-blue)" />,
  },
  dateRangeFilterData: {
    fromYear: 2017,
    toYear: new Date().getFullYear(),
    shortcutButtons: {
      FY_22_23: { label: 'FY 2022-23', value: '2022-2023' },
      FY_23_24: { label: 'FY 2023-24', value: '2023-2024' },
      FY_24_25: { label: 'FY 2024-25', value: '2024-2025' },
      RESET: { label: 'Reset' },
    },
  },
  transactionFilters: [
    {
      label: 'Wallets',
      isDynamicFilter: true,
      key: transactionFilterKeys.WALLETS,
      type: transactionFilterTypes.MULTI_SELECT,
      optionsHaveIcon: true,
      optionsDisplayLimit: 2,
    },
    {
      label: 'Types',
      isDynamicFilter: true,
      key: transactionFilterKeys.TYPES,
      type: transactionFilterTypes.MULTI_SELECT,
      optionsDisplayLimit: 1,
      optionsHaveIcon: true,
    },
    {
      label: 'Labels',
      isDynamicFilter: true,
      key: transactionFilterKeys.LABELS,
      type: transactionFilterTypes.MULTI_SELECT,
      optionsDisplayLimit: 1,
      optionsHaveIcon: true,
    },
    {
      label: 'Coins',
      isDynamicFilter: true,
      key: transactionFilterKeys.COINS,
      type: transactionFilterTypes.MULTI_SELECT,
      optionsHaveIcon: true,
      optionsDisplayLimit: 4,
    },
    {
      key: transactionFilterKeys.DATE_RANGE,
      type: transactionFilterTypes.DATE_RANGE,
    },
    {
      label: 'Sort By',
      options: sortByOptions,
      type: transactionFilterTypes.SELECT,
      key: transactionFilterKeys.SORT_BY,
    }
  ],
};

