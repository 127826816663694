import { DEEP_INTEGRATION_MAPPING } from '@/Utils/deepIntegrationMapping';

export const generateDeepIntegrationPopupContent = (source) => {
  return {
    heading: DEEP_INTEGRATION_MAPPING[source].popupTitle,
    knowMore: 'Know more',
    title: 'Sync Transactions',
    proceed: `Proceed to ${DEEP_INTEGRATION_MAPPING[source].displayName}`,
    visitCoindcxWeb: `Visit ${DEEP_INTEGRATION_MAPPING[source].displayName} Web`,
    popupImage: DEEP_INTEGRATION_MAPPING[source].popupImage,
    deepLink: DEEP_INTEGRATION_MAPPING[source]?.deepLink,
  };
};
