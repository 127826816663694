import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

// import useScreenType from '@src/Hooks/useScreenType';
import useStorage from '@/Hooks/useStorage';
import useSources from '@/Hooks/useSources';

import { routeEnums } from '@/Utils/enums/routes';
import { integrationTypeEnum } from '@/Utils/enums/integrationTypes';

import SelectConnectionType from './Components/SelectConnectionType';
import BackButton from '@/Components/Ui/BackButton';

import styles from './AddExchanges.module.css';
import AllExchanges from './Components/AllExchanges/AllExchanges';
import DescriptionSection from './Components/DescriptionSection/DescriptionSection';
import { KOINX } from './constants';

const UnMemoizedAddExchanges = () => {
  const {
    sources,
    sourceNameToSourceDataMap,
    selectedIntegration,
    enabledIntegrationMethods,
  } = useSelector((state) => state.sources);
  const { walletToNamesMap } = useSelector((state) => state.wallet);

  const [selectedMethod, setSelectedMethod] = useState(null);

  const [searchParams] = useSearchParams();
  const { walletId: paramWalletId } = useParams();
  const { getFromLocalStorage } = useStorage();
  const navigate = useNavigate();
  // const isMobileScreen = useScreenType();
  const { handleSetSelectedIntegration } = useSources();

  const walletId = searchParams.get('wallet');
  const methodFromUrl = searchParams.get('method');

  const isEligibleCSKuber = getFromLocalStorage('isEligibleCSKuber') === 'true';
  const referralCode = getFromLocalStorage('referralCode');
  const initializeIntegration = useCallback(() => {
    if (paramWalletId) {
      if (paramWalletId === KOINX) {
        handleSetSelectedIntegration(sourceNameToSourceDataMap[KOINX]);
        return;
      }
      const wallet = walletToNamesMap[paramWalletId];
      if (wallet) {
        if (
          wallet.integrationType === integrationTypeEnum.FILE_UPLOAD ||
          !wallet.isSyncBasedIntegration
        ) {
          handleSetSelectedIntegration(
            sourceNameToSourceDataMap[wallet.sourceName].source
          );
        } else {
          navigate(routeEnums.ADD_WALLET_PAGE);
        }
      } else {
        navigate(routeEnums.ADD_WALLET_PAGE);
      }
    } else if (walletId) {
      const source = sources.find((wallet) => wallet._id === walletId);
      if (source) {
        handleSetSelectedIntegration(source);
      } else {
        navigate(routeEnums.ADD_WALLET_PAGE);
      }
    } else if (isEligibleCSKuber && referralCode === 'cskuber') {
      handleSetSelectedIntegration(sourceNameToSourceDataMap['coinswitch']);
    }
  }, [
    handleSetSelectedIntegration,
    isEligibleCSKuber,
    navigate,
    paramWalletId,
    referralCode,
    sourceNameToSourceDataMap,
    sources,
    walletId,
    walletToNamesMap,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    initializeIntegration();
  }, [initializeIntegration]);

  useEffect(() => {
    if (!walletId && !paramWalletId) {
      handleSetSelectedIntegration(null);
    }
  }, [handleSetSelectedIntegration, walletId, paramWalletId]);

  useEffect(() => {
    if (selectedIntegration) {
      if (methodFromUrl && enabledIntegrationMethods.includes(methodFromUrl)) {
        setSelectedMethod(methodFromUrl);
      } else {
        setSelectedMethod(enabledIntegrationMethods[0]);
      }
    }
  }, [enabledIntegrationMethods, methodFromUrl, selectedIntegration]);

  return selectedIntegration ? (
    <>
      <SelectConnectionType
        selectedMethod={selectedMethod}
        setSelectedMethod={setSelectedMethod}
      />

      <DescriptionSection selectedMethod={selectedMethod} />
    </>
  ) : (
    <AllExchanges />
  );
};

const AddExchanges = memo(UnMemoizedAddExchanges);
export default AddExchanges;
