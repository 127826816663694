import AmountGroup from '@/Components/Ui/AmountGroup';
import BlankCellValue from '@/Components/Ui/BlankCellValue/BlankCellValue';
import styles from '@/Components/Routes/TransactionsPage/CustomTable/CustomTable.module.css';

const AmountCell = ({ getValue, row, coinKey, priceKey }) => {
  const value = getValue();
  const coin = row.original[coinKey];
  const price = row.original[priceKey];
  if (value > 0) {
    return (
      <AmountGroup
        coin={coin}
        cryptoAmount={value}
        price={
          coinKey === 'outCoin' || coinKey === 'inCoin' ? price : price * value
        }
        priceClassName={styles.Price}
        cryptoAmountClassName={styles.CryptoAmount}
      />
    );
  } else return <BlankCellValue align="end" />;
};
export default AmountCell;
