import { useEffect } from 'react';
import styles from './TransactionsPage.module.css';
import TransactionsPageWrapper from '@/Components/Routes/TransactionsPage/TransactionsPageWrapper';
import useTransaction from '@/Hooks/useTransaction';
import { TransactionStatusTypes } from '@/Utils/enums/TransactionStatusTypes';
import { useSearchParams } from 'react-router-dom';
import { TabTypesEnum } from '@/Utils/enums/tabTypes';
import { useSelector } from 'react-redux';
import { generateTransactionAPIQueryParams } from '@/Components/Routes/TransactionsPage/TransactionFilters/helper';

const TransactionsPage = () => {
  const { handleFetchTransactions, handleFetchTop1000Coins } = useTransaction();
  let [searchParams, setSearchParams] = useSearchParams();
  let transactionStatus =
    searchParams.get('tab') || TransactionStatusTypes.PROCESSED;
  if (transactionStatus === TabTypesEnum.UNCATEGORIZED) {
    transactionStatus = TransactionStatusTypes.PROCESSED;
  }
  const { cursor, rowsPerPage } = useSelector((state) => state.transactions);
  const filters = useSelector((state) => state.transactions?.filters);

  useEffect(() => {
    const params = generateTransactionAPIQueryParams(filters);
    handleFetchTransactions(
      transactionStatus || TransactionStatusTypes.PROCESSED,
      cursor,
      rowsPerPage,
      params
    );
  }, [
    handleFetchTransactions,
    transactionStatus,
    cursor,
    rowsPerPage,
    filters,
  ]);

  useEffect(() => {
    handleFetchTop1000Coins();
  }, [handleFetchTop1000Coins]);

  return (
    <div className={styles.Wrapper}>
      <TransactionsPageWrapper />
    </div>
  );
};

export default TransactionsPage;
