import { EDIT_TRANSACTIONS_URL } from '@/Utils/constants';

export const editTransaction = (data, axiosInstance) => {
  const URL = EDIT_TRANSACTIONS_URL.replace(
    ':transactionId',
    data.transactionId
  );

  return axiosInstance.patch(URL, data);
};
