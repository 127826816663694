const colorNames = ['blue', 'orange', 'green', 'red', 'gray'];

/**
 * This function creates a unique style for each account name, ensuring that the same
 * account always gets the same color scheme. It uses a simple hash function to generate
 * a number from the account name, which is then used to determine the color and shade.

 *
 * @param {string} accountName - The name of the account for which to generate the badge style.
 * @returns {Object} An object containing CSS properties for the badge, including:
 *   - backgroundColor: the background color.
 *   - color: the text color.
 *   - borderRadius: Set to '0.4rem'.
 *   - height: Set to 'fit-content'.
 *   - width: Set to 'fit-content'.
 *   - fontSize: Set to font size 16.
 *   - fontWeight: Set to 500.
 *
 * The color scheme is chosen from a predefined list of colors (blue, orange, green, red, gray),
 * with varying shades for background and text to ensure readability.
 */
export function generateAccountBadgeStyle(accountName) {
  const words = accountName?.split(' ');

  const displayText =
    words?.length > 1
      ? words
          ?.map((word) => word[0])
          ?.join('')
          ?.toUpperCase()
      : accountName?.slice(0, 2).toUpperCase();

  const hash = displayText?.charCodeAt(0) + (displayText?.charCodeAt(1) || 0);

  const colorName = colorNames[hash % colorNames.length];

  const bgColorNumber = (hash % 5) + 3;
  const textColorNumber = bgColorNumber + 6;

  const backgroundColor = `var(--${colorName}-${bgColorNumber.toString().padStart(2, '0')})`;
  const color = `var(--${colorName}-${textColorNumber.toString().padStart(2, '0')})`;

  return {
    backgroundColor,
    color,
    borderRadius: '0.4rem',
    height: 'fit-content',
    width: 'fit-content',
  };
}
