import axios from 'axios';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import InvalidFileFormatPopup from '@/Components/AddExchangesPage/Components/Popups/InvalidFileFormatPopup';

import { fetchUserWallets } from '@/Services/dataSources/fetchUserWallets';
import { uploadUserTransactions } from '@/Services/dataSources/uploadUserTransactions';
import { removeWallet } from '@/Services/dataSources/removeWallet';
import updateUserWalletName from '@/Services/dataSources/updateUserWalletName';
import { syncUserTransactions } from '@/Services/dataSources/syncUserTransactions';
import { fetchWalletSummary } from '@/Services/dataSources/fetchWalletSummary';
import { fetchUserUploadedCSVMetadata } from '@/Services/dataSources/fetchUserUploadedcsv';
import { fetchOngoingIntegrationStatus } from '@/Services/dataSources/fetchOngoingIntegrationStatus';
// import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import useAxiosPrivate from './useAxiosPrivate';
import { navigateToIntegrationLoading } from '@/Utils/helper/navigateToIntegrationLoading';
import notify from '@/Utils/notifyToast';
import usePopup from './usePopup';
// import SpinWheelPopUp from '../Components/SpinWheelPopUp/SpinWheelPopUp';
// import useUser from './useUser';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import {
  setCSVMetadata,
  setDeepIntegrationWallet,
  setDeleteUserWallet,
  setDeleteUserWalletFailure,
  setDeleteUserWalletSuccess,
  setFetchOngoingIntegrationStatusFailure,
  setFetchOngoingIntegrationStatusSuccess,
  setFetchUserWallets,
  setFetchUserWalletsFailure,
  setFetchUserWalletsSuccess,
  setFetchingCSVData,
  setFetchingMetaData,
  setSyncUserTransactions,
  setSyncUserTransactionsFailure,
  setSyncUserTransactionsSuccess,
  setUpdateUserWalletName,
  setUpdateUserWalletNameError,
  setUpdateUserWalletNameSuccess,
  setUploadUserTransactions,
  setUploadUserTransactionsFailure,
  setUploadUserTransactionsSuccess,
  setWalletSummary,
  setWalletToNamesMap,
} from '@/Redux/slices/walletSlice';

const useWallet = () => {
  const [userWallets, setUserWallets] = useState([]);
  // const totalUserWallets = useSelector((state) => state.wallet.wallets).length;
  const { handlePopupCenterComponentRender, handlePopupCenterOpen } =
    usePopup();
  // const { handleGetUserSpinWheelResult } = useUser();
  // const { userSpinWheelResult } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  // const gaEventTracker = useAnalyticsEventTracker('Exchanges');

  const handleUserWallets = useCallback(
    async (handleUserWalletsCallback) => {
      try {
        dispatch(setFetchUserWallets());

        const response = await fetchUserWallets(axiosPrivate);
        setUserWallets(response.data);
        dispatch(setFetchUserWalletsSuccess(response.data));
        if (handleUserWalletsCallback) handleUserWalletsCallback();
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(setFetchUserWalletsFailure(getErrorStatement(error)));
          notify(getErrorStatement(error), 'error');

          if (handleUserWalletsCallback) handleUserWalletsCallback();
        }
      }
    },
    [dispatch, axiosPrivate]
  );

  const handleDeleteUserWallet = useCallback(
    (walletId, handleDeleteUserWalletCallback) => {
      dispatch(setDeleteUserWallet());

      removeWallet(walletId, axiosPrivate)
        .then((response) => {
          dispatch(setDeleteUserWalletSuccess(response.data));
          if (handleDeleteUserWalletCallback) handleDeleteUserWalletCallback();
          notify('Wallet unlinked successfully!', 'success');
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(setDeleteUserWalletFailure(getErrorStatement(error)));
            notify(getErrorStatement(error), 'error');
            if (handleDeleteUserWalletCallback)
              handleDeleteUserWalletCallback();
          }
        });
    },
    [dispatch, axiosPrivate]
  );

  const handleUploadUserTransactions = useCallback(
    (walletName, fileInputData, currentIntegration, selectedMethod) => {
      dispatch(setUploadUserTransactions());

      uploadUserTransactions(fileInputData, axiosPrivate)
        .then((response) => {
          // gaEventTracker(
          //   'Upload transactions successfull',
          //   `${walletName}: Upload transactions successfull`
          // );
          dispatch(setUploadUserTransactionsSuccess(response.data.message));
          navigateToIntegrationLoading(navigate, dispatch);
          // handleGetUserSpinWheelResult();

          // POP-UP FOR INVALID TRANSACTIONS Removed
          // if (
          //   response?.data?.successfulRows ||
          //   response?.data?.invalidRows?.length ||
          //   response?.data?.ignoredRows?.length
          // ) {
          //   handlePopupCenterComponentRender(
          //     <InvalidTransactionsPopup
          //       fileInputData={fileInputData}
          //       transactionsData={response.data}
          //     />
          //   );
          //   handlePopupCenterOpen(true);
          // }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // gaEventTracker(
            //   'Upload transactions error',
            //   'Upload transactions error'
            // );
            dispatch(
              setUploadUserTransactionsFailure(getErrorStatement(error))
            );
            if (
              error?.response?.data?.errors?.[0]?.name === 'InvalidFileError'
            ) {
              if (
                currentIntegration?.videoLinks?.[selectedMethod] ||
                currentIntegration?.blogLinks?.[selectedMethod]
              ) {
                handlePopupCenterComponentRender(
                  <InvalidFileFormatPopup
                    currentIntegration={currentIntegration}
                    selectedMethod={selectedMethod}
                  />
                );
                handlePopupCenterOpen(true);
              }
            }
            notify(getErrorStatement(error), 'error');
          }
        });
    },
    [
      dispatch,
      axiosPrivate,
      // gaEventTracker,
      navigate,
      handlePopupCenterComponentRender,
      handlePopupCenterOpen,
    ]
  );

  const handleSyncUserTransactions = useCallback(
    (reqBodyParams, walletName) => {
      dispatch(setSyncUserTransactions());

      syncUserTransactions(
        {
          ...reqBodyParams,
          exchangeName: walletName,
        },
        axiosPrivate
      )
        .then((response) => {
          // gaEventTracker(
          //   'Sync transactions successfull',
          //   `${walletName}: Sync transactions successfull`
          // );
          dispatch(setSyncUserTransactionsSuccess(response.data.message));
          navigateToIntegrationLoading(navigate, dispatch);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(setSyncUserTransactionsFailure(getErrorStatement(error)));
            notify(getErrorStatement(error), 'error');
          }
        });
    },
    [
      dispatch,
      axiosPrivate,
      // gaEventTracker,
      navigate,
    ]
  );

  const handleUpdateUserWalletName = async (
    walletId,
    customWalletName,
    handlePopupClose
  ) => {
    try {
      dispatch(setUpdateUserWalletName());
      const response = await updateUserWalletName(
        axiosPrivate,
        walletId,
        customWalletName
      );
      dispatch(setUpdateUserWalletNameSuccess(response.data.message));
      handlePopupClose();
      notify('Wallet renamed successfully!', 'success');
      handleUserWallets();
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(setUpdateUserWalletNameError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    }
  };

  const handleWalletToNamesMap = useCallback(
    (walletToNamesMap) => {
      dispatch(setWalletToNamesMap(walletToNamesMap));
    },
    [dispatch]
  );

  // Fetches the User's Uploaded CSV Metadata
  const handleFetchUserUploadedCSVMetadata = useCallback(
    (walletId) => {
      dispatch(setFetchingMetaData());
      fetchUserUploadedCSVMetadata(axiosPrivate, walletId).then((response) => {
        dispatch(setCSVMetadata(response.data));
      });
    },
    [axiosPrivate, dispatch]
  );

  // Fetches User Summary Data
  const handleFetchUserSummaryData = useCallback(
    (_id) => {
      dispatch(setFetchingCSVData());
      fetchWalletSummary(axiosPrivate, _id).then((response) => {
        dispatch(setWalletSummary(response.data, _id));
      });
    },
    [axiosPrivate, dispatch]
  );

  //Fetch is wallet processing
  const handleFetchOngoingIntegrationStatus = useCallback(() => {
    fetchOngoingIntegrationStatus(axiosPrivate)
      .then((response) => {
        dispatch(
          setFetchOngoingIntegrationStatusSuccess(
            response.data.ongoingIntegration
          )
        );
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            setFetchOngoingIntegrationStatusFailure(getErrorStatement(error))
          );
        }
      });
  }, [dispatch, axiosPrivate]);

  const handleSetDeepIntegrated = useCallback(
    (isDeepIntegrated) => {
      dispatch(setDeepIntegrationWallet(isDeepIntegrated));
    },
    [dispatch]
  );

  return {
    userWallets,
    handleUserWallets,
    handleDeleteUserWallet,
    handleUploadUserTransactions,
    handleUpdateUserWalletName,
    handleWalletToNamesMap,
    handleSyncUserTransactions,
    handleFetchUserUploadedCSVMetadata,
    handleFetchUserSummaryData,
    handleFetchOngoingIntegrationStatus,
    handleSetDeepIntegrated,
  };
};

export default useWallet;
