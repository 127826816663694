import {
  addEditTransactionDrawerStaticContent,
  labels,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/constants';
import styles from './SentSection.module.css';
import SearchComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/SearchComponent';
import TextFieldComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/TextfieldComponent';
import {
  hideSentSection,
  showSentSectionCoinInfo,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/helper';
import { replaceTemplateString } from '@/Utils/helper/replaceTemplateString';
import { useSelector } from 'react-redux';

const {
  sent,
  coinsPlaceholder,
  noOfCoins,
  amountInUserCurrency,
  marketPriceInUserCurrency,
} = addEditTransactionDrawerStaticContent;

const SentSection = ({ transaction, handleChange }) => {
  const baseCurrency = useSelector((state) => state.workspace.baseCurrency);

  if (hideSentSection(transaction?.type, transaction?.label)) {
    return null;
  }

  return (
    <div
      className={styles.Wrapper}
      data-testid="add-edit-txn-popup-sent-section">
      <div>
        <div className={styles.Title}>{sent}</div>
        <div className={styles.HorizontalLine} />
      </div>
      <div className={styles.Row}>
        <SearchComponent
          label={labels.OUTCOIN}
          onChange={handleChange}
          value={transaction?.outCoin}
          isCustomOptionCreationAllowed={true}
          data-testid="add-edit-txn-popup-sent-curr-selector"
        />
        <TextFieldComponent
          label={labels.OUTCOINAMOUNT}
          numeric={true}
          onChange={handleChange}
          placeholder={coinsPlaceholder}
          title={noOfCoins}
          value={transaction?.outCoinAmount}
          data-testid="add-edit-txn-popup-sent-coins-count-input"
        />
      </div>
      {showSentSectionCoinInfo(transaction?.type) && (
        <>
          <div className={styles.Row}>
            <TextFieldComponent
              label={labels.INCOINMARKETPRICE}
              numeric={true}
              onChange={handleChange}
              placeholder={coinsPlaceholder}
              title={replaceTemplateString(amountInUserCurrency, [
                baseCurrency,
              ])}
              value={transaction?.inCoinMarketPrice}
              data-testid="add-edit-txn-popup-sent-coins-amount-input"
            />
            <TextFieldComponent
              label={labels.NOTIONALINCOINMARKETPRICE}
              numeric={true}
              onChange={handleChange}
              placeholder={coinsPlaceholder}
              title={replaceTemplateString(marketPriceInUserCurrency, [
                baseCurrency,
              ])}
              value={transaction?.notionalInCoinMarketPrice}
              data-testid="add-edit-txn-popup-sent-coins-marketprice-input"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SentSection;
