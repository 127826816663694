import { memo } from 'react';
import { Drawer } from '@koinx/xui';
import { useSelector } from 'react-redux';
import styles from './DeepIntegrationPopup.module.css';
import { generateDeepIntegrationPopupContent } from './helper';
import DeepIntegration from './DeepIntegration';

const DeepIntegrationDrawerComp = ({ source, openDrawer, setOpenDrawer }) => {
  const sourcesData = useSelector((state) => state.sources.sources);
  const deepIntegrationSourceData = sourcesData.find(
    (sourceData) => sourceData.name === source
  );

  const staticContent = generateDeepIntegrationPopupContent(source);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Drawer
      isOpen={openDrawer}
      handleonOpenChange={setOpenDrawer}
      handleClose={handleClose}
      title={
        <div className={styles.HeaderWrapper}>
          <div className={styles.LogoWrapper}>
            <img src={deepIntegrationSourceData.logo} alt={source} width="30" />
            <h2 className={styles.HeaderText}>{staticContent.title}</h2>
          </div>
        </div>
      }
    >
      <DeepIntegration source={source} handleClose={handleClose} />
    </Drawer>
  );
};

const DeepIntegrationDrawer = memo(DeepIntegrationDrawerComp);
export default DeepIntegrationDrawer;
