import { OperatingCountriesEnums } from '@/Utils/enums/operatingCountries';
import { useSelector } from 'react-redux';
import styles from './TDSSection.module.css';
import {
  tdsSectionStaticData,
  addEditTransactionDrawerStaticContent,
  labels,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/constants';
import SearchComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/SearchComponent';
import TextFieldComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/TextfieldComponent';

const { tdstitle } = tdsSectionStaticData;
const { coinsPlaceholder, noOfCoins } = addEditTransactionDrawerStaticContent;

const TDSSection = ({ transaction, handleChange }) => {
  const { taxSettingsData } = useSelector((state) => state.workspace);

  if (taxSettingsData?.country === OperatingCountriesEnums.IN) {
    return (
      <div className={styles.Wrapper}>
        <div>
          <p className={styles.Title}>{tdstitle}</p>
          <div className={styles.HorizontalLine}></div>
        </div>
        <div className={styles.Row}>
          <SearchComponent
            value={transaction?.tdsCoin}
            onChange={handleChange}
            label={labels.TDSCOIN}
            isCustomOptionCreationAllowed={true}
            data-testid="add-edit-txn-popup-fees-coin-selector"
          />
          <TextFieldComponent
            title={noOfCoins}
            placeholder={coinsPlaceholder}
            onChange={handleChange}
            value={transaction?.tds}
            label={labels.TDS}
            numeric={true}
            data-testid="add-edit-txn-popup-fees-coins-count-input"
          />
        </div>
      </div>
    );
  }

  return null;
};

export default TDSSection;
