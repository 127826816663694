import KoinXLogo from '@/Assets/KoinxLogo.png';
import SignInImage from '@/Assets/SignIn/SignInImage.png';

export const staticContent = {
  logo: KoinXLogo,
  logoAlt: 'KoinX Logo',
  platformHeading: 'Crypto Accounting',
  title: 'Account, Manage and Monitor Crypto Finances.',
  illustration: SignInImage,
  illustrationAlt: 'Illustration image of calulator and a desktop monitor.',
};
