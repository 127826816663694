import { routeEnums } from '@/Utils/enums/routes';
import {
  AccountBalanceWallet,
  SyncAlt,
  Journal,
  Items,
  Bookmark,
  Shop,
  Checklist,
  Report,
  Chart,
  Home,
  Settings,
} from '@koinx/xui';
export const sidebarData = {
  states: {
    opened: 'Collapse',
    closed: 'Expand',
  },
  listItems: [
    // {
    //   name: 'Home',
    //   isExpandable: false,
    //   icon: Home,
    //   link: routeEnums.HOME_PAGE,
    // },  //TODO: Needed in v1, uncoment at that time
    {
      name: 'Data Sources',
      isExpandable: true,
      subListItems: [
        {
          name: 'Integrations',
          isExpandable: false,
          link: routeEnums.INTEGRATIONS_PAGE,
        },
        {
          name: 'Treasury Accounts',
          isExpandable: false,
          link: routeEnums.TREASURY_ACCOUNTS_PAGE,
        },
      ],
      icon: AccountBalanceWallet,
      link: routeEnums.DATA_SOURCE_PAGE,
    },
    {
      name: 'Transactions',
      isExpandable: false,
      icon: SyncAlt,
      link: routeEnums.TRANSACTIONS_PAGE,
    },
    {
      name: 'Journals',
      isExpandable: false,
      link: routeEnums.JOURNAL_TABLE_PAGE,

      icon: Journal,
    },
    // {
    //   name: 'Items',
    //   isExpandable: false,
    //   icon: Items,
    //   link: routeEnums.ITEMS_PAGE,
    // },
    // {
    //   name: 'Sales',
    //   isExpandable: false,
    //   icon: Bookmark,
    //   link: routeEnums.SALES_PAGE,
    // },
    // {
    //   name: 'Purchases',
    //   isExpandable: false,
    //   icon: Shop,
    //   link: routeEnums.PURCHASES_PAGE,
    // },  //TODO: Needed in v1, uncoment at that time
    {
      name: 'Chart of Accounts',
      isExpandable: false,
      icon: Chart,
      link: routeEnums.CHART_OF_ACCOUNTS_PAGE,
    },
    {
      name: 'Reports',
      isExpandable: false,
      icon: Report,
      link: routeEnums.REPORTS_PAGE,
    },
    // {
    //   name: 'Automation',
    //   isExpandable: false,
    //   icon: Checklist,
    //   link: routeEnums.AUTOMATION_PAGE,
    // },  //TODO: Needed in v1, uncoment at that time
    {
      name: 'Settings',
      icon: Settings,
      link: routeEnums.SETTINGS_PAGE,
      isExpandable: true,
      subListItems: [
        // { name: 'General', link: routeEnums.SETTINGS_GENERAL_PAGE },  //TODO: Needed in v1, uncoment at that time
        {
          name: 'Team',
          link: routeEnums.SETTINGS_TEAM_PAGE,
        },
        // { name: 'Customers', link: routeEnums.SETTINGS_CUSTOMERS_PAGE },
        // { name: 'Vendor', link: routeEnums.SETTINGS_VENDOR_PAGE },  //TODO: Needed in v1, uncoment at that time
      ],
    },
  ],
};
