import axios from 'axios';
import { RESEND_OTP_URL } from '@/Utils/constants';

export function resendOTP(email, userId) {
  const body = {
    email,
    userId,
  };

  Object.keys(body).forEach((key) => {
    if (body[key] === undefined || body[key] === null) {
      delete body[key];
    }
  });
  return axios.post(RESEND_OTP_URL, body);
}
