import { archiveLabelsEnum } from './enums/ArchiveLabelsEnum';

// TODO: remove unwanted labels after archive table gets data from the api
export const archiveLablesColorMap = {
  [archiveLabelsEnum.SPAM]: {
    backgroundColor: 'var(--badge-light-red)',
    color: 'var(--red-09)',
  },
  [archiveLabelsEnum.DUST]: {
    backgroundColor: '#3E57651A',
    color: 'var(--day-grey)',
  },
  [archiveLabelsEnum.SLIPPAGE]: {
    backgroundColor: '#9913B41A',
    color: '#9913B4',
  },
};
