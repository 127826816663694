export const getCoinObject = (
  metadata,
  transactionCoins,
  inCoinValue = null,
  outCoinValue = null
) => {
  if (transactionCoins) {
    if (inCoinValue) {
      if (metadata.inCoinContractAddress) {
        return transactionCoins[
          `${inCoinValue}@${metadata.inCoinContractAddress}`
        ];
      } else {
        return transactionCoins[inCoinValue];
      }
    }

    if (outCoinValue) {
      if (metadata.outCoinContractAddress) {
        return transactionCoins[
          `${outCoinValue}@${metadata.outCoinContractAddress}`
        ];
      } else {
        return transactionCoins[outCoinValue];
      }
    }
  }
};
