import { useCallback } from 'react';
import { useState } from 'react';

const useStorage = () => {
  const [storageMode, setStorageMode] = useState('localStorage');
  const [backupStorage, setBackupStorage] = useState({});

  const checkForStorage = useCallback(() => {
    try {
      if (localStorage?.length);
    } catch (e) {
      try {
        if (sessionStorage?.length);
        setStorageMode('sessionStorage');
      } catch (e) {
        setStorageMode('backupStorage');
      }
    }
  }, []);

  const storeInLocalStorage = useCallback(
    (key, value) => {
      if (storageMode === 'localStorage') {
        localStorage.setItem(key, value);
      } else if (storageMode === 'sessionStorage') {
        sessionStorage.setItem(key, value);
      } else {
        setBackupStorage({ ...backupStorage, [key]: value });
      }
    },
    [backupStorage, storageMode]
  );

  const getFromLocalStorage = useCallback(
    (key) => {
      if (storageMode === 'localStorage') {
        return localStorage.getItem(key);
      } else if (storageMode === 'sessionStorage') {
        return sessionStorage.getItem(key);
      } else {
        return backupStorage[key];
      }
    },
    [backupStorage, storageMode]
  );

  const removeFromLocalStorage = useCallback(
    (key) => {
      if (storageMode === 'localStorage') {
        localStorage.removeItem(key);
      } else if (storageMode === 'sessionStorage') {
        sessionStorage.removeItem(key);
      } else {
        const { [key]: value, ...rest } = backupStorage;
        setBackupStorage(rest);
      }
    },
    [backupStorage, storageMode]
  );

  const clearLocalStorage = useCallback(() => {
    if (storageMode === 'localStorage') {
      localStorage.clear();
    }
  }, [storageMode]);

  return {
    checkForStorage,
    storeInLocalStorage,
    getFromLocalStorage,
    removeFromLocalStorage,
    clearLocalStorage,
  };
};

export default useStorage;
