// import CustomizedInput from '@/Components/Onboarding/CustomizedInput';
import { integrationMethodsEnum } from '@/Utils/enums/integrationMethods';
import { routeEnums } from '@/Utils/enums/routes';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ChainView from '@/Components/AddExchangesPage/Components/IntegrationMethodsView/ChainView';
import styles from './SelectConnectionType.module.css';
import HeadingSection from '@/Components/AddExchangesPage/Components/HeadingSection/HeadingSection';
import useSources from '@/Hooks/useSources';
import BackButton from '@/Components/Ui/BackButton';
import {
  ADD_CUSTOM_NAME,
  KOINX,
} from '@/Components/AddExchangesPage/constants';
import TabPanel from '@/Components/AddExchangesPage/Components/TabPanel/TabPanel';
import AlertSectionDrawer from '@/Components/AddExchangesPage/Components/AlertSection/AlertSectionDrawer';
import HowToIntegrateDrawer from '@/Components/AddExchangesPage/Components/HowToIntegrate/HowToIntegrateDrawer';

import { Input } from '@koinx/xui';
import { getCustomNameIntegration } from '@/Utils/helper/getCustomNameIntegration';

const SelectConnectionType = ({ selectedMethod, setSelectedMethod }) => {
  const { wallets: userWallets } = useSelector((state) => state.wallet);
  const { userData } = useSelector((state) => state.user);
  const { selectedIntegration } = useSelector((state) => state.sources);

  const { handleSetSelectedIntegration } = useSources();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();

  const [customName, setCustomName] = useState(() =>
    getCustomNameIntegration(userWallets, selectedIntegration)
  );
  const handleCustomNameChange = ({ target: { value } }) => {
    setCustomName(value);
  };

  const handleBackBtn = () => {
    if (params.walletId === KOINX || searchParams.get('wallet')) {
      navigate(`${routeEnums.ADD_WALLET_PAGE}`);
    } else {
      if (params?.walletId) {
        navigate(`${routeEnums.WALLET_DETAILS_PAGE}/${params.walletId}`);
      }
    }
    handleSetSelectedIntegration(null);
  };
  const isIntegratedWalletFileSyncFlow =
    params?.walletId && params?.walletId !== KOINX;

  return (
    <>
      {' '}
      <BackButton className={styles.BackBtn} clickFn={handleBackBtn} />
      <div className={styles.Wrapper}>
        <HeadingSection
          title={`Integrate ${selectedIntegration?.displayName}`}
        />
        <div className={styles.MainWrapper}>
          {/* {selectedIntegration?.sourceType === integrationMethodsEnum.CHAIN &&
            isMobileScreen && (
              <div className={styles.BtnContainer}>
                <AlertSectionDrawer selectedMethod={selectedMethod} />
                <HowToIntegrateDrawer selectedMethod={selectedMethod} />
              </div>
            )} */}
          {!isIntegratedWalletFileSyncFlow && (
            <Input
              onChange={handleCustomNameChange}
              value={customName}
              label={ADD_CUSTOM_NAME}
              name="customName"
              width="100%"
              autoFocus={true}
              placeholder={`${userData?.firstName}’s ${selectedIntegration?.displayName}`}
            />
          )}
          {selectedIntegration?.sourceType === integrationMethodsEnum.CHAIN ? (
            <ChainView customName={customName} />
          ) : (
            <TabPanel
              setSelectedMethod={setSelectedMethod}
              selectedMethod={selectedMethod}
              customName={customName}
            />
          )}
        </div>
      </div>
    </>
  );
};

const MemoizedSelectConnectionType = React.memo(SelectConnectionType);
export default MemoizedSelectConnectionType;
