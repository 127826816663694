import dateFormatter from '@/Utils/helper/formatDate';
import styles from '@/Components/Routes/TransactionsPage/CustomTable/CustomTable.module.css';
import { TransactionLabelToIconMap } from '@/Utils/TransactionLabelToIconMap';
import { capitalise } from '@/Utils/capitalise';
const DateAndTypeCell = ({ getValue, row }) => {
  const value = getValue();
  const type = row.original.externalLabel || 'Default';
  const label = row.original.externalLabel || 'Default';
  const Icon = TransactionLabelToIconMap[capitalise(label)];

  return (
    <div className={styles.TypeCellWrapper}>
      <Icon
        data-testid="transactionDetails-typeIcon"
        className={styles.TypeIcon}
      />
      <div className={styles.TypeWrapper}>
        <p className={styles.Type} title={type}>
          {type}
        </p>
      </div>
      <p className={styles.Date}>{dateFormatter(value, true, false)}</p>
    </div>
  );
};
export default DateAndTypeCell;
