import { ToolTip } from '@koinx/xui';
import styles from '@/Components/Ui/Sidebar/SidebarTab/SidebarTab.module.css';
import { useNavigate } from 'react-router';

const NotNestedFixedTab = ({ tab, isActiveTab }) => {
  const navigate = useNavigate();
  return (
    <ToolTip
      className={styles.TooltipClass}
      content={tab.name}
      side="right"
      sideOffset={5}
    >
      <div
        data-testid={tab.name}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate(tab.link);
          }
        }}
        tabIndex={0}
        onClick={() => navigate(tab.link)}
        data-collapsed-fixed-tab-active={isActiveTab(tab.link)}
        className={styles.FixedTab}
      >
        <div>
          <div className={styles.NameAndIconWrapper}>
            {tab.icon ? <tab.icon className={styles.TabIcon} /> : <></>}
          </div>
        </div>
      </div>
    </ToolTip>
  );
};

export default NotNestedFixedTab;
