import {
  addEditTransactionDrawerStaticContent,
  labels,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/constants';
import TextFieldComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/TextfieldComponent';

const { fromTitle, toTitle, hashTitle } = addEditTransactionDrawerStaticContent;

const AccountingSpecificFields = ({ transaction, onChange }) => {
  return (
    <>
      <FromAndTo onChange={onChange} transaction={transaction} />
      <TextFieldComponent
        label={labels.TXNHASH}
        onChange={onChange}
        placeholder={'0x123123123123123...'}
        title={hashTitle}
        value={transaction?.txnHash}
      />
    </>
  );
};

const FromAndTo = ({ transaction, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'stretch',
        gap: '1rem',
        marginBottom: '1rem',
      }}>
      <TextFieldComponent
        label={labels.FROMADDRESS}
        onChange={onChange}
        placeholder={'0x123123123123123...'}
        title={fromTitle}
        value={transaction?.fromAddress}
      />
      <TextFieldComponent
        label={labels.TOADDRESS}
        onChange={onChange}
        placeholder={'0x123123123123123...'}
        title={toTitle}
        value={transaction?.toAddress}
      />
    </div>
  );
};

export default AccountingSpecificFields;
