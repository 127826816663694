import { supportedCountries } from '@/Utils/enums/supportedCountries';

export const extractPopularIntegrations = (sources, userCountry) => {
  return sources.filter((source) => {
    const isPartnered =
      source?.partnerCountries?.includes(userCountry) ||
      source?.partnerCountries?.includes(supportedCountries.WORLD);

    const isPopular =
      source?.popularCountries?.includes(userCountry) ||
      source?.popularCountries?.includes(supportedCountries.WORLD);
    return !isPartnered && isPopular;
  });
};
