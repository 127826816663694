import { capitalise } from '@/Utils/capitalise';
import { TabTypesEnum } from '@/Utils/enums/tabTypes';

export const getTabs = (count, activeTab) => {
  let tabsArray = [];
  tabsArray.push(
    {
      label: `${capitalise(TabTypesEnum.UNCATEGORIZED)} ${activeTab === TabTypesEnum.UNCATEGORIZED ? `(${count})` : ''}`,
      value: `${TabTypesEnum.UNCATEGORIZED}`,
    },
    {
      label: `${capitalise(TabTypesEnum.CATEGORIZED)} ${activeTab === TabTypesEnum.CATEGORIZED ? `(${count})` : ''}`,
      value: `${TabTypesEnum.CATEGORIZED}`,
    },
    {
      label: `${capitalise(TabTypesEnum.ARCHIVED)} ${activeTab === TabTypesEnum.ARCHIVED ? `(${count})` : ''}`,
      value: `${TabTypesEnum.ARCHIVED}`,
    }
  );
  return tabsArray;
};
