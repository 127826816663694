import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  workspaceID: null,
  workspaceName: null,
  error: null,
  loading: false,
  message: null,
  email: null,
  inviteId: null,
  inviteLink: null,
  isUserWorkspaceInviteListLoading: false,
  userWorkspaceInviteList: [],
  isUserRevokeInviteLoading: false,
  isInviteUserLoading: false,
};

const accountingInviteSlice = createSlice({
  name: 'accountingInvite',
  initialState,
  reducers: {
    setInviteId: (state, action) => {
      state.inviteId = action.payload;
    },
    setGetInviteDetails: (state, action) => {
      state.loading = true;
    },
    setGetInviteDetailsSuccess: (state, action) => {
      state.loading = false;
      state.workspaceID = action.payload.workspace._id;
      state.workspaceName = action.payload.workspace.name;
      state.email = action.payload.inviteeMail;
    },
    setGetInviteDetailsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setAcceptInviteDetails: (state) => {
      state.loading = true;
    },
    setAcceptInviteDetailsSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    setAcceptInviteDetailsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setUserWorkspaceInviteList: (state, action) => {
      state.isUserWorkspaceInviteListLoading = true;
    },
    setUserWorkspaceInviteListSuccess: (state, action) => {
      state.isUserWorkspaceInviteListLoading = false;
      state.userWorkspaceInviteList = action.payload;
    },
    setUserWorkspaceInviteListError: (state, action) => {
      state.isUserWorkspaceInviteListLoading = false;
      state.error = action.payload;
    },
    setInviteeRevokeInvite: (state) => {
      state.isUserRevokeInviteLoading = true;
    },
    setInviteeRevokeInviteSuccess: (state) => {
      state.isUserRevokeInviteLoading = false;
    },
    setInviteeRevokeInviteError: (state, action) => {
      state.isUserRevokeInviteLoading = false;
      state.error = action.payload;
    },
    setUserInvite: (state) => {
      state.isInviteUserLoading = true;
    },
    setUserInviteSuccess: (state, action) => {
      state.isInviteUserLoading = false;
      state.inviteLink = action.payload.inviteLink;
    },
    setUserInviteError: (state, action) => {
      state.isInviteUserLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setAcceptInviteDetails,
  setAcceptInviteDetailsError,
  setAcceptInviteDetailsSuccess,
  setGetInviteDetails,
  setGetInviteDetailsError,
  setGetInviteDetailsSuccess,
  setUserWorkspaceInviteList,
  setUserWorkspaceInviteListSuccess,
  setUserWorkspaceInviteListError,
  setInviteeRevokeInvite,
  setInviteeRevokeInviteSuccess,
  setInviteeRevokeInviteError,
  setUserInvite,
  setUserInviteSuccess,
  setUserInviteError,
  setInviteId,
} = accountingInviteSlice.actions;

export default accountingInviteSlice.reducer;
