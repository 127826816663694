import { CountryToCurrency } from '@/Utils/countryToCurrencyMapping';
import { countryData } from '@/Utils/countryData';

export const fiatCoins = Object.keys(CountryToCurrency).reduce((acc, key) => {
  acc[CountryToCurrency[key]] = {
    name: countryData[key].currency,
    symbol: countryData[key].currencyCode,
    slug: countryData[key].currency,
    image: countryData[key].currencyLogo,
  };
  return acc;
}, {});
