import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

// import ImportBlockchainPopup from '@src/Components/AddExchangesPage/Components/OldComponents/ImportBlockchainPopup';
import syncBlockchainWallet from '@/Services/blockchainIntegration/syncBlockchainWallet';
import useAxiosPrivate from './useAxiosPrivate';
// import usePopup from './usePopup';
import { navigateToIntegrationLoading } from '@/Utils/helper/navigateToIntegrationLoading';
import notify from '@/Utils/notifyToast';
import {
  setSyncBlockchainWallet,
  setSyncBlockchainWalletError,
  setSyncBlockchainWalletSuccess,
} from '@/Redux/slices/blockchainIntegrationSlice';
import getErrorStatement from '@/Utils/helper/getErrorStatement';

function useBlockchainIntegration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  // const { handlePopupCenterOpen, handlePopupCenterComponentRender } =
  //   usePopup();

  const handleSyncBlockchainWallet = async (
    customWalletName,
    source,
    address,
    autoSuggested = true,
    chainId = 1,
    key // this is optional and is used to identify certain services like wallet
  ) => {
    try {
      dispatch(setSyncBlockchainWallet());
      const response = await syncBlockchainWallet(axiosPrivate, {
        customWalletName,
        source,
        address,
        autoSuggested,
        chainId,
      });
      dispatch(setSyncBlockchainWalletSuccess(response?.data?.message));
      // Not in use currently
      // handleGetUserSpinWheelResult();
      // if (response?.data?.otherSources?.length > 0) {
      //   handlePopupCenterOpen(true);
      //   handlePopupCenterComponentRender(
      //     <ImportBlockchainPopup
      //       userBLockchains={response?.data?.otherSources}
      //       address={response?.data?.address}
      //     />
      //   );
      // }
      navigateToIntegrationLoading(navigate, dispatch);
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(setSyncBlockchainWalletError(getErrorStatement(error)));
        if (
          error?.response?.data?.errors?.[0]?.message !== 'Invalid chainId' &&
          key !== 'WalletConnect' // wallet connect usually gives invalid chainid, which will result in too many notification stacks this is to avoid the same
        ) {
          notify(getErrorStatement(error));
        }
      }
    }
  };

  return { handleSyncBlockchainWallet };
}

export default useBlockchainIntegration;
