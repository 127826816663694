import CoinDCXApp from '@/Assets/Partnerships/CoinDCXApp.png';
import SunCryptoApp from '@/Assets/Partnerships/SunCryptoApp.png';

export const DEEP_INTEGRATION_MAPPING = {
  coindcx: {
    displayName: 'CoinDCX',
    localStorageflagName: 'fromCoinDCX',
    value: 'coindcx',
    isReturningUserLocalStorageFlag: 'isReturningUserFromCoinDCX',
    popupImage: CoinDCXApp,
    popupTitle: `Open CoinDCX app. Under your profile, click on “Tax Report with KoinX”.`,
    deepLink: 'dcxgo://go.coindcx.com/account',
  },
  suncrypto: {
    displayName: 'SunCrypto',
    localStorageflagName: 'fromSunCrypto',
    value: 'suncrypto',
    isReturningUserLocalStorageFlag: 'isReturningUserFromSunCrypto',
    popupImage: SunCryptoApp,
    popupTitle: `Open SunCrypto app. Under your profile, click on “Tax Reports”. \n (Update the app for latest features)`,
  },
};
