import { useSelector } from 'react-redux';

export const useUserHasPermission = (permissionCategory, permission = null) => {
  const { currentWorkspaceId, userData } = useSelector((state) => state.user);

  const workspace = userData?.workspaces.find(
    (item) => item._id === currentWorkspaceId
  );

  if (!workspace?.role?.permissions?.[permissionCategory]) {
    return false;
  }

  if (permission) {
    return workspace.role.permissions[permissionCategory].includes(permission);
  }

  return true;
};
