import React, { useCallback, useEffect, useRef, useState } from 'react';
import AlreadyMember from '@/Components/UserOnboarding/AlreadyMember';
import styles from './SignUpComponent.module.css';
import { routeEnums } from '@/Utils/enums/routes';
import { Button, Building, Input } from '@koinx/xui';
import { useSelector } from 'react-redux';
import useAuth from '@/Hooks/useAuth';
import notify from '@/Utils/notifyToast';
import ReCAPTCHA from 'react-google-recaptcha';
import { Skeleton } from '@mui/material';
import { staticContent } from './constants';

const {
  infoText,
  title,
  firstName,
  LastName,
  emailText,
  button,
  notifyText,
  termsOfUse,
} = staticContent;

const SignUpComponent = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
  });
  const { handleBeginAuth } = useAuth();
  const { email, workspaceName } = useSelector(
    (state) => state.accountingInvite
  );
  const { loading } = useSelector((state) => state.auth);

  const recaptchaRef = useRef();
  const [token, setToken] = useState(null);

  const handleChangeUserDetails = (event) => {
    setUserDetails((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleRecaptchaVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  const handleRecaptchaExpired = (token) => {
    setToken(token);
  };
  const resetCaptcha = () => {
    recaptchaRef?.current?.reset();
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    !userDetails.firstName || !email
      ? notify(notifyText, 'error')
      : handleBeginAuth(
          email,
          token,
          resetCaptcha,
          userDetails.firstName,
          userDetails.lastName
        );
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.UpperWrapper}>
        {workspaceName ? (
          <div className={styles.WorkspaceWrapper}>
            <Building className={styles.WorkspaceIcon} />
            <h3 className={styles.WorkspaceName}>{workspaceName}</h3>
          </div>
        ) : (
          <Skeleton variant="rectangular" width={100} height={20} />
        )}

        <AlreadyMember infoText={infoText} link={routeEnums.SIGN_IN_PAGE} />
      </div>
      <div className={styles.SignInWrapperWrapper}>
        <div className={styles.SignInWrapper}>
          <h1 className={styles.SignUpText}>{title}</h1>
          <div className={styles.NameFieldsWrappper}>
            <Input
              required
              label={firstName.text}
              value={userDetails.firstName}
              onChange={handleChangeUserDetails}
              placeholder={firstName.placeholder}
              width="100%"
              name={firstName.name}
            />
            <Input
              label={LastName.text}
              value={userDetails.lastName}
              onChange={handleChangeUserDetails}
              placeholder={LastName.placeholder}
              name={LastName.name}
              width="100%"
            />
          </div>
          <Input
            label={emailText}
            disabled={true}
            value={email ? email : ''}
            width="100%"
          />

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={handleRecaptchaVerify}
            onExpired={handleRecaptchaExpired}
          />
          <Button
            variant={'primary'}
            onClick={handleOnSubmit}
            isLoading={loading}
            fullWidth
            size={'lg'}
          >
            {loading ? button.loading : button.default}
          </Button>
          <div className={styles.TermsOfUse}>
            <p className={styles.Text}>
              <span>{termsOfUse.text}</span>
              <a href={routeEnums.TERMS_AND_CONDITIONS} className={styles.Link}>
                {termsOfUse.terms}
              </a>
              <span> and </span>
              <a href={routeEnums.PRIVACY_POLICY} className={styles.Link}>
                {termsOfUse.policy}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpComponent;
