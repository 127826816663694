import { memo } from 'react';
import usePopup from '@/Hooks/usePopup';
import DeepIntegration from './DeepIntegration';

const DeepIntegrationPopupComp = ({ source }) => {
  const { handlePopupCenterOpen } = usePopup();
  const handleClosePopup = () => {
    handlePopupCenterOpen(false);
  };

  return (
    <DeepIntegration source={source} handleClose={handleClosePopup} isPopup />
  );
};

const DeepIntegrationPopup = memo(DeepIntegrationPopupComp);
export default DeepIntegrationPopup;
