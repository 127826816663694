export const invitePopUpData = {
  title: 'Invite Member',
  selectRoleText: 'Select Role',
  inputPlaceholder: 'john.doe@gmail.com',
  viewAllPermissionsText: 'View All Permissions',
  btnText: 'Send Invite',
  invalidEmailText: 'Invalid Email.',
  emailInputLabel: 'Email Address',
  dropdownPlaceholder: 'Select a role',
};
