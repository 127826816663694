export const drawerTitle = 'Transaction Details';

export const transactionDetailsFields = {
  type: 'Type',
  status: 'Status',
  timeStamp: 'Date and Time',
  transactionId: 'Transaction ID',
  from: 'From',
  to: 'To',
  feesTitle: 'Fees',
  gainsTitle: 'Gains',
  tdsTitle: 'TDS',
  accountlable: 'Account',
};
