import { ArrowBack, Button } from '@koinx/xui';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BackButton.module.css';

const BackButton = ({ customText = 'Back', clickFn, className, ...props }) => {
  const navigate = useNavigate();

  const handleCtaClick = () => {
    navigate(-1);
  };

  return (
    <Button
      onClick={clickFn ?? handleCtaClick}
      buttonType="icon_left"
      variant="subtle"
      data-testid="back-button"
      className={`${styles.BackBtn} ${className ? className : ''}`}
      icon={<ArrowBack className={styles.BackBtn} />}
      {...props}
    />
  );
};

export default BackButton;
