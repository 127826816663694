import React, { useState } from 'react';
import styles from './ChartOfAccountsTable.module.css';
import ChartOfAccountsTableSkeleton from '@/Components/Routes/ChartOfAccounts/ChartOfAccountsTableSkeleton/ChartOfAccountsTableSkeleton';
import { handleRowExpansionChange, processData } from './helper';

import { noMatchText } from './constants';
import CreateAccountDrawer from '@/Components/Routes/ChartOfAccounts/CreateAccountDrawer';
import CustomNestedTable from '@/Components/Routes/ChartOfAccounts/CustomNestedTable/CustomNestedTable';

import { permissionsCategoryEnums } from '@/Utils/enums/permissionsCategory';
import { permissionsEnums } from '@/Utils/enums/permissions';
import { useUserHasPermission } from '@/Hooks/useUserHasPermission';

const ChartOfAccountsTable = () => {
  const [openEditAccountDrawer, setOpenEditAccountDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const onDismiss = () => {
    setOpenEditAccountDrawer(false);
  };
  const userCanEditAccounts = useUserHasPermission(
    permissionsCategoryEnums.ACCOUNTS,
    permissionsEnums.UPDATE
  );
  const handleOptionClick = (rowData) => {
    setOpenEditAccountDrawer(true);
    setSelectedRowData(rowData);
  };

  return (
    <div className={styles.TableContainer}>
      <CustomNestedTable
        processData={processData}
        handleRowExpansionChange={handleRowExpansionChange}
        handleOptionClick={handleOptionClick}
        isTreasuryFlow={false}
        SkeletonComponent={() => (
          <ChartOfAccountsTableSkeleton
            rowCount={8}
            columnCount={userCanEditAccounts ? 6 : 5}
          />
        )}
        noMatchText={noMatchText}
      />
      {openEditAccountDrawer && (
        <CreateAccountDrawer
          rowData={selectedRowData}
          isEditFlow={true}
          isOpen={openEditAccountDrawer}
          handleClose={onDismiss}
          handleOnOpenChange={setOpenEditAccountDrawer}
          width={'40%'}
        />
      )}
    </div>
  );
};

export default ChartOfAccountsTable;
