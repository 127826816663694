import Enum from './enums';

class OperatingCountries extends Enum {
  static IN = 'INDIA';
  static AU = 'AUSTRALIA';
  static UK = 'UNITED KINGDOM';
  static JP = 'JAPAN';
  static US = 'UNITED STATES';
  static CA = 'CANADA';
  static BRL = 'BRAZIL';
  static GER = 'GERMANY';
  static IDR = 'INDONESIA';
  static SWEDEN = 'SWEDEN';
  static DENMARK = 'DENMARK';

  static AL = 'ALBANIA';
  static DZ = 'ALGERIA';
  static AD = 'ANDORRA';
  static AR = 'ARGENTINA';
  static AM = 'ARMENIA';
  static AT = 'AUSTRIA';
  static AZ = 'AZERBAIJAN';
  static BD = 'BANGLADESH';
  static BE = 'BELGIUM';
  static BA = 'BOSNIA AND HERZEGOVINA';
  static BG = 'BULGARIA';
  static KH = 'CAMBODIA';
  static CL = 'CHILE';
  static CN = 'CHINA';
  static CO = 'COLOMBIA';
  static CY = 'CYPRUS';
  static CZ = 'CZECH REPUBLIC';
  static EC = 'ECUADOR';
  static EG = 'EGYPT';
  static EE = 'ESTONIA';
  static FI = 'FINLAND';
  static FR = 'FRANCE';
  static GE = 'GEORGIA';
  static GH = 'GHANA';
  static GR = 'GREECE';
  static HK = 'HONG KONG';
  static HU = 'HUNGARY';
  static IS = 'ICELAND';
  static IR = 'IRAN';
  static IE = 'IRELAND';
  static IL = 'ISRAEL';
  static IT = 'ITALY';
  static KZ = 'KAZAKHSTAN';
  static KE = 'KENYA';
  static KR = 'SOUTH KOREA';
  static KG = 'KYRGYZSTAN';
  static LA = 'LAOS';
  static LV = 'LATVIA';
  static LI = 'LIECHTENSTEIN';
  static LT = 'LITHUANIA';
  static LU = 'LUXEMBOURG';
  static MK = 'NORTH MACEDONIA';
  static MY = 'MALAYSIA';
  static MT = 'MALTA';
  static MX = 'MEXICO';
  static MD = 'MOLDOVA';
  static MN = 'MONGOLIA';
  static ME = 'MONTENEGRO';
  static MA = 'MOROCCO';
  static NL = 'NETHERLANDS';
  static NZ = 'NEW ZEALAND';
  static NG = 'NIGERIA';
  static NO = 'NORWAY';
  static PK = 'PAKISTAN';
  static PY = 'PARAGUAY';
  static PE = 'PERU';
  static PH = 'PHILIPPINES';
  static PL = 'POLAND';
  static PT = 'PORTUGAL';
  static RO = 'ROMANIA';
  static RS = 'SERBIA';
  static SG = 'SINGAPORE';
  static SK = 'SLOVAKIA';
  static SI = 'SLOVENIA';
  static ZA = 'SOUTH AFRICA';
  static ES = 'SPAIN';
  static LK = 'SRI LANKA';
  static CH = 'SWITZERLAND';
  static TH = 'THAILAND';
  static UA = 'UKRAINE';
  static AE = 'UNITED ARAB EMIRATES';
  static UZ = 'UZBEKISTAN';
  static VE = 'VENEZUELA';
  static VN = 'VIETNAM';
  static ZW = 'ZIMBABWE';
}

export const OperatingCountriesEnums = Object.freeze(OperatingCountries);
