import { useNavigate } from 'react-router';
import SomethingWentWrongImage from '@/Assets/SomethingWentWrongPage/SomethingWentWrongImage.svg';
import styles from './UnderMaintainance.module.css';
import { UnderMaintainancePageData } from './constants';

const content = UnderMaintainancePageData;
const UnderMaintainance = () => {
  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate('/');
  };
  const handleGoToBlog = () => {
    window.open('https://koinx.com/blog');
  };

  return (
    <div className={styles.Wrapper}>
      <img src={SomethingWentWrongImage} alt="" className={styles.Image} />
      <h1 className={styles.Heading}> {content.heading} </h1>
      <p className={styles.SubHeading}>{content.subHeading}</p>
      {/* <Button
        className={styles.Btn}
        text={content.cta[0]}
        btnType={'primary'}
        onClick={handleGoToHome}
      />
      <Button
        className={styles.Btn}
        text={content.cta[1]}
        onClick={handleGoToBlog}
      /> */}
    </div>
  );
};

export default UnderMaintainance;
