import Enum from './enums';

class SupportedCountries extends Enum {
  static AUSTRALIA = 'AUSTRALIA';
  static INDIA = 'INDIA';
  static UK = 'UNITED KINGDOM';
  static JP = 'JAPAN';
  static US = 'UNITED STATES';
  static CA = 'CANADA';
  static WORLD = 'WORLD';
}

export const supportedCountries = Object.freeze(SupportedCountries);
