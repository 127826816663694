import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './UserIntegrationsPage.module.css';
import AddWalletsSkeleton from '@/Components/Routes/DataSources/AddWalletsSkeleton';
import UserIntegrations from '@/Components/Routes/DataSources/UserIntegrations';

const UserIntegrationsPage = () => {
  const { loading: isLoading } = useSelector((state) => state.wallet);
  const sourcesLoading = useSelector((state) => state.sources.loading);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.WalletWrapper}>
        {isLoading && sourcesLoading ? (
          <AddWalletsSkeleton />
        ) : (
          <UserIntegrations />
        )}
      </div>
    </div>
  );
};

export default UserIntegrationsPage;
