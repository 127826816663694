import ChartOfAccountsWrapper from '@/Components/Routes/ChartOfAccounts/ChartOfAccountsWrapper/ChartOfAccountsWrapper';
import React from 'react';
import styles from './ChartOfAccountsPage.module.css';
const ChartOfAccountsPage = () => {
  return (
    <div className={styles.Wrapper}>
      <ChartOfAccountsWrapper />
    </div>
  );
};

export default ChartOfAccountsPage;
