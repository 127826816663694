import Enum from './enums';

class OAuth extends Enum {
  static COINJAR = 'coinjar';
  static BITBNS = 'bitbns';
  static BINANCE = 'binance';
  static BINANCE_OAUTH = 'binance-oauth';
  static MUDREX = 'mudrex';
}

export const OAuthEnum = Object.freeze(OAuth);
