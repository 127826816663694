import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cursor: 0,
  rowsPerPage: 10,
  isJournalsListLoading: false,
  journalsList: [],
  totalJournalCount: 0,
  isAddJournalLoading: false,
  addJournalSuccess: null,
  addJournalError: null,
  isEditJournalLoading: false,
  editJournalSuccess: null,
  editJournalError: null,
  isJournalDetailsLoading: false,
  journalDetails: null,
  journalDetailsError: null,
  error: null,
};

const journalTableSlice = createSlice({
  name: 'journalTable',
  initialState,
  reducers: {
    setCursorandRowsPerPageInJournals: (state, action) => {
      state.cursor = action.payload.cursor;
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    setJournalTable: (state) => {
      state.isJournalsListLoading = true;
    },
    setJournalTableSuccess: (state, action) => {
      state.isJournalsListLoading = false;
      state.journalsList = action.payload.journalsList;
      state.totalJournalCount = action.payload.totalJournalCount;
    },
    setJournalTableError: (state, action) => {
      state.isJournalsListLoading = false;
      state.error = action.payload;
    },
    setAddJournal: (state) => {
      state.isAddJournalLoading = true;
    },
    setAddJournalSuccess: (state, action) => {
      state.isAddJournalLoading = false;
      state.addJournalSuccess = action.payload;
    },
    setAddJournalError: (state, action) => {
      state.isAddJournalLoading = false;
      state.addJournalError = action.payload;
    },
    setEditJournal: (state) => {
      state.isEditJournalLoading = true;
    },
    setEditJournalSuccess: (state, action) => {
      state.isEditJournalLoading = false;
      state.editJournalSuccess = action.payload;
    },
    setEditJournalError: (state, action) => {
      state.isEditJournalLoading = false;
      state.editJournalError = action.payload;
    },
    setJournalDetails: (state) => {
      state.isJournalDetailsLoading = true;
    },
    setJournalDetailsSuccess: (state, action) => {
      state.isJournalDetailsLoading = false;
      state.journalDetails = action.payload;
    },
    setJournalDetailsError: (state, action) => {
      state.isJournalDetailsLoading = false;
      state.journalDetailsError = action.payload;
    },
  },
});

export const {
  setJournalTable,
  setJournalTableSuccess,
  setJournalTableError,
  setAddJournal,
  setAddJournalError,
  setAddJournalSuccess,
  setEditJournal,
  setEditJournalError,
  setEditJournalSuccess,
  setJournalDetails,
  setJournalDetailsSuccess,
  setJournalDetailsError,
  setEditJournalFlow,
  setCursorandRowsPerPageInJournals,
} = journalTableSlice.actions;

export default journalTableSlice.reducer;
