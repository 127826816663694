import { TableBody, TableCell, TableRow, Skeleton } from '@mui/material';

const TableSkeleton = ({ rowCount = 5, columnCount = 5 }) => {
  return (
    <TableBody data-testid="transactionPage-skeletonTable">
      {Array.from({ length: rowCount }).map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from({ length: columnCount }).map((_, colIndex) => (
            <TableCell key={colIndex}>
              <Skeleton height={40} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TableSkeleton;
