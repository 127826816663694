import Enum from './enums';

class IntegrationTypeEnum extends Enum {
  static FILE_UPLOAD = 'File Upload';
  static API = 'API';
  static ADDRESS = 'Address';
  static OAUTH = 'OAuth';
}

export const integrationTypeEnum = Object.freeze(IntegrationTypeEnum);
