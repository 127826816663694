import { OAuthEnum } from '@/Utils/enums/OAuthEnum';
import { sourceTypesEnum } from '@/Utils/enums/sourceTypes';
import UploadIcon from '@/Assets/WalletPage/UploadIcon.svg';
import CSVIcon from '@/Assets/WalletPage/CSVIcon.svg';
import DeleteIcon from '@/Assets/WalletPage/DeleteIcon.svg';
import EmptyStateImage from '@/Assets/WalletPage/EmptyStateImage.svg';
import SpreadSheetWhite from '@/Assets/General/SpreadSheetWhite.svg';
import SpreadSheetIcon from '@/Assets/General/SpreadSheetIcon.svg';
import KoinXCustomSpreadSheetTemplate from '@/Assets/WalletPage/KoinXCustomSpreadSheetTemplate.xlsx';
import { routeEnums } from '@/Utils/enums/routes';
import { ReactComponent as WalletIcon } from '@/Assets/Icons/WalletIcon.svg';
import GreenTickIcon from '@/Assets/General/GreenTickIcon.svg';
import { ReactComponent as DownArrow } from '@/Assets/General/DownArrow.svg';
import AddIntegrationIcon from '@/Assets/Integrations/WalletMobile.svg';
import TradePlatformCoinSwitch from '@/Assets/WalletPage/TradePlatformCoinSwitch.png';
export const KOINX = 'koinx';

function mapToKeyValue(obj) {
  const keyValuePairs = {};
  Object.keys(obj)
    .map((key) => key)
    .forEach((path) => {
      const splittedPath = path.split('/');
      const filename = splittedPath.pop().split('.')[0]; // Extract filename without extension
      const remainingPath = splittedPath.slice(2).join('/');

      //why not directly use path? https://github.com/vitejs/vite/issues/14581
      keyValuePairs[filename] = new URL(
        `../Assets/${remainingPath}/${filename}.svg`,
        import.meta.url
      ).href;
    });
  return keyValuePairs;
}

const exchangePlatformAssets = mapToKeyValue(
  import.meta.glob(
    '../Assets/DataSourcesPage/ExchangePlatform/*.{png,jpeg,jpg,svg}',
    { query: '?raw', import: 'default' }
  )
);

export const integrationsContainerData = {
  integrationValuesToNameMaps: {
    [sourceTypesEnum.EXCHANGE]: 'Exchanges',
    [sourceTypesEnum.POPULAR]: 'Popular',
    [sourceTypesEnum.WALLET]: 'Wallets',
    [sourceTypesEnum.CHAIN]: 'Blockchains',
    [sourceTypesEnum.DEX]: 'Decentralized Exchanges',
    [sourceTypesEnum.PARTNERS]: 'Partners',
    // [sourceTypesEnum.NFT]: 'NFTs',
  },
  title: 'Add Integration',
  filterButtons: [
    {
      btnText: 'All integrations',
      value: sourceTypesEnum.ALL,
    },
    { btnText: 'Partners', value: sourceTypesEnum.PARTNERS },
    { btnText: 'Popular', value: sourceTypesEnum.POPULAR },
    { btnText: 'Exchanges', value: sourceTypesEnum.EXCHANGE },
    { btnText: 'Wallets', value: sourceTypesEnum.WALLET },
    { btnText: 'Blockchains', value: sourceTypesEnum.CHAIN },
    // { btnText: 'NFTS', value: sourceTypesEnum.NFT },
    { btnText: 'Decentralized Exchanges', value: sourceTypesEnum.DEX },
  ],
  mobilePlaceholder: 'Search',
  placeholder: 'Search your wallets, exchanges & integrations...',
};

export const emptyStateComponentData = {
  heading: 'No Results Found',
  subHeading: 'Don’t worry, You can use our custom file format',
  btnText: 'Use KoinX Custom Format',
  EmptyStateImage,
  SpreadSheetWhite,
};

export const invalidFileFormatPopupData = {
  heading: 'Invalid File Format',
  description:
    'Seems the file format you’ve uploaded is incorrect. Please try gain',
  // subHeading: 'How to integrate Binance with KoinX?',
  btnTexts: ['Read Step by Step Blog', 'Try Again'],
};

export const tabPanelData = {
  DIRECT_CONNECT: 'Direct Connect',
  UPLOAD_DATA: 'Upload Data',
  RECOMMENDED: 'Recommended',
  recommendedBoxExceptions: [OAuthEnum.BINANCE],
};

export const fileUploadComponent = {
  uploadText: 'Click or drag file to this area to upload',
  uploadButtonText: 'Submit',
  uploadDocsText: 'documents uploaded',
  UploadIcon,
  CSVIcon,
  SpreadSheetIcon,
  KoinXCustomSpreadSheetTemplate,
  customFileTemplate: {
    heading: 'Please update details according to the template:',
    btnText: 'Download Template File',
  },
};

export const HOW_TO_INTEGRATE = 'How To Integrate?';
export const ALERTS = 'Alerts';
export const ADD_CUSTOM_NAME = 'Add Custom Name';

export const chainViewData = {
  label: 'Public Address or Blockchain Domain',
  button: 'Submit',
  toast: 'Public address is required',
};

export const walletPage = {
  title: 'Add Integration',
  subTitle: 'Please add an integration to continue.',
  exchangeText: 'Choose an integration',
  requestWalletText: 'Can’t find yours?',
  filterButtons: [
    { btnText: 'All integrations', value: 'allIntegrations' },
    { btnText: 'Popular', value: 'popular' },
    { btnText: 'Exchanges', value: 'exchange' },
    { btnText: 'Wallets', value: 'wallet' },
    { btnText: 'Blockchains', value: 'chain' },
    // { btnText: "NFTs", value: "nft" },
    { btnText: 'Decentralized Exchanges', value: 'dex' },
  ],
  requestWalletLink: routeEnums.REQUEST_EXCHANGE_PAGE,
  emptyState: {
    heading: 'No Results Found',
    subHeading: 'Don’t worry, You can use our custom file format',
    btnText: 'Use KoinX Custom Format',
  },
  integrationTypes: {
    oAuth: 'OAuth',
    address: 'Address',
    csv: 'File Upload',
    api: 'API',
  },
  fileUploadComponent: {
    titles: {
      coindcx: 'Upload your CSV files',
      vauld: 'Upload your CSV file',
      binance: 'Upload your CSV file',
      wazirx: 'Upload your XLSX file',
      coinswitch: 'Upload your XLSX file',
    },
    mobileSubTitle: 'How to download?',
    uploadText: 'Click or drag file to this area to upload',
    uploadButtonText: 'Submit',
    uploadDocsText: 'documents uploaded',
    note: 'Note: Uploading all the files is not mandatory. Please upload the ones in which you have made any transactions.',

    UploadIcon,
    CSVIcon,
    DeleteIcon,
    customFileTemplate: {
      heading: 'Please update details according to the template',
      btnText: 'Download Template File',
    },
  },
  instructionSection: {
    title: (exchange) => `How to integrate ${exchange} with KoinX?`,
    blogText: 'Read Step by Step Blog',
    blogLinks: {
      binance:
        'https://koinx.com/blog/2022/07/how-to-download-your-transaction-report_20.html',
      bitbns:
        'https://koinx.com/blog/2022/07/how-to-integrate-bitbns-account-with.html',
      coinswitch: 'https://koinx.com/blog/',
      coindcx:
        'https://koinx.com/blog/2022/07/how-to-download-your-transaction-report_90.html',
      wazirx:
        'https://koinx.com/blog/2022/07/how-to-download-your-transaction-report_13.html',
      vauld:
        'https://koinx.com/blog/2022/07/how-to-download-your-transaction-report_14.html',
    },
    // instructions: [
    //   ["Sign in to your", "account"],
    //   "Go to your profile",
    //   "Click on 'download trading report'",
    //   "Import your report here",
    // ],
    // videoText: "Watch Video",
    // PlayIcon,
  },
  options: [{ name: 'Rename' }, { name: 'Unlink' }],
  optionsColor: ['#374151', '#F7324CCC'],
  renamePopup: {
    popUpHeader: 'Rename Wallet',
    button: ['Cancel', 'Save'],
    inputLabel: 'Custom Wallet Name',
  },
  removePopup: {
    popUpHeader: 'Unlink Integration?',
    popUpText: [
      'Are you sure you want to unlink wallet? All wallet details and',
      'trasactions data will be lost.',
    ],
    button: ['Back', 'Unlink'],
  },
  // totalWorth: "Total Worth",
  transactionMade: 'Transactions',
  lastSync: 'Last synced:',
  lastTransaction: 'Last transaction:',
  upperSection: {
    title: 'Integrations',
    searchBarPlaceholder: 'Search Wallet',
    addWalletButton: {
      text: 'Add Integration',
      icon: WalletIcon,
    },
    sortingOptions: [
      'Sort by',
      'Date Added',
      'Name A-Z',
      'Name Z-A',
      'Most Transactions',
    ],
    sortIcon: DownArrow,
  },
  mobileFlow: {
    GreenTickIcon: GreenTickIcon,
    AddIntegrationIcon: AddIntegrationIcon,
    tradePlatforms: [
      {
        name: 'Bitbns',
        icon: ['Bitbns'],
      },
      {
        name: 'WazirX',
        icon: exchangePlatformAssets['WazirX'],
      },
      {
        name: 'Metamask',
        icon: exchangePlatformAssets['Metamask'],
      },
      {
        name: 'CoinSwitch',
        icon: TradePlatformCoinSwitch,
      },
      {
        name: 'Binance',
        icon: exchangePlatformAssets['Binance'],
      },
    ],
  },
};
