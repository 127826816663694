import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  disconnectSocket,
  setUpSocket,
  setUpSocketFailure,
  setUpSocketSuccess,
  updateIntegrationStateSuccess,
  updateSocketStateSuccess,
} from '@/Redux/slices/socketSlice';
import { setUpConnection } from '@/Services/socket/setUpConnection';
import { filterSocketEventsByTimeStamp } from '@/Utils/helper/filterSocketEventsByTimestamp';
import notify from '@/Utils/notifyToast';
import useUser from './useUser';
import useWallet from './useWallet';
import { integrationImportingStatusEnums as importingStatusEnums } from '@/Utils/enums/integrationImportingStatusEnums';

const useSocket = () => {
  const socketRef = useRef(null);
  const dispatch = useDispatch();
  // const { handleGetUserNotifications } = useUser();

  const socket = useSelector((state) => state.socket);
  // used to avoid duplicate notifications for the same integration
  const sentNotificationsState = useRef([]);
  const { handleUserWallets } = useWallet();

  useEffect(() => {
    socketRef.current = socket.socket;
  }, [socket.socket]);

  useEffect(() => {
    if (socket.state.error) {
      notify('Error processing transactions', 'error');
      dispatch(
        updateSocketStateSuccess({
          stateCount: 0,
          message: 'No processing integrations',
          error: false,
        })
      );
    }
  }, [dispatch, socket.state]);

  const socketUpdateEventHandler = useCallback(
    (socketData) => {
      let localState = {
        initialState: 0,
        tradesFetched: 0,
        pricesUpdated: 0,
        gainsCalculated: 0,
        error: false,
      };

      let localIntegrationState = [];

      const socketProcessingCalculationState =
        socketData.isProcessingCalculationRequest;
      if (socketProcessingCalculationState) {
        const integrations = Object.keys(socketProcessingCalculationState);
        for (const integration of integrations) {
          const integrationState =
            socketProcessingCalculationState[integration];
          const isIntegrationLessThanTwoHourOld =
            filterSocketEventsByTimeStamp(integrationState);
          if (isIntegrationLessThanTwoHourOld) {
            if (
              integrationState?.id === 'trades_fetched' ||
              integrationState?.id === 'processing_started'
            ) {
              localState.tradesFetched++;
              localIntegrationState.push({
                integrationId: integration,
                state: importingStatusEnums.LOADING,
                name: integrationState.wallet,
              });
            } else if (integrationState?.id === 'prices_updated') {
              localState.pricesUpdated++;
              localIntegrationState.push({
                integrationId: integration,
                state: importingStatusEnums.LOADING,
                name: integrationState.wallet,
              });
            } else if (integrationState?.id === 'gains_calculated') {
              localState.gainsCalculated++;
              localIntegrationState.push({
                integrationId: integration,
                state: importingStatusEnums.LOADED,
                name: integrationState.wallet,
              });
            } else if (integrationState?.error) {
              localState.error = true;
              localIntegrationState.push({
                integrationId: integration,
                state: importingStatusEnums.ERROR,
                name: integrationState.wallet,
                message: integrationState.message,
              });
              if (!sentNotificationsState.current.includes(integration)) {
                notify(
                  integrationState.wallet[0].toUpperCase() +
                    integrationState.wallet.slice(1) +
                    ': ' +
                    integrationState.message,
                  'error'
                );
                sentNotificationsState.current.push(integration);
              }
            } else localState.initialState++;
          }
        }
      }
      if (localIntegrationState.length > 0) {
        dispatch(updateIntegrationStateSuccess(localIntegrationState));
      }

      if (localState.tradesFetched > 0) {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 2,
            message:
              'We have received all your transactions! Give us a few moments while we process them!',
            error: false,
          })
        );
        return;
      } else if (localState.pricesUpdated > 0) {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 3,
            message:
              'Almost there! Just a few moments before we complete processing your transactions!',
            error: false,
          })
        );
        return;
      } else if (localState.gainsCalculated > 0) {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 4,
            message:
              'We have processed your transactions and your portfolio has been updated!',
            error: false,
          })
        );
        handleUserWallets();
        return;
      } else if (localState.error) {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 0,
            message: 'Error processing your transactions',
            error: true,
          })
        );
        return;
      } else {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 0,
            message: 'No processing integrations',
            error: false,
          })
        );
        return;
      }
    },
    [dispatch, handleUserWallets]
  );

  const setInitialIntegrationProcessingState = useCallback(
    (socketData) => {
      let localState = {
        initialState: 0,
        tradesFetched: 0,
        pricesUpdated: 0,
        gainsCalculated: 0,
        error: false,
      };

      let localIntegrationState = [];

      const socketProcessingCalculationState =
        socketData.isProcessingCalculationRequest;
      if (socketProcessingCalculationState) {
        const integrations = Object.keys(socketProcessingCalculationState);
        for (const integration of integrations) {
          const integrationState =
            socketProcessingCalculationState[integration];
          const isIntegrationLessThanTwoHourOld =
            filterSocketEventsByTimeStamp(integrationState);
          if (isIntegrationLessThanTwoHourOld) {
            if (integrationState?.id === 'trades_fetched') {
              localState.tradesFetched++;
              localIntegrationState.push({
                integrationId: integration,
                state: importingStatusEnums.LOADING,
                name: integrationState.wallet,
              });
            } else if (integrationState?.id === 'prices_updated') {
              localState.pricesUpdated++;
              localIntegrationState.push({
                integrationId: integration,
                state: importingStatusEnums.LOADING,
                name: integrationState.wallet,
              });
            } else if (integrationState?.error) {
              localState.error = true;
              localIntegrationState.push({
                integrationId: integration,
                state: importingStatusEnums.ERROR,
                name: integrationState.wallet,
              });
            } else localState.initialState++;
          }
        }
      }
      if (localIntegrationState.length > 0) {
        dispatch(updateIntegrationStateSuccess(localIntegrationState));
      }

      if (localState.tradesFetched > 0) {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 2,
            message:
              'We have received all your transactions! Give us a few moments while we process them!',
            error: false,
          })
        );
        return;
      } else if (localState.pricesUpdated > 0) {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 3,
            message:
              'Almost there! Just a few moments before we complete processing your transactions!',
            error: false,
          })
        );
        return;
      } else if (localState.initialState > 0 || localState.error) {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 0,
            message: 'No processing integrations',
            error: false,
          })
        );
        return;
      } else {
        dispatch(
          updateSocketStateSuccess({
            stateCount: 0,
            message: 'No processing integrations',
            error: false,
          })
        );
        return;
      }
    },
    [dispatch]
  );

  // const refreshNotificationEventHandler = (socketData) => {
  //   handleGetUserNotifications();
  // };

  const handleSetupSocket = useCallback(
    (userId) => {
      if (socketRef.current) {
        socketRef.current.close();
      }

      dispatch(setUpSocket());

      const socket = setUpConnection(userId);
      socketRef.current = socket;

      socket.once('connect', () => {
        dispatch(setUpSocketSuccess(socket));
      });

      socket.once('connect_error', (error) => {
        dispatch(setUpSocketFailure(error));
      });

      socket.once('disconnect', () => {
        dispatch(disconnectSocket());
      });

      // listen to initial-state and state-update

      socket.once('initial-state', (data) => {
        setInitialIntegrationProcessingState(data);
      });

      socket.on('state-update', (data) => {
        socketUpdateEventHandler(data);
      });
      socket.on('refresh-notification', (data) => {
        // refreshNotificationEventHandler(data);
      });
    },
    [dispatch, setInitialIntegrationProcessingState, socketUpdateEventHandler]
  );

  return {
    handleSetupSocket,
    socket: socket.state,
  };
};

export default useSocket;
