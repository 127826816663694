import React from 'react';
import { TableBody, TableCell, TableRow, Skeleton } from '@mui/material';

const ChartOfAccountsTableSkeleton = ({ rowCount = 5, columnCount = 5 }) => {
  return (
    <TableBody>
      {Array.from({ length: rowCount }).map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from({ length: columnCount }).map((_, colIndex) => (
            <TableCell key={colIndex}>
              <Skeleton height={40} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default ChartOfAccountsTableSkeleton;
