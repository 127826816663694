import { Outlet, Route } from 'react-router';
import { routeEnums } from './Utils/enums/routes';
import SignInPage from './Containers/SignInPage/SignInPage';
import SignUpPage from './Containers/SignUpPage/SignUpPage';
import VerifyOtpPage from './Containers/VerifyOtpPage';
import LeftComponent from './Components/UserOnboarding/LeftComponent';

const PublicRoutes = () => (
  <>
    <Route
      element={
        <main id="onboarding_screens_wrapper">
          <LeftComponent />
          <Outlet />
        </main>
      }
    >
      <Route exact path={routeEnums.SIGN_IN_PAGE} element={<SignInPage />} />
      <Route exact path={routeEnums.SIGN_UP_PAGE} element={<SignUpPage />} />
      <Route
        exact
        path={routeEnums.VERIFY_OTP_PAGE}
        element={<VerifyOtpPage />}
      />
    </Route>
  </>
);

export default PublicRoutes;
