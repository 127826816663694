import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from '@tanstack/react-table';
import { IconButton } from '@mui/material';
import {
  ExpandMore,
  Folder,
  InfoComponent,
  KeyboardArrowUp,
  Text,
} from '@koinx/xui';
import styles from './CustomNestedTable.module.css';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import EmptyDataImage from '@/Assets/General/EmptyDataImage.svg';
import useChartOfAccounts from '@/Hooks/useChartOfAccounts';
import replaceAccountIdParamWithId from '@/Utils/helper/replaceAccountIdParamWithId';
import { customNestedTableHeaders } from './constants';
import BlankCellValue from '@/Components/Ui/BlankCellValue/BlankCellValue';
import { capitalise } from '@/Utils/capitalise';
import { permissionsCategoryEnums } from '@/Utils/enums/permissionsCategory';
import { permissionsEnums } from '@/Utils/enums/permissions';
import { useUserHasPermission } from '@/Hooks/useUserHasPermission';

const CustomNestedTable = ({
  processData,
  handleRowExpansionChange,
  handleOptionClick,
  SkeletonComponent,
  noMatchText,
  tableOptions = {},
  isTreasuryFlow = false,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const { isAccountsDataLoading, accountDetailsList } = useSelector(
    (state) => state.chartOfAccounts
  );
  const { currentWorkspaceId, userData } = useSelector((state) => state.user);
  const { handleGetAccountDetailsList } = useChartOfAccounts();
  const userCanEditAccounts = useUserHasPermission(
    permissionsCategoryEnums.ACCOUNTS,
    permissionsEnums.UPDATE
  );

  useEffect(() => {
    isTreasuryFlow
      ? handleGetAccountDetailsList({ details: true, isTreasuryAccount: true })
      : handleGetAccountDetailsList({ details: true });
  }, [handleGetAccountDetailsList, isTreasuryFlow]);

  useEffect(() => {
    if (accountDetailsList && !isAccountsDataLoading) {
      let fullData;
      if (isTreasuryFlow) {
        fullData = processData(accountDetailsList);
      } else {
        fullData = processData(accountDetailsList, handleOptionClick);
      }
      setInitialData(fullData);
      setData(fullData);
    }
  }, [
    isAccountsDataLoading,
    accountDetailsList,
    processData,
    handleOptionClick,
    isTreasuryFlow,
  ]);

  const handleRowClick = useCallback(
    (rowData) => {
      const path = replaceAccountIdParamWithId(
        rowData.original._id,
        isTreasuryFlow
      );
      navigate(path);
    },
    [navigate, isTreasuryFlow]
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        id: customNestedTableHeaders[0].id,
        header: customNestedTableHeaders[0].label,
        accessorKey: customNestedTableHeaders[0].id,
        cell: ({ row, getValue }) => (
          <div
            style={{
              paddingLeft:
                (row.original.depth + (row.original.hasChildren ? 0 : 1.5)) *
                30,
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
            }}>
            {row.original.hasChildren && (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleRowExpansionChange(
                    [{ dataIndex: row.index }],
                    data,
                    setData,
                    initialData
                  );
                }}>
                {row.original.expanded ? (
                  <KeyboardArrowUp className={styles.Icon} />
                ) : (
                  <ExpandMore className={styles.Icon} />
                )}
              </IconButton>
            )}
            {row.original.hasChildren && <Folder className={styles.Icon} />}
            <p>{capitalise(getValue())}</p>
          </div>
        ),
      },
      {
        id: customNestedTableHeaders[1].id,
        header: customNestedTableHeaders[1].label,
        accessorKey: customNestedTableHeaders[1].id,
        cell: ({ getValue }) => <div>{getValue() || <BlankCellValue />}</div>,
      },
      {
        id: customNestedTableHeaders[2].id,
        header: customNestedTableHeaders[2].label,
        accessorKey: customNestedTableHeaders[2].id,
        cell: ({ getValue }) => <div>{getValue() || <BlankCellValue />}</div>,
      },
      {
        id: customNestedTableHeaders[3].id,
        header: customNestedTableHeaders[3].label,
        accessorKey: customNestedTableHeaders[3].id,
        cell: ({ getValue }) => (
          <div>{getValue()?.name || <BlankCellValue />}</div>
        ),
      },
    ];

    if (isTreasuryFlow) {
      baseColumns.push({
        id: customNestedTableHeaders[4].id,
        header: customNestedTableHeaders[4].label,
        accessorKey: customNestedTableHeaders[4].id,
        cell: ({ getValue }) => (
          <div>{getValue()?.name || <BlankCellValue />}</div>
        ),
      });
    }

    baseColumns.push({
      id: customNestedTableHeaders[5].id,
      header: customNestedTableHeaders[5].label,
      accessorKey: customNestedTableHeaders[5].id,
      cell: ({ getValue }) => <div>{getValue() || <BlankCellValue />}</div>,
    });

    if (!isTreasuryFlow && userCanEditAccounts) {
      baseColumns.push({
        id: 'menu',
        header: 'Actions',
        accessorKey: 'menu',
        cell: ({ row }) => (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              cursor: row.original.depth === 0 ? 'not-allowed' : 'pointer',
            }}>
            {row.original.menu}
          </div>
        ),
      });
    }

    return baseColumns;
  }, [
    data,
    initialData,
    handleRowExpansionChange,
    isTreasuryFlow,
    userCanEditAccounts,
  ]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSubRows: (row) => data.filter((r) => r.parentId === row._id),
    getRowCanExpand: (row) => row.original.hasChildren,
  });

  if (!isAccountsDataLoading && data.length === 0) {
    return (
      <InfoComponent
        wrapperClass={styles.InfoWrapper}
        title={`No ${isTreasuryFlow ? 'Treasury' : 'Chart of'} Accounts found.`}
        imageAlt="No Treasury Accounts found."
        image={EmptyDataImage}
        enableButton={false}
        subtitle={`Looks like there are no ${isTreasuryFlow ? 'Treasury' : 'Chart of'} Accounts.`}
      />
    );
  }

  return (
    <div
      className={`${styles.TableContainer} ${!isTreasuryFlow ? styles.FixedLastColumn : ''}`}
      data-testid={`${isTreasuryFlow ? 'treasuryAccounts-table' : 'chartOfAccounts-table'}`}>
      <div className={styles.TableWrapper}>
        <table className={styles.Table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={styles.TableHeader}>
                    <Text variant="heading-6" textColor="gray-12">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Text>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {isAccountsDataLoading ? (
            <SkeletonComponent />
          ) : (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={styles.TableRow}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: 'pointer' }}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className={styles.TableCell}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default CustomNestedTable;
