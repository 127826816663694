import { Badge, Avatar, Menu, Text } from '@koinx/xui';
import RemoveWalletPopup from '@/Components/Popups/DataSources/RemoveWalletPopup';
import RenameWalletPopup from '@/Components/Popups/DataSources/RenameWalletPopup';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import usePopup from '@/Hooks/usePopup';
import { routeEnums } from '@/Utils/enums/routes';
import styles from './WalletCard.module.css';
import { walletPageData } from './constants';
import SyncTransactionsButton, {
  syncTransactionButtonVariants,
} from '@/Components/Routes/DataSources/UserIntegrations/Components/SyncTransactionsButton';
import CustomTooltip from '@/Components/Ui/CustomTooltip';
import { permissionsEnums } from '@/Utils/enums/permissions';
import { permissionsCategoryEnums } from '@/Utils/enums/permissionsCategory';
import { useUserHasPermission } from '@/Hooks/useUserHasPermission';

const walletPage = walletPageData;
const DesktopWalletCard = ({
  transactionMade,
  lastSync,
  lastTransaction,
  walletId,
  currWallet,
}) => {
  const navigate = useNavigate();
  const userCanAddIntegrations = useUserHasPermission(
    permissionsCategoryEnums.INTEGRATIONS,
    permissionsEnums.CREATE
  );
  // const isMobileScreen = useScreenType();
  // const { handlePopupCenterComponentRender, handlePopupCenterOpen } =
  //   usePopup();

  const walletToNamesMap = useSelector(
    (state) => state.wallet.walletToNamesMap
  );
  const {
    logo: imgSrc,
    displayName: title,
    customName: customWalletName,
    integrationType,
    sourceId,
  } = useMemo(
    () => walletToNamesMap[walletId] || {},
    [walletToNamesMap, walletId]
  );

  // const handleCardClick = (event) => {
  //   navigate(`${routeEnums.WALLET_DETAILS_PAGE}/${walletId}`);
  //   event?.stopPropagation();
  // };

  // const handleRemoveWalletPopup = (event) => {
  //   handlePopupCenterComponentRender(<RemoveWalletPopup walletId={walletId} />);
  //   handlePopupCenterOpen(true);
  //   event?.stopPropagation();
  // };

  // const handleRenameWalletPopup = (event) => {
  //   handlePopupCenterComponentRender(<RenameWalletPopup walletId={walletId} />);
  //   handlePopupCenterOpen(true);
  //   event?.stopPropagation();
  // };

  // const handleFunction = (e, option) => {
  //   switch (option) {
  //     case 'Rename':
  //       handleRenameWalletPopup(e);
  //       break;
  //     case 'Unlink':
  //       handleRemoveWalletPopup(e);
  //       break;
  //     default:
  //       break;
  //   }
  //   e.stopPropagation();
  // };

  return (
    <>
      <div
        className={`${styles.Wrapper}
      
         `}
        // onClick={handleCardClick}
      >
        {/* {isMobileScreen ? (
          <div className={styles.GreenTick}>
            <img src={walletPage.mobileFlow.GreenTickIcon} alt="green tick" />
          </div>
        ) : ( */}
        {/* <div className={styles.OptionsWrapper}>
            <Menu
              options={walletPage.options}
              optionTextColors={walletPage.optionsColor}
              handleOptionClick={handleFunction}
              buttonClassName={styles.MenuButton}
            />
          </div> */}
        {/* )} */}
        <div className={styles.MainWrapper}>
          <div className={styles.MainUpperSection}>
            <Avatar
              imageSrc={imgSrc}
              fallbackContent={'X'}
              className={styles.ImageWrapper}
            />
            <div className={styles.MainUpperSectionContent}>
              <div>
                {customWalletName ? (
                  customWalletName?.length > 10 ? (
                    <CustomTooltip
                      component={
                        <Text variant="heading-3">{`${customWalletName?.substring(0, 11)}...`}</Text>
                      }
                      title={customWalletName}
                    />
                  ) : (
                    <Text variant="heading-3">{customWalletName}</Text>
                  )
                ) : (
                  <Text variant="heading-3">{title}</Text>
                )}
                {currWallet?.address && (
                  <Text variant="heading-3" className={styles.TextComp}>
                    {'(' +
                      currWallet.address.slice(0, 3) +
                      '...' +
                      currWallet.address.slice(
                        currWallet.address.length - 3,
                        currWallet.address.length
                      ) +
                      ')'}
                  </Text>
                )}
              </div>
              <div className={styles.Transactions}>
                <div className={styles.TransactionMade}>
                  <Text variant="body-2" className={styles.TextComp}>
                    {transactionMade} {walletPage.transactionMade}
                  </Text>
                  <Badge
                    variant="primary_light"
                    className={styles.IntegrationTypeBadge}>
                    {integrationType}
                  </Badge>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.LastSync}>
            <Text variant="label-1" className={styles.TextComp}>
              {walletPage.lastSync}
            </Text>
            <Text variant="label-1" className={styles.LastTxnLabel}>
              {lastSync.includes('about')
                ? lastSync.replace('about ', '')
                : lastSync}
            </Text>
            {userCanAddIntegrations && (
              <SyncTransactionsButton
                variant={syncTransactionButtonVariants.SYNC_TRANSACTIONS_BADGE}
                wallet={currWallet}
              />
            )}
          </div>
          <p className={styles.LastSync}>
            <Text variant="label-1" className={styles.TextComp}>
              {walletPage.lastTransaction}
            </Text>
            <Text variant="label-1" className={styles.LastTxnLabel}>
              {lastTransaction.includes('about')
                ? lastTransaction.replace('about ', '')
                : lastTransaction}
            </Text>
          </p>
        </div>
      </div>
    </>
  );
};

export default DesktopWalletCard;
