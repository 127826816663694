import { useEffect, useMemo, useState } from 'react';
import styles from '@/Components/Routes/ChartOfAccounts/Details/Details.module.css';
import { DetailsData } from '@/Components/Routes/ChartOfAccounts/Details/constants';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { AddIntegration, InfoComponent, Pagination } from '@koinx/xui';
import TransactionsTableSkeleton from '@/Components/Routes/ChartOfAccounts/Details/components/TransactionsTableSkeleton';
import { useSelector } from 'react-redux';
import AmountGroup from '@/Components/Ui/AmountGroup';
import useChartOfAccounts from '@/Hooks/useChartOfAccounts';
import { useParams } from 'react-router';
import LedgersEmptyStateImage from '@/Assets/General/EmptyDataImage.svg';
import { getRows } from './helper';

const TransactionsTable = () => {
  const { accountId } = useParams();

  const {
    handleFetchLedgerAccounts,
    handleSetLedgerAccountsCursorAndRowPerPage,
  } = useChartOfAccounts();

  const {
    isLedgerAccountsLoading,
    ledgersData,
    totalLedgerAccounts,
    ledgersTableCursor,
    ledgersTableRowsPerPage,
  } = useSelector((state) => state.chartOfAccounts);

  const columns = DetailsData.tableHeadings;

  const rows = useMemo(
    () => getRows(ledgersData ? ledgersData : null),
    [ledgersData]
  );

  useEffect(() => {
    handleFetchLedgerAccounts({
      accountId: accountId,
      cursor: ledgersTableCursor,
      limit: ledgersTableRowsPerPage,
    });
  }, [
    accountId,
    handleFetchLedgerAccounts,
    ledgersTableCursor,
    ledgersTableRowsPerPage,
  ]);
  const currentPage = useMemo(() => {
    return Math.floor(ledgersTableCursor / ledgersTableRowsPerPage) + 1;
  }, [ledgersTableCursor, ledgersTableRowsPerPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalLedgerAccounts / ledgersTableRowsPerPage);
  }, [totalLedgerAccounts, ledgersTableRowsPerPage]);

  if (isLedgerAccountsLoading) {
    return (
      <TransactionsTableSkeleton
        rowCount={5}
        columnCount={columns.length}
        columns={columns}
      />
    );
  }

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      const newCursor = (newPage - 1) * ledgersTableRowsPerPage;
      handleSetLedgerAccountsCursorAndRowPerPage({
        cursor: newCursor,
        rowsPerPage: ledgersTableRowsPerPage,
      });
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    handleSetLedgerAccountsCursorAndRowPerPage({
      cursor: 0,
      rowsPerPage: newRowsPerPage,
    });
  };

  return (
    <div className={styles.TableContainer}>
      <TableContainer>
        <Table
          data-testid="LedgersTable"
          stickyHeader
          aria-label="sticky table">
          <TableHead className={styles.Header}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  data-testid={`ledgersTable-header-${column.id}`}
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontSize: 'var(--font-16)',
                    fontWeight: 600,
                    color: 'var(--gray-12)',
                    background: '#f1f5fe',
                  }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-testid="ledgersTable-body">
            {rows.length < 1 ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <InfoComponent
                    data-testid="ledgersTable-infoComponent"
                    title="No Ledgers Found"
                    image={LedgersEmptyStateImage}
                  />
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            fontSize: 'var(--font-14)',
                            fontWeight: 400,
                          }}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length > 0 && totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          rowsPerPage={ledgersTableRowsPerPage}
          rowOptionsGap={1}
          rowOptionsLength={100}
          setCurrentPage={handlePageChange}
          setRowsPerPage={handleRowsPerPageChange}
          totalPages={totalPages}
          paginationWrapperClass={styles.PaginationWrapper}
        />
      )}
    </div>
  );
};

export default TransactionsTable;
