import { AddIntegration, Button, Categorise, ToolTip, Add } from '@koinx/xui';
import TabsComponent from '@/Components/Routes/TransactionsPage/TabsComponent/TabsComponent';
import styles from './TransactionsPageWrapper.module.css';
import { transactionsPageData, headingWhenRowsSelected } from './constants';
import { getTabs } from './helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArchivedTable from '@/Components/Routes/TransactionsPage/Tables/ArchivedTable';
import UnrecognizedTable from '@/Components/Routes/TransactionsPage/Tables/UnrecognizedTable/UnrecognizedTable';
import { replaceTemplateString } from '@/Utils/helper/replaceTemplateString';
import { setSelectedTableRows } from '@/Redux/slices/transactionSlice';
import CategorizedTable from '@/Components/Routes/TransactionsPage/Tables/CategorizedTable/CategorizedTable';
import ArchiveButtonsGroup from './components/ArchiveButtonsGroup/ArchiveButtonsGroup';
import { pluralize } from '@/Utils/helper/pluralize';
import useTransaction from '@/Hooks/useTransaction';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TabTypesEnum } from '@/Utils/enums/tabTypes';
import { routeEnums } from '@/Utils/enums/routes';
import CategoriseTransactionsDrawer from '@/Components/Routes/TransactionsPage/CategoriseTransactionsDrawer';
import TransactionFilters from '@/Components/Routes/TransactionsPage/TransactionFilters/TransactionFilters';
import AddEditTransactionDrawer from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer';
import { addEditTransactionDrawerTypes } from '@/Utils/enums/addEditTransactionDrawerTypes.js';

const TransactionsPageWrapper = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAddTransactionDrawerOpen, setIsAddTransactionDrawerOpen] =
    useState(false);
  const dispatch = useDispatch();
  const { totalTransactionsCount, selectedTableRows, isCategorisationAllowed } =
    useSelector((state) => state.transactions);
  const { handleGetArchiveLabels } = useTransaction();

  const tabsArray = getTabs(
    totalTransactionsCount,
    searchParams.get('tab') || TabTypesEnum.UNCATEGORIZED
  );

  const [selectedTab, setSelectedTab] = useState(
    searchParams.get('tab') || TabTypesEnum.UNCATEGORIZED
  );

  const userHasSelectedSomeRows = selectedTableRows.length > 0;
  const noRowsAreSelected = selectedTableRows.length < 1;
  const { handleFetchTransactions } = useTransaction();
  useEffect(() => {
    dispatch(setSelectedTableRows([]));
  }, [dispatch, selectedTab]); // add filters as dependency when filters are added.

  // TODO: add txns fetch api call on filter(s) change.
  useEffect(() => {
    handleGetArchiveLabels();
  }, [handleGetArchiveLabels]);

  const handleAddIntegration = () => {
    navigate(routeEnums.ADD_INTEGRATION_PAGE);
  };

  const handleAddManualTransaction = () => {
    setIsAddTransactionDrawerOpen(true);
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.TitleWrapper}>
        <h1 className={styles.Title} data-testid="transactionsPage-heading">
          {userHasSelectedSomeRows
            ? replaceTemplateString(headingWhenRowsSelected, [
                selectedTableRows.length,
                pluralize(
                  selectedTableRows.length,
                  transactionsPageData.pluralize.singular,
                  transactionsPageData.pluralize.plural
                ),
              ])
            : transactionsPageData.title}
        </h1>
        <div className={styles.ButtonsWrapper}>
          {noRowsAreSelected && (
            <>
              <Button
                data-testid="transactionsPage-importTransactionsButton"
                icon={<AddIntegration className={styles.AddIcon} />}
                buttonType="icon_left"
                onClick={handleAddIntegration}>
                {transactionsPageData.button.import}
              </Button>
              <Button
                variant="outline"
                data-testid="transactionsPage-importTransactionsButton"
                icon={<Add />}
                buttonType="icon_left"
                onClick={handleAddManualTransaction}>
                {transactionsPageData.button.manual}
              </Button>
            </>
          )}
          {userHasSelectedSomeRows && (
            <>
              {isCategorisationAllowed ? (
                <Button
                  icon={<Categorise className={styles.AddIcon} />}
                  buttonType="icon_left"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDrawerOpen(true);
                  }}>
                  {transactionsPageData.button.categorise}
                </Button>
              ) : (
                <ToolTip side="left" content={transactionsPageData.tooltip}>
                  <div>
                    <Button
                      disabled={true}
                      icon={<Categorise className={styles.AddIcon} />}
                      buttonType="icon_left">
                      {transactionsPageData.button.categorise}
                    </Button>
                  </div>
                </ToolTip>
              )}
            </>
          )}
          <ArchiveButtonsGroup />
        </div>
      </div>
      <div className={styles.TabsAndFiltersWrapper}>
        <TabsComponent tabsArray={tabsArray} setSelectedTab={setSelectedTab} />
        <TransactionFilters />
      </div>
      <div className={styles.TableContainer}>
        {selectedTab === tabsArray[0]?.value && <UnrecognizedTable />}{' '}
        {/* uncomment in v1 */}
        {/* {selectedTab === tabsArray[0]?.value && <RecognizedTable />} */}
        {selectedTab === tabsArray[1]?.value && <CategorizedTable />}
        {selectedTab === tabsArray[2]?.value && <ArchivedTable />}
      </div>
      {isDrawerOpen && (
        <CategoriseTransactionsDrawer
          isOpen={isDrawerOpen}
          rowData={selectedTableRows}
          handleClose={() => setIsDrawerOpen(false)}
          isMultiRow={true}
        />
      )}
      {isAddTransactionDrawerOpen && (
        <AddEditTransactionDrawer
          type={addEditTransactionDrawerTypes.ADD}
          isOpen={isAddTransactionDrawerOpen}
          handleClose={() => setIsAddTransactionDrawerOpen(false)}
          isMultiRow={true}
        />
      )}
    </div>
  );
};

export default TransactionsPageWrapper;
