import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import styles from './HeadingSection.module.css';
import BackButton from '@/Components/Ui/BackButton';
import { useSelector } from 'react-redux';
import { Avatar } from '@koinx/xui';
import { walletPage } from '@/Components/AddExchangesPage/constants';
import useSources from '@/Hooks/useSources';
import { routeEnums } from '@/Utils/enums/routes';
import { KOINX } from '@/Components/AddExchangesPage/constants';

const HeadingSection = ({ title }) => {
  const walletPageData = walletPage;
  const { selectedIntegration } = useSelector((state) => state.sources);
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { handleSetSelectedIntegration } = useSources();
  const renderAvatar = () => (
    <Avatar
      imageSrc={selectedIntegration?.logo}
      fallbackContent={selectedIntegration.displayName?.charAt(0)}
      fallbackClass={styles.FallbackClass}
      className={styles.Avatar}
    />
  );

  const renderTitle = () => title && <h2 className={styles.Title}>{title}</h2>;

  const handleBackBtn = () => {
    if (params.walletId === KOINX || searchParams.get('wallet')) {
      navigate(`${routeEnums.ADD_INTEGRATION_PAGE}`);
    } else if (params?.walletId) {
      navigate(`${routeEnums.ADD_INTEGRATION_PAGE}/${params.walletId}`);
    } else {
      navigate(routeEnums.INTEGRATIONS_PAGE);
    }
    handleSetSelectedIntegration(null);
  };

  return (
    <div className={`${styles.Wrapper}`}>
      <div className={styles.ArrowWrapper}>
        <BackButton
          className={styles.BackBtn}
          clickFn={handleBackBtn}
          data-heapId="add-wallet-back-button"
        />
        {selectedIntegration && renderAvatar()}
        {renderTitle()}
      </div>
      {/* {!selectedIntegration && (
        <Link className={styles.Link} to={walletPageData.requestWalletLink}>
          {walletPageData.requestWalletText}
        </Link>
      )} */}
    </div>
  );
};

export default HeadingSection;
