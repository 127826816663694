const BlankCellValue = ({ align = 'start' }) => {
  return (
    <span
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: `${align}`,
        fontSize: '16px',
      }}>
      -
    </span>
  );
};

export default BlankCellValue;
