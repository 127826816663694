import { TRANSACTIONS_URL } from '@/Utils/constants';
import qs from 'qs';
export const fetchTransactions = (axiosInstance, params) => {
  return axiosInstance.get(TRANSACTIONS_URL, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  });
};

