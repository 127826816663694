'use client';
import { useDispatch } from 'react-redux';
import {
  setIsClosable,
  setPopupCenterComponentRender,
  setPopupCenterOpen,
  setPopupComponentRender,
  setPopupOpen,
} from '@/Redux/slices/popUpSlice';

function usePopup() {
  const dispatch = useDispatch();

  const handlePopupOpen = (popupOpen) => {
    dispatch(setPopupOpen({ popupOpen: popupOpen }));
  };

  const handlePopupComponentRender = (popupComponent) => {
    dispatch(setPopupComponentRender({ popupComponent: popupComponent }));
  };

  const handlePopupCenterOpen = (popupCenterOpen) => {
    dispatch(setPopupCenterOpen({ popupCenterOpen: popupCenterOpen }));
  };

  const handleIsClosable = (isClosable) => {
    dispatch(setIsClosable(isClosable));
  };

  const handlePopupCenterComponentRender = (popupCenterComponent) => {
    dispatch(
      setPopupCenterComponentRender({
        popupCenterComponent: popupCenterComponent,
      })
    );
  };
  return {
    handlePopupOpen,
    handlePopupComponentRender,
    handlePopupCenterOpen,
    handlePopupCenterComponentRender,
    handleIsClosable,
  };
}

export default usePopup;
