import logo from '@/Assets/General/Logo.svg';
export const navbarContent = {
  logo: {
    src: logo,
    alt: 'Koinx Logo',
  },
  profileDropdown: {
    dropdownList: [
      {
        label: 'Contact Us',
        path: '/test',
        value: '/contact-us',
      },
      {
        label: 'Helpdesk',
        isExternal: true,
        path: 'https://care.koinx.com',
      },
      {
        label: 'Blog',
        isExternal: true,
        path: 'https://koinx.com/blog',
      },
      {
        label: 'Settings',
        path: '/test',
        value: '/settings/account',
      },
    ],
    signout: {
      label: 'Signout',
    },
  },
};
