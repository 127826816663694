import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import notify from '@/Utils/notifyToast';
import useAxiosPrivate from './useAxiosPrivate';
import {
  setJournalTable,
  setJournalTableSuccess,
  setJournalTableError,
  setAddJournal,
  setAddJournalSuccess,
  setAddJournalError,
  setJournalDetails,
  setJournalDetailsError,
  setJournalDetailsSuccess,
  setEditJournalFlow,
  setEditJournal,
  setEditJournalSuccess,
  setEditJournalError,
  setCursorandRowsPerPageInJournals,
} from '@/Redux/slices/journalsSlice';
import { fetchJournals } from '@/Services/journals/journals/fetchJournals';
import { createJournals } from '@/Services/journals/journals/createJournals';
import { fetchJournalDetail } from '@/Services/journals/journals/fetchJournalDetail';
import { editJournals } from '@/Services/journals/journals/editJournals';

function useJournal() {
  const dispatch = useDispatch();
  const axiosInstance = useAxiosPrivate();
  const { cursor, rowsPerPage } = useSelector((state) => state.journalTable);

  const handleSetCursorAndRowsPerPageInJournals = useCallback(
    async ({ cursor, rowsPerPage }) => {
      try {
        dispatch(setCursorandRowsPerPageInJournals({ cursor, rowsPerPage }));
      } catch (error) {
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch]
  );
  const handleFetchJournalTableData = useCallback(
    async ({
      limit = null,
      cursor = null,
      fromTimestamp = null,
      toTimestamp = null,
    }) => {
      try {
        dispatch(setJournalTable());
        const response = await fetchJournals(axiosInstance, {
          limit,
          cursor,
          fromTimestamp,
          toTimestamp,
        });
        dispatch(
          setJournalTableSuccess({
            journalsList: response.data.journals,
            totalJournalCount: response.data.count,
          })
        );
      } catch (error) {
        dispatch(setJournalTableError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch, axiosInstance]
  );

  const handleAddJournal = useCallback(
    async (
      {
        templateId = null,
        description = null,
        associatedAccounts,
        isCustomflow = false,
      },
      handleClose
    ) => {
      try {
        dispatch(setAddJournal());
        let transformedAssociatedAccounts;
        if (!isCustomflow) {
          transformedAssociatedAccounts = associatedAccounts.map((account) => {
            const { _id, accountId, ...rest } = account;
            return {
              ...rest,
              accountId: accountId._id,
            };
          });
        }
        const response = await createJournals(axiosInstance, {
          ...(templateId && { templateId: templateId }),
          associatedAccounts: isCustomflow
            ? associatedAccounts
            : transformedAssociatedAccounts,
          ...(description && {
            description: description,
          }),
        });
        dispatch(setAddJournalSuccess(response.data));
        notify(response.data.message, 'success');
        handleClose();
        handleFetchJournalTableData({
          limit: rowsPerPage,
          cursor: cursor,
        });
      } catch (error) {
        dispatch(setAddJournalError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, handleFetchJournalTableData, rowsPerPage, cursor]
  );
  const handleEditJournal = useCallback(
    async (
      { journalId, templateId, description = null, associatedAccounts },
      handleClose
    ) => {
      try {
        dispatch(setEditJournal());
        const transformedAssociatedAccounts = associatedAccounts.map(
          (account) => {
            const { _id, accountId, ...rest } = account;
            return {
              ...rest,
              accountId: accountId._id,
            };
          }
        );

        const response = await editJournals(axiosInstance, {
          journalId: journalId,
          templateId: templateId,
          associatedAccounts: transformedAssociatedAccounts,
          ...(description && {
            description: description,
          }),
        });
        dispatch(setEditJournalSuccess(response.data));
        notify(response.data.message, 'success');
        handleFetchJournalTableData({
          limit: rowsPerPage,
          cursor: cursor,
        });
        handleClose();
      } catch (error) {
        dispatch(setEditJournalError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, handleFetchJournalTableData, rowsPerPage, cursor]
  );
  const handleGetJournalDetails = useCallback(
    async ({ journalId }) => {
      try {
        dispatch(setJournalDetails());
        const response = await fetchJournalDetail(axiosInstance, journalId);
        dispatch(setJournalDetailsSuccess(response.data));
      } catch (error) {
        dispatch(setJournalDetailsError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch, axiosInstance]
  );
  return {
    handleSetCursorAndRowsPerPageInJournals,
    handleFetchJournalTableData,
    handleAddJournal,
    handleGetJournalDetails,
    handleEditJournal,
  };
}

export default useJournal;
