import Wallet from '@/Components/AddExchangesPage/Components/Wallet/Wallet';
import styles from './IntegrationCategory.module.css';
import { memo } from 'react';
import { KOINX } from '@/Components/AddExchangesPage/constants';
import { Text } from '@koinx/xui';

const UnMemoizedIntegrationCategory = ({ title, sources }) => {
  return (
    <div data-testid="integration-category">
      <Text variant="body-2">{title}</Text>
      <div className={styles.GridWrapper}>
        {sources.map((source) => (
          <Wallet
            icon={source.logo}
            displayName={source.displayName}
            key={source._id}
            id={source._id}
            name={source.name}
            isCustomFile={source?.name === KOINX ? true : false}
          />
        ))}
      </div>
    </div>
  );
};

const IntegrationCategory = memo(UnMemoizedIntegrationCategory);
export default IntegrationCategory;
