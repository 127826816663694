import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from './useAxiosPrivate';
import { getUser } from '@/Services/user/getUser';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import notify from '@/Utils/notifyToast';
import {
  setWorkspaceSettings,
  setWorkspaceSettingsError,
  setWorkspaceSettingsSuccess,
} from '@/Redux/slices/workspaceSlice';
import { fetchWorkspaceSettings } from '@/Services/workspace/fetchWorkspaceSettings';

const useWorkspace = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const handleFetchWorkspaceSettings = useCallback(async () => {
    try {
      dispatch(setWorkspaceSettings());
      const response = await fetchWorkspaceSettings(axiosPrivate);
      dispatch(setWorkspaceSettingsSuccess(response.data));
    } catch (error) {
      dispatch(setWorkspaceSettingsError(getErrorStatement(error)));
      notify(getErrorStatement(error), 'error');
    }
  }, [axiosPrivate, dispatch]);

  return { handleFetchWorkspaceSettings };
};

export default useWorkspace;
