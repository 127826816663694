import styles from './TransactionFilters.module.css';
import { staticData } from './constants';
import { transactionFilterKeys } from './constants';
import { Button } from '@koinx/xui';
import TransactionFilter from './TransactionFilter';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { createDynamicFilterOptions } from './helper';
import useTransaction from '@/Hooks/useTransaction';

const { clearFilterBtn, transactionFilters } = staticData;

/*
 * NOTE ON ADDING NEW TRANSACTION FILTER:
 * While adding a new filter, also update the generateTransactionAPIQueryParam helper function,
 * which generates query params for transaction api based on filter options selected.
 * */

const TransactionFilters = () => {
  const walletToNamesMap = useSelector(
    (state) => state.wallet?.walletToNamesMap
  );
  const filters = useSelector((state) => state.transactions?.filters);
  const dynamicFilters = useSelector(
    (state) => state.transactions?.dynamicFilters
  );
  const userTransactionCoins = useSelector(
    (state) => state.transactions?.transactionCoins
  );

  const dynamicFilterOptions = useMemo(
    () =>
      createDynamicFilterOptions(
        dynamicFilters,
        walletToNamesMap,
        userTransactionCoins
      ),
    [dynamicFilters, walletToNamesMap, userTransactionCoins]
  );

  const { handleUpdateFilters, handleResetFilters } = useTransaction();

  const handleFilterValueChange = (updatedFilters) => {
    handleUpdateFilters({
      ...filters,
      ...updatedFilters,
      [transactionFilterKeys.CURSOR]: 0,
    });
  };

  const handleClearFilterBtnClick = () => {
    handleResetFilters();
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.FiltersWrapper}>
        {transactionFilters.map((filter) => (
          <TransactionFilter
            key={filter.key}
            filter={{
              ...filter,
              ...(filter.isDynamicFilter && {
                options: dynamicFilterOptions[filter.key],
              }),
            }}
            onValueChange={handleFilterValueChange}
          />
        ))}
        <Button
          className={styles.ClearFilterButton}
          icon={clearFilterBtn.icon}
          variant="ghost"
          buttonType="icon_left"
          onClick={handleClearFilterBtnClick}
          data-testid="txn-clear-filter-button">
          {clearFilterBtn.btnText}
        </Button>
      </div>
    </div>
  );
};

export default TransactionFilters;
