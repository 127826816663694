import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  popupOpen: false,
  popupComponent: null,
  popupCenterOpen: false,
  popupCenterComponent: null,
  isClosable: true,
};

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setPopupCenterComponentRender: (state, action) => {
      state.popupCenterComponent = action.payload.popupCenterComponent;
    },
    setPopupComponentRender: (state, action) => {
      state.popupComponent = action.payload.popupComponent;
    },
    setPopupCenterOpen: (state, action) => {
      state.popupCenterOpen = action.payload.popupCenterOpen;
    },
    setPopupOpen: (state, action) => {
      state.popupOpen = action.payload.popupOpen;
    },
    setIsClosable: (state, action) => {
      state.isClosable = action.payload;
    },
  },
});

export const {
  setPopupOpen,
  setPopupCenterComponentRender,
  setPopupComponentRender,
  setPopupCenterOpen,
  setIsClosable,
} = popupSlice.actions;
export default popupSlice.reducer;
