import { integrationMethodsEnum } from '@/Utils/enums/integrationMethods';

export const generateDefaultNotes = (integrationMethod, displayName) => {
  switch (integrationMethod) {
    case integrationMethodsEnum.CSV:
      return [
        `Log in to your ${displayName} account.<br />On the Dashboard (likely on the top right-hand side), you will be able to see transaction history. Select it.`,
        `You will be given either an option to download a single file or an option to download multiple files according to the type of transactions like deposits, withdrawals, margin trades, futures etc. Please download all the files and upload them here in the respective file upload input field.`,
        `Click on “Submit”.`,
        `Your ${displayName} account will be integrated with the transactions here in your KoinX account.`,
      ];
    case integrationMethodsEnum.API_KEY:
      return [
        `Log in to your ${displayName} account. On the Dashboard (likely on the top right-hand side), you will find an option to “Generate API Keys”. Select it.`,
        `You will be given directions on how to get your API Key and Secret Key. Once generated, copy both of them and paste them in the respective input fields on this page.`,
        `Click on “Submit”`,
        ` Your ${displayName} account will be integrated with the transactions here in your KoinX account.`,
      ];
    case integrationMethodsEnum.OAUTH:
      return [
        `Click on the “Connect” button to link your ${displayName} account with KoinX in a single click.`,
        `You will be redirected to ${displayName}. Click on allow to enable secure & seamless integration.`,
      ];
    case integrationMethodsEnum.ADDRESS:
    case integrationMethodsEnum.CHAIN:
      return [
        `Copy your Public Address or Blockchain Domain and paste it in the “Public Address or Blockchain Domain” input field on this page and click on “Submit”.`,
      ];
    case integrationMethodsEnum.DEEP_INTEGRATION:
      return [
        `Open the ${displayName} app.`,
        `Under your profile section of your ${displayName} App, click on the &quot;Tax Reports&quot; button to link your account with KoinX in a single click. <strong>(Note: If you can&apos;t find the Tax Report button on the&nbsp; ${displayName} App, please update the app.)</strong>`,
      ];
    default:
      return [];
  }
};
