import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  socket: null,
  state: {
    stateCount: 0,
    message: 'No processing integrations',
    error: false,
  },
  integrationsImportState: [], // [{integrationId: <integrationId>, state: <stateEnum>, name: <name>}]
  loading: false,
  errors: [],
};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setUpSocket: (state) => {
      state.loading = true;
    },
    setUpSocketSuccess: (state, action) => {
      state.loading = false;
      state.socket = action.payload;
    },
    setUpSocketFailure: (state, action) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    },
    disconnectSocket: (state, action) => {
      state.socket = null;
    },
    updateSocketStateSuccess: (state, action) => {
      state.state = action.payload;
    },
    updateIntegrationStateSuccess: (state, action) => {
      state.integrationsImportState = action.payload;
    },
  },
});

export const {
  setUpSocket,
  setUpSocketFailure,
  setUpSocketSuccess,
  disconnectSocket,
  updateSocketStateSuccess,
  updateIntegrationStateSuccess,
} = socketSlice.actions;

export default socketSlice.reducer;
