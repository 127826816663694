import {
  dateAndTimeStaticData,
  labels,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/constants';
import styles from './DateAndTimeSection.module.css';
import { DateTimePicker, Select, TimePicker } from '@koinx/xui';
import { timezones } from '@/Utils/timezones';

const { timeTitle } = dateAndTimeStaticData;

const DateAndTimeSection = ({ transaction, onChange }) => {
  if (!transaction) return null;

  return (
    <div>
      <div className={styles.DateAndTime}>{timeTitle}</div>
      <div className={styles.DateAndTimePickers}>
        <DateTimePicker
          buttonClass={styles.DatePickerTrigger}
          value={transaction?.timestamp}
          onChange={(date) => {
            onChange(date, labels.TIMESTAMP);
          }}
          granularity="day"
        />
        <TimePicker
          hourInputClass="bg-gray-2"
          minuteInputClass="bg-gray-2"
          timePeriodSelectButtonClass="bg-gray-2"
          date={transaction?.timestamp}
          onChange={(date) => {
            onChange(date, labels.TIMESTAMP);
          }}
          granularity="minute"
        />
        <Select
          options={Object.keys(timezones).map((tz) => ({
            label: timezones[tz],
            value: tz,
          }))}
          value={transaction?.timezone}
          onChange={(timezone) => {
            onChange(timezone, labels.TIMEZONE);
          }}
          sortedOptions
          placeholder="Select Timezone"
          className={styles.TimezoneSelect}
          width="100%"
        />
      </div>
    </div>
  );
};

export default DateAndTimeSection;
