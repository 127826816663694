import { Button } from '@koinx/xui';

const OAuthView = ({
  selectedIntegration,
  handleOnClick,
  isLoading = false,
}) => {
  return (
    <div>
      <Button
        variant="primary"
        isLoading={isLoading}
        style={{ marginTop: '2rem' }}
        onClick={handleOnClick}
        data-heapId="add-wallet-oauth-button"
      >
        {isLoading
          ? `Connecting...`
          : 'Connect ' + selectedIntegration?.displayName}
      </Button>
    </div>
  );
};

export default OAuthView;
