import { getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';

export const getTableConfig = ({
  tableData,
  columns,
  totalTransactionsCount,
  rowsPerPage,
  selectableRows,
  cursor,
  handleSetTransactionsPageCursorAndRowPerPage,
  onRowSelectionChange,
  prevCache,
}) => {
  return {
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: Math.ceil(totalTransactionsCount / rowsPerPage),
    manualPagination: true,
    enableRowSelection: selectableRows,
    onRowSelectionChange: selectableRows ? onRowSelectionChange : undefined,
    state: {
      rowSelection: selectableRows ? prevCache : {},
      pagination: {
        pageIndex: cursor / rowsPerPage,
        pageSize: rowsPerPage,
      },
    },
    getRowId: (row) => row._id,
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newPagination = updater({
          pageIndex: cursor / rowsPerPage,
          pageSize: rowsPerPage,
        });
        const newCursor = newPagination.pageIndex * newPagination.pageSize;

        handleSetTransactionsPageCursorAndRowPerPage({
          cursor: newCursor,
          rowsPerPage: newPagination.pageSize,
        });
      }
    },
  };
};
