import { useSelector } from 'react-redux';
import { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoaderWrapper from './Components/LoaderWrapper';
import Disconnected from './Components/Disconnected';
import UnderMaintainance from './Components/UnderMaintainance';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import useNetworkStatus from './Hooks/useNetworkStatus';
import useAuth from './Hooks/useAuth';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import useUser from './Hooks/useUser';
import PopUpCenterWrapper from './Components/Popups/PopUpCenter/PopUpCenterWrapper';
import PopUpWrapper from './Components/Popups/PopUp/PopUpWrapper';
import useAccountingInvites from './Hooks/useAccountingInvites';
import * as SentryBrowser from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useSources from './Hooks/useSources';
import { wagmiConfig } from './Utils/wagmiConfig';
import { WagmiProvider } from 'wagmi';
import useWallet from './Hooks/useWallet';
import useSocket from './Hooks/useSocket';
import useTransaction from './Hooks/useTransaction';
import useWorkspace from './Hooks/useWorkspace';

function App() {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const { handleRefreshToken } = useAuth();
  const isOnline = useNetworkStatus();
  const [searchParams] = useSearchParams();
  const { handleGetUser, handleFetchWorkspaceUserData } = useUser();
  const { handleSources } = useSources();
  const { handleUserWallets, handleWalletToNamesMap, handleSetDeepIntegrated } =
    useWallet();
  const { handleGetInviteDetails, handleSetInviteId } = useAccountingInvites();
  const { handleFetchTransactionCoins, handleFetchTransactionFilters } = useTransaction();
  const { handleFetchWorkspaceSettings } = useWorkspace();
  const { accessToken } = useSelector((state) => state.auth);
  const sourcesData = useSelector((state) => state.sources.sources);
  const walletData = useSelector((state) => state.wallet.wallets);
  const {
    isWorkspacesLoading,
    userData,
    workspaceUserData,
    currentWorkspaceId,
  } = useSelector((state) => state.user);
  const inviteId = searchParams.get('inviteId');

  const { handleSetupSocket } = useSocket();

  useEffect(() => {
    if (!accessToken) {
      handleRefreshToken();
    }
  }, [accessToken, handleRefreshToken]);

  const queryClient = new QueryClient();

  // Fetching inital user wallets and user details
  useEffect(() => {
    if (accessToken) {
      setIsAppInitialized(false);
      const dataFetchingStatesLocal = {
        userData: false,
      };

      const handleDataFetchStatusUpdation = (key) => () => {
        dataFetchingStatesLocal[key] = true;
        if (
          Object.values(dataFetchingStatesLocal).every(
            (value) => value === true
          )
        ) {
          setIsAppInitialized(true);
        }
      };

      handleGetUser(handleDataFetchStatusUpdation('userData'));
      handleSources();

      handleSetDeepIntegrated(true);
    } else {
      if (inviteId) {
        handleSetInviteId(inviteId);
        handleGetInviteDetails(inviteId);
      }
      setIsAppInitialized(true);
    }
  }, [
    accessToken,
    handleGetInviteDetails,
    handleGetUser,
    handleSources,
    handleSetInviteId,
    inviteId,
    handleSetDeepIntegrated,
    handleFetchWorkspaceUserData,
    handleFetchTransactionCoins,
    handleFetchTransactionFilters
  ]);

  useEffect(() => {
    if (currentWorkspaceId) {
      handleFetchWorkspaceUserData();
      handleFetchWorkspaceSettings();
      handleFetchTransactionCoins();
      handleFetchTransactionFilters();
    }
  }, [
    handleFetchWorkspaceUserData,
    currentWorkspaceId,
    handleFetchTransactionCoins,
    handleFetchTransactionFilters,
    handleFetchWorkspaceSettings,
  ]);

  //create walletId to custom wallet name, source name and display name mapping
  useEffect(() => {
    let walletIdToWalletNameMapping = {};
    for (const wallet of walletData) {
      if (!walletIdToWalletNameMapping[wallet._id]) {
        walletIdToWalletNameMapping[wallet._id] = {};
        walletIdToWalletNameMapping[wallet._id].sourceName =
          wallet.walletSource;
        walletIdToWalletNameMapping[wallet._id].customName =
          wallet.customWalletName;
        walletIdToWalletNameMapping[wallet._id].isSyncBasedIntegration =
          wallet.isSyncBasedIntegration;
        walletIdToWalletNameMapping[wallet._id].integrationType =
          wallet.integrationType;
      }
      for (const source of sourcesData) {
        if (source.name === wallet.walletSource) {
          walletIdToWalletNameMapping[wallet._id].displayName =
            source.displayName;
          walletIdToWalletNameMapping[wallet._id].logo = source.logo;
        }
      }
    }
    handleWalletToNamesMap(walletIdToWalletNameMapping);
  }, [handleWalletToNamesMap, sourcesData, walletData]);

  // Handle Sentry user in production
  useEffect(() => {
    if (userData && workspaceUserData) {
      handleSetupSocket(workspaceUserData._id);
      handleUserWallets();
      SentryBrowser.setUser({
        id: userData._id,
        username: userData.name,
      });
    }
    /* The Below code can uncommented whenever required */

    // if (window?.heap) {
    //   try {
    //     window.heap.identify(userData._id);
    //     window.heap.addUserProperties({
    //       email: userData.email,
    //       id: userData._id,
    //       username: userData.name,
    //     });
    //   } catch (error) {
    //     Sentry.setTag('errorType', SENTRY_HEAP_USER_IDENTITY);
    //     Sentry.captureException(error);
    //   }
    // }
  }, [userData, handleSetupSocket, handleUserWallets, workspaceUserData]);

  const providerWrapper = () => {
    if (process.env.REACT_APP_UNDER_MAINTAINANCE === 'true') {
      return (
        <Routes>
          <Route path="*" element={<UnderMaintainance />} />
        </Routes>
      );
    }

    if (!isOnline) {
      toast.dismiss();
      return (
        <Routes>
          <Route path="*" element={<Disconnected />} />
        </Routes>
      );
    }

    return (
      <>
        {isAppInitialized ? (
          <div style={{ position: 'relative' }}>
            <ToastContainer bodyClassName="ToastifyToastBody" />
            <WagmiProvider config={wagmiConfig}>
              <QueryClientProvider client={queryClient}>
                <PopUpWrapper />
                <PopUpCenterWrapper />
                <Suspense fallback={<LoaderWrapper />}>
                  <Routes>
                    {userData && !isWorkspacesLoading
                      ? PrivateRoutes(walletData)
                      : PublicRoutes()}
                  </Routes>
                </Suspense>
              </QueryClientProvider>
            </WagmiProvider>
          </div>
        ) : (
          // Preloader while app is initializing
          <LoaderWrapper />
        )}
      </>
    );
  };
  return <>{providerWrapper()}</>;
}

const AppWithProfiler = Sentry.withProfiler(App);
export default AppWithProfiler;
