import Enum from './enums';

class DeepIntegrationExchanges extends Enum {
  static SUNCRYPTO = 'suncrypto';
  static COINDCX = 'coindcx';
}

export const deepIntegrationExchangesEnums = Object.freeze(
  DeepIntegrationExchanges
);
