import { useEffect, useRef } from 'react';
import styles from './TextFieldComponent.module.css';
import { Input } from '@koinx/xui';

const TextFieldComponent = ({
  title,
  onChange,
  placeholder,
  value,
  label,
  numeric,
  autoFocus = false,
  ...inputProps
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef?.current) inputRef?.current?.focus();
  }, [autoFocus]);

  const handleChange = (event) => {
    onChange(event.target.value, label);
  };

  return (
    <div className={styles.InputWrapper}>
      <label htmlFor={label} className={styles.Title}>
        {title}
      </label>
      <Input
        onChange={handleChange}
        onWheel={(e) => e.target.blur()}
        value={value}
        name={label}
        ref={inputRef}
        placeholder={placeholder}
        type={numeric ? 'number' : 'text'}
        id={label}
        width="100%"
        height="4.5rem"
        {...inputProps}
      />
    </div>
  );
};

export default TextFieldComponent;
