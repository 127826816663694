import React, { useState } from 'react';
import styles from './ChartOfAccountsWrapper.module.css';
import ChartOfAccountsTable from '@/Components/Routes/ChartOfAccounts/ChartOfAccountsTable/ChartOfAccountsTable';
import { Add, Button } from '@koinx/xui';
import { ChartOfAccountsData } from './constants';
import CreateAccountDrawer from '@/Components/Routes/ChartOfAccounts/CreateAccountDrawer';
import { permissionsCategoryEnums } from '@/Utils/enums/permissionsCategory';
import { permissionsEnums } from '@/Utils/enums/permissions';
import { useUserHasPermission } from '@/Hooks/useUserHasPermission';

const ChartOfAccountsWrapper = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const userHasPermissionToCreateAccounts = useUserHasPermission(
    permissionsCategoryEnums.ACCOUNTS,
    permissionsEnums.CREATE
  );
  const onAddAccount = () => {
    setOpenDrawer(true);
  };
  const onDismiss = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.TitleWrapper}>
          <h3 className={styles.Title}>{ChartOfAccountsData.title}</h3>
          {userHasPermissionToCreateAccounts && (
            <Button
              data-testid="add-account-button"
              buttonType="icon_left"
              variant="primary"
              icon={<Add className={styles.AddIcon} />}
              onClick={onAddAccount}
            >
              {ChartOfAccountsData.btnText}
            </Button>
          )}
        </div>

        <ChartOfAccountsTable />
      </div>
      {openDrawer && (
        <CreateAccountDrawer
          isOpen={openDrawer}
          handleClose={onDismiss}
          handleonOpenChange={setOpenDrawer}
          width={'40%'}
        />
      )}
    </>
  );
};

export default ChartOfAccountsWrapper;
