import styles from './JournalsWrapper.module.css';
import { Add, Button } from '@koinx/xui';
import { journalsPageData } from './constants';
import JournalTable from '@/Components/Routes/Journals/Journal/JournalTable/JournalTable';
import { useNavigate } from 'react-router';
import { routeEnums } from '@/Utils/enums/routes';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { permissionsCategoryEnums } from '@/Utils/enums/permissionsCategory';
import { permissionsEnums } from '@/Utils/enums/permissions';
import { useUserHasPermission } from '@/Hooks/useUserHasPermission';
import DateRangeFilter from '@/Components/Routes/TransactionsPage/TransactionFilters/DateRangeFilter';
import { useState } from 'react';
import useJournal from '@/Hooks/useJournal';

const JournalsWrapper = () => {
  const [dateRange, setDateRange] = useState({
    from: '',
    to: '',
  });
  const { isJournalsListLoading, totalJournalCount, cursor, rowsPerPage } =
    useSelector((state) => state.journalTable);
  const { handleFetchJournalTableData } = useJournal();
  const userCanAddJournals = useUserHasPermission(
    permissionsCategoryEnums.JOURNALS,
    permissionsEnums.CREATE
  );
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(routeEnums.ADD_JOURNAL_PAGE);
  };

  const handleDateRangeChange = (from, to) => {
    const baseParams = {
      cursor: cursor,
      limit: rowsPerPage,
    };

    setDateRange({ from: from || '', to: to || '' });

    handleFetchJournalTableData({
      ...baseParams,
      ...(from &&
        to && {
          fromTimestamp: new Date(from).toISOString(),
          toTimestamp: new Date(to).toISOString(),
        }),
    });
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.TitleWrapper}>
        <div className={styles.TitleMainWrapper}>
          {' '}
          <h3 className={styles.Title}>
            <span data-testid="journals-heading">{journalsPageData.title}</span>
            {isJournalsListLoading ? (
              <Skeleton width={50} height={40} />
            ) : (
              <span>
                (
                <span data-testid="journalsHeading-totalJournalCount">
                  {totalJournalCount}
                </span>
                )
              </span>
            )}
          </h3>
          {userCanAddJournals && (
            <Button
              onClick={handleClick}
              data-testid="newJournal-button"
              variant="primary"
              buttonType="icon_left"
              icon={<Add className={styles.AddIcon} />}>
              {journalsPageData.buttonText}
            </Button>
          )}
        </div>

        <DateRangeFilter
          value={dateRange}
          onChange={handleDateRangeChange}
          disabled={isJournalsListLoading}
          className={styles.DateRangeFilter}
        />
      </div>

      <JournalTable />
    </div>
  );
};

export default JournalsWrapper;
