import { ARCHIVE_TRANSACTIONS_URL } from '@/Utils/constants';

export const archiveTransactions = (
  axiosInstance,
  archiveTransactionsArray
) => {
  return axiosInstance.patch(
    ARCHIVE_TRANSACTIONS_URL,
    archiveTransactionsArray
  );
};
