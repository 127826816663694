/**
 *
 * @param {number} count
 * @param {string} singularWord
 * @param {string} pluralWord
 * @returns {string} singlularWord or pluralWord based on count
 *
 * If count is 1, the function will return the singularWord, else if the count is more than 1,
 * the function will return the pluralWord.
 */
export const pluralize = (count, singularWord, pluralWord) => {
  if (count <= 1) {
    return singularWord;
  } else if (count >= 1) {
    return pluralWord;
  }
};
