export const commonHeaders = [
  { id: 'timestamp', label: 'Date' },
  { id: 'inCoin', label: 'Incoming Asset' },
  { id: 'outCoin', label: 'Outgoing Asset' },
  { id: 'fromAddress', label: 'From' },
  { id: 'toAddress', label: 'To' },
  { id: 'inCoinAmount', label: 'Amount In' },
  { id: 'outCoinAmount', label: 'Amount Out' },
  { id: 'feeCoinAmount', label: 'Fee' },
];

export const UnrecognizedTableHeaders = [
  ...commonHeaders,
  { id: 'category', label: 'Category' },
];

export const RecognizedTableHeaders = [
  ...commonHeaders,
  { id: 'accounting', label: 'Recognized Category' },
];

export const ArchivedTableHeaders = [
  ...commonHeaders,
  { id: 'accounting', label: 'Label' },
];

export const CategorizedTableHeaders = [
  ...commonHeaders,
  { id: 'accounting', label: 'Category' },
];
