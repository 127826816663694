import { useEffect, useState } from 'react';
import styles from './Details.module.css';
import { Divider } from '@mui/material';
import { useParams } from 'react-router';
import useChartOfAccounts from '@/Hooks/useChartOfAccounts';
import TopDetailsSection from './components/TopDetailsSection';
import TransactionDetailsSection from './components/TransactionDetailsSection';
import TransactionsTable from './components/TransactionsTable';
import { useSearchParams } from 'react-router-dom';

const Details = ({ isTreasuryAccountFlow = false }) => {
  const { accountId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const { handleGetSelectedAccountDetails } = useChartOfAccounts();
  const [isTreasuryState, setIsTreasuryState] = useState(isTreasuryAccountFlow);

  useEffect(() => {
    const urlTreasuryParam = searchParams.get('isTreasury');

    if (urlTreasuryParam !== null) {
      setIsTreasuryState(urlTreasuryParam === 'true');
    } else if (isTreasuryAccountFlow) {
      setIsTreasuryState(true);
      setSearchParams({ isTreasury: 'true' }, { replace: true });
    }
  }, [isTreasuryAccountFlow, searchParams, setSearchParams]);

  useEffect(() => {
    if (accountId) {
      handleGetSelectedAccountDetails({
        accountId: accountId,
        hierarchy: true,
        isTreasuryAccount: isTreasuryState,
      });
    }
  }, [accountId, handleGetSelectedAccountDetails, isTreasuryState]);

  return (
    <div className={styles.Wrapper}>
      <TopDetailsSection isTreasuryFlow={isTreasuryState} />
      <Divider data-testid="detailsPage-divider" />
      <TransactionDetailsSection />
      <TransactionsTable />
    </div>
  );
};

export default Details;
