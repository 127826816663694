import Enum from './enums';

class IntegrationMethodsEnum extends Enum {
  static OAUTH = 'oauth';
  static DEEP_INTEGRATION = 'deep_integration';
  static CSV = 'csv';
  static API_KEY = 'api_key';
  static CHAIN = 'chain';
  static ADDRESS = 'address';
}

export const integrationMethodsEnum = Object.freeze(IntegrationMethodsEnum);
