import { Box, Tab, Tabs } from '@mui/material';
import { integrationMethodsEnum } from '@/Utils/enums/integrationMethods';
import { useSelector } from 'react-redux';
import styles from './TabPanel.module.css';
import { tabPanelData } from '@/Components/AddExchangesPage/constants';
import TabView from './TabView';
import AlertSectionDrawer from '@/Components/AddExchangesPage/Components/AlertSection/AlertSectionDrawer';
import HowToIntegrateDrawer from '@/Components/AddExchangesPage/Components/HowToIntegrate/HowToIntegrateDrawer';
// import useScreenType from '@/Hooks/useScreenType'

const { DIRECT_CONNECT, RECOMMENDED, UPLOAD_DATA, recommendedBoxExceptions } =
  tabPanelData;

const TabPanel = ({ customName, selectedMethod, setSelectedMethod }) => {
  const { enabledIntegrationMethods, selectedIntegration } = useSelector(
    (state) => state.sources
  );
  const handleChange = (_, newValue) => {
    setSelectedMethod(newValue);
  };

  const getRecommededBox = (integrationName) => {
    // if (!isMobileScreen) {
    return (
      <div className={styles.RecommededBoxWrapper}>
        <span>{DIRECT_CONNECT}</span>
        {!recommendedBoxExceptions.includes(integrationName) && (
          <div className={styles.RecommededBox}>{RECOMMENDED}</div>
        )}
      </div>
    );
    // } else {
    //   return (
    //     <>
    //       <span>{DIRECT_CONNECT}</span>
    //     </>
    //   );
    // }
  };

  const labelGenerator = (item) => {
    if (item === integrationMethodsEnum.OAUTH) {
      return getRecommededBox(selectedIntegration?.name);
    } else if (item === integrationMethodsEnum.DEEP_INTEGRATION) {
      return getRecommededBox(selectedIntegration?.name);
    } else if (item === integrationMethodsEnum.CSV) {
      return UPLOAD_DATA;
    } else {
      return item?.split('_').join(' ');
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedMethod}
          onChange={handleChange}
          aria-label=""
          variant={
            // isMobileScreen ? 'fullWidth' :
            'standard'
          }
        >
          {enabledIntegrationMethods?.map((item) => {
            return <Tab key={item} label={labelGenerator(item)} value={item} />;
          })}
        </Tabs>
      </Box>
      <div className={styles.TabPanel}>
        {/* {isMobileScreen && (
          <div className={styles.BtnContainer}>
            <AlertSectionDrawer selectedMethod={selectedMethod} />
            <HowToIntegrateDrawer selectedMethod={selectedMethod} />
          </div>
        )} */}
        <TabView customName={customName} selectedMethod={selectedMethod} />
      </div>
    </Box>
  );
};

export default TabPanel;
