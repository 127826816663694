import { countryData } from '@/Utils/countryData';
import { transactionLabelsEnum } from '@koinx/utils';
import { categorizeSections, mainTransactionLabels } from './constants';
import { useSelector } from 'react-redux';

/**
 * @function getMainTransactionAmount
 * @description Calculates the main transaction amount based on the type of transaction.
 * @param {Object} rowData - The data object for the transaction.
 * @returns {number} - The calculated main transaction amount.
 */
export function getMainTransactionAmount(rowData, baseCurrency) {
  const {
    externalLabel,
    notionalReceivedValue,
    inCoin,
    outCoin,
    receivedValue,
    outCoinTotalCostOfAcquisition,
    isP2P,
  } = rowData;

  // Case 1: Fixed Deposit Unlock or Receive from Pool
  if (
    externalLabel === transactionLabelsEnum.FIXED_DEPOSIT_UNLOCK ||
    externalLabel === transactionLabelsEnum.RECEIVE_FROM_POOL
  ) {
    return notionalReceivedValue || 0;
  }

  // Case 2: Borrow Settlement or Repay Loan
  if (
    externalLabel === transactionLabelsEnum.BORROW_SETTLEMENT ||
    externalLabel === transactionLabelsEnum.REPAY_LOAN
  ) {
    return notionalReceivedValue || 0;
  }

  // Case 3: Both inCoin and outCoin exist
  if (inCoin && outCoin) {
    if (inCoin === baseCurrency && isP2P) {
      return receivedValue || 0;
    }
    if (outCoin === baseCurrency && isP2P) {
      return outCoinTotalCostOfAcquisition || 0;
    }
  }

  // Case 4: Only one coin exists
  if (inCoin && !outCoin) {
    return receivedValue || 0;
  }
  if (outCoin && !inCoin) {
    return outCoinTotalCostOfAcquisition || 0;
  }
  return 0;
}

/**
 * @function getMainTransactionLabel
 * @description Determines the label for the main transaction amount, depending on the transaction type.
 * @param {Object|Array} rowData - The data object for the transaction or an array of transaction rows.
 * @param {boolean} [isMultiRow=false] - Whether the transaction involves multiple rows.
 * @returns {string} - The label for the transaction amount.
 */
export function getMainTransactionLabel(
  rowData,
  isMultiRow = false,
  baseCurrency
) {
  if (isMultiRow) {
    const allRowsHaveOnlyOutCoin = rowData.every(
      (row) => !row.inCoin && row.outCoin
    );
    if (allRowsHaveOnlyOutCoin) {
      return mainTransactionLabels.SENT_AMOUNT;
    }

    const allRowsHaveOnlyInCoin = rowData.every(
      (row) => row.inCoin && !row.outCoin
    );
    if (allRowsHaveOnlyInCoin) {
      return mainTransactionLabels.RECEIVED_AMOUNT;
    }

    const allRowsAreFDUnlockOrReceiveFromPool = rowData.every(
      (row) =>
        row.externalLabel === transactionLabelsEnum.FIXED_DEPOSIT_UNLOCK ||
        row.externalLabel === transactionLabelsEnum.RECEIVE_FROM_POOL
    );
    if (allRowsAreFDUnlockOrReceiveFromPool) {
      return mainTransactionLabels.RECEIVED_AMOUNT;
    }

    const allRowsAreBorrowSettlementOrRepayLoan = rowData.every(
      (row) =>
        row.externalLabel === transactionLabelsEnum.BORROW_SETTLEMENT ||
        row.externalLabel === transactionLabelsEnum.REPAY_LOAN
    );
    if (allRowsAreBorrowSettlementOrRepayLoan) {
      return mainTransactionLabels.SENT_AMOUNT;
    }

    return mainTransactionLabels.DEFAULT_AMOUNT;
  }

  const { externalLabel, inCoin, outCoin, isP2P } = rowData;

  if (
    externalLabel === transactionLabelsEnum.FIXED_DEPOSIT_UNLOCK ||
    externalLabel === transactionLabelsEnum.RECEIVE_FROM_POOL
  ) {
    return mainTransactionLabels.RECEIVED_AMOUNT;
  }

  if (
    externalLabel === transactionLabelsEnum.BORROW_SETTLEMENT ||
    externalLabel === transactionLabelsEnum.REPAY_LOAN
  ) {
    return mainTransactionLabels.SENT_AMOUNT;
  }

  if (inCoin && outCoin) {
    if (inCoin === baseCurrency && isP2P) {
      return mainTransactionLabels.RECEIVED_AMOUNT;
    }
    if (outCoin === baseCurrency && isP2P) {
      return mainTransactionLabels.SENT_AMOUNT;
    }
  }

  if (inCoin && !outCoin) {
    return mainTransactionLabels.RECEIVED_AMOUNT;
  }
  if (outCoin && !inCoin) {
    return mainTransactionLabels.SENT_AMOUNT;
  }

  return mainTransactionLabels.DEFAULT_AMOUNT;
}

export const roundToTwoDecimals = (value) => {
  return Number(Number(value).toFixed(2));
};

/**
 * @function initializeSelectedValues
 * @description Initializes the selected values for categorizing transactions based on the row data.
 * @param {Object|Array} data - The transaction data or an array of transaction rows.
 * @param {boolean} isMultiRow - Whether multiple rows are being processed.
 * @returns {Object} - The initialized selected values for each transaction section.
 */
export const initializeSelectedValues = (data, isMultiRow, baseCurrency) => {
  const rows = isMultiRow ? data : [data];
  return rows.reduce((acc, row) => {
    const rowValues = {
      ...(((row.inCoin && !row.outCoin) ||
        (!row.inCoin && row.outCoin) ||
        (row.inCoin && row.outCoin && row.isP2P)) && {
        mainTransaction: {
          category: '',
          account: '',
          amount: getMainTransactionAmount(row, baseCurrency),
        },
      }),
      ...(row.gain > 0 && {
        gains: { category: '', account: '', amount: row?.gain },
      }),
      ...(row?.feeCoin &&
        row?.feeCoinAmount > 0 && {
          fees: { category: '', account: '', amount: row?.fees },
        }),
      ...(row?.tdsCoin &&
        row?.tds > 0 && {
          tds: { category: '', account: '', amount: row?.tdsValue },
        }),
    };

    Object.keys(rowValues).forEach((key) => {
      if (!acc[key]) {
        acc[key] = rowValues[key];
      } else {
        acc[key].amount += rowValues[key].amount;
      }
    });

    return acc;
  }, {});
};

/**
 * @function createAccountEntries
 * @description Creates the account entries for a specific transaction section.
 * @param {Object} row - The data object for the transaction.
 * @param {string} section - The transaction section (e.g., mainTransaction, gains, fees, tds).
 * @param {Object} selectedValues - The selected category and account information.
 * @returns {Array} - An array of account entries.
 */
export const createAccountEntries = (row, section, selectedValues) => {
  if (!selectedValues[section]) return [];

  const sectionAmount = (() => {
    switch (section) {
      case categorizeSections.mainTransaction:
        return getMainTransactionAmount(row);
      case categorizeSections.gains:
        return row.gain;
      case categorizeSections.fees:
        return row.fees;
      case categorizeSections.tds:
        return row.tdsValue;
      default:
        return 0;
    }
  })();
  return [
    {
      accountId: selectedValues[section].account,
      amount: sectionAmount,
    },
  ];
};

export const getCurrencyLogo = (currencyCode) => {
  const matchingCountry = Object.values(countryData).find(
    (country) => country.currencyCode === currencyCode
  );
  return matchingCountry ? matchingCountry.currencyLogo : null;
};

export const shouldRenderMainTransaction = (rowData, isMultiRow) => {
  return isMultiRow
    ? rowData.some(
        (row) =>
          (row.inCoin && !row.outCoin) ||
          (!row.inCoin && row.outCoin) ||
          (row.inCoin && row.outCoin && row.isP2P)
      )
    : (rowData.inCoin && !rowData.outCoin) ||
        (!rowData.inCoin && rowData.outCoin) ||
        (rowData.inCoin && rowData.outCoin && rowData.isP2P);
};

export const shouldRenderGains = (rowData, isMultiRow) => {
  return isMultiRow ? rowData.some((row) => row.gain > 0) : rowData.gain > 0;
};

export const shouldRenderFees = (rowData, isMultiRow) => {
  return isMultiRow
    ? rowData.some((row) => row.feeCoin && row.feeCoinAmount > 0)
    : rowData.feeCoin && rowData.feeCoinAmount > 0;
};

export const shouldRenderTDS = (rowData, isMultiRow) => {
  return isMultiRow
    ? rowData.some((row) => row.tdsCoin && row.tds > 0)
    : rowData.tdsCoin && rowData.tds > 0;
};
