import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from './useAxiosPrivate';
import { getUser } from '@/Services/user/getUser';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import notify from '@/Utils/notifyToast';
import {
  setUserData,
  setUserDataError,
  setUserDataSuccess,
  setWorkspaceUserData,
  setWorkspaceUserDataSuccess,
  setWorkspaceUserDataError,
} from '@/Redux/slices/userSlice';
import { fetchWorkspaceUserData } from '@/Services/user/fetchWorkspaceUserData';

const useUser = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const handleGetUser = useCallback(
    async (handleDataFetchStatusUpdation) => {
      try {
        dispatch(setUserData());
        const response = await getUser(axiosPrivate);
        if (handleDataFetchStatusUpdation) handleDataFetchStatusUpdation();
        dispatch(setUserDataSuccess(response.data));
      } catch (error) {
        dispatch(setUserDataError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosPrivate, dispatch]
  );

  const handleFetchWorkspaceUserData = useCallback(async () => {
    try {
      dispatch(setWorkspaceUserData());
      const response = await fetchWorkspaceUserData(axiosPrivate);
      dispatch(setWorkspaceUserDataSuccess(response.data));
    } catch (error) {
      dispatch(setWorkspaceUserDataError(getErrorStatement(error)));
      notify(getErrorStatement(error), 'error');
    }
  }, [axiosPrivate, dispatch]);

  return { handleGetUser, handleFetchWorkspaceUserData };
};

export default useUser;
