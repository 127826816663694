import styles from '@/Components/Ui/Sidebar/SidebarTab/SidebarTab.module.css';
import { KeyboardArrowRight } from '@koinx/xui';
import PopoverContent from '@/Components/Ui/Sidebar/SidebarTab/PopoverContent';

const ExpandableNestedTab = ({
  tab,
  isActiveTab,
  handlePopoverOpen,
  handleClose,
  isCollapsed,
  anchorEl,
  isPopoverOpen,
}) => {
  return (
    <div
      data-nested-tab-active={isActiveTab(tab.link)}
      className={styles.ExpandableNestedTabContainer}
    >
      <div
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handlePopoverOpen(e);
          }
        }}
        data-testid={tab.name}
        onClick={handlePopoverOpen}
        className={styles.ExpandableTabWrapper}
      >
        <div className={styles.NameAndIconWrapper}>
          {tab.icon ? <tab.icon className={styles.TabIcon} /> : <></>}
          <span className={styles.TabName}>{tab.name}</span>
        </div>
        <KeyboardArrowRight className={styles.ArrowIconRight} />
      </div>
      <PopoverContent
        tab={tab}
        handleClose={handleClose}
        anchorEl={anchorEl}
        isCollapsed={isCollapsed}
        isPopoverOpen={isPopoverOpen}
      />
    </div>
  );
};

export default ExpandableNestedTab;
