import { useSelector } from 'react-redux';
import styles from './SearchComponent.module.css';
import { Select } from '@koinx/xui';
import { useMemo } from 'react';
import { CountryToCurrency } from '@/Utils/countryToCurrencyMapping';
import { searchComponentStaticData } from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/constants';
import { formatCoinsDropdownOptions } from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/helper';

const { currencyTitle, placeholder } = searchComponentStaticData;

const fiatCurrencyCodes = Object.values(CountryToCurrency).sort();

const SearchComponent = ({ onChange, value, label, ...rest }) => {
  const coins = useSelector((state) => state.transactions.top1000coins);
  const userTransactionCoins = useSelector(
    (state) => state.transactions.transactionCoins
  );

  const coinOptions = useMemo(() => {
    const userCoins = Object.keys(userTransactionCoins).sort();
    const fiatCoins = fiatCurrencyCodes;
    const topCoins = coins.map((item) => item.symbol.toUpperCase());

    let filteredTopCoins = topCoins.filter((coin) =>
      userTransactionCoins?.[coin] ? false : true
    );

    filteredTopCoins = [...filteredTopCoins, 'LOOM', 'NAS'].sort();

    const updatedOptions = [
      ...formatCoinsDropdownOptions(userCoins, userTransactionCoins),
      ...formatCoinsDropdownOptions(fiatCoins, userTransactionCoins, true),
      ...formatCoinsDropdownOptions(filteredTopCoins, userTransactionCoins),
    ];

    return updatedOptions;
  }, [coins, userTransactionCoins]);

  const handleChange = (newVal) => {
    onChange(newVal, label);
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Title}>{currencyTitle}</div>
      <Select
        options={coinOptions}
        optionItemLeftImage
        searchInput
        onChange={handleChange}
        value={value ? value : ''}
        className={styles.Select}
        virtualizeOptions
        sortedOptions={false}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default SearchComponent;
