/**
 *
 * @param {TransactionDetailsObject[]} transactionsArray
 * @param {number[]} rowsSelected
 * @param {string} labelSelected
 * @returns an array of objects containing transactionId and labels array.
 */
export const prepareArchiveTransactionsData = (
  transactionsArray,
  rowsSelected,
  labelSelected
) => {
  let array = rowsSelected.map((transaction) => ({
    transactionId: transaction._id,
    labels: [labelSelected],
  }));
  return array;
};
