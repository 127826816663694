import React from 'react';
import UnauthorizedComponent from '@/Components/UnauthorizedComponent/UnauthorizedComponent';

const CheckForUnauthorizedAccess = ({ check, children }) => {
  if (check) {
    return children;
  }
  return <UnauthorizedComponent />;
};

export default CheckForUnauthorizedAccess;
