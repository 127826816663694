import { Sync, AddIntegration, Button, Text } from '@koinx/xui';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import SyncAllPopup from './SyncAllPopup';
import Wallets from './Wallets/Wallets';
import styles from '@/Components/Routes/DataSources/UserIntegrations/UserIntegrations.module.css';
import { routeEnums } from '@/Utils/enums/routes';
import { walletsPageData } from '@/Components/Routes/DataSources/UserIntegrations/constants';
import usePopup from '@/Hooks/usePopup';
import { showSyncAllButton } from '@/Components/Routes/DataSources/UserIntegrations/helper';
import { staticData } from '@/Components/AddExchangesPage/Components/AlertSection/constants';
import { walletPageData } from './WalletCard/constants';
import { permissionsCategoryEnums } from '@/Utils/enums/permissionsCategory';
import { permissionsEnums } from '@/Utils/enums/permissions';
import { useUserHasPermission } from '@/Hooks/useUserHasPermission';

const { addIntegrationText, syncAllText } = walletsPageData;

const UserIntegrationsDesktop = () => {
  const { handlePopupCenterComponentRender, handlePopupCenterOpen } =
    usePopup();
  const userCanAddIntegrations = useUserHasPermission(
    permissionsCategoryEnums.INTEGRATIONS,
    permissionsEnums.CREATE
  );
  const walletData = useSelector((state) => state.wallet.wallets);

  const navigate = useNavigate();

  const handleAddWalletClick = () => {
    navigate(routeEnums.ADD_INTEGRATION_PAGE);
  };

  const hasSyncBasedIntegration = showSyncAllButton(walletData);

  const handleSyncAllCtaClick = () => {
    handlePopupCenterOpen(true);
    handlePopupCenterComponentRender(<SyncAllPopup />);
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.UpperWrapper}>
        <h1 className={styles.Header}>{walletPageData.upperSection.title}</h1>
        <div className={styles.Buttons}>
          {hasSyncBasedIntegration && userCanAddIntegrations && (
            <Button
              variant="outline"
              buttonType="icon_right"
              icon={<Sync />}
              onClick={handleSyncAllCtaClick}>
              {syncAllText}
            </Button>
          )}
          {userCanAddIntegrations && (
            <Button
              buttonType="icon_right"
              icon={<AddIntegration fill="var(--absolute-white)" />}
              onClick={handleAddWalletClick}>
              {addIntegrationText}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.WalletWrapper}>
        <Wallets />
        {userCanAddIntegrations && (
          <div
            className={styles.AddNewWalletWrapper}
            onClick={handleAddWalletClick}>
            <AddIntegration fill="var(--blue-09)" width={30} height={30} />
            <Text variant="heading-3">
              {walletPageData.upperSection.addWalletButton.text}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserIntegrationsDesktop;
