export const categoriseTransactionsData = {
  title: 'Categorise Transaction',
  leftButtonTxt: 'Cancel',
  rightButtonTxt: 'Save',
  accountLabel: 'Account',
  categoryLabel: 'Category',
};
export const categorizeSections = {
  mainTransaction: 'mainTransaction',
  tds: 'tds',
  fees: 'fees',
  gains: 'gains',
};

export const mainTransactionLabels = {
  SENT_AMOUNT: 'Sent Amount',
  RECEIVED_AMOUNT: 'Received Amount',
  DEFAULT_AMOUNT: 'Amount',
};
