import styles from '@/Components/Ui/Sidebar/SidebarTab/SidebarTab.module.css';
import { KeyboardArrowRight } from '@koinx/xui';
import { useNavigate } from 'react-router';
import SidebarTab from '@/Components/Ui/Sidebar/SidebarTab/SidebarTab';

const ExpandableTab = ({
  tab,
  isOpen,
  isActiveTab,
  handleToggleTab,
  isCollapsed,
}) => {
  return (
    <>
      <div
        data-testid={tab.name}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleToggleTab();
          }
        }}
        tabIndex={0}
        data-active={isActiveTab(tab.link)}
        className={styles.OpenedSidebarExpandableTabContainer}
        onClick={handleToggleTab}
      >
        <div className={styles.OpenedSidebarTabWrapper}>
          <div className={styles.ExpandedNameAndIconWrapper}>
            {tab.icon ? <tab.icon className={styles.TabIcon} /> : <></>}
            <span className={styles.TabName}>{tab.name}</span>
          </div>
          {tab.isExpandable && (
            <KeyboardArrowRight
              className={styles.ArrowIconRight}
              data-arrow-rotate={isOpen}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <ul className={styles.OpenedSidebarTabList}>
          {tab.subListItems.map((nestedTab, key) => {
            return (
              <li key={key}>
                <SidebarTab
                  tab={nestedTab}
                  isCollapsed={isCollapsed}
                  isNested={true}
                />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
export default ExpandableTab;
