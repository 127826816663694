import {
  ADD_NEW_WALLET,
  externalTypeToExternalLabelMapping,
  initialState,
  labels,
  numberDataTypes,
} from './constants';
import { addEditTransactionDrawerTypes } from '@/Utils/enums/addEditTransactionDrawerTypes';
import { AddIntegration, Avatar } from '@koinx/xui';
import {
  transactionLabelsToIconMapping,
  transactionTypesEnum,
  transactionTypesToIconMapping,
  truncateString,
  transactionLabelsEnum,
} from '@koinx/utils';
import { getCryptoCoinDetails } from '@/Utils/helper/getCryptoCoinDetails';

export const formatCoinsDropdownOptions = (
  coinOptions,
  userTransactionCoins,
  isFiatCoin = false
) => {
  return coinOptions?.map((item) => {
    let coinName = item;
    if (coinName?.includes('@')) {
      coinName = `${coinName.split('@')[0]} (${coinName.split('@')[1]})`;
      coinName = truncateString(coinName, 20) + ')';
    }
    return {
      label: coinName,
      value: item,
      itemLogo: getCryptoCoinDetails(
        item,
        null,
        userTransactionCoins,
        isFiatCoin
      ).image,
    };
  });
};

export const hideReceivedSection = (type, label) => {
  const typesForWhichReceivedSectionShouldBeHidden = new Set([
    transactionTypesEnum.WITHDRAWAL,
    transactionTypesEnum.EXPENSE,
  ]);

  const labelsForWhichReceivedSectionShouldBeHidden = new Set([]);

  return (
    typesForWhichReceivedSectionShouldBeHidden.has(type) ||
    labelsForWhichReceivedSectionShouldBeHidden.has(label)
  );
};

export const hideSentSection = (type, label) => {
  const typesForWhichSentSectionShouldBeHidden = new Set([
    transactionTypesEnum.DEPOSIT,
  ]);

  const labelsForWhichSentSectionShouldBeHidden = new Set([
    transactionLabelsEnum.BORROW,
    transactionLabelsEnum.LEND_SETTLEMENT,
  ]);

  return (
    typesForWhichSentSectionShouldBeHidden.has(type) ||
    labelsForWhichSentSectionShouldBeHidden.has(label)
  );
};

export const showSentSectionCoinInfo = (type) => {
  const typesForWhichSentSectionCoinInfoShouldBeShown = new Set([
    transactionTypesEnum.WITHDRAWAL,
    transactionTypesEnum.EXPENSE,
  ]);

  return typesForWhichSentSectionCoinInfoShouldBeShown.has(type);
};

export const initializeState = (
  type,
  userTransaction,
  sources,
  wallets,
  aquisitionAmount,
  sourceNameToSourceDataMap,
  manualWalletId
) => {
  if (userTransaction) {
    const baseState = {
      ...initialState,
      type: userTransaction?.externalType ?? initialState.type,
      label: userTransaction?.externalLabel ?? initialState.label,
      timestamp: new Date(userTransaction.timestamp),
      walletId: userTransaction?.walletId ?? initialState.walletId,
      sourceId:
        sourceNameToSourceDataMap[userTransaction?.source]?._id ??
        initialState.sourceId,
      inCoin: userTransaction?.inCoin ?? initialState.inCoin,
      inCoinMarketPrice:
        userTransaction?.inCoinMarketPrice ?? initialState.inCoinMarketPrice,
      notionalInCoinMarketPrice:
        userTransaction?.notionalInCoinMarketPrice ??
        initialState.notionalInCoinMarketPrice,
      outCoin: userTransaction?.outCoin ?? initialState.outCoin,
      inCoinAmount: userTransaction?.inCoinAmount ?? initialState.inCoinAmount,
      outCoinAmount:
        userTransaction?.outCoinAmount ?? initialState.outCoinAmount,
      feeCoin: userTransaction?.feeCoin ?? initialState.feeCoin,
      feeCoinAmount:
        userTransaction?.feeCoinAmount ?? initialState.feeCoinAmount,
      tdsCoin: userTransaction?.tdsCoin ?? initialState.tdsCoin,
      tds: userTransaction?.tds ?? initialState.tds,
      description: userTransaction?.description ?? initialState.description,
      fromAddress: userTransaction?.fromAddress ?? initialState.fromAddress,
      toAddress: userTransaction?.toAddress ?? initialState.toAddress,
      txnHash: userTransaction?.txnHash ?? initialState.txnHash,
    };
    if (type === addEditTransactionDrawerTypes.ADD) {
      return {
        ...baseState,
        inCoinAmount: aquisitionAmount
          ? Number(aquisitionAmount)
          : baseState.inCoinAmount,
        walletId: manualWalletId ?? baseState.walletId,
      };
    } else {
      return {
        ...baseState,
        notionalInCoinMarketPrice: hideReceivedSection(
          baseState.type,
          baseState.label
        )
          ? baseState.notionalInCoinMarketPrice
          : baseState.inCoinMarketPrice,
        inCoinMarketPrice: hideReceivedSection(baseState.type, baseState.label)
          ? ((
              baseState.notionalInCoinMarketPrice * baseState.outCoinAmount
            ).toString() ?? '')
          : ((
              baseState.inCoinMarketPrice * baseState.inCoinAmount
            ).toString() ?? ''),
        inCoin:
          userTransaction?.inCoin &&
          userTransaction?.metadata?.inCoinContractAddress
            ? userTransaction?.inCoin +
              '@' +
              userTransaction?.metadata?.inCoinContractAddress
            : baseState?.inCoin,
        outCoin:
          userTransaction?.outCoin &&
          userTransaction?.metadata?.outCoinContractAddress
            ? userTransaction?.outCoin +
              '@' +
              userTransaction?.metadata?.outCoinContractAddress
            : baseState?.outCoin,
      };
    }
  }

  return {
    ...initialState,
    sourceId: sources[0]?._id,
    walletId: wallets[0]?._id,
  };
};

export const convertTimestampToEpochWithTimeOption = (timestamp, timezone) => {
  // Step 1: Convert the timestamp to a Date object
  const date = new Date(timestamp);

  // Step 2: Format the date string to 'Mon Sep 23 2024'
  const dateString = date.toDateString(); // This will give 'Mon Sep 23 2024'

  // Step 3: Get the time string, i.e., "12:34:12"
  const timeString = date.toTimeString().split(' ')[0];

  // Step 4: Replace the timezone with the provided timezone
  const dateTimeWithTimezone = `${dateString} ${timeString} ${timezone}`;

  // Step 5: Convert the final date-time string back to epoch (milliseconds since Jan 1, 1970)
  const epochValue = Date.parse(dateTimeWithTimezone);

  return epochValue;
};

const cleanObject = (obj) => {
  const cleanedObj = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      if (key === labels.WALLETID) {
        if (obj[key] === ADD_NEW_WALLET) {
          continue;
        }
      }

      if (numberDataTypes.has(key)) {
        cleanedObj[key] = Number(obj[key]);
      } else {
        cleanedObj[key] = obj[key];
      }
    }
  }

  return cleanedObj;
};

export const cleanTransaction = (transaction) => {
  let processedTransaction = {};

  if (
    !hideSentSection(transaction?.type, transaction?.label) &&
    showSentSectionCoinInfo(transaction?.type)
  ) {
    processedTransaction = transaction;
    processedTransaction.inCoinMarketPrice = null;
  } else if (!hideReceivedSection(transaction?.type, transaction?.label)) {
    processedTransaction = transaction;
    processedTransaction.inCoinMarketPrice =
      processedTransaction.notionalInCoinMarketPrice;
    processedTransaction.notionalInCoinMarketPrice = null;
  }

  return cleanObject(processedTransaction);
};

export const handleTransactionChange = (prevTransaction, name, value) => {
  if (name === labels.TYPE) {
    return {
      ...prevTransaction,
      [name]: value,
      [labels.LABEL]: externalTypeToExternalLabelMapping[value][0],
    };
  }
  if (
    hideReceivedSection(prevTransaction?.type, prevTransaction?.label) &&
    showSentSectionCoinInfo(prevTransaction?.type)
  ) {
    if (name === labels.OUTCOINAMOUNT) {
      if (prevTransaction?.inCoinMarketPrice) {
        return {
          ...prevTransaction,
          [name]: value,
          notionalInCoinMarketPrice: prevTransaction?.inCoinMarketPrice / value,
        };
      } else if (prevTransaction?.notionalInCoinMarketPrice) {
        return {
          ...prevTransaction,
          [name]: value,
          inCoinMarketPrice: prevTransaction?.notionalInCoinMarketPrice * value,
        };
      }
    } else if (name === labels.INCOINMARKETPRICE) {
      if (prevTransaction?.outCoinAmount) {
        return {
          ...prevTransaction,
          [name]: value,
          notionalInCoinMarketPrice: value / prevTransaction?.outCoinAmount,
        };
      } else if (prevTransaction?.notionalInCoinMarketPrice) {
        return {
          ...prevTransaction,
          [name]: value,
          outCoinAmount: value / prevTransaction?.notionalInCoinMarketPrice,
        };
      }
    } else if (name === labels.NOTIONALINCOINMARKETPRICE) {
      if (prevTransaction?.outCoinAmount) {
        return {
          ...prevTransaction,
          [name]: value,
          inCoinMarketPrice: value * prevTransaction?.outCoinAmount,
        };
      } else if (prevTransaction?.inCoinMarketPrice) {
        return {
          ...prevTransaction,
          [name]: value,
          outCoinAmount: prevTransaction?.inCoinMarketPrice / value,
        };
      }
    } else {
      return {
        ...prevTransaction,
        [name]: value,
      };
    }
  } else {
    if (name === labels.INCOINAMOUNT) {
      if (prevTransaction?.inCoinMarketPrice) {
        return {
          ...prevTransaction,
          [name]: value,
          notionalInCoinMarketPrice: prevTransaction?.inCoinMarketPrice / value,
        };
      } else if (prevTransaction?.notionalInCoinMarketPrice) {
        return {
          ...prevTransaction,
          [name]: value,
          inCoinMarketPrice: prevTransaction?.notionalInCoinMarketPrice * value,
        };
      }
    } else if (name === labels.INCOINMARKETPRICE) {
      if (prevTransaction?.inCoinAmount) {
        return {
          ...prevTransaction,
          [name]: value,
          notionalInCoinMarketPrice: value / prevTransaction?.inCoinAmount,
        };
      } else if (prevTransaction?.notionalInCoinMarketPrice) {
        return {
          ...prevTransaction,
          [name]: value,
          inCoinAmount: value / prevTransaction?.notionalInCoinMarketPrice,
        };
      }
    } else if (name === labels.NOTIONALINCOINMARKETPRICE) {
      if (prevTransaction?.inCoinAmount) {
        return {
          ...prevTransaction,
          [name]: value,
          inCoinMarketPrice: value * prevTransaction?.inCoinAmount,
        };
      } else if (prevTransaction?.inCoinMarketPrice) {
        return {
          ...prevTransaction,
          [name]: value,
          inCoinAmount: prevTransaction?.inCoinMarketPrice / value,
        };
      }
    } else {
      return {
        ...prevTransaction,
        [name]: value,
      };
    }
  }

  return {
    ...prevTransaction,
    [name]: value,
  };
};

export const getTransactionTypeOptions = () => {
  return Object.keys(externalTypeToExternalLabelMapping).map((key) => {
    const TypeIcon = transactionTypesToIconMapping[key];
    return {
      label: key,
      value: key,
      itemLogo: <TypeIcon fill="var(--sec-blue)" />,
    };
  });
};

export const getTransactionLabelOptions = (transactionType) => {
  return externalTypeToExternalLabelMapping?.[transactionType]?.map((label) => {
    const LabelIcon = transactionLabelsToIconMapping[label];
    return {
      label: label,
      value: label,
      itemLogo: <LabelIcon fill="var(--sec-blue)" />,
    };
  });
};

export const getSourceOptions = (sources) => {
  return sources.map((source) => ({
    label: source.displayName,
    value: source._id,
    itemLogo: (
      <Avatar
        imageSrc={source.logo}
        fallbackContent={source.displayName?.charAt(0)}
        style={{ width: '2rem', height: '2rem' }}
      />
    ),
  }));
};

export const getWalletOptions = (wallets, walletToNamesMap) => {
  const walletOptions = wallets.map((wallet) => {
    const walletInfo = walletToNamesMap[wallet._id];
    return {
      label: walletInfo?.customName ?? walletInfo?.displayName,
      value: wallet._id,
      itemLogo: (
        <Avatar
          imageSrc={walletInfo?.logo}
          fallbackContent={walletInfo?.displayName?.charAt(0)}
          style={{ width: '2rem', height: '2rem' }}
        />
      ),
    };
  });

  return [
    ...walletOptions,
    {
      label: ADD_NEW_WALLET,
      value: ADD_NEW_WALLET,
      itemLogo: <AddIntegration fill="var(--sec-blue)" width="2rem" />,
    },
  ];
};
