import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { bitbnsTokens } from '@/Services/bitbns/bitbnsTokens';
import { BITBNS_AUTH_URL } from '@/Utils/constants';
import { bitbnsSyncTransactions } from '@/Services/bitbns/bitbnsSyncTransactions';
import {
  generateRandomString,
  generateCodeChallenge,
} from '@/Services/bitbns/helpers/bitbnsAuthHelpers';
import {
  BITBNS_CLIENT_ID,
  BITBNS_CODE_CHALLENGE_METHOD,
  BITBNS_REDIRECT_URL,
  BITBNS_RESPONSE_TYPE,
  BITBNS_SCOPE,
  BITBNS_STATE,
} from '@/Utils/config';
import useAxiosPrivate from './useAxiosPrivate';
// import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import { routeEnums } from '@/Utils/enums/routes';
import notify from '@/Utils/notifyToast';
import useStorage from './useStorage';
import { navigateToIntegrationLoading } from '@/Utils/helper/navigateToIntegrationLoading';
import { integrationMethodsEnum } from '@/Utils/enums/integrationMethods';
import { OAuthEnum } from '@/Utils/enums/OAuthEnum';
import {
  setBitbnsSyncTransactions,
  setBitbnsSyncTransactionsError,
  setBitbnsSyncTransactionsSuccess,
  setBitbnsTokens,
  setBitbnsTokensError,
  setBitbnsTokensSuccess,
} from '@/Redux/slices/bitBnsSlice';
import getErrorStatement from '@/Utils/helper/getErrorStatement';

function useBitbns() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const { removeFromLocalStorage, storeInLocalStorage } = useStorage();
  // const { handleGetUserSpinWheelResult } = useUser();

  const sourceNameToSourceDataMap = useSelector(
    (state) => state.sources.sourceNameToSourceDataMap
  );

  // const gaEventTracker = useAnalyticsEventTracker('Bitbns');
  let attemptedTimes = 0;

  const handleBitbnsAuth = useCallback(async () => {
    try {
      const randString = generateRandomString();
      storeInLocalStorage('code_verifier', randString);
      const code_challenge = await generateCodeChallenge(randString);
      const response_type = BITBNS_RESPONSE_TYPE;
      const client_id = BITBNS_CLIENT_ID;
      const redirect_uri = encodeURIComponent(BITBNS_REDIRECT_URL);
      const scope = BITBNS_SCOPE;
      const state = BITBNS_STATE;
      const code_challenge_method = BITBNS_CODE_CHALLENGE_METHOD;

      window.location.href =
        BITBNS_AUTH_URL +
        redirect_uri +
        '&response_type=' +
        response_type +
        '&client_id=' +
        client_id +
        '&scope=' +
        scope +
        '&state=' +
        state +
        '&code_challenge=' +
        code_challenge +
        '&code_challenge_method=' +
        code_challenge_method;
    } catch (error) {
      if (!axios.isCancel(error)) {
        // gaEventTracker('Bitbns auth error', 'Bitbns auth error');
        notify('Bitbns Signin Failed', 'error');
      }
    }
  }, [
    // gaEventTracker,
    storeInLocalStorage,
  ]);

  const handleBitbnsSyncTransactions = useCallback(
    (walletId, isResync) => {
      attemptedTimes++;
      dispatch(setBitbnsSyncTransactions());

      const exchangeSourceData = sourceNameToSourceDataMap[OAuthEnum.BITBNS];
      const exchangeOAuthSyncEndpoint =
        exchangeSourceData?.endpoints?.[integrationMethodsEnum.OAUTH]?.sync;

      bitbnsSyncTransactions(
        axiosPrivate,
        exchangeOAuthSyncEndpoint,
        walletId ? { walletId } : {}
      )
        .then((response) => {
          // gaEventTracker(
          //   'Bitbns sync transactions successfull',
          //   'Bitbns sync transactions successfull'
          // );
          navigate(
            isResync
              ? routeEnums.INTEGRATION_LOADING_PAGE
              : `${routeEnums.ADD_WALLET_PAGE}?success=true`
          );
          dispatch(setBitbnsSyncTransactionsSuccess(response.data.message));
          // dispatch(
          //   updateSocketStateSuccess({
          //     stateCount: 1,
          //     message: 'Integration data uploaded successfully to the servers',
          //     error: false,
          //   })
          // );
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            const responseError = error?.response?.data?.errors?.[0];
            if (
              attemptedTimes <= 1 &&
              responseError?.name === 'InvalidKeysError' &&
              responseError?.source === 'bitbns'
            ) {
              return handleBitbnsAuth();
            }
            // gaEventTracker(
            //   'Bitbns sync transactions error',
            //   'Bitbns sync transactions error'
            // );
            dispatch(setBitbnsSyncTransactionsError(getErrorStatement(error)));
            notify(getErrorStatement(error), 'error');
          }
        });
    },
    [
      attemptedTimes,
      dispatch,
      sourceNameToSourceDataMap,
      axiosPrivate,
      // gaEventTracker,
      navigate,
      handleBitbnsAuth,
    ]
  );

  const handleBitbnsTokens = useCallback(
    (code, codeVerifier) => {
      dispatch(setBitbnsTokens());

      const exchangeSourceData = sourceNameToSourceDataMap[OAuthEnum.BITBNS];

      const exchangeOAuthTokensEndpoint =
        exchangeSourceData?.endpoints?.[integrationMethodsEnum.OAUTH]?.tokens;

      bitbnsTokens(axiosPrivate, exchangeOAuthTokensEndpoint, {
        code,
        codeVerifier,
      })
        .then((response) => {
          // gaEventTracker(
          //   'Bitbns fetch transactions successfull',
          //   'Bitbns fetch transactions successfull'
          // );
          dispatch(setBitbnsTokensSuccess(response.data.message));
          removeFromLocalStorage('code_verifier');
          navigateToIntegrationLoading(navigate, dispatch);
          // handleGetUserSpinWheelResult();
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            if (
              attemptedTimes <= 1 &&
              error?.response?.data?.errors?.[0]?.name === 'InvalidBitbnsKeys'
            ) {
              return handleBitbnsAuth();
            }
            // gaEventTracker(
            //   'Bitbns fetch transactions error',
            //   'Bitbns fetch transactions error'
            // );
            dispatch(setBitbnsTokensError(getErrorStatement(error)));
            notify(getErrorStatement(error), 'error');
          }
        });
    },
    [
      dispatch,
      sourceNameToSourceDataMap,
      axiosPrivate,
      // gaEventTracker,
      navigate,
      removeFromLocalStorage,
      attemptedTimes,
      handleBitbnsAuth,
    ]
  );

  return {
    handleBitbnsSyncTransactions,
    handleBitbnsTokens,
    handleBitbnsAuth,
  };
}

export default useBitbns;
