import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  KeyboardArrowRight,
  ExpandMore,
  InfoFilled,
  Button,
  DatePicker,
  ToolTip,
  Input,
} from '@koinx/xui';
import useWallet from '@/Hooks/useWallet';
import styles from './ApiKeyView.module.css';
import { staticContent } from './constants';

const { advancedTab, tooltipContent, button } = staticContent;

const ApiKeyView = ({ selectedIntegration, customName }) => {
  const [inputFields, setInputFields] = useState({});
  const [startDate, setStartDate] = useState(new Date('2021-01-01'));
  const [isOpen, setIsOpen] = useState(false);
  const { handleSyncUserTransactions } = useWallet();
  const { loading } = useSelector((state) => state.wallet);

  const handleInputValueChange = ({ target: { value, name } }) => {
    setInputFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSyncUserTransactions(
      {
        ...inputFields,
        customWalletName: customName,
        startTimestamp: new Date(startDate).getTime(),
      },
      selectedIntegration.name
    );
  };

  // Setting date in following manner to avoid timezone issues in Github Actions
  const handleStartTimestampInputChange = (date) => {
    // Create a new Date object from the input date
    const inputDate = new Date(date);

    // Set the time to 00:00:00 in local timezone first
    inputDate.setHours(0, 0, 0, 0);

    // Convert the local time to UTC+05:30 (Asia/Kolkata)
    const utcDate = new Date(
      inputDate.getTime() - inputDate.getTimezoneOffset() * 60000
    );
    const adjustedDate = new Date(utcDate.getTime());

    // Call the setter with the adjusted date
    setStartDate(adjustedDate);
  };

  useEffect(() => {
    const inputFields = {};
    selectedIntegration?.inputs?.api_key?.forEach((field) => {
      inputFields[field.title] = '';
    });
    setInputFields(inputFields);
  }, [selectedIntegration]);

  return (
    <form className={styles.Wrapper} onSubmit={handleSubmit}>
      {selectedIntegration?.inputs?.api_key?.map((item, index) => {
        return (
          <div key={index} className={styles.InputWrapper}>
            <label className={styles.Label} htmlFor={item.title}>
              {item.displayName}
            </label>
            <Input
              onChange={handleInputValueChange}
              label={item.displayName}
              value={inputFields[item.title]}
              type={item.type}
              name={item.title}
              required={item.required ?? true}
              width="100%"
            />
          </div>
        );
      })}
      <hr className={styles.Hr}></hr>
      <div
        className={styles.AdvancedTabContainer}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="advanced-tab"
      >
        <div className={styles.AdvancedTab}>
          {isOpen ? <ExpandMore /> : <KeyboardArrowRight />}
          <p className={styles.Label}>{advancedTab.tabHeading}</p>
        </div>
      </div>
      {isOpen && (
        <div>
          <div className={styles.ImportFromLabel}>
            <p className={styles.Label}>{advancedTab.inputLabel}</p>
            <ToolTip
              content={
                <p className={styles.TooltipContent}>{tooltipContent}</p>
              }
            >
              <InfoFilled className={styles.InfoIcon} />
            </ToolTip>
          </div>
          <DatePicker
            date={startDate}
            onSelect={handleStartTimestampInputChange}
            className={`${styles.DateInput} api-key-date-picker`}
          />
        </div>
      )}
      <Button
        isLoading={loading}
        className={styles.ImportBtn}
        data-heapId="add-wallet-api-key-import-button"
      >
        {loading ? null : button}
      </Button>
    </form>
  );
};

export default ApiKeyView;
