import { Route, Outlet, Navigate } from 'react-router-dom';
import Navbar from '@/Components/Ui/Navbar';
import Sidebar from '@/Components/Ui/Sidebar';
import { routeEnums } from './Utils/enums/routes';
import { lazyWithRetry } from './Utils/lazyWithRetry';
import ChartOfAccountsPage from './Containers/ChartOfAccountsPage';
import ChartOfAccountsDetailsPage from './Containers/ChartOfAccountsDetailsPage';
import JournalsPage from './Containers/JournalsPage/JournalsPage';
import AddJournalsPage from './Containers/AddJournalsPage';
import AddIntegrationPage from './Containers/AddIntegrationPage';
import UserIntegrationsPage from './Containers/UserIntegrationsPage';
import TransactionsPage from '@/Containers/TransactionPage/TransactionsPage';

const TeamsPage = lazyWithRetry(() => import('./Containers/TeamsPage'));
const JournalDetailsPage = lazyWithRetry(
  () => import('./Containers/JournalDetailsPage/JournalDetailsPage')
);
const AddJournalPage = lazyWithRetry(
  () => import('./Containers/AddJournalsPage/')
);
const TreasuryAccountsPage = lazyWithRetry(
  () => import('@/Containers/TreasuryAccountsPage/')
);

const NewLoadingPage = lazyWithRetry(
  () => import('@/Containers/NewLoadingPage/')
);

const TreasuryAccountDetailsPage = lazyWithRetry(
  () => import('@/Containers/TreasuryAccountDetailsPage/')
);

const PrivateRoutes = (walletData) => {
  return (
    <Route
      element={
        <>
          <Navbar />
          <div className="AppPageWrapper">
            <Sidebar />
            <Outlet />
          </div>
        </>
      }>
      <Route
        exact
        path={routeEnums.SETTINGS_TEAM_PAGE}
        element={<TeamsPage />}
      />
      <Route
        exact
        path={routeEnums.CHART_OF_ACCOUNTS_PAGE}
        element={<ChartOfAccountsPage />}
      />
      <Route
        exact
        path={routeEnums.CHART_OF_ACCOUNTS_DETAILS_PAGE}
        element={<ChartOfAccountsDetailsPage />}
      />
      <Route
        exact
        path={routeEnums.TREASURY_ACCOUNTS_DETAILS_PAGE}
        element={<TreasuryAccountDetailsPage />}
      />
      <Route
        exact
        path={routeEnums.TRANSACTIONS_PAGE}
        element={<TransactionsPage />}
      />
      <Route
        exact
        path="*"
        element={<Navigate to={routeEnums.CHART_OF_ACCOUNTS_PAGE} />}
      />
      <Route
        exact
        path={routeEnums.ROOT}
        element={<Navigate to={routeEnums.CHART_OF_ACCOUNTS_PAGE} />}
      />
      <Route
        exact
        path={routeEnums.JOURNAL_TABLE_PAGE || routeEnums.JOURNALS_PAGE}
        element={<JournalsPage />}
      />
      {/* //TODO: Uncomment after Journal Template flow is added */}
      {/* <Route
        exact
        path={routeEnums.JOURNAL_TEMPLATES_PAGE}
        element={<JournalTemplatesPage />}
      /> */}
      <Route
        exact
        path={routeEnums.JOURNALS_DETAILS_PAGE}
        element={<JournalDetailsPage />}
      />
      <Route
        exact
        path={routeEnums.ADD_JOURNAL_PAGE}
        element={<AddJournalPage />}
      />
      {/* <Route
        exact
        path={routeEnums.EDIT_JOURNAL_PAGE}
        element={<AddJournalPage />}
      /> */}

      <Route
        exact
        path={routeEnums.INTEGRATIONS_PAGE}
        element={<UserIntegrationsPage />}
      />
      {[
        routeEnums.ADD_INTEGRATION_PAGE,
        routeEnums.ADD_INTEGRATION_WITH_WALLET_ID,
      ].map((path) => (
        <Route key={path} exact path={path} element={<AddIntegrationPage />} />
      ))}
      <Route
        exact
        path={routeEnums.INTEGRATION_LOADING_PAGE}
        element={<NewLoadingPage />}
      />

      <Route
        exact
        path={routeEnums.TREASURY_ACCOUNTS_PAGE}
        element={<TreasuryAccountsPage />}
      />
    </Route>
  );
};

export default PrivateRoutes;
