export const CountryToCurrency = {
  INDIA: 'INR',
  AUSTRALIA: 'AUD',
  'UNITED KINGDOM': 'GBP',
  JAPAN: 'JPY',
  'UNITED STATES': 'USD',
  CANADA: 'CAD',
  BRAZIL: 'BRL',
  GERMANY: 'EUR',
  INDONESIA: 'IDR',
  SWEDEN: 'SEK',
  DENMARK: 'DKK',
};
