import { Select } from '@koinx/xui';
import styles from './SelectComponent.module.css';

const SelectComponent = ({
  title,
  options,
  onChange,
  disabled = false,
  label,
  value,
  ...selectProps
}) => {
  const handleChange = (newVal) => {
    onChange(newVal, label);
  };

  return (
    <div className={styles.Wrapper}>
      <h2 className={styles.Title}>{title}</h2>
      <Select
        className={styles.Select}
        options={options}
        optionItemLeftImage
        onChange={handleChange}
        disabled={disabled}
        width="100%"
        value={value}
        sortedOptions={true}
        {...selectProps}
      />
    </div>
  );
};

export default SelectComponent;
