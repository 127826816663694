import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  message: null,
  error: null,
};

const oauthIntegrationSlice = createSlice({
  name: 'oauthIntegration',
  initialState,
  reducers: {
    setOAuthIntegrationTokens: (state) => {
      state.loading = true;
    },
    setOAuthIntegrationTokensSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    setOAuthIntegrationTokensError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setOAuthIntegrationSyncTransactions: (state) => {
      state.loading = true;
    },
    setOAuthIntegrationSyncTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    setOAuthIntegrationSyncTransactionsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setOAuthIntegrationTokens,
  setOAuthIntegrationTokensSuccess,
  setOAuthIntegrationTokensError,
  setOAuthIntegrationSyncTransactions,
  setOAuthIntegrationSyncTransactionsSuccess,
  setOAuthIntegrationSyncTransactionsError,
} = oauthIntegrationSlice.actions;

export default oauthIntegrationSlice.reducer;
