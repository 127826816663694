import { data } from './constants';
import styles from './ErrorComponent.module.css';
import { InfoComponent } from '@koinx/xui';

const ErrorComponent = () => {
  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.Wrapper}>
        <InfoComponent
          image={data.image}
          imageClass={styles.Image}
          enableButton={true}
          buttonText={data.buttonText}
          imageAlt={data.imageAlt}
          subtitle={data.subtitle}
          title={data.title}
          titleClass={styles.Title}
          buttonClickHandler={handleRetry}
          buttonClass={styles.Button}
        />
      </div>
    </div>
  );
};

export default ErrorComponent;
