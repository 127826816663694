import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigureStore } from './Redux/ConfigureStore';
import ErrorComponent from './Components/ErrorComponent';

let isPreloadError = false;

try {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // ignoreErrors: [
    //   'TypeError: Failed to fetch',
    //   'TypeError: NetworkError when attempting to fetch resource.',
    //   `Cannot read properties of undefined (reading 'style')`,
    //   `Cannot read properties of undefined (reading 'remove')`,
    //   'Network Error',
    //   /Failed to fetch dynamically imported module/,
    //   /mob-footer/,
    // ],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.1,
  });
} catch (error) {
  //
}
const globalMUITheme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: '#0052FE',
    },
  },
});

if (typeof window !== 'undefined') {
  window.addEventListener('vite:preloadError', (event) => {
    event.preventDefault();
    isPreloadError = true;
    window.location.reload();
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={() => {
        return isPreloadError ? null : <ErrorComponent />;
      }}
    >
      <ThemeProvider theme={globalMUITheme}>
        <Provider store={ConfigureStore()}>
          <Router>
            <App />
          </Router>
        </Provider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
