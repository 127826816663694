'use client';
import axios from 'axios';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from '@/Services/auth/refreshToken';
import { userLogout } from '@/Services/auth/userLogout';
import { axiosPrivate } from '@/Services/axios';
import { setHandleUserTokens } from '@/Redux/slices/authSlice';
import useAuth from './useAuth';
import useStorage from './useStorage';

function useAxiosPrivate() {
  const dispatch = useDispatch();
  const { storeInLocalStorage } = useStorage();
  const { currentWorkspaceId } = useSelector((state) => state.user);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const { handleRefreshToken } = useAuth();

  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        if (!config.headers['x-workspace-id']) {
          if (currentWorkspaceId) {
            config.headers['x-workspace-id'] = currentWorkspaceId;
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        const errorName = error?.response?.data?.errors?.[0]?.name;
        const missingErrorField =
          error?.response?.data?.errors?.[0]?.missingFields?.[0];
        const isMissingFieldsError = errorName === 'MissingFieldsError';
        const isInvalidFieldsError = errorName === 'InvalidFieldsError';
        const isTokenExpiredError = errorName === 'TokenExpiredError';
        const isUserDoesNotExistError = errorName === 'UserDoesNotExistError';
        const isJsonWebTokenError = errorName === 'JsonWebTokenError';
        const isUnauthrizedError = errorName === 'UnauthorizedError';

        const isToken = missingErrorField === 'token';
        const isAccessToken = missingErrorField === 'access token';
        const isRefreshToken = missingErrorField === 'refresh token';

        const isJwtExpired =
          error?.response?.data?.errors?.[0]?.message === 'jwt expired';

        if (
          (isMissingFieldsError && isToken) ||
          (isInvalidFieldsError && isAccessToken) ||
          (isJwtExpired && !prevRequest.sent) ||
          isJsonWebTokenError ||
          isUnauthrizedError
        ) {
          prevRequest.sent = true;
          let refreshTokenData;
          try {
            const { data } = await refreshToken();
            refreshTokenData = data;
          } catch (error) {
            if (!axios.isCancel(error)) {
              if (
                (isMissingFieldsError && isRefreshToken) ||
                (isJwtExpired && isTokenExpiredError) ||
                isUserDoesNotExistError ||
                isJsonWebTokenError ||
                isUnauthrizedError
              ) {
                toast.dismiss();
                await userLogout();
                storeInLocalStorage('security-logout', true);
              }
            }
          }
          const newAccessToken = refreshTokenData?.accessToken;
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          dispatch(setHandleUserTokens(newAccessToken));
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, [
    handleRefreshToken,
    accessToken,
    dispatch,
    currentWorkspaceId,
    storeInLocalStorage,
  ]);

  return axiosPrivate;
}

export default useAxiosPrivate;
