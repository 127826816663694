import React, { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Input } from '@koinx/xui';
import styles from './SignInComponent.module.css';
import useAuth from '@/Hooks/useAuth';
import { useSelector } from 'react-redux';
import { staticContent } from './constants';
const { button, heading, input } = staticContent;

const SignInComponent = () => {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  //TODO: Set the default state of the userEmail to be the pre-existing email of the user from the backend
  const [userEmail, setUserEmail] = useState('');
  const recaptchaRef = useRef();
  const { handleBeginAuth } = useAuth();
  const { loading } = useSelector((state) => state.auth);

  const handleChangeUserEmail = (event) => {
    setUserEmail(() => event.target.value);
  };

  const handleRecaptchaVerify = useCallback(
    (token) => {
      setRecaptchaToken(token);
    },
    [setRecaptchaToken]
  );

  const handleRecaptchaExpired = (token) => {
    setRecaptchaToken(token);
  };

  const resetCaptcha = () => {
    recaptchaRef?.current?.reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleBeginAuth(userEmail, recaptchaToken, resetCaptcha);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.SignInWrapper}>
        <h1 className={styles.SignInHeading}>{heading}</h1>
        <div className={styles.EmailInputWrapper}>
          <Input
            label={input.label}
            placeholder={input.placeholder}
            onChange={handleChangeUserEmail}
            width="100%"
            type="email"
            name={input.name}
          />
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          onChange={handleRecaptchaVerify}
          onExpired={handleRecaptchaExpired}
        />
        <Button
          className={styles.Button}
          variant={'primary'}
          onClick={handleSubmit}
          isLoading={loading}
          fullWidth
          size={'lg'}
        >
          {loading ? button.loading : button.default}
        </Button>
      </div>
    </div>
  );
};

export default SignInComponent;
