export const ArchivePopupContent = {
  pluralize: {
    singular: 'Transaction',
    plural: 'Transactions',
  },
  heading: 'Archive {0}',
  subHeading: '{0} {1} Selected',
  select: {
    label: 'Select Label',
    placeholder: 'Select a Label',
  },

  buttons: {
    cancel: 'Cancel',
    archive: 'Archive',
  },
};
