import { DateRangePicker } from '@koinx/xui';
import { createDateRangeFilterShortcuts } from '@/Components/Routes/TransactionsPage/TransactionFilters/helper';
import { dateRangeFilterData } from './constants';
import useTimeStamp from '@/Hooks/useTimestamp';

const { fromYear, toYear, shortcutButtons } = dateRangeFilterData;

const DateRangeFilter = ({ value, onChange, ...dateRangePickerProps }) => {
  const timestamp_fy_22_23 = useTimeStamp(shortcutButtons.FY_22_23.value);
  const timestamp_fy_23_24 = useTimeStamp(shortcutButtons.FY_23_24.value);
  const timestamp_fy_24_25 = useTimeStamp(shortcutButtons.FY_24_25.value);

  const shortcutsChipOptions = createDateRangeFilterShortcuts(
    [
      { label: shortcutButtons.FY_22_23.label, ...timestamp_fy_22_23 },
      { label: shortcutButtons.FY_23_24.label, ...timestamp_fy_23_24 },
      { label: shortcutButtons.FY_24_25.label, ...timestamp_fy_24_25 },
    ],
    shortcutButtons.RESET
  );

  return (
    <DateRangePicker
      shortcutsChipOptions={shortcutsChipOptions}
      value={value}
      fromYear={fromYear}
      toYear={toYear}
      onSelect={onChange}
      {...dateRangePickerProps}
    />
  );
};

export default DateRangeFilter;
