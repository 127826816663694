import { memo, useCallback, useMemo, useState } from 'react';
import styles from './SyncTransactionsButton.module.css';
// import useScreenType from '@src/Hooks/useScreenType';
import usePopup from '@/Hooks/usePopup';
import useWallet from '@/Hooks/useWallet';
import useBlockchainIntegration from '@/Hooks/useBlockchainIntegration';
import useBitbns from '@/Hooks/useBitbns';
import useOAuthIntegration from '@/Hooks/useOAuthIntegration';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { integrationTypeEnum } from '@/Utils/enums/integrationTypes';
import { routeEnums } from '@/Utils/enums/routes';
import { OAuthEnum } from '@/Utils/enums/OAuthEnum';

import { Button, Sync, Text } from '@koinx/xui';
import { syncTransactionButtonVariants, btnText } from './constants';
import { deepIntegrationExchangesEnums } from '@/Utils/enums/deepIntegrationExchanges';
import { DeepIntegrationPopup } from '@/Components/AddExchangesPage/Components/DeepIntegrationPopup';

const { SYNC_ALL, SYNC_TRANSACTIONS_BADGE, SYNC_TRANSACTIONS_BUTTON } =
  syncTransactionButtonVariants;

// wallet is not required in case of SYNC_ALL variant
const UnMemoizedSyncTransactionsButton = ({ variant, wallet }) => {
  // const isMobileScreen = useScreenType();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const { handlePopupCenterOpen, handlePopupCenterComponentRender } =
    usePopup();
  const { wallets } = useSelector((state) => state.wallet);

  const { handleSyncUserTransactions } = useWallet();
  const { handleSyncBlockchainWallet } = useBlockchainIntegration();
  const { handleBitbnsSyncTransactions } = useBitbns();
  const { handleOAuthIntegrationSyncTransactions } = useOAuthIntegration();

  const handleSyncTransactions = useCallback(
    (walletInfo) => {
      if (!walletInfo) return;

      const integrationType = walletInfo?.integrationType;
      const walletId = walletInfo?._id;
      const walletSource = walletInfo?.walletSource;
      const customWalletName = walletInfo?.customWalletName;
      const address = walletInfo?.address;

      // If integration is deep integration, then render the deep integration popup
      // Suncrypto's transactions can be synced from api, so no need to show popup
      if (
        variant !== SYNC_ALL &&
        walletInfo?.isDeepIntegration &&
        walletSource === deepIntegrationExchangesEnums.COINDCX
      ) {
        // if (isMobileScreen) setOpenDrawer(true);
        // else {
        handlePopupCenterOpen(true);
        handlePopupCenterComponentRender(
          <DeepIntegrationPopup source={walletSource} />
        );
        handlePopupCenterOpen(true);
        handlePopupCenterComponentRender(
          <DeepIntegrationPopup source={walletSource} />
        );
        // }
        return;
      }

      // If integration is not deep integration, then sync the transactions
      switch (integrationType) {
        // If integration type is file upload, then navigate to add wallet page to upload file with latest transactions
        case integrationTypeEnum.FILE_UPLOAD:
          return navigate(`${routeEnums.ADD_INTEGRATION_PAGE}/${walletId}`);

        // If integration type is API or Address, then send request to backend to sync transactions
        case integrationTypeEnum.API:
          return handleSyncUserTransactions({ walletId }, walletSource);
        case integrationTypeEnum.ADDRESS:
          return handleSyncBlockchainWallet(
            customWalletName,
            walletSource,
            address
          );

        // If integration type is OAuth, then sync transactions based on wallet source
        case integrationTypeEnum.OAUTH: {
          switch (walletSource) {
            case OAuthEnum.MUDREX:
            case OAuthEnum.COINJAR:
              return handleOAuthIntegrationSyncTransactions(
                walletSource,
                walletId,
                true
              );

            case OAuthEnum.BINANCE:
              return handleOAuthIntegrationSyncTransactions(
                OAuthEnum.BINANCE_OAUTH,
                walletId,
                true
              );

            case OAuthEnum.BITBNS:
              return handleBitbnsSyncTransactions(walletId, true);

            default:
              return null;
          }
        }

        default:
          return null;
      }
    },
    [
      handleBitbnsSyncTransactions,
      handleOAuthIntegrationSyncTransactions,
      handlePopupCenterComponentRender,
      handlePopupCenterOpen,
      handleSyncBlockchainWallet,
      handleSyncUserTransactions,
      // isMobileScreen,
      navigate,
      variant,
    ]
  );

  const handleSyncAllCtaClick = useCallback(() => {
    wallets.map((wallInfo) => {
      if (
        wallInfo?.isSyncBasedIntegration &&
        wallInfo?.walletSource !== deepIntegrationExchangesEnums.COINDCX
      ) {
        return handleSyncTransactions(wallInfo);
      }
    });
    handlePopupCenterOpen(false);
    handlePopupCenterComponentRender(null);
  }, [
    wallets,
    handleSyncTransactions,
    handlePopupCenterOpen,
    handlePopupCenterComponentRender,
  ]);

  const handleSyncNowCtaClick = useCallback(
    (event) => {
      event?.stopPropagation();
      handleSyncTransactions(wallet);
    },
    [handleSyncTransactions, wallet]
  );

  const buttonToRender = useMemo(() => {
    switch (variant) {
      case SYNC_ALL:
        return (
          <Button fullWidth onClick={handleSyncAllCtaClick}>
            {btnText}
          </Button>
        );
      case SYNC_TRANSACTIONS_BADGE:
        return (
          <div className={styles.Circle} onClick={handleSyncNowCtaClick}>
            <Sync
              width={20}
              height={20}
              fill="var(--blue-09)"
              className={styles.SyncIcon}
            />
            <Text variant="body-3" className={styles.SyncTextHidden}>
              {btnText}
            </Text>
          </div>
        );
      case SYNC_TRANSACTIONS_BUTTON:
        return (
          <Button
            variant="outline"
            className={styles.SyncBtn}
            onClick={handleSyncNowCtaClick}
            icon={<Sync fill="var(--blue-09)" />}
            buttonType="icon_left">
            {btnText}
          </Button>
        );
      default:
        return null;
    }
  }, [variant, handleSyncAllCtaClick, handleSyncNowCtaClick]);

  return (
    <>
      {buttonToRender}
      {/* {isMobileScreen && variant !== SYNC_ALL && openDrawer && (
        <DeepIntegrationDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          source={wallet?.walletSource}
        />
      )} */}
    </>
  );
};

const SyncTransactionsButton = memo(UnMemoizedSyncTransactionsButton);
export default SyncTransactionsButton;
