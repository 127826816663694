import EmptyStateComponent from '@/Components/AddExchangesPage/Components/EmptyStateComponent';
import { checkIfNoData } from '@/Components/AddExchangesPage/helper';
import {
  KOINX,
  integrationsContainerData,
} from '@/Components/AddExchangesPage/constants';
import { sourceTypesEnum } from '@/Utils/enums/sourceTypes';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './IntegrationCategory.module.css';
import IntegrationCategory from './IntegrationCategory';
import { useNavigate } from 'react-router-dom';
import useBlockchainWallets from '@/Hooks/useBlockchainWallets';

const { integrationValuesToNameMaps } = integrationsContainerData;

const UnMemoizedIntegrationCategoryContainer = ({
  selectedIntegrationType,
  filteredData,
}) => {
  const navigate = useNavigate();
  const { sourceNameToSourceDataMap } = useSelector((state) => state.sources);

  const { handleWeb3WalletClick } = useBlockchainWallets();

  const showEmptyState = useMemo(
    () => checkIfNoData(filteredData),
    [filteredData]
  );

  const handleSetCustomFile = () => {
    const source = sourceNameToSourceDataMap[KOINX];
    navigate(`?wallet=${source?._id}`);
  };

  const handleClick = (e) => {
    let target = e.target;

    // Traverse up the DOM tree to find the element with the data-sourceid attribute
    while (
      target &&
      !target.getAttribute('data-id') &&
      target !== e.currentTarget
    ) {
      target = target.parentNode;
    }

    const walletId = target.getAttribute('data-id');
    const sourceName = target.getAttribute('data-name');
    const source = sourceNameToSourceDataMap[sourceName];
    if (!walletId || !source) return;
    if (source?.sourceType === sourceTypesEnum.WALLET) {
      handleWeb3WalletClick(source);
      return;
    }
    navigate(`?wallet=${walletId}`);
  };

  if (showEmptyState) {
    return <EmptyStateComponent handleSetCustomFile={handleSetCustomFile} />;
  }

  return (
    <div className={styles.Wrapper} onClick={handleClick}>
      {selectedIntegrationType === sourceTypesEnum.ALL ? (
        Object.keys(filteredData).map((sourceType) => {
          return (
            filteredData[sourceType].length > 0 && (
              <IntegrationCategory
                key={sourceType}
                title={integrationValuesToNameMaps[sourceType]}
                sources={filteredData[sourceType]}
              />
            )
          );
        })
      ) : (
        <IntegrationCategory
          title={integrationValuesToNameMaps[selectedIntegrationType]}
          sources={filteredData[selectedIntegrationType]}
        />
      )}
    </div>
  );
};

const IntegrationCategoryContainer = memo(
  UnMemoizedIntegrationCategoryContainer
);
export default IntegrationCategoryContainer;
