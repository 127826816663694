export const countryData = {
  AUSTRALIA: {
    currency: 'Australian Dollar',
    shortRepresentation: 'AU',
    currencySymbol: 'A$',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/AusDollar.png',
    currencyCode: 'AUD',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/australia_flag.svg',
    countryName: 'AUSTRALIA',
  },
  INDIA: {
    currency: 'Indian Rupee',
    shortRepresentation: 'IN',
    currencySymbol: '₹',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/INR.png',
    currencyCode: 'INR',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/india_flag.svg',
    countryName: 'INDIA',
  },
  'UNITED KINGDOM': {
    currency: 'Sterling',
    shortRepresentation: 'UK',
    currencySymbol: '£',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/Pound.png',
    currencyCode: 'GBP',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/UK.svg',
    countryName: 'UNITED KINGDOM',
  },
  JAPAN: {
    currency: 'Japanese Yen',
    currencySymbol: '¥',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/JPY.png',
    currencyCode: 'JPY',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/Japan.svg',
    countryName: 'JAPAN',
  },
  'UNITED STATES': {
    currency: 'US Dollar',
    shortRepresentation: 'USA',
    currencySymbol: '$',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/USD.png',
    currencyCode: 'USD',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/usa_flag.svg',
    countryName: 'USA',
  },
  CANADA: {
    currency: 'Canadian Dollar',
    shortRepresentation: 'CA',
    currencySymbol: 'C$',
    currencyLogo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/cad.svg',
    currencyCode: 'CAD',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/canada_flag.svg',
    countryName: 'CANADA',
  },
  BRAZIL: {
    currency: 'Brazilian Real',
    shortRepresentation: 'BR',
    currencySymbol: 'R$',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/BrazilianReal.svg',
    currencyCode: 'BRL',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/brazil_flag.svg',
    countryName: 'BRAZIL',
  },
  GERMANY: {
    currency: 'Euro',
    shortRepresentation: 'GR',
    currencySymbol: '€',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/Euro.svg',
    currencyCode: 'EUR',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/germany_flag.svg',
    countryName: 'GERMANY',
  },
  INDONESIA: {
    currency: 'Indonesian Rupiah',
    shortRepresentation: 'ID',
    currencySymbol: 'Rp',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/IndonesianRupiah.svg',
    currencyCode: 'IDR',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/indonesia_flag.svg',
    countryName: 'INDONESIA',
  },
  SWEDEN: {
    currency: 'Swedish Krona',
    shortRepresentation: 'SW',
    currencySymbol: 'kr',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/DKK.png',
    currencyCode: 'SEK',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/sweden_flag.svg',
    countryName: 'SWEDEN',
  },
  DENMARK: {
    currency: 'Danish Krone',
    shortRepresentation: 'DK',
    currencySymbol: 'kr',
    currencyLogo:
      'https://koinx-statics.s3.ap-south-1.amazonaws.com/currencies/DKK.png',
    currencyCode: 'DKK',
    logo: 'https://koinx-statics.s3.ap-south-1.amazonaws.com/flag-icons/denmark_flag.svg',
    countryName: 'DENMARK',
  },
};
