const dateFormatter = (
  date,
  isDayMonthYearFormatting = false,
  showTime = false
) => {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  const monthNumber = dateObj.getMonth() + 1;

  const dateWithTime = new Date(date).toLocaleString('default', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  if (isDayMonthYearFormatting) return `${day}-${monthNumber}-${year}`;
  if (showTime) return dateWithTime;

  return `${month} ${day}, ${year}`;
};

export default dateFormatter;
