import { useState } from 'react';
import styles from './OtpComponent.module.css';
import AlreadyMember from '@/Components/UserOnboarding/AlreadyMember';
import { routeEnums } from '@/Utils/enums/routes';
import { Button, Building, Input, Text } from '@koinx/xui';
import useAuth from '@/Hooks/useAuth';
import useAccountingInvites from '@/Hooks/useAccountingInvites';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { staticContent } from './constants';
import { Skeleton } from '@mui/material';
const { button, change, infoText, input, retry, subText, title } =
  staticContent;

const OtpComponent = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const { inviteId } = useSelector((state) => state.accountingInvite);
  const { workspaceName } = useSelector((state) => state.accountingInvite);
  const { email, loading } = useSelector((state) => state.auth);
  const { handleValidateOTP, handleResendOTP } = useAuth();
  const { handleAcceptInviteDetails } = useAccountingInvites();
  const handleChangeOtp = (event) => {
    setOtp(event.target.value);
  };

  const onSuccessOTPValidation = () => {
    // TODO: Change it back to Dahboard when ready
    navigate(routeEnums.CHART_OF_ACCOUNTS_PAGE);
  };

  const handleChangeEmail = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  const handleOnClickValidateOTP = (event) => {
    event.preventDefault();
    handleValidateOTP(
      otp,
      email,
      inviteId ? inviteId : null,
      onSuccessOTPValidation
    );
  };

  const handleOnClickResendOTP = (event) => {
    event.preventDefault();
    handleResendOTP(email, null); //TODO: send the userId once recieved
  };

  return (
    <div className={styles.Wrapper}>
      {/* only visible when user is on sign-up flow */}
      {inviteId && (
        <div className={styles.Header}>
          <>
            {workspaceName ? (
              <div className={styles.WorkspaceWrapper}>
                <Building className={styles.WorkspaceIcon} />
                <h3 className={styles.WorkspaceName}>{workspaceName}</h3>
              </div>
            ) : (
              <Skeleton variant="rectangular" width={100} height={20} />
            )}
            <div className={styles.AlreadyMemberWrapper}>
              <AlreadyMember infoText={infoText} a={routeEnums.SIGN_IN_PAGE} />
            </div>
          </>
        </div>
      )}
      <div
        className={`${styles.CreateAccountWrapper} ${inviteId ? '' : styles.SignUpFlowPosition}`}
      >
        <div className={styles.UpperWrapper}>
          <h2 className={styles.Title}>{title}</h2>
          <div className={styles.UserEmailWrapper}>
            <Text variant="heading-4" className={styles.UserEmail}>
              {email}
            </Text>
            {!inviteId && (
              <a
                className={styles.ChangeLink}
                onClick={handleChangeEmail}
                //TODO: add url
                href={'#'}
              >
                {change}
              </a>
            )}
          </div>
          <Text variant="body-1" textColor="gray-11">
            {subText}
          </Text>
        </div>
        <Input
          label={input.label}
          placeholder={input.placeholder}
          name={input.name}
          autoFocus={true}
          onChange={handleChangeOtp}
          type="number"
          width="100%"
          required={true}
        />

        <p className={styles.Retry}>
          {retry.text}
          <a onClick={handleOnClickResendOTP} className={styles.ResendOtp}>
            {retry.link}
          </a>
        </p>
        <Button
          fullWidth
          variant={'primary'}
          onClick={handleOnClickValidateOTP}
          isLoading={loading}
        >
          {loading ? button.loading : inviteId ? button.signUp : button.signIn}
        </Button>
      </div>
    </div>
  );
};

export default OtpComponent;
