import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import popUpSlice from './slices/popUpSlice';
import accountingInviteSlice from './slices/accountingInviteSlice';
import authSlice from './slices/authSlice';
import userSlice from './slices/userSlice';
import accountingMemberSlice from './slices/accountingMemberSlice';
import chartOfAccountsSlice from './slices/chartOfAccountsSlice';
import journalTemplateSlice from './slices/journalTemplateSlice';
import journalTableSlice from './slices/journalsSlice';
import sourcesSlice from './slices/sourcesSlice';
import transactionSlice from './slices/transactionSlice';
import bitBnsSlice from './slices/bitBnsSlice';
import oauthIntegrationSlice from './slices/oauthIntegrationSlice';
import socketSlice from './slices/socketSlice';
import walletSlice from './slices/walletSlice';
import blockchainIntegrationSlice from './slices/blockchainIntegrationSlice';
import workspaceSlice from './slices/workspaceSlice';

export const rootReducer = combineReducers({
  popup: popUpSlice,
  auth: authSlice,
  user: userSlice,
  accountingInvite: accountingInviteSlice,
  accountingMember: accountingMemberSlice,
  chartOfAccounts: chartOfAccountsSlice,
  journalTemplates: journalTemplateSlice,
  journalTable: journalTableSlice,
  sources: sourcesSlice,
  transactions: transactionSlice,
  bitBns: bitBnsSlice,
  oauthIntegration: oauthIntegrationSlice,
  wallet: walletSlice,
  socket: socketSlice,
  blockchainIntegration: blockchainIntegrationSlice,
  workspace: workspaceSlice,
});

export const ConfigureStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  return store;
};
