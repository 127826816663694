'use client';
import usePopup from '@/Hooks/usePopup';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopUp from './PopUp';

const PopUpWrapper = () => {
  const {
    popupComponent: componentToRender,
    popupOpen: popUpState,
    uniqueComponent,
  } = useSelector((state) => state.popup);
  const { handlePopupOpen, handleClosingUniqueComponent } = usePopup();
  return (
    <PopUp
      ContentComp={componentToRender}
      isOpen={popUpState}
      closeFun={() => {
        if (uniqueComponent) {
          handleClosingUniqueComponent();
        }
        handlePopupOpen(false);
      }}
    />
  );
};

export default PopUpWrapper;
