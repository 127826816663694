export const DetailsData = {
  accountDetails: [
    {
      label: 'Closing Balance:',
      isAmount: true,
      dataTestId: 'closingBalance',
      key: 'closingBalance',
    },
    {
      label: 'Account ID:',
      isAmount: false,
      dataTestId: 'accountId',
      key: '_id',
    },
    {
      label: 'Account Type:',
      isAmount: false,
      dataTestId: 'accountType',
      key: 'type',
    },
  ],
  description: 'Description:',
  transactionsSection: {
    title: 'General Ledger',
    accountDetails: [
      {
        label: 'Opening Balance:',
        isAmount: true,
        dataTestId: 'ledgersOpeningBalance',
        key: 'openingBalance',
        coinKey: 'coinAmountOpeningBalance',
      },
      {
        label: 'Total Debits:',
        isAmount: true,
        dataTestId: 'totalDebits',
        key: 'debits',
      },
      {
        label: 'Total Credits:',
        isAmount: true,
        dataTestId: 'totalCredits',
        key: 'credits',
      },
      {
        label: 'Closing Balance:',
        isAmount: true,
        dataTestId: 'ledgersClosingBalance',
        key: 'closingBalance',
        coinKey: 'coinAmountClosingBalance',
      },
    ],
  },
  tableHeadings: [
    { id: 'timestamp', label: 'Date', minWidth: 120, align: 'left' },
    { id: 'accountName', label: 'Account', minWidth: 120, align: 'left' },
    { id: 'description', label: 'Details', minWidth: 120, align: 'left' },
    {
      id: 'runningDebits',
      label: 'Debit Amount',
      minWidth: 120,
      align: 'right',
    },
    {
      id: 'runningCredits',
      label: 'Credit Amount',
      minWidth: 120,
      align: 'right',
    },
    {
      id: 'runningBalance',
      label: 'Running Balance',
      minWidth: 120,
      align: 'right',
    },
  ],
};
