import AlertSection from '@/Components/AddExchangesPage/Components/AlertSection/AlertSection';
import styles from './DescriptionSection.module.css';
import HowToIntegrate from '@/Components/AddExchangesPage/Components/HowToIntegrate/HowToIntegrate';

const DescriptionSection = ({ selectedMethod }) => {
  return (
    <div className={styles.Wrapper}>
      <AlertSection selectedMethod={selectedMethod}>
        <AlertSection.Title />
      </AlertSection>
      <HowToIntegrate selectedMethod={selectedMethod}>
        <HowToIntegrate.Title />
      </HowToIntegrate>
    </div>
  );
};

export default DescriptionSection;
