export const dateRangeFilterData = {
  fromYear: 2017,
  toYear: new Date().getFullYear(),
  shortcutButtons: {
    FY_22_23: { label: 'FY 2022-23', value: '2022-2023' },
    FY_23_24: { label: 'FY 2023-24', value: '2023-2024' },
    FY_24_25: { label: 'FY 2024-25', value: '2024-2025' },
    RESET: { label: 'Reset' },
  },
};
