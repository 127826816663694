import Enum from './enums';

class AddEditTransactionDrawerTypesEnum extends Enum {
  static ADD = 'Add';
  static EDIT = 'Edit';
}

export const addEditTransactionDrawerTypes = Object.freeze(
  AddEditTransactionDrawerTypesEnum
);
