import Enum from './enums';

class SourceTypes extends Enum {
  static EXCHANGE = 'exchange';
  static WALLET = 'wallet';
  static CHAIN = 'chain';
  static NFT = 'nft';
  static DEX = 'dex';
  static POPULAR = 'popular';
  static ALL = 'allIntegrations';
  static PARTNERS = 'partners';
}

export const sourceTypesEnum = Object.freeze(SourceTypes);
