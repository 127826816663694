import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAccountsDataLoading: false,
  accountDetailsList: null,
  selectedAccountDetails: null,
  isSelectedAccountDetailsLoading: false,
  error: null,
  isCreateAccountLoading: false,
  createAccountSuccessData: null,
  createAccountError: null,
  isEditAccountLoading: false,
  editAccountSuccessData: null,
  editAccountError: null,
  isParentAccountsLoading: false,
  parentAccounts: null,
  parentAccountsError: null,
  isLedgerAccountsLoading: false,
  ledgersData: null,
  totalLedgerAccounts: 0,
  ledgersTableCursor: 0,
  ledgersTableRowsPerPage: 10,
};

const chartOfAccountsSlice = createSlice({
  name: 'chartOfAccounts',
  initialState,
  reducers: {
    setGetAccountDetailsList: (state) => {
      state.isAccountsDataLoading = true;
    },
    setGetAccountDetailsListSuccess: (state, action) => {
      state.isAccountsDataLoading = false;
      state.accountDetailsList = action.payload;
    },
    setGetAccountDetailsListError: (state, action) => {
      state.isAccountsDataLoading = false;
      state.error = action.payload;
    },
    setSelectedAccountDetails: (state) => {
      state.isSelectedAccountDetailsLoading = true;
    },
    setSelectedAccountDetailsSuccess: (state, action) => {
      state.isSelectedAccountDetailsLoading = false;
      state.selectedAccountDetails = action.payload;
    },
    setSelectedAccountDetailsError: (state, action) => {
      state.isSelectedAccountDetailsLoading = false;
      state.error = action.payload;
    },
    setCreateAccount: (state) => {
      state.isCreateAccountLoading = true;
    },
    setCreateAccountSuccessData: (state, action) => {
      state.isCreateAccountLoading = false;
      state.createAccountSuccessData = action.payload;
    },
    setCreateAccountError: (state, action) => {
      state.isCreateAccountLoading = false;
      state.createAccountError = action.payload;
    },
    setEditAccount: (state) => {
      state.isEditAccountLoading = true;
    },
    setEditAccountSuccessData: (state, action) => {
      state.isEditAccountLoading = false;
      state.editAccountSuccessData = action.payload;
    },
    setEditAccountError: (state, action) => {
      state.isEditAccountLoading = false;
      state.editAccountError = action.payload;
    },
    setGetParentAccounts: (state) => {
      state.isParentAccountsLoading = true;
    },
    setGetParentAccountSuccess: (state, action) => {
      state.isParentAccountsLoading = false;
      state.parentAccounts = action.payload;
    },
    setGetParentAccountError: (state, action) => {
      state.isParentAccountsLoading = false;
      state.parentAccountsError = action.payload;
    },
    setFetchLedgerAccounts: (state) => {
      state.isLedgerAccountsLoading = true;
    },
    setFetchLedgerAccountsSuccess: (state, action) => {
      state.isLedgerAccountsLoading = false;
      state.ledgersData = action.payload.ledgerData;
      state.totalLedgerAccounts = action.payload.totalLedgerAccounts;
    },
    setFetchLedgerAccountsError: (state, action) => {
      state.isLedgerAccountsLoading = false;
      state.error = action.payload;
    },
    setLedgerAccountsCursorAndRowPerPage: (state, action) => {
      state.ledgersTableCursor = action.payload.cursor;
      state.ledgersTableRowsPerPage = action.payload.rowsPerPage;
    },
  },
});

export const {
  setGetAccountDetailsList,
  setGetAccountDetailsListSuccess,
  setGetAccountDetailsListError,
  setSelectedAccountDetails,
  setSelectedAccountDetailsError,
  setSelectedAccountDetailsSuccess,
  setCreateAccount,
  setCreateAccountError,
  setCreateAccountSuccessData,
  setEditAccount,
  setEditAccountError,
  setEditAccountSuccessData,
  setGetParentAccounts,
  setGetParentAccountSuccess,
  setGetParentAccountError,
  setFetchLedgerAccounts,
  setFetchLedgerAccountsSuccess,
  setFetchLedgerAccountsError,
  setLedgerAccountsCursorAndRowPerPage,
} = chartOfAccountsSlice.actions;

export default chartOfAccountsSlice.reducer;
