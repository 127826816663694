import React from 'react';
import styles from './AlreadyMember.module.css';

const AlreadyMember = ({ infoText, link }) => {
  return (
    <div className={styles.InfoWrapper}>
      <div className={styles.InfoText}>
        {infoText[0]}{' '}
        <a href={link} className={styles.Link}>
          {infoText[1]}
        </a>
      </div>
    </div>
  );
};

export default AlreadyMember;
