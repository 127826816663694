import styles from '@/Components/Routes/ChartOfAccounts/Details/Details.module.css';
import { Skeleton } from '@mui/material';
import AmountComponent from '@/Components/Ui/AmountComponent';

const AccountDetails = ({
  label,
  value,
  isAmount,
  isDataLoading,
  skeletonWidth = 80,
  dataTestId,
  coin,
  coinAmount,
}) => {
  const Amount = ({ coin, coinAmount, value }) => {
    if (coinAmount && coin) {
      return (
        <>
          <AmountComponent
            isCurrencySign={true}
            isColored={value < 0}
            value={value}
          />
          <span className={styles.CryptoAmount}>
            {' '}
            ({`${coinAmount} ${coin}`})
          </span>
        </>
      );
    }
    return (
      <AmountComponent
        isCurrencySign={true}
        isColored={value < 0}
        value={value}
      />
    );
  };
  return (
    <div className={styles.InfoWrapper}>
      <p
        data-testid={`accountDetails-label-${dataTestId}`}
        className={styles.AccountInfoLabel}>
        {label}
      </p>
      {isDataLoading ? (
        <Skeleton width={`${skeletonWidth}px`} height="25px" />
      ) : isAmount ? (
        <Amount coin={coin} coinAmount={coinAmount} value={value} />
      ) : (
        <p
          data-testid={`accountDetails-stringValue-${dataTestId}`}
          className={styles.AccountInfoValue}>
          {value}
        </p>
      )}
    </div>
  );
};

export default AccountDetails;
