// Freshdesk API Key
export const FRESHDESK_API_KEY = process.env.REACT_APP_FRESHDESK_BASE64_API_KEY;

// Bitbns API Key
export const BITBNS_RESPONSE_TYPE = process.env.REACT_APP_BITBNS_RESPONSE_TYPE;
export const BITBNS_CLIENT_ID = process.env.REACT_APP_BITBNS_CLIENT_ID;
export const BITBNS_REDIRECT_URL =
  process.env.REACT_APP_MODE === 'development'
    ? process.env.REACT_APP_LOCAL_HOST
    : process.env.REACT_APP_PRODUCTION_HOST;
export const BITBNS_SCOPE = process.env.REACT_APP_BITBNS_SCOPE;
export const BITBNS_STATE = process.env.REACT_APP_BITBNS_STATE;
export const BITBNS_CODE_CHALLENGE_METHOD =
  process.env.REACT_APP_BITBNS_CODE_CHALLENGE_METHOD;
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
export const LOGROCKET_KEY = process.env.REACT_APP_LOGROCKET_KEY;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
export const COINJAR_AUTH_URL = process.env.REACT_APP_COINJAR_AUTH_URL;
export const MUDREX_AUTH_URL = process.env.REACT_APP_MUDREX_AUTH_URL;
export const BINANCE_CLIENT_ID = process.env.REACT_APP_BINANCE_CLIENT_ID;
export const BINANCE_REDIRECT_URL =
  process.env.REACT_APP_MODE === 'development'
    ? 'https%3A%2F%2Fdev-app.koinx.com%2Fadd-wallet%3Fsource%3Dbinance-oauth'
    : 'https%3A%2F%2Fapp.koinx.com%2Fadd-wallet%3Fsource%3Dbinance-oauth';
export const BINANCE_CLIENT_SECRET =
  process.env.REACT_APP_BINANCE_CLIENT_SECRET;

export const BINANCE_AUTH_URL = `${process.env.REACT_APP_BINANCE_AUTH_URL}?response_type=code&client_id=${BINANCE_CLIENT_ID}&redirect_uri=${BINANCE_REDIRECT_URL}&scope=create:apikey`;
