import { ReactComponent as Rename } from '@/Assets/Icons/Edit.svg';

export const syncAllPopupData = {
  title: 'Sync All',
  description: 'Transactions on the following chains and APIs will be synced.',
  note: '*Note: CSV-based integrations require file uploads. Please upload your latest transactions for accurate tax calculations.',
};

export const walletsPageData = {
  addIntegrationText: 'Add Integration',
  syncAllText: 'Sync All',
  otherPlatformsQuestion: 'Do you trade on other platforms?',
  choosePlatformText: 'Choose from 100+ other integrations',
  mobileNextCtaText: 'Next: Tax Overview',
};

// export const walletCardData = { //NOT NEEDED IN v0
//   menuItemStyle: {
//     gap: '1.5rem',
//     width: '15rem',
//     fontWeight: 500,
//   },
//   moreHorizIconStyle: {
//     fill: 'var(--black)',
//   },
//   optionsIcons: [
//     <Rename key="rename" />,
//     <LinkOff sx={{ fontSize: '16px' }} key="linkOff" />,
//   ],
// };
