export const extractFinancialYear = (
  yearInput,
  returnEmptyStringInCaseOfAll
) => {
  if (yearInput) {
    if (yearInput === 'All') {
      return returnEmptyStringInCaseOfAll ? '' : 2024;
    } else {
      const year = parseInt(yearInput.split('-')[1]);
      return year;
    }
  } else {
    return 2024;
  }
};
