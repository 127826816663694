import { formatDistance } from 'date-fns';

export const formatSyncDate = (date) => {
  if (date) {
    return formatDistance(new Date(date), new Date(), {
      addSuffix: true,
    });
  }
  return new Date(date);
};
