import { useState } from 'react';
import { sidebarData } from './constants';
import styles from './Sidebar.module.css';
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  Button,
  ToolTip,
} from '@koinx/xui';
import SidebarTab from './SidebarTab/SidebarTab';

const { listItems, states } = sidebarData;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  return (
    <aside
      id="sidebarWrapper"
      data-collapsed={collapsed}
      className={styles.Wrapper}
    >
      {listItems.map((tab, index) => {
        return <SidebarTab key={index} isCollapsed={collapsed} tab={tab} />;
      })}
      {collapsed ? (
        <ToolTip
          divClass={styles.CollapsedBUttonTooltipDiv}
          content={states.closed}
          side="right"
          sideOffset={10}
        >
          <Button
            data-testid="sidebar-open-button"
            className={styles.CollapedButton}
            asChild
            variant={'just_icon'}
            icon={<KeyboardArrowRight className={styles.CollapsedButtonIcon} />}
            onClick={toggleCollapse}
          >
            <div>
              <KeyboardArrowRight />
            </div>
          </Button>
        </ToolTip>
      ) : (
        <Button
          data-testid="sidebar-collapse-button"
          className={styles.ExpandedButton}
          variant={'ghost'}
          fullWidth
          buttonType="icon_left"
          icon={<KeyboardArrowLeft className={styles.CollapsedButtonIcon} />}
          onClick={toggleCollapse}
        >
          {states.opened}
        </Button>
      )}
    </aside>
  );
};

export default Sidebar;
