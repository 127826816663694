import styles from './LoaderWrapper.module.css';

const LoaderWrapper = () => {
  return (
    <div className={styles.LoaderWrapper}>
      <div className={styles.Loader}></div>
    </div>
  );
};

export default LoaderWrapper;
