import { Chip } from '@mui/material';
import styles from './Wallet.module.css';
import { Avatar, Text } from '@koinx/xui';
import { memo } from 'react';
import { characterFormatter } from '@/Utils/helper/characterFormatter';

function UnMemoizedWallet({
  icon,
  displayName,
  name,
  style,
  isCustomFile,
  id,
}) {
  return (
    <div
      className={styles.Wrapper}
      style={style}
      data-id={id}
      data-name={name}
      data-testid={`${displayName.toLowerCase().split(' ').join('-')}-wallet`}
      data-heapid={`add-wallet-${displayName.toLowerCase().split(' ').join('-')}-card`}>
      <Avatar
        fallbackContent={displayName.charAt(0)}
        imageSrc={icon}
        className={styles.Icon}
        fallbackClass={styles.FallbackIcon}
      />
      {isCustomFile ? null : (
        <Text variant="subtitle-2" className={styles.Name}>
          {characterFormatter(displayName, 12)}
        </Text>
      )}
      {isCustomFile ? (
        <Chip
          style={{ backgroundColor: 'var(--gray-04)' }}
          label="Custom File"
        />
      ) : null}
    </div>
  );
}

const Wallet = memo(UnMemoizedWallet);
export default Wallet;
