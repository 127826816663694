import {
  CheckCircle,
  CheckCircleFilled,
  CloudDone,
  ArchivedOutline,
} from '@koinx/xui';
import { TransactionStatusTypes } from './enums/TransactionStatusTypes';

export const TransactionStatusTypesIconMapping = {
  [TransactionStatusTypes.CATEGORIZED]: {
    value: TransactionStatusTypes.CATEGORIZED,
    icon: CheckCircleFilled,
  },
  [TransactionStatusTypes.UPLOADED]: {
    value: TransactionStatusTypes.UPLOADED,
    icon: CloudDone,
  },
  [TransactionStatusTypes.PROCESSED]: {
    value: TransactionStatusTypes.PROCESSED,
    icon: CheckCircle,
  },
  [TransactionStatusTypes.ARCHIVED]: {
    value: TransactionStatusTypes.ARCHIVED,
    icon: ArchivedOutline,
  },
};
