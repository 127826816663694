import React from 'react';
import styles from '@/Components/Routes/Journals/Journal/JournalTable/JournalTable.module.css';
import { flexRender } from '@tanstack/react-table';
import { Text } from '@koinx/xui';
const TableHeader = ({ headerGroups }) => {
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id} className={styles.TableHeader}>
              <Text variant="heading-6" textColor="gray-12">
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </Text>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
