import BlankCellValue from '@/Components/Ui/BlankCellValue/BlankCellValue';
import TransactionDetail from '@/Components/Routes/TransactionsPage/TransactionDetail/TransactionDetail';
import styles from '@/Components/Routes/TransactionsPage/CustomTable/CustomTable.module.css';

const AddressCell = ({ getValue, row, sourceNameToSourceDataMap }) => {
  const value = getValue();
  if (value) {
    return (
      <TransactionDetail
        fontClassName={styles.TableFont}
        stringValue={value}
        avatar={true}
        image={
          sourceNameToSourceDataMap[row.original.source]?.source?.logo ||
          sourceNameToSourceDataMap['defaultCoin']?.logo
        }
        valueToCopy={value}
        isAddressFromTable={true}
      />
    );
  } else {
    return <BlankCellValue align="end" />;
  }
};

export default AddressCell;
