import { deepIntegrationExchangesEnums } from '@/Utils/enums/deepIntegrationExchanges';

export const showSyncAllButton = (walletsData) => {
  return walletsData.some(
    (wallet) =>
      wallet.isSyncBasedIntegration &&
      wallet.walletSource !== deepIntegrationExchangesEnums.COINDCX
  );
};

export const extractSyncableWallets = (walletsData, walletsToNameMap) => {
  const syncableWallets = walletsData.filter(
    (wallet) =>
      wallet.isSyncBasedIntegration &&
      wallet.walletSource !== deepIntegrationExchangesEnums.COINDCX
  );
  const seenNames = new Set();
  const syncableWalletsSources = [];

  for (const wallet of syncableWallets) {
    const staticData = walletsToNameMap[wallet._id];

    if (
      staticData &&
      !seenNames.has(staticData?.customName ?? staticData?.displayName)
    ) {
      seenNames.add(staticData?.customName ?? staticData?.displayName);
      syncableWalletsSources.push({
        name: staticData?.customName ?? staticData?.displayName,
        logo: staticData?.logo,
      });
    }
  }

  return syncableWalletsSources;
};
