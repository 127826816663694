import styles from '@/Components/Ui/Sidebar/SidebarTab/SidebarTab.module.css';
import { Button, KeyboardArrowRight } from '@koinx/xui';
import { useNavigate } from 'react-router';

const FixedTab = ({ tab, isActiveTab }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.TabWrapper}>
      <Button
        data-testid={tab.name}
        variant="ghost"
        data-active={isActiveTab(tab.link)}
        onClick={() => navigate(tab.link)}
        className={styles.ExpandedTab}
      >
        <div className={styles.ExpandedTabWrapper}>
          <div className={styles.ExpandedNameAndIconWrapper}>
            {tab.icon ? <tab.icon className={styles.TabIcon} /> : <></>}
            <span className={styles.TabName}>{tab.name}</span>
          </div>
          {tab.isExpandable && <KeyboardArrowRight />}
        </div>
      </Button>
    </div>
  );
};

export default FixedTab;
