import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setAcceptInviteDetails,
  setAcceptInviteDetailsError,
  setAcceptInviteDetailsSuccess,
  setGetInviteDetails,
  setGetInviteDetailsError,
  setGetInviteDetailsSuccess,
  setUserWorkspaceInviteList,
  setUserWorkspaceInviteListError,
  setUserWorkspaceInviteListSuccess,
  setInviteeRevokeInvite,
  setInviteeRevokeInviteError,
  setInviteeRevokeInviteSuccess,
  setUserInviteError,
  setUserInvite,
  setUserInviteSuccess,
  setInviteId,
} from '@/Redux/slices/accountingInviteSlice';
import { getInviteDetails } from '@/Services/invites/getInviteDetails';
import { acceptInviteDetails } from '@/Services/invites/acceptInviteDetails';
import axios from 'axios';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import useStorage from './useStorage';
import { getUserInvitesList } from '@/Services/invites/getUserInvitesList';
import notify from '@/Utils/notifyToast';
import useAxiosPrivate from './useAxiosPrivate';
import { revokeInviteService } from '@/Services/invites/revokeInviteService';
import { sendInviteService } from '@/Services/invites/sendInviteService';
import { isValidEmail } from '@/Utils/helper/isValidEmail';
import { invitePopUpData } from '@/Components/Popups/Workspace/InvitePopups/InviteMemberPopup/constants';
function useAccountingInvites() {
  const dispatch = useDispatch();
  const axiosInstance = useAxiosPrivate();
  const { getFromLocalStorage } = useStorage();

  const handleSetInviteId = useCallback(
    async (inviteId) => {
      dispatch(setInviteId(inviteId));
    },
    [dispatch]
  );
  // Sign up page details for user
  const handleGetInviteDetails = useCallback(
    async (inviteId) => {
      dispatch(setGetInviteDetails());
      try {
        const response = await getInviteDetails({ inviteId });
        dispatch(setGetInviteDetailsSuccess(response.data));
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(setGetInviteDetailsError(getErrorStatement(error)));
        }
      }
    },
    [dispatch]
  );

  const handleAcceptInviteDetails = useCallback(
    async (inviteId) => {
      dispatch(setAcceptInviteDetails());
      try {
        const response = await acceptInviteDetails(axiosInstance, inviteId);
        dispatch(setAcceptInviteDetailsSuccess(response.data.message));
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(setAcceptInviteDetailsError(getErrorStatement(error)));
        }
      }
    },
    [axiosInstance, dispatch]
  );

  const handleFetchUserInvitesList = useCallback(async () => {
    dispatch(setUserWorkspaceInviteList());
    try {
      const response = await getUserInvitesList(axiosInstance);
      dispatch(setUserWorkspaceInviteListSuccess(response.data));
    } catch (error) {
      dispatch(setUserWorkspaceInviteListError(getErrorStatement(error)));
      notify(getErrorStatement(error), 'error');
    }
  }, [axiosInstance, dispatch]);

  const handleInviteeRevokeInvite = useCallback(
    async (inviteId) => {
      dispatch(setInviteeRevokeInvite()); // loading state
      try {
        const response = await revokeInviteService(axiosInstance, inviteId);
        dispatch(setInviteeRevokeInviteSuccess());
        notify(response.message, 'success');
        handleFetchUserInvitesList();
      } catch (error) {
        dispatch(setInviteeRevokeInviteError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, handleFetchUserInvitesList]
  );

  const handleSendInvite = useCallback(
    async (email, roleId, onSuccess) => {
      try {
        if (!isValidEmail(email)) {
          notify(invitePopUpData.invalidEmailText, 'error');
          return;
        }
        dispatch(setUserInvite());
        const response = await sendInviteService(axiosInstance, {
          inviteeMail: email,
          inviteeRoleId: roleId,
        });
        dispatch(setUserInviteSuccess(response.data));
        handleFetchUserInvitesList();
        onSuccess();
      } catch (error) {
        dispatch(setUserInviteError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, handleFetchUserInvitesList]
  );

  return {
    handleAcceptInviteDetails,
    handleGetInviteDetails,
    handleFetchUserInvitesList,
    handleInviteeRevokeInvite,
    handleSendInvite,
    handleSetInviteId,
  };
}

export default useAccountingInvites;
