import axios from 'axios';
import { BASE_URL } from '@/Utils/constants';

const { CancelToken } = axios;
export const source = CancelToken.source();

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  cancelToken: source.token,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Content-Security-Policy': "default-src 'self'",
    'X-Frame-Options': 'SAMEORIGIN',
  },
  cancelToken: source.token,
});
