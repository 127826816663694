import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import notify from '@/Utils/notifyToast';
import useAxiosPrivate from './useAxiosPrivate';
import {
  setEditTransaction,
  setEditTransactionSuccess,
  setEditTransactionError,
  setFetchTransactions,
  setFetchTransactionsSuccessData,
  setFetchTransactionsError,
  setArchiveTransactions,
  setArchiveTransactionsSuccess,
  setArchiveTransactionsError,
  setSelectedTableRows,
  setTransactionsPageCursorAndRowPerPage,
  setCategorizeTransactions,
  setCategorizeTransactionsSuccess,
  setCategorizeTransactionsError,
  setTransactionCoins,
  setTransactionCoinsSuccess,
  setTransactionCoinsError,
  setFetchDynamicFilters,
  setFetchDynamicFiltersSuccessData,
  setFetchDynamicFiltersError,
  setUpdateFilter,
  setResetFilters,
  setAddTransaction,
  setAddTransactionSuccessData,
  setAddTransactionError,
  setFetchTop1000Coins,
  setFetchTop1000CoinsSuccess,
  setFetchTop1000CoinsError,
} from '@/Redux/slices/transactionSlice';
import { editTransaction } from '@/Services/transactions/editTransaction';
import { fetchTransactions } from '@/Services/transactions/fetchTransactions';
import {
  setArchiveLabels,
  setArchiveLabelsError,
  setArchiveLabelsSuccess,
} from '@/Redux/slices/transactionSlice';
import { getArchiveLabels } from '@/Services/transactions/getArchiveLabels';
import { createSelectOptions } from '@/Utils/helper/createSelectOptions';
import { archiveTransactions } from '@/Services/transactions/archiveTransactions';
import { TransactionStatusTypes } from '@/Utils/enums/TransactionStatusTypes';
import { categorizeTransactions } from '@/Services/transactions/categorizeTransactions';
import { getTransactionCoins } from '@/Services/transactions/getTransactionCoins';
import { countryData } from '@/Utils/countryData';
import { getTransactionFilters } from '@/Services/transactions/getTransactionFilters';
import { addTransaction } from '@/Services/transactions/addTransaction';
import { fetchTop1000Coins } from '@/Services/transactions/fetchTop1000Coins';

function useTransaction() {
  const dispatch = useDispatch();
  const axiosInstance = useAxiosPrivate();
  const { cursor, rowsPerPage } = useSelector((state) => state.transactions);

  const handleSetTransactionsPageCursorAndRowPerPage = useCallback(
    async ({ cursor, rowsPerPage }) => {
      try {
        dispatch(
          setTransactionsPageCursorAndRowPerPage({ cursor, rowsPerPage })
        );
      } catch (error) {
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch]
  );

  const handleFetchTransactions = useCallback(
    async (accountingStatus, cursor, limit, params = {}) => {
      try {
        dispatch(setFetchTransactions());
        const response = await fetchTransactions(axiosInstance, {
          accountingStatus: accountingStatus,
          ...(cursor && {
            cursor: cursor,
          }),
          ...(limit && {
            limit: limit,
          }),
          ...params,
        });

        dispatch(setFetchTransactionsSuccessData(response.data));
      } catch (error) {
        dispatch(setFetchTransactionsError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch, axiosInstance]
  );

  const handleEditTransaction = useCallback(
    async (data, handleClose) => {
      try {
        dispatch(setEditTransaction());
        const response = await editTransaction(data, axiosInstance);
        dispatch(setEditTransactionSuccess(response.data));
        notify(response.data.message, 'success');
        handleClose();
        handleFetchTransactions(
          TransactionStatusTypes.PROCESSED,
          cursor,
          rowsPerPage
        );
      } catch (error) {
        dispatch(setEditTransactionError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, handleFetchTransactions, cursor, rowsPerPage]
  );

  const handleAddTransaction = useCallback(
    async (transaction, handleClose) => {
      dispatch(setAddTransaction());
      try {
        const response = await addTransaction(transaction, axiosInstance);
        if (response) {
          dispatch(setAddTransactionSuccessData(response.data));
          handleClose();
          notify(response.data.message, 'success');
          handleFetchTransactions(
            TransactionStatusTypes.PROCESSED,
            cursor,
            rowsPerPage
          );
        }
      } catch (error) {
        dispatch(setAddTransactionError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, cursor, dispatch, handleFetchTransactions, rowsPerPage]
  );

  const handleArchiveTransactions = useCallback(
    async (archiveTransactionsArray, handleClosePopup) => {
      dispatch(setArchiveTransactions());
      try {
        const response = await archiveTransactions(
          axiosInstance,
          archiveTransactionsArray
        );
        dispatch(setArchiveTransactionsSuccess(response.data));
        notify(response.data.message, 'success');
        if (handleClosePopup) {
          handleClosePopup();
        }
        dispatch(setSelectedTableRows([]));
        handleFetchTransactions(
          TransactionStatusTypes.PROCESSED,
          cursor,
          rowsPerPage
        );
      } catch (error) {
        dispatch(setArchiveTransactionsError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, handleFetchTransactions, cursor, rowsPerPage]
  );

  const handleGetArchiveLabels = useCallback(async () => {
    dispatch(setArchiveLabels());
    try {
      const response = await getArchiveLabels(axiosInstance);
      const labels = createSelectOptions(response.data?.labels);
      dispatch(setArchiveLabelsSuccess(labels));
    } catch (error) {
      dispatch(setArchiveLabelsError(getErrorStatement(error)));
      notify(getErrorStatement(error), 'error');
    }
  }, [axiosInstance, dispatch]);

  const handleCategorizeTransactions = useCallback(
    async (handleClose, categorizations) => {
      try {
        dispatch(setCategorizeTransactions());

        const response = await categorizeTransactions(axiosInstance, {
          categorizations,
        });

        dispatch(setCategorizeTransactionsSuccess(response.data));
        notify(response.data.message, 'success');
        dispatch(setSelectedTableRows([]));
        handleClose();
        handleFetchTransactions(
          TransactionStatusTypes.PROCESSED,
          cursor,
          rowsPerPage
        );
      } catch (error) {
        dispatch(setCategorizeTransactionsError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, rowsPerPage, cursor, handleFetchTransactions]
  );
  const handleFetchTransactionCoins = useCallback(async () => {
    try {
      dispatch(setTransactionCoins());
      const response = await getTransactionCoins(axiosInstance);
      const coins = response.data.coins;
      checkAndFixNullValues(coins, false);
      const baseCurrenciesMap = getBaseCurrenciesMap();
      mergeCoinsWithBaseCurrencies(coins, baseCurrenciesMap);

      dispatch(setTransactionCoinsSuccess(coins));
    } catch (error) {
      dispatch(setTransactionCoinsError(getErrorStatement(error)));
      notify(getErrorStatement(error), 'error');
    }
  }, [axiosInstance, dispatch]);

  const handleFetchTransactionFilters = useCallback(async () => {
    try {
      dispatch(setFetchDynamicFilters());
      const response = await getTransactionFilters(axiosInstance);

      dispatch(setFetchDynamicFiltersSuccessData(response.data));
    } catch (error) {
      dispatch(setFetchDynamicFiltersError(getErrorStatement(error)));
      notify(getErrorStatement(error), 'error');
    }
  }, [axiosInstance, dispatch]);

  const handleUpdateFilters = useCallback(
    (filters) => {
      dispatch(setUpdateFilter(filters));
    },
    [dispatch]
  );

  const handleResetFilters = useCallback(() => {
    dispatch(setResetFilters());
  }, [dispatch]);

  const handleFetchTop1000Coins = useCallback(async () => {
    try {
      dispatch(setFetchTop1000Coins());
      const response = await fetchTop1000Coins(axiosInstance);
      dispatch(setFetchTop1000CoinsSuccess(response.data));
    } catch (error) {
      dispatch(setFetchTop1000CoinsError(getErrorStatement(error)));
      notify(getErrorStatement(error), 'error');
    }
  }, [dispatch, axiosInstance]);

  return {
    handleFetchTransactions,
    handleEditTransaction,
    handleArchiveTransactions,
    handleGetArchiveLabels,
    handleSetTransactionsPageCursorAndRowPerPage,
    handleCategorizeTransactions,
    handleFetchTransactionCoins,
    handleFetchTransactionFilters,
    handleUpdateFilters,
    handleResetFilters,
    handleAddTransaction,
    handleFetchTop1000Coins,
  };
}

export const getBaseCurrenciesMap = () => {
  let map = {};
  for (let key in countryData) {
    const keyForBaseCurrency = countryData[key].currencyCode;
    map[keyForBaseCurrency] = {
      name: countryData[key].currency,
      image: countryData[key].currencyLogo,
    };
  }
  return map;
};

export const mergeCoinsWithBaseCurrencies = (coins, baseCurrenciesMap) => {
  for (let key in baseCurrenciesMap) {
    coins[key] = baseCurrenciesMap[key];
  }
};

export const checkAndFixNullValues = (coins) => {
  for (let key in coins) {
    const atIndex = key.indexOf('@');
    if (atIndex !== -1 && coins[key].name === null) {
      const name = key.slice(0, atIndex);
      coins[key].name = name;
    }
  }
};

export default useTransaction;
