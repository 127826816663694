import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import getErrorStatement from '@/Utils/helper/getErrorStatement';
import notify from '@/Utils/notifyToast';
import useAxiosPrivate from './useAxiosPrivate';
import {
  setGetAccountDetailsList,
  setGetAccountDetailsListError,
  setGetAccountDetailsListSuccess,
  setSelectedAccountDetails,
  setSelectedAccountDetailsError,
  setSelectedAccountDetailsSuccess,
  setGetParentAccounts,
  setGetParentAccountError,
  setGetParentAccountSuccess,
  setCreateAccount,
  setCreateAccountError,
  setCreateAccountSuccessData,
  setEditAccount,
  setEditAccountSuccessData,
  setEditAccountError,
  setFetchLedgerAccounts,
  setFetchLedgerAccountsSuccess,
  setFetchLedgerAccountsError,
  setLedgerAccountsCursorAndRowPerPage,
} from '@/Redux/slices/chartOfAccountsSlice';
import { getWorkspaceAccounts } from '@/Services/chartOfAccounts/getWorkspaceAccounts';
import { fetchAccountDetails } from '@/Services/chartOfAccounts/fetchAccountDetails';
import { createAccount } from '@/Services/chartOfAccounts/createAccount';
import { editAccount } from '@/Services/chartOfAccounts/editAccount';
import { fetchLedgerAccounts } from '@/Services/chartOfAccounts/fetchLedgerAccounts';

function useChartOfAccounts() {
  const dispatch = useDispatch();
  const axiosInstance = useAxiosPrivate();

  const handleGetAccountDetailsList = useCallback(
    async ({
      details = true,
      parentAccount = null,
      isTreasuryAccount = false,
      type = null,
    }) => {
      try {
        dispatch(setGetAccountDetailsList());
        const response = await getWorkspaceAccounts(axiosInstance, {
          ...(details ||
            (details === false && {
              details: details,
            })),
          ...(parentAccount && {
            parentAccount: parentAccount,
          }),
          isTreasuryAccount: isTreasuryAccount,
          ...(type && {
            type: type,
          }),
        });
        dispatch(setGetAccountDetailsListSuccess(response.data));
      } catch (error) {
        dispatch(setGetAccountDetailsListError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch, axiosInstance]
  );

  const handleGetSelectedAccountDetails = useCallback(
    async ({ accountId, hierarchy, isTreasuryAccount }) => {
      try {
        dispatch(setSelectedAccountDetails());
        const response = await fetchAccountDetails(
          axiosInstance,
          accountId,
          hierarchy,
          isTreasuryAccount
        );
        dispatch(setSelectedAccountDetailsSuccess(response.data));
      } catch (error) {
        dispatch(setSelectedAccountDetailsError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch, axiosInstance]
  );

  const handleGetParentAccountList = useCallback(
    async ({
      details = true,
      parentAccount = null,
      level = null,
      type = null,
    }) => {
      try {
        dispatch(setGetParentAccounts());
        const response = await getWorkspaceAccounts(axiosInstance, {
          ...(details ||
            (details === false && {
              details: details,
            })),
          ...(parentAccount && {
            parentAccount: parentAccount,
          }),
          ...(level && {
            level: level,
          }),
          ...(type && {
            type: type,
          }),
        });
        dispatch(setGetParentAccountSuccess(response.data));
      } catch (error) {
        dispatch(setGetParentAccountError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch, axiosInstance]
  );

  const handleCreateAccount = useCallback(
    async (
      { name, description, type, parentAccount = null, openingBalance },
      handleClose
    ) => {
      try {
        dispatch(setCreateAccount());
        const response = await createAccount(axiosInstance, {
          name: name,
          description: description,
          ...(parentAccount && {
            parentAccount: parentAccount,
          }),
          type: type,
          openingBalance: parseFloat(openingBalance),
        });
        dispatch(setCreateAccountSuccessData(response.data));
        notify('Account Created Successfully.', 'success'); // TODO: replace with api response once available.
        handleGetAccountDetailsList({});
        handleClose();
      } catch (error) {
        dispatch(setCreateAccountError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, handleGetAccountDetailsList]
  );

  const handleEditAccount = useCallback(
    async ({ accountId, name, description, openingBalance }, handleClose) => {
      try {
        dispatch(setEditAccount());
        const response = await editAccount(axiosInstance, {
          accountId: accountId,
          name: name,
          description: description,
          openingBalance: parseFloat(openingBalance),
        });
        dispatch(setEditAccountSuccessData(response.data));
        notify('Account Edited Successfully.', 'success'); // TODO: replace with api response once available.
        handleGetAccountDetailsList({});
        handleClose();
      } catch (error) {
        dispatch(setEditAccountError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch, handleGetAccountDetailsList]
  );

  const handleFetchLedgerAccounts = useCallback(
    async ({
      accountId,
      fromTimestamp = null,
      toTimestamp = null,
      cursor,
      limit,
    }) => {
      try {
        dispatch(setFetchLedgerAccounts());

        // Create URLSearchParams without encoding
        const params = new URLSearchParams();
        if (fromTimestamp) params.append('fromTimestamp', fromTimestamp);
        if (toTimestamp) params.append('toTimestamp', toTimestamp);
        if (cursor) params.append('cursor', cursor);
        if (limit) params.append('limit', limit);

        const response = await fetchLedgerAccounts(
          axiosInstance,
          accountId,
          params
        );

        dispatch(
          setFetchLedgerAccountsSuccess({
            ledgerData: response.data,
            totalLedgerAccounts: response.data.ledgerCount,
          })
        );
      } catch (error) {
        dispatch(setFetchLedgerAccountsError(getErrorStatement(error)));
        notify(getErrorStatement(error), 'error');
      }
    },
    [axiosInstance, dispatch]
  );

  const handleSetLedgerAccountsCursorAndRowPerPage = useCallback(
    async ({ cursor, rowsPerPage }) => {
      try {
        dispatch(setLedgerAccountsCursorAndRowPerPage({ cursor, rowsPerPage }));
      } catch (error) {
        notify(getErrorStatement(error), 'error');
      }
    },
    [dispatch]
  );

  return {
    handleGetAccountDetailsList,
    handleGetSelectedAccountDetails,
    handleCreateAccount,
    handleEditAccount,
    handleGetParentAccountList,
    handleFetchLedgerAccounts,
    handleSetLedgerAccountsCursorAndRowPerPage,
  };
}

export default useChartOfAccounts;
