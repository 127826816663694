import { updateSocketStateSuccess } from '@/Redux/slices/socketSlice';
import { routeEnums } from '@/Utils/enums/routes';

export const navigateToIntegrationLoading = (navigate, dispatch) => {
  dispatch(
    updateSocketStateSuccess({
      stateCount: 1,
      message: 'Integration data uploaded successfully to the servers',
      error: false,
    })
  );
  navigate(routeEnums.INTEGRATION_LOADING_PAGE);
};
