import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: null,
  lastName: null,
  userId: null,
  userRole: null,
  workspaces: null,
  currentWorkspaceId: null,
  userData: null,
  isWorkspacesLoading: false,
  error: null,
  isWorkspaceUserDataLoading: false,
  workspaceUserData: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state) => {
      state.isWorkspacesLoading = true;
    },
    setUserDataSuccess: (state, action) => {
      state.isWorkspacesLoading = false;
      state.userData = action.payload;
      state.userId = action.payload._id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.workspaces = action.payload.workspaces;
      state.currentWorkspaceId = action.payload.workspaces[0]?._id;
      state.userRole = action.payload.workspaces[0]?.role.name;
    },
    setUserDataError: (state, action) => {
      state.isWorkspacesLoading = false;
      state.error = action.payload;
    },
    setFirstAndLastName: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setUserWorkspaces: (state, action) => {
      state.isWorkspacesLoading = true;
    },
    setUserWorkSpacesSuccess: (state, action) => {
      state.isWorkspacesLoading = false;
      state.workspaces = action.payload;
    },
    setUserWorkSpacesError: (state, action) => {
      state.isWorkspacesLoading = false;
      state.error = action.payload;
    },
    setCurrentWorkspaceId: (state, action) => {
      state.currentWorkspaceId = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setWorkspaceUserData: (state, action) => {
      state.isWorkspaceUserDataLoading = true;
    },
    setWorkspaceUserDataSuccess: (state, action) => {
      state.isWorkspaceUserDataLoading = false;
      state.workspaceUserData = action.payload;
    },
    setWorkspaceUserDataError: (state, action) => {
      state.isWorkspaceUserDataLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setUserData,
  setUserDataError,
  setUserDataSuccess,
  setFirstAndLastName,
  setUserWorkSpacesError,
  setUserWorkSpacesSuccess,
  setUserWorkspaces,
  setCurrentWorkspaceId,
  setUserRole,
  setWorkspaceUserData,
  setWorkspaceUserDataSuccess,
  setWorkspaceUserDataError,
} = userSlice.actions;

export default userSlice.reducer;
