import React from 'react';
import styles from './ChartOfAccountsDetailsPage.module.css';
import Details from '@/Components/Routes/ChartOfAccounts/Details';
const ChartOfAccountsDetailsPage = () => {
  return (
    <div className={styles.Wrapper}>
      <Details />
    </div>
  );
};

export default ChartOfAccountsDetailsPage;
