import { io } from 'socket.io-client';
import { WEB_SOCKET_URL } from '@/Utils/constants';

export const setUpConnection = (userId) => {
  return io(WEB_SOCKET_URL, {
    upgrade: false,
    transports: ['websocket'],
    query: {
      userId,
    },
  });
};
