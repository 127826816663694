import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  newUser: false,
  contact: null,
  loading: false,
  isTokenLoading: false,
  error: null,
  message: null,
  email: null,
  isResendOtpLoading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setValidateOTP: (state) => {
      state.loading = true;
    },
    setValidateOTPSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.newUser = action.payload.newUser;
      state.accessToken = action.payload.accessToken;
    },
    setValidateOTPError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setHandleUserTokens: (state, action) => {
      state.accessToken = action.payload;
    },
    setResendOTP: (state) => {
      state.isResendOtpLoading = true;
    },
    setResendOTPSuccess: (state, action) => {
      state.isResendOtpLoading = false;
    },
    setResendOTPError: (state, action) => {
      state.isResendOtpLoading = false;
      state.error = action.payload;
    },
    setHandleRefreshToken: (state) => {
      state.loading = true;
      state.isTokenLoading = true;
    },
    setHandleRefreshTokenSuccess: (state, action) => {
      state.loading = false;
      state.isTokenLoading = false;
      state.message = action.payload;
    },
    setHandleRefreshTokenError: (state, action) => {
      state.loading = false;
      state.isTokenLoading = false;
      state.error = action.payload;
    },
    setBeginAuth: (state, action) => {
      state.loading = true;
      state.email = action.payload.email;
    },
    setBeginAuthSuccess: (state) => {
      state.loading = false;
    },
    setBeginAuthError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setUserSignout: (state) => {
      state.loading = true;
    },
    setUserSignOutSuccess: (state) => {
      state.loading = false;
    },
    setUserSignoutError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setValidateOTP,
  setValidateOTPSuccess,
  setValidateOTPError,
  setHandleUserTokens,
  setResendOTP,
  setResendOTPSuccess,
  setResendOTPError,
  setHandleRefreshToken,
  setHandleRefreshTokenSuccess,
  setHandleRefreshTokenError,
  setBeginAuth,
  setBeginAuthSuccess,
  setBeginAuthError,
  setUserSignOutSuccess,
  setUserSignout,
  setUserSignoutError,
} = authSlice.actions;

export default authSlice.reducer;
