import styles from '@/Components/Ui/Sidebar/SidebarTab/SidebarTab.module.css';
import { KeyboardArrowRight, Button } from '@koinx/xui';
import { useNavigate } from 'react-router';

const NestedFixedTab = ({ tab, isActiveTab, isNested, handlePopoverClose }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.TabWrapper}>
      <Button
        variant="ghost"
        data-active={isActiveTab(tab.link)}
        data-testid={tab.name}
        onClick={(e) => {
          e.preventDefault();
          navigate(tab.link);
          handlePopoverClose();
        }}
        className={styles.FixedTab}
      >
        <div>
          <div className={styles.NameAndIconWrapper}>
            {tab.icon ? <tab.icon className={styles.TabIcon} /> : <></>}
            {isNested && <span className={styles.TabName}>{tab.name}</span>}
          </div>
          {tab.isExpandable && <KeyboardArrowRight />}
        </div>
      </Button>
    </div>
  );
};

export default NestedFixedTab;
