import styles from './JournalsPage.module.css';
import JournalsWrapper from '@/Components/Routes/Journals/Journal/JournalsWrapper';
const JournalsPage = () => {
  return (
    <div className={styles.Wrapper}>
      <JournalsWrapper />
    </div>
  );
};

export default JournalsPage;
