import LoaderWrapper from '@/Components/LoaderWrapper';
// import useScreenType from '@src/Hooks/useScreenType';
import { sourceTypesEnum } from '@/Utils/enums/sourceTypes';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { integrationsContainerData } from '@/Components/AddExchangesPage/constants';
import { Button, Input, KeyboardArrowRight } from '@koinx/xui';
import styles from './AllExchanges.module.css';
import IntegrationCategoryContainer from '@/Components/AddExchangesPage/Components/IntegrationCategoryContainer/IntegrationCategoryContainer';
import HeadingSection from '@/Components/AddExchangesPage/Components/HeadingSection/HeadingSection';
import {
  FILTER_TYPES,
  filterIntegrationsData,
} from '@/Utils/helper/filterIntegrationsData.js';
import { countryData } from '@/Utils/countryData';
import { supportedCountries } from '@/Utils/enums/supportedCountries';

const { filterButtons, title, mobilePlaceholder, placeholder } =
  integrationsContainerData;

const UnMemoizedAllExchanges = () => {
  // const isMobileScreen = useScreenType();

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedIntegrationType, setSelectedIntegrationType] = useState(
    sourceTypesEnum.ALL
  );
  const inputRef = useRef(null);
  const filterBtnsWrapperRef = useRef(null);

  const { sourcesLoading } = useSelector((state) => state.sources);
  const { sources } = useSelector((state) => state.sources);

  const filteredData = useMemo(() => {
    return filterIntegrationsData({
      sources,
      filterType:
        searchQuery.trim().length > 0
          ? FILTER_TYPES.QUERY
          : FILTER_TYPES.CATEGORY,
      filterValue: searchQuery?.trim().length > 0 ? searchQuery : null,
      userCountry: supportedCountries.INDIA,
    });
  }, [searchQuery, sources]);

  // This effect will reset the selected integration type to all when search query is not empty
  useEffect(() => {
    if (searchQuery.trim().length !== 0) {
      setSelectedIntegrationType(sourceTypesEnum.ALL);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handelSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleIntegrationTypeSelection = (typeSelected) => {
    if (typeSelected !== sourceTypesEnum.ALL) setSearchQuery('');
    setSelectedIntegrationType(typeSelected);
  };
  const handleForwardScrollFilterBtns = () => {
    filterBtnsWrapperRef.current.scrollBy(100, 0);
  };

  if (sourcesLoading) {
    return <LoaderWrapper />;
  }

  return (
    <>
      <div className={styles.Wrapper} data-testid="add-wallets-container">
        <HeadingSection title={title} />
        <div className={styles.FilterWrapper}>
          <Input
            type="text"
            width="100%"
            style={{ marginBottom: '1rem' }}
            placeholder={placeholder}
            value={searchQuery}
            onChange={handelSearchQuery}
            ref={inputRef}
            data-testId="integrations-search-bar"
          />

          <div className={styles.FilterBtnWrapperWrapper}>
            <div className={styles.FilterBtnWrapper} ref={filterBtnsWrapperRef}>
              {filterButtons.map(({ btnText, value }) => {
                return (
                  (value === sourceTypesEnum.ALL ||
                    filteredData[value].length > 0) && (
                    <Button
                      data-testid={`${value}-filter-button`}
                      key={value}
                      onClick={() => handleIntegrationTypeSelection(value)}
                      variant={
                        selectedIntegrationType === value
                          ? 'primary'
                          : 'outline'
                      }
                    >
                      <p className={styles.FilterBtnsText}>{btnText}</p>
                    </Button>
                  )
                );
              })}
            </div>
            <Button
              className={styles.FilterBtnScrollForward}
              variant="ghost"
              onClick={handleForwardScrollFilterBtns}
            >
              <KeyboardArrowRight />
            </Button>
          </div>
        </div>
        <div className={styles.IntegrationsWrapper}>
          <IntegrationCategoryContainer
            filteredData={filteredData}
            selectedIntegrationType={selectedIntegrationType}
          />
        </div>
      </div>
    </>
  );
};

const AllExchanges = memo(UnMemoizedAllExchanges);
export default AllExchanges;
