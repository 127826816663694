import Enum from "./enums";

class TransactionSortOptions extends Enum {
  static NONE = 'none';
  static NEWEST = 'newest';
  static OLDEST = 'oldest';
  static HIGHEST_GAINS = 'highest_gains';
  static HIGHEST_LOSSES = 'highest_losses';
  static COST = 'cost';
  static FEES = 'fees';
}

export const transactionSortOptions = Object.freeze(TransactionSortOptions);
