import { toast } from 'react-toastify';

const notify = (message, type, autoClose, options) => {
  toast(message, {
    type,
    autoClose: autoClose ? autoClose : 10000,
    ...options,
  });
};

export default notify;
