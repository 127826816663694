import styles from '@/Components/Routes/ChartOfAccounts/Details/Details.module.css';
import { ArrowBack, Breadcrumbs } from '@koinx/xui';
import AccountDetails from '@/Components/Routes/ChartOfAccounts/Details/components/AccountDetails';
import { DetailsData } from '@/Components/Routes/ChartOfAccounts/Details/constants';
import { useNavigate } from 'react-router';
import { routeEnums } from '@/Utils/enums/routes';
import { useSelector } from 'react-redux';
import TopDetailsSkeleton from './TopDetailsSkeleton';
import { getLinks } from './helper';
import { useMemo } from 'react';
import BlankCellValue from '@/Components/Ui/BlankCellValue/BlankCellValue';

const TopDetailsSection = ({ isTreasuryFlow }) => {
  const navigate = useNavigate();

  const { isSelectedAccountDetailsLoading, selectedAccountDetails } =
    useSelector((state) => state.chartOfAccounts);

  const links = useMemo(
    () => getLinks(selectedAccountDetails?.hierarchy),
    [selectedAccountDetails?.hierarchy]
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleBreadcrumbNavigation = (link) => {
    if (isTreasuryFlow) {
      navigate(`${link}?isTreasury=true`);
    } else {
      navigate(link);
    }
  };

  if (isSelectedAccountDetailsLoading) {
    return <TopDetailsSkeleton handleGoBack={handleGoBack} />;
  }

  return (
    <div className={styles.MainTopWrapper}>
      <div className={styles.TopWrapper}>
        <ArrowBack
          data-testid="topDetailsSection-arrowBack"
          onClick={handleGoBack}
          style={{ cursor: 'pointer', marginRight: '0.8rem' }}
        />
        <Breadcrumbs
          options={links}
          breadcrumbClass="p-0 hover:bg-transparent"
          handleNavigation={handleBreadcrumbNavigation}
          lastChildClass="cursor-text"
          breadcrumbsToShowFromLast={4}
          data-testid="breadcrumbs"
        />
      </div>
      <h2
        data-testid="topDetailsSection-accountNameHeading"
        className={styles.TopTitle}>
        {selectedAccountDetails?.account?.name}
      </h2>
      <div className={styles.AccountDetailsWrapper}>
        {selectedAccountDetails?.account &&
          DetailsData.accountDetails.map(
            ({ label, dataTestId, isAmount, key }) => (
              <AccountDetails
                dataTestId={dataTestId}
                key={key}
                label={label}
                isAmount={isAmount}
                value={selectedAccountDetails?.account[key]}
              />
            )
          )}
      </div>
      <div className={styles.Description}>
        <p
          data-testid="accountDetails-description"
          className={styles.DescriptionTitle}>
          {DetailsData.description}
        </p>
        {selectedAccountDetails?.account?.description ? (
          <p
            data-testid="accountDetails-description-value"
            className={styles.DescriptionText}>
            {selectedAccountDetails?.account?.description}
          </p>
        ) : (
          <BlankCellValue />
        )}
      </div>
    </div>
  );
};

export default TopDetailsSection;
