import styles from "@/Components/Routes/TransactionsPage/TransactionFilters/TransactionFilters.module.css";
import {
  transactionFilterTypes,
  transactionFilterKeys,
} from "@/Components/Routes/TransactionsPage/TransactionFilters/constants"
import { Select, MultiSelect } from '@koinx/xui';
import DateRangeFilter from '@/Components/Routes/TransactionsPage/TransactionFilters/DateRangeFilter';
import { useSelector } from 'react-redux';
import { getTransactionFilterValue } from '@/Components/Routes/TransactionsPage/TransactionFilters/helper';

const TransactionFilter = ({ filter, onValueChange }) => {
  const { label, type, options, optionsHaveIcon, optionsDisplayLimit, key } =
    filter;

  const value = useSelector((state) =>
    getTransactionFilterValue(key, state.transactions?.filters, options)
  );
  const isTransactionsLoading = useSelector(
    (state) => state.transactions?.isTransactionsLoading
  );
  const isDynamicFilterLoading = useSelector(
    (state) => state.transactions?.isDynamicFilterLoading
  );
  const isTransactionCoinsLoading = useSelector(
    (state) => state.transactions?.isTransactionCoinsLoading
  );

  const handleOptionChange = (value) => {
    const updatedFilters = {};
    if (key === transactionFilterKeys.WARNINGS) {
      options.forEach((warningOption) => {
        updatedFilters[warningOption.value] = value.some(
          (selectedWarning) => warningOption.value === selectedWarning.value
        );
      });
    } else updatedFilters[key] = value;
    onValueChange(updatedFilters);
  };

  const handleDateRangeChange = (fromDate, toDate) => {
    if (fromDate === undefined || toDate === undefined) return;
    onValueChange({
      [transactionFilterKeys.START_DATE]: fromDate,
      [transactionFilterKeys.END_DATE]: toDate,
    });
  };

  return (
    <>
      {type === transactionFilterTypes.MULTI_SELECT && (
        <MultiSelect
          value={value}
          options={options}
          placeholder={label}
          optionItemLeftImage={optionsHaveIcon}
          onChange={handleOptionChange}
          optionsDisplayLimit={optionsDisplayLimit}
          badgeClassName={styles.Badge}
          disabled={
            isTransactionsLoading ||
            isDynamicFilterLoading ||
            isTransactionCoinsLoading
          }
          sortOptions={true}
          data-testid={`txn-filter-${key}`}
        />
      )}
      {type === transactionFilterTypes.SELECT && (
        <Select
          value={value}
          options={options}
          onChange={handleOptionChange}
          placeholder={label}
          disabled={isTransactionsLoading || isDynamicFilterLoading}
          optionItemLeftImage={optionsHaveIcon}
          className={styles.SelectFilter}
          sortedOptions={true}
          data-testid={`txn-filter-${key}`}
        />
      )}
      {type === transactionFilterTypes.DATE_RANGE && (
        <DateRangeFilter
          value={value}
          onChange={handleDateRangeChange}
          disabled={isTransactionsLoading}
          className={styles.DateRangeFilter}
          data-testid={`txn-filter-${key}`}
        />
      )}
    </>
  );
};

export default TransactionFilter;
