import { Skeleton } from '@mui/material';
import styles from './AddWalletsSkeleton.module.css';

const AddWalletsSkeleton = () => {
  return (
    <>
      <div className={styles.AddWalletWrapper}>
        <Skeleton variant="rect" width={130} height={29} />
        <Skeleton variant="rect" width={188} height={43} />
      </div>
      <div className={styles.WalletsWrapper}>
        <Skeleton
          className={styles.WalletSkeleton}
          variant="rect"
          width={280}
          height={280}
        />
        <Skeleton
          className={styles.WalletSkeleton}
          variant="rect"
          width={280}
          height={280}
        />
        <Skeleton
          className={styles.WalletSkeleton}
          variant="rect"
          width={280}
          height={280}
        />
      </div>
    </>
  );
};

export default AddWalletsSkeleton;
