import ArchivePopup from '@/Components/Popups/TransactionsPage/ArchivePopup';
import usePopup from '@/Hooks/usePopup';
import { Button, Archive, Spam } from '@koinx/xui';
import { useSelector } from 'react-redux';
import styles from './ArchiveButtonsGroup.module.css';
import { transactionsPageData } from '@/Components/Routes/TransactionsPage/TransactionsPageWrapper/constants';
import { prepareArchiveTransactionsData } from '@/Components/Popups/TransactionsPage/ArchivePopup/helper';
import useTransaction from '@/Hooks/useTransaction';
import { archiveLabelsEnum } from '@/Utils/enums/ArchiveLabelsEnum';

const ArchiveButtonsGroup = () => {
  const { handleArchiveTransactions } = useTransaction();

  const { selectedTableRows, transactionsSuccessData } = useSelector(
    (state) => state.transactions
  );

  const { handlePopupCenterOpen, handlePopupCenterComponentRender } =
    usePopup();

  const handleCloseArchivePopup = () => {
    handlePopupCenterOpen(false);
    handlePopupCenterComponentRender(null);
  };

  const handleArchivePopupOpen = () => {
    handlePopupCenterOpen(true);
    handlePopupCenterComponentRender(
      <ArchivePopup handleClose={handleCloseArchivePopup} />
    );
  };

  const handleArchiveAsSpamButtonClick = () => {
    let archiveTransactionsArray = prepareArchiveTransactionsData(
      transactionsSuccessData,
      selectedTableRows,
      archiveLabelsEnum.SPAM
    );
    handleArchiveTransactions(archiveTransactionsArray);
  };

  return (
    <>
      {selectedTableRows.length > 0 && (
        <>
          <Button
            data-testid="transactionsPage-archiveButton"
            onClick={handleArchivePopupOpen}
            variant="outline"
            className={styles.ArchiveButton}
            icon={<Archive className={styles.ArchiveIcon} />}
            buttonType="icon_left"
          >
            {transactionsPageData.button.archive}
          </Button>
          <Button
            onClick={handleArchiveAsSpamButtonClick}
            data-testid="transactionsPage-spamButton"
            variant="outline"
            className={styles.SpamButton}
            icon={<Spam className={styles.SpamIcon} />}
            buttonType="icon_left"
          >
            {transactionsPageData.button.spam}
          </Button>
        </>
      )}
    </>
  );
};

export default ArchiveButtonsGroup;
