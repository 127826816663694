import {
  Drawer,
  Select,
  Button,
  ArrowDropDown,
  Input,
  Label,
  DateTimePicker,
  TimePicker,
} from '@koinx/xui';
import { getAccountTypeOptions, transformAccounts } from './helpers';
import styles from './CreateAccountDrawer.module.css';
import { useState, useMemo, useCallback } from 'react';
import { Currency } from '@/Utils/enums/currency';
import useChartOfAccounts from '@/Hooks/useChartOfAccounts';
import { useSelector } from 'react-redux';
import { Skeleton, Divider } from '@mui/material';
import { staticContent } from './constants';
import { useForm, Controller } from 'react-hook-form';
import { getCurrencyLogo } from '@/Components/Routes/TransactionsPage/CategoriseTransactionsDrawer/helper';

const {
  defaultTitle,
  editAccountTitle,
  accountType,
  accountName,
  description,
  openingBalance,
  buttons,
  openingTimestamp,
} = staticContent;

const CreateAccountDrawer = ({
  isOpen,
  handleOnOpenChange,
  handleClose,
  width,
  isEditFlow,
  rowData,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      accountName: isEditFlow && rowData ? rowData.name : '',
      accountType: isEditFlow && rowData ? rowData.accountType : '',
      parentAccount: '',
      description: isEditFlow && rowData ? rowData.description : '',
      openingBalance: isEditFlow && rowData ? rowData.openingBalance || 0 : 0,
      openingTimestamp:
        isEditFlow && rowData
          ? rowData.openingTimestamp || new Date()
          : new Date(),
    },
    mode: 'onTouched',
  });

  const {
    parentAccounts,
    isParentAccountsLoading,
    isCreateAccountLoading,
    isEditAccountLoading,
  } = useSelector((state) => state.chartOfAccounts);
  const [makeChildAccount, setMakeChildAccount] = useState(false);

  const [showWarning, setShowWarning] = useState(false);

  const accountTypeOptions = getAccountTypeOptions();
  const baseCurrency = useSelector((state) => state.workspace.baseCurrency);

  const { handleCreateAccount, handleGetParentAccountList, handleEditAccount } =
    useChartOfAccounts();

  const parentAccountOptionsArray = useMemo(() => {
    if (parentAccounts) {
      return transformAccounts(parentAccounts.accounts);
    }
  }, [parentAccounts]);

  const onSubmit = useCallback(
    (data) => {
      let accountData = {
        name: data.accountName,
        description: data.description,
        openingBalance: data.openingBalance,
        openingTimestamp: Date.parse(data.openingTimestamp),
        ...(isEditFlow && { accountId: rowData?._id }),
        ...(!isEditFlow && { parentAccount: data.parentAccount }),
        ...(!isEditFlow && { type: data.accountType }),
      };
      if (isEditFlow) {
        handleEditAccount(accountData, handleClose);
      } else {
        handleCreateAccount(accountData, handleClose);
      }
    },
    [
      handleClose,
      handleCreateAccount,
      handleEditAccount,
      isEditFlow,
      rowData?._id,
    ]
  );

  const accountTypeValue = watch('accountType');

  const AccountTypeSelect = useCallback(() => {
    if (isEditFlow) {
      return null;
    } else {
      return (
        <div className={styles.InputContainer}>
          <Label className={styles.Label} required={true}>
            {accountType.label}
          </Label>
          <Controller
            name="accountType"
            control={control}
            rules={{ required: 'Account Type is required' }}
            render={({ field }) => {
              const { ref, ...rest } = field; // warning: https://github.com/orgs/react-hook-form/discussions/8015
              return (
                <>
                  <Select
                    {...rest}
                    id="accountType"
                    placeholder={accountType.placeholder}
                    onChange={(newAccountType) => {
                      field.onChange(newAccountType);
                      setValue('parentAccountId', '');
                      if (makeChildAccount) {
                        handleGetParentAccountList({
                          details: false,
                          type: newAccountType,
                        });
                      }
                    }}
                    iconRight={<ArrowDropDown />}
                    className={`${styles.Select} ${isSubmitted && !field.value ? styles.InvalidSelect : ''}`}
                    options={accountTypeOptions}
                    width="100%"
                  />
                </>
              );
            }}
          />
          {showWarning && !accountTypeValue && (
            <span className={styles.SelectAccountTypeWarning}>
              <accountType.warning.icon className={styles.WarningIcon} />
              <span className={styles.WarningText}>
                {accountType.warning.text}
              </span>
            </span>
          )}
        </div>
      );
    }
  }, [
    accountTypeOptions,
    accountTypeValue,
    control,
    handleGetParentAccountList,
    isEditFlow,
    isSubmitted,
    makeChildAccount,
    setValue,
    showWarning,
  ]);

  const FormField = ({
    controllerName,
    control,
    rules,
    inputName,
    label,
    placeholder,
    type = 'text',
    isMultiline = false,
    minRows = 3,
    props,
  }) => {
    return (
      <Controller
        name={controllerName}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error, invalid } }) => {
          return (
            <Input
              {...field}
              required
              isError={invalid || (isSubmitted && !field.value)}
              errorText={error?.message}
              width="100%"
              label={label}
              labelClass={styles.Label}
              name={inputName}
              placeholder={placeholder}
              type={type}
              isMultiline={isMultiline}
              minRows={minRows}
              {...props}
            />
          );
        }}
      />
    );
  };

  const ChildCheckboxAndParentAccountSelect = useCallback(() => {
    if (isEditFlow) {
      return null;
    } else {
      return (
        <>
          <div
            style={{ position: 'relative' }}
            className={styles.CheckboxInputContainer}>
            {/* Customized input does support `checked` prop, because the underlying
             * TextField component does not supports it. So used native input as a checkbox to keep it simple
             */}
            <div
              className={styles.CheckboxOverlay}
              onClick={() => {
                setShowWarning(true);
              }}
              data-checkbox-overlay-visible={!accountTypeValue}></div>
            <input
              disabled={!accountTypeValue}
              onChange={(e) => {
                setMakeChildAccount(e.target.checked);
                if (e.target.checked === false) {
                  setValue('parentAccount', '');
                }
                if (e.target.checked === true && accountTypeValue) {
                  handleGetParentAccountList({
                    details: false,
                    type: accountTypeValue,
                  });
                }
              }}
              name={accountName.childAccount.checkbox.name}
              className={styles.Checkbox}
              checked={makeChildAccount}
              type="checkbox"
            />
            <span>{accountName.childAccount.checkbox.text}</span>
          </div>
          {makeChildAccount && (
            <div className={styles.InputContainer}>
              <label className={styles.InputLabel}>
                {accountName.childAccount.label}
              </label>
              {isParentAccountsLoading ? (
                <Skeleton
                  className={styles.ParentAccountSelectSkeleton}
                  width="100%"
                  height="4.4rem"
                  variant="rounded"
                />
              ) : (
                <Controller
                  name="parentAccount"
                  control={control}
                  render={({ field }) => {
                    const { ref, ...rest } = field;
                    return (
                      <Select
                        {...rest}
                        id="parentAccount"
                        sortedOptions={true}
                        disabled={!accountTypeValue}
                        check={false}
                        placeholder={accountName.childAccount.placeholder}
                        iconRight={<ArrowDropDown />}
                        className={`${styles.Select} ${isSubmitted && !field.value ? styles.InvalidSelect : ''}`}
                        options={parentAccountOptionsArray}
                        width="100%"
                      />
                    );
                  }}
                />
              )}
            </div>
          )}
        </>
      );
    }
  }, [
    accountTypeValue,
    control,
    handleGetParentAccountList,
    isEditFlow,
    isParentAccountsLoading,
    isSubmitted,
    makeChildAccount,
    parentAccountOptionsArray,
    setValue,
  ]);

  return (
    <Drawer
      title={isEditFlow ? editAccountTitle : defaultTitle}
      variant="right_fixed_bottom_content"
      isOpen={isOpen}
      handleonOpenChange={handleOnOpenChange}
      handleClose={handleClose}
      width={width}
      titleClass={styles.DrawerTitle}
      bottomContent={
        <div className={styles.ButtonsContainer}>
          <Button
            onClick={handleClose}
            className={styles.Button}
            variant="outline">
            {buttons.left}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className={styles.Button}
            isLoading={
              isEditFlow ? isEditAccountLoading : isCreateAccountLoading
            }
            variant={'primary'}>
            {buttons.right}
          </Button>
        </div>
      }>
      <section className={styles.Wrapper}>
        <AccountTypeSelect />
        <FormField
          controllerName="accountName"
          rules={{ required: 'Account Name is required' }}
          label={accountName.label}
          name={accountName.name}
          placeholder={accountName.placeholder}
          control={control}
        />
        <ChildCheckboxAndParentAccountSelect />
        <FormField
          controllerName="description"
          rules={{ required: 'Account Name is required' }}
          label={description.label}
          name={description.name}
          placeholder={description.placeholder}
          control={control}
          isMultiline={true}
          minRows={3}
        />
        <Divider />
        <Controller
          name="openingBalance"
          rules={{ required: 'Opening Balance is required' }}
          control={control}
          render={({ field, fieldState: { error, invalid } }) => {
            return (
              <Input
                {...field}
                id="openingBalance"
                type="number"
                required
                isError={
                  invalid || (isSubmitted && !field.value && field.value !== 0)
                }
                errorText={error?.message}
                labelClass={styles.Label}
                label={openingBalance.label}
                descriptionText={openingBalance.info}
                placeholder={openingBalance.placeholder + ` (${Currency.INR})`}
                name={openingBalance.name}
                onSelectChange={() => {}}
                startAdornmentDisabled
                width="100%"
                startSelectAdornmentOptions={[
                  {
                    itemLogo: getCurrencyLogo(baseCurrency),
                    label: baseCurrency,
                    value: baseCurrency,
                  },
                ]}
              />
            );
          }}
        />
        <Controller
          name="openingTimestamp"
          rules={{ required: 'Opening Timestamp is required' }}
          control={control}
          render={({ field }) => {
            return (
              <div className={styles.DateTimePickerWrapper}>
                <Label className={styles.Label} required={true}>
                  {openingTimestamp.label}
                </Label>
                <div className={styles.DateTimePickerContainer}>
                  <DateTimePicker
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                    granularity="day"
                    buttonClass="bg-gray-2"
                  />
                  <TimePicker
                    date={field.value}
                    onChange={(date) => field.onChange(date)}
                    granularity="minute"
                    hourInputClass="bg-gray-2"
                    minuteInputClass="bg-gray-2"
                    timePeriodSelectButtonClass="bg-gray-2"
                  />
                </div>
              </div>
            );
          }}
        />
      </section>
    </Drawer>
  );
};

export default CreateAccountDrawer;
