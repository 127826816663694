import { Button } from '@koinx/xui';

import styles from './EmptyStateComponent.module.css';
import { emptyStateComponentData } from '@/Components/AddExchangesPage/constants';

const { btnText, heading, subHeading, EmptyStateImage, SpreadSheetWhite } =
  emptyStateComponentData;

const EmptyStateComponent = ({ handleSetCustomFile }) => {
  return (
    <div className={styles.Wrapper}>
      <img src={EmptyStateImage} alt="no results found" />
      <h1 className={styles.Heading}> {heading} </h1>
      <p className={styles.SubHeading}> {subHeading} </p>
      <Button
        btnType={'primary'}
        src={SpreadSheetWhite}
        isReverse={true}
        className={styles.Btn}
        onClick={handleSetCustomFile}
      >
        {' '}
        {btnText}
      </Button>
    </div>
  );
};

export default EmptyStateComponent;
