import styles from './SignInPage.module.css';
import SignInComponent from '@/Components/UserOnboarding/SignInComponent';

const SignInPage = () => {
  return (
    <section className={styles.Container}>
      <SignInComponent />
    </section>
  );
};

export default SignInPage;
