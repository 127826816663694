import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wallets: [],
  walletToNamesMap: {},
  message: null,
  loading: false,
  isWalletNameUpdating: false,
  error: null,
  walletMetadata: null,
  loadingMetadata: false,
  walletSummaryData: null,
  loadingSummary: false,
  ongoingIntegrationStatus: true,
  isDeepIntegrated: false,
  walletErrors: {},
  isWalletErrorsLoading: true,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setFetchUserWallets: (state) => {
      state.loading = true;
    },
    setFetchUserWalletsSuccess: (state, action) => {
      state.loading = false;
      state.wallets = action.payload;
    },
    setFetchUserWalletsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setFetchOngoingIntegrationStatusSuccess: (state, action) => {
      state.ongoingIntegrationStatus = action.payload;
    },
    setFetchOngoingIntegrationStatusFailure: (state) => {
      state.ongoingIntegrationStatus = true;
    },
    setDeleteUserWallet: (state) => {
      state.loading = true;
    },
    setDeleteUserWalletSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    setDeleteUserWalletFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setUploadUserTransactions: (state, action) => {
      state.loading = true;
    },
    setUploadUserTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    setUploadUserTransactionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setCSVMetadata: (state, action) => {
      state.loadingMetadata = false;
      state.walletMetadata = action.payload;
      state.walletSummaryData = {
        ...state.walletSummaryData,
        [action.payload?.metadata[0]?._id]: { data: null, loading: true },
      };
    },
    setWalletSummary: (state, action) => {
      state.walletSummaryData = {
        ...state.walletSummaryData,
        [action.payload?._id]: {
          data: action.payload?.summary,
          loading: false,
        },
      };
      state.loadingSummary = false;
    },
    setFetchingCSVData: (state, action) => {
      state.loadingSummary = true;
    },
    setFetchingMetaData: (state, action) => {
      state.loadingMetadata = true;
    },
    setUpdateUserWalletName: (state, action) => {
      state.isWalletNameUpdating = true;
    },
    setUpdateUserWalletNameSuccess: (state, action) => {
      state.isWalletNameUpdating = false;

      state.message = action.payload;
    },
    setUpdateUserWalletNameError: (state, action) => {
      state.isWalletNameUpdating = false;
      state.error = action.payload;
    },
    setWalletToNamesMap: (state, action) => {
      state.walletToNamesMap = action.payload;
    },
    setSyncUserTransactions: (state, action) => {
      state.loading = true;
    },
    setSyncUserTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload;
    },
    setSyncUserTransactionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setDeepIntegrationWallet: (state, action) => {
      state.isDeepIntegrated = action.payload;
    },
    setWalletErrorsLoading: (state, action) => {
      state.isWalletErrorsLoading = true;
    },
    setWalletErrorsFinished: (state, action) => {
      state.isWalletErrorsLoading = false;
    },
    setWalletErrors: (state, action) => {
      state.walletErrors = action.payload;
    },
    resetWalletErrors: (state, action) => {
      state.walletErrors = {};
    },
  },
});

export const {
  setFetchUserWallets,
  setCSVMetadata,
  setDeepIntegrationWallet,
  setDeleteUserWallet,
  setDeleteUserWalletFailure,
  setDeleteUserWalletSuccess,
  setFetchOngoingIntegrationStatusFailure,
  setFetchOngoingIntegrationStatusSuccess,
  setFetchUserWalletsFailure,
  setFetchUserWalletsSuccess,
  setUpdateUserWalletName,
  setUpdateUserWalletNameError,
  setFetchingCSVData,
  setFetchingMetaData,
  setUploadUserTransactions,
  setUploadUserTransactionsFailure,
  setUploadUserTransactionsSuccess,
  setWalletToNamesMap,
  setSyncUserTransactions,
  setSyncUserTransactionsSuccess,
  setSyncUserTransactionsFailure,
  setWalletSummary,
  setWalletErrorsLoading,
  setUpdateUserWalletNameSuccess,
  setWalletErrorsFinished,
  setWalletErrors,
  resetWalletErrors,
} = walletSlice.actions;

export default walletSlice.reducer;
