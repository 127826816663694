import React, { useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useJournal from '@/Hooks/useJournal';
import { SystemCreatedJournal, journalTableHeaders } from './constants';
import { Pagination } from '@koinx/xui';
import styles from './JournalTable.module.css';
import JournalTableSkeleton from './components/JournalTableSkeleton/JournalTableSkeleton';
import dateFormatter from '@/Utils/helper/formatDate';
import BlankCellValue from '@/Components/Ui/BlankCellValue/BlankCellValue';
import { capitalise } from '@/Utils/capitalise';
import AmountComponent from '@/Components/Ui/AmountComponent';
import TableHeader from './components/TableHeader';
import TableBody from './components/TableBody';

const JournalTable = () => {
  const navigate = useNavigate();
  const {
    isJournalsListLoading,
    journalsList,
    cursor,
    rowsPerPage,
    totalJournalCount,
  } = useSelector((state) => state.journalTable);

  const {
    handleFetchJournalTableData,
    handleSetCursorAndRowsPerPageInJournals,
  } = useJournal();
  const journalsListIsNotLoadingAndExists =
    !isJournalsListLoading && journalsList.length > 0;

  useEffect(() => {
    handleFetchJournalTableData({ limit: rowsPerPage, cursor: cursor });
  }, [cursor, handleFetchJournalTableData, rowsPerPage]);

  const columns = React.useMemo(
    () => [
      {
        header: journalTableHeaders[0].label,
        accessorKey: journalTableHeaders[0].id,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? (
            <span className={styles.DateAndTimeWrapper}>
              <span className={styles.Date}>{dateFormatter(value, true)}</span>
              <span className={styles.Time}>
                {dateFormatter(value, false, true).slice(
                  dateFormatter(value, false, true).lastIndexOf(',') + 1
                )}
              </span>
            </span>
          ) : (
            <BlankCellValue />
          );
        },
      },
      {
        header: journalTableHeaders[1].label,
        accessorKey: journalTableHeaders[1].id,
        cell: ({ getValue }) => <AmountComponent value={getValue()} />,
      },
      {
        header: journalTableHeaders[2].label,
        accessorKey: journalTableHeaders[2].id,
        cell: ({ getValue }) => capitalise(getValue()) || <BlankCellValue />,
      },
      {
        header: journalTableHeaders[3].label,
        accessorKey: journalTableHeaders[3].id,
        cell: ({ row, getValue }) => {
          const value = getValue();
          console.log(row);
          return value &&
            value.firstName &&
            value.lastName &&
            row.original.createType !== SystemCreatedJournal ? (
            `${value?.firstName} ${value?.lastName}`
          ) : (
            <BlankCellValue />
          );
        },
      },
      {
        header: journalTableHeaders[4].label,
        accessorKey: journalTableHeaders[4].id,
        cell: ({ getValue }) => getValue() || <BlankCellValue />,
      },
    ],
    []
  );

  const table = useReactTable({
    data: journalsList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: Math.ceil(totalJournalCount / rowsPerPage),
    manualPagination: true,
    state: {
      pagination: {
        pageIndex: cursor / rowsPerPage,
        pageSize: rowsPerPage,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newPagination = updater({
          pageIndex: cursor / rowsPerPage,
          pageSize: rowsPerPage,
        });
        const newCursor = newPagination.pageIndex * newPagination.pageSize;
        handleSetCursorAndRowsPerPageInJournals({
          cursor: newCursor,
          rowsPerPage: newPagination.pageSize,
        });
        handleFetchJournalTableData({
          cursor: newCursor,
          limit: newPagination.pageSize,
        });
      }
    },
  });

  return (
    <div className={styles.TableWrapper} data-testid="journal-table">
      <div className={styles.TableScrollContainer}>
        <table className={styles.Table}>
          <TableHeader headerGroups={table.getHeaderGroups()} />
          {isJournalsListLoading ? (
            <JournalTableSkeleton
              rowCount={rowsPerPage}
              columnCount={columns.length}
            />
          ) : (
            <TableBody rows={table.getRowModel().rows} columns={columns} />
          )}
        </table>
      </div>
      {journalsListIsNotLoadingAndExists && (
        <div className={styles.Pagination}>
          <Pagination
            data-testid="journalTable-pagination"
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            rowOptionsGap={1}
            rowOptionsLength={100}
            setCurrentPage={(page) => table.setPageIndex(page - 1)}
            rowsPerPage={table.getState().pagination.pageSize}
            setRowsPerPage={table.setPageSize}
            paginationWrapperClass={styles.PaginationWrapperClass}
          />
        </div>
      )}
    </div>
  );
};

export default JournalTable;
