import { useSelector } from 'react-redux';
import { generateDeepIntegrationPopupContent } from './helper';
import { useEffect, useState } from 'react';
import styles from './DeepIntegrationPopup.module.css';
// import useScreenType from '@/Hooks/useScreenType';
import { Button, Close } from '@koinx/xui';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const DeepIntegration = ({ source, handleClose, isPopup = false }) => {
  // const isMobileScreen = useScreenType();
  const sourcesData = useSelector((state) => state.sources.sources);
  const deepIntegrationSourceData = sourcesData.find(
    (sourceData) => sourceData.name === source
  );

  const staticContent = generateDeepIntegrationPopupContent(source);

  const [time, setTime] = useState({ minutes: 5, seconds: 0 });
  const [loaderTimer, setLoaderTimer] = useState(100);

  useEffect(() => {
    const countDown = setInterval(() => {
      setLoaderTimer((prevState) => prevState - 1);
    }, 3000);

    return () => {
      clearInterval(countDown);
    };
  }, []);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime.seconds > 0) {
          sessionStorage.setItem(
            'timer',
            JSON.stringify({ ...prevTime, seconds: prevTime.seconds - 1 })
          );
          return { ...prevTime, seconds: prevTime.seconds - 1 };
        } else {
          if (prevTime.minutes > 0) {
            sessionStorage.setItem(
              'timer',
              JSON.stringify({
                minutes: prevTime.minutes - 1,
                seconds: 59,
              })
            );
            return { minutes: prevTime.minutes - 1, seconds: 59 };
          } else {
            clearInterval(countdown);
            return prevTime;
          }
        }
      });
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, []);

  return (
    <div
      className={isPopup ? styles.Wrapper : styles.DrawerWrapper}
      data-testid="deep-integration-wrapper"
    >
      {/* {!isMobileScreen && ( */}
      <div className={styles.HeaderWrapper}>
        <div className={styles.LogoWrapper}>
          <img
            src={deepIntegrationSourceData.logo}
            alt={source}
            width="30rem"
          />
          <h2 className={styles.HeaderText}>{staticContent.title}</h2>
        </div>
        <Close className={'CursorPointer'} onClick={handleClose} />
      </div>
      {/* )} */}

      <h2 className={styles.Heading}>{staticContent.heading}</h2>
      <div className={styles.TimerWrapper}>
        <CircularProgressbarWithChildren
          value={loaderTimer}
          strokeWidth={2}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathTransitionDuration: 0.1,
            pathColor: 'var(--blue-09)',
            trailColor: 'var(--light-quad-blue)',
            backgroundColor: '#3e98c7',
          })}
        >
          <span className={styles.Timer}>
            {time.minutes} : {time.seconds < 10 ? '0' : ''}
            {time.seconds}
          </span>
        </CircularProgressbarWithChildren>
      </div>
      <img
        src={staticContent.popupImage}
        alt={source}
        width={
          // isMobileScreen ? 200 :
          300
        }
      />
      {/* {isMobileScreen && staticContent?.deepLink && (
        <Button
          variant="primary"
          buttonType="icon_right"
          fullWidth={isMobileScreen}
          icon={<OpenInNewIcon fontSize="small" />}
          asChild
          data-heapId="deep-integration-popup-drawer-proceed-button"
        >
          <a href={staticContent?.deepLink} target="_self">
            {staticContent?.proceed}
          </a>
        </Button>
      )} */}
    </div>
  );
};

export default DeepIntegration;
