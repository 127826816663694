import { integrationMethodsEnum } from '@/Utils/enums/integrationMethods';
import { useSelector } from 'react-redux';
import FileUploadComponent from '@/Components/AddExchangesPage/Components/FileUploadComponent';
import { getCustomNameIntegration } from '@/Utils/helper/getCustomNameIntegration';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import ApiKeyView from '@/Components/AddExchangesPage/Components/IntegrationMethodsView/ApiKeyView';
import { Button } from '@koinx/xui';
// import useScreenType from '@/Hooks/useScreenType';
import usePopup from '@/Hooks/usePopup';

import { OAuthEnum } from '@/Utils/enums/OAuthEnum';
import OAuthView from '@/Components/AddExchangesPage/Components/IntegrationMethodsView/OAuthView';
import styles from './TabPanel.module.css';
import useOAuthIntegration from '@/Hooks/useOAuthIntegration';
import useBitbns from '@/Hooks/useBitbns';
// import { DeepIntegrationPopup } from '../DeepIntegrationPopup';

const TabView = ({ selectedMethod, customName }) => {
  const params = useParams();
  // // const isMobileScreen = useScreenType();
  // const { handlePopupCenterOpen, handlePopupCenterComponentRender } =
  //   usePopup();
  const { handleOAuthIntegrationAuth } = useOAuthIntegration();
  const { handleBitbnsAuth } = useBitbns();

  const { enabledIntegrationMethods, selectedIntegration } = useSelector(
    (state) => state.sources
  );
  const { wallets: userWallets } = useSelector((state) => state.wallet);

  const [uploadedFiles, setUploadedFiles] = useState({});
  // const [openDrawer, setOpenDrawer] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const [searchParams] = useSearchParams();
  const methodFromUrl = searchParams.get('method');

  const handleSetUploadedFiles = useCallback(() => {
    let tempUploadedFiles = {};
    selectedIntegration?.inputs?.csv?.map((file) => {
      tempUploadedFiles[file.type] = null;
    });
    setUploadedFiles(tempUploadedFiles);
  }, [selectedIntegration]);

  // const handleDeepIntegration = () => {
  //   // if (isMobileScreen) {
  //   //   setOpenDrawer(true);
  //   // } else {
  //   handlePopupCenterOpen(true);
  //   handlePopupCenterComponentRender(
  //     <DeepIntegrationPopup source={selectedIntegration?.name} />
  //   );
  //   // }
  // };

  const handleOAuthIntegrationClick = useCallback(
    (exchangeName) => {
      setRedirecting(true);
      setTimeout(() => {
        handleOAuthIntegrationAuth(exchangeName);
      }, 2000);
    },
    [handleOAuthIntegrationAuth]
  );

  const handleBitBnsConnectClick = useCallback(() => {
    setRedirecting(true);
    setTimeout(() => {
      handleBitbnsAuth();
    }, 2000);
  }, [handleBitbnsAuth]);

  const handleViewRender = () => {
    switch (selectedMethod) {
      case integrationMethodsEnum.CSV:
        return (
          <FileUploadComponent
            customWalletName={getCustomNameIntegration(
              userWallets,
              selectedIntegration,
              customName
            )}
            redirectedWallet={
              params?.walletId ? { _id: params.walletId } : null
            }
            currentWallet={selectedIntegration}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            handleSetUploadedFiles={handleSetUploadedFiles}
            selectedMethod={selectedMethod}
          />
        );
      case integrationMethodsEnum.API_KEY:
        return (
          <ApiKeyView
            selectedIntegration={selectedIntegration}
            customName={customName}
          />
        );
      // case integrationMethodsEnum.DEEP_INTEGRATION:
      //   return (
      //     <div>
      //       <Button
      //         variant="primary"
      //         className={styles.ConnectIntegrationBtn}
      //         onClick={handleDeepIntegration}>
      //         Connect {selectedIntegration?.displayName}
      //       </Button>
      //     </div>
      //   );
      case integrationMethodsEnum.OAUTH:
        switch (selectedIntegration?.name) {
          case OAuthEnum.COINJAR:
          case OAuthEnum.MUDREX:
            return (
              <OAuthView
                selectedIntegration={selectedIntegration}
                handleOnClick={() =>
                  handleOAuthIntegrationClick(selectedIntegration?.name)
                }
                isLoading={redirecting}
              />
            );
          case OAuthEnum.BINANCE:
            return (
              <OAuthView
                selectedIntegration={selectedIntegration}
                handleOnClick={() =>
                  handleOAuthIntegrationClick(OAuthEnum.BINANCE_OAUTH)
                }
                isLoading={redirecting}
              />
            );
          case OAuthEnum.BITBNS:
            return (
              <OAuthView
                selectedIntegration={selectedIntegration}
                handleOnClick={handleBitBnsConnectClick}
                isLoading={redirecting}
              />
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  useEffect(() => {
    if (selectedIntegration) {
      handleSetUploadedFiles();
    }
  }, [handleSetUploadedFiles, selectedIntegration]);

  useEffect(() => {
    if (
      enabledIntegrationMethods?.includes(methodFromUrl) &&
      methodFromUrl === integrationMethodsEnum.OAUTH
    ) {
      switch (selectedIntegration?.name) {
        case OAuthEnum.COINJAR:
        case OAuthEnum.MUDREX:
          handleOAuthIntegrationClick(selectedIntegration?.name);
          break;
        case OAuthEnum.BINANCE:
          handleOAuthIntegrationClick(OAuthEnum.BINANCE_OAUTH);
          break;
        case OAuthEnum.BITBNS:
          handleBitBnsConnectClick();
          break;
        default:
          return;
      }
    }
  }, [
    enabledIntegrationMethods,
    handleBitBnsConnectClick,
    handleOAuthIntegrationClick,
    methodFromUrl,
    selectedIntegration,
  ]);

  return (
    <>
      {handleViewRender()}
      {/* {isMobileScreen && openDrawer && (
        <DeepIntegrationDrawer
          source={selectedIntegration?.name}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )} */}
    </>
  );
};

export default TabView;
