import { useEffect, useState } from 'react';
import { Close } from '@koinx/xui';
import { Replay10 } from '@koinx/xui';
import { Document } from '@koinx/xui';
import { Button } from '@koinx/xui';
import usePopup from '@/Hooks/usePopup';
import RedWarningIcon from '@/Assets/General/RedWarningIcon.svg';
import styles from './InvalidFileFormatPopup.module.css';
import { invalidFileFormatPopupData } from '@/Components/AddExchangesPage/constants';
import { Link } from 'react-router-dom';

const { btnTexts, description, heading } = invalidFileFormatPopupData;

const InvalidFileFormatPopup = ({ currentIntegration, selectedMethod }) => {
  const [contentType, setContentType] = useState({
    video: null,
    blog: null,
  });
  const [selectedIntegrationMethod, setSelectedIntegrationMethod] =
    useState(selectedMethod);
  const { handlePopupCenterOpen } = usePopup();
  const handlePopupClose = () => {
    handlePopupCenterOpen(false);
  };

  useEffect(() => {
    setSelectedIntegrationMethod(selectedMethod);
  }, [currentIntegration, selectedMethod]);

  useEffect(() => {
    const contentType = {};
    if (currentIntegration?.videoLinks?.[selectedIntegrationMethod]) {
      contentType.video =
        currentIntegration?.videoLinks?.[selectedIntegrationMethod];
    }
    if (currentIntegration?.blogLinks?.[selectedIntegrationMethod]) {
      contentType.blog =
        currentIntegration?.blogLinks?.[selectedIntegrationMethod];
    }
    setContentType(contentType);
  }, [currentIntegration, selectedIntegrationMethod]);

  return (
    <div className={styles.Wrapper}>
      <Close className={styles.Close} onClick={handlePopupClose} />
      <div className={styles.HeadingWrapper}>
        <img src={RedWarningIcon} alt="" className={styles.WarningIcon} />
        <h1 className={styles.Heading}>{heading}</h1>
      </div>
      <p className={styles.Description}> {description} </p>
      <h2 className={styles.SubHeading}>
        How to integrate{' '}
        {currentIntegration?.displayName ?? currentIntegration?.name} with
        KoinX?
      </h2>
      {contentType?.video && (
        <iframe src={contentType.video} className={styles.Iframe}></iframe>
      )}
      {contentType?.blog && (
        <Button
          variant="ghost"
          className={styles.ReadBlogBtn}
          buttonType="icon_left"
          icon={<Document sx={{ color: 'var(--blue-09)' }} />}
          asChild
        >
          <Link to={contentType.blog} target="_blank" rel="noreferrer">
            {btnTexts[0]}
          </Link>
        </Button>
      )}
      <Button
        variant="primary"
        className={styles.TryBtn}
        buttonType="icon_left"
        icon={<Replay10 />}
        onClick={handlePopupClose}
      >
        {btnTexts[1]}
      </Button>
    </div>
  );
};

export default InvalidFileFormatPopup;
