import { Drawer, Edit } from '@koinx/xui';
import styles from './TransactionDetailsDrawer.module.css';
import { drawerTitle, transactionDetailsFields } from './constants';
import { shortenString } from '@/Utils/helper/shortenString';
import dateFormatter from '@/Utils/helper/formatDate';
import { TransactionStatusTypes } from '@/Utils/enums/TransactionStatusTypes';
import { TransactionStatusTypesIconMapping } from '@/Utils/TransactionStatusTypesIconMapping';
import { useSelector } from 'react-redux';
import { capitalise } from '@/Utils/capitalise';
import { useState } from 'react';
import TransactionDetail from '@/Components/Routes/TransactionsPage/TransactionDetail/TransactionDetail';
import { TransactionLabelToIconMap } from '@/Utils/TransactionLabelToIconMap';
import { Divider } from '@mui/material';
import CategorizedTransactionDetails from './components/CategorizedTransactionDetail';
import AddEditTransactionDrawer from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer';
import { addEditTransactionDrawerTypes } from '@/Utils/enums/addEditTransactionDrawerTypes';
import { useSearchParams } from 'react-router-dom';
import { TabTypesEnum } from '@/Utils/enums/tabTypes';

const TransactionDetailsDrawer = ({
  rowData,
  isOpen,
  handleOnOpenChange,
  handleClose,
  width,
}) => {
  const { sourceNameToSourceDataMap } = useSelector((state) => state.sources);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  const handleOpenEditDrawer = () => {
    setOpenEditDrawer(true);
  };

  const onDismiss = () => {
    setOpenEditDrawer(false);
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  return (
    <>
      <Drawer
        variant="right"
        isOpen={isOpen}
        handleonOpenChange={handleOnOpenChange}
        handleClose={handleClose}
        width={width ? width : '40%'}
        title={drawerTitle}
        titleClass={styles.DrawerTitle}
        closeIconClass={styles.IconClass}
        headingIcon={
          tab !== TabTypesEnum.CATEGORIZED && (
            <Edit className={styles.IconClass} onClick={handleOpenEditDrawer} />
          )
        }>
        <section
          className={styles.DrawerContentContainer}
          data-testid="transactionDetailsDrawer">
          <div className={styles.DetailWrapper}>
            {rowData.fromAddress && (
              <TransactionDetail
                label={transactionDetailsFields.from}
                stringValue={shortenString(rowData.fromAddress)}
                valueToCopy={rowData.fromAddress}
                image={
                  sourceNameToSourceDataMap[rowData.source]?.source.logo ||
                  sourceNameToSourceDataMap['defaultCoin']?.logo
                }
                avatar={true}
              />
            )}
            {rowData.toAddress && (
              <TransactionDetail
                label={transactionDetailsFields.to}
                stringValue={shortenString(rowData.toAddress)}
                valueToCopy={rowData.toAddress}
                image={
                  sourceNameToSourceDataMap[rowData.source]?.source.logo ||
                  sourceNameToSourceDataMap['defaultCoin']?.logo
                }
                avatar={true}
              />
            )}
          </div>

          <div className={styles.DetailWrapper}>
            {rowData.type && (
              <TransactionDetail
                label={transactionDetailsFields.type}
                stringValue={capitalise(rowData?.externalLabel)}
                Icon={
                  TransactionLabelToIconMap[
                    capitalise(rowData?.externalLabel)
                  ] || TransactionLabelToIconMap['Default']
                }
                iconFillColor={'var(--blue-09)'}
              />
            )}
            {rowData.accounting && (
              <TransactionDetail
                label={transactionDetailsFields.status}
                stringValue={capitalise(rowData?.accounting?.status)}
                Icon={
                  TransactionStatusTypesIconMapping[rowData?.accounting?.status]
                    .icon
                }
                iconFillColor={
                  rowData.accounting.status ===
                  TransactionStatusTypes.CATEGORIZED
                    ? 'var(--profit-green)'
                    : 'var(--icon-grey)'
                }
              />
            )}
          </div>
          <Divider />
          {rowData?.accounting?.status ===
            TransactionStatusTypes.CATEGORIZED && (
            <CategorizedTransactionDetails rowData={rowData} />
          )}
          <div className={styles.DetailWrapper}>
            {rowData.timestamp && (
              <TransactionDetail
                label={transactionDetailsFields.timeStamp}
                stringValue={dateFormatter(rowData.timestamp, false, true)}
              />
            )}
            {rowData.txnHash && (
              <TransactionDetail
                label={transactionDetailsFields.transactionId}
                stringValue={shortenString(rowData.txnHash)}
                valueToCopy={rowData.txnHash}
              />
            )}
          </div>
        </section>
      </Drawer>
      {openEditDrawer && (
        <AddEditTransactionDrawer
          type={addEditTransactionDrawerTypes.EDIT}
          isOpen={openEditDrawer}
          handleClose={onDismiss}
          isMultiRow={true}
          userTransaction={rowData}
        />
      )}
    </>
  );
};

export default TransactionDetailsDrawer;
