import { BLOCKCHAIN_INTEGRATION_URL } from '@/Utils/constants';

const syncBlockchainWallet = (axiosInstance, blockchainWalletDetails) => {
  return axiosInstance.post(
    BLOCKCHAIN_INTEGRATION_URL,
    blockchainWalletDetails
  );
};

export default syncBlockchainWallet;
