import Enum from './enums';

class AccountType extends Enum {
  static ASSETS = 'Assets';
  static LIABILITY = 'Liability';
  static INCOME = 'Income';
  static EXPENSE = 'Expense';
  static EQUITY = 'Equity';
}

export const accountTypeEnums = Object.freeze(AccountType);
