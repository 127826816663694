import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import usePopup from '@/Hooks/usePopup';
import useStorage from '@/Hooks/useStorage';
import useOAuthIntegration from '@/Hooks/useOAuthIntegration';
import { OAuthEnum } from '@/Utils/enums/OAuthEnum';
import styles from './AddIntegrationPage.module.css';
import useBitbns from '@/Hooks/useBitbns';
import { setSelectedIntegration } from '@/Redux/slices/sourcesSlice';
import AddExchanges from '@/Components/AddExchangesPage/AddExchanges';
import { permissionsEnums } from '@/Utils/enums/permissions';
import { permissionsCategoryEnums } from '@/Utils/enums/permissionsCategory';
import { useUserHasPermission } from '@/Hooks/useUserHasPermission';
import CheckForUnauthorizedAccess from '@/Components/CheckForUnauthorizedAccess/CheckForUnauthorizedAccess';

const UnMemoizedAddIntegrationsPage = () => {
  const [isNewSignUp, setIsNewSignUp] = useState(false);
  const search = useLocation().search;
  const OAuthCode = new URLSearchParams(search).get('code');
  const redirectSource = new URLSearchParams(search).get('source');
  const signupSuccess = new URLSearchParams(search).get('success');

  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.auth);

  const { handleBitbnsTokens } = useBitbns();
  const { handleOAuthIntegrationTokens } = useOAuthIntegration();
  const { handlePopupCenterComponentRender, handlePopupCenterOpen } =
    usePopup();
  const { getFromLocalStorage } = useStorage();
  const userCanAddIntegrations = useUserHasPermission(
    permissionsCategoryEnums.INTEGRATIONS,
    permissionsEnums.CREATE
  );
  const { userData } = useSelector((state) => state.user);
  // useEffect(() => {
  //     window.dataLayer = window.dataLayer || [];
  //     window.dataLayer.push({
  //         event: 'DLP_Signup',
  //         userID: userData?._id,
  //     });
  //     if (signupSuccess) {
  //         handlePopupCenterComponentRender(
  //             <ProfileUpdatePopUp
  //                 firstName={userData?.firstName}
  //                 lastName={userData?.lastName}
  //                 email={userData?.email}
  //                 mobile={userData?.mobile}
  //             />
  //         );
  //         handlePopupCenterOpen(true);
  //         setIsNewSignUp(true);
  //     }
  // }, [
  //     handlePopupCenterComponentRender,
  //     handlePopupCenterOpen,
  //     signupSuccess,
  //     userData?._id,
  //     userData?.email,
  //     userData?.firstName,
  //     userData?.lastName,
  //     userData?.mobile,
  // ]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedIntegration(null));
    };
  }, [dispatch]);

  // Fetching bitbns, binance, coinjar, mudrex tokens
  useEffect(
    () => {
      if (accessToken && OAuthCode) {
        switch (redirectSource) {
          case OAuthEnum.BINANCE_OAUTH:
          case OAuthEnum.COINJAR:
          case OAuthEnum.MUDREX:
            handleOAuthIntegrationTokens(redirectSource, OAuthCode);
            break;
          default:
            handleBitbnsTokens(OAuthCode, getFromLocalStorage('code_verifier'));
            break;
        }
      }
    },
    //DO NOT UPDATE THIS DEPDENDENCY ARRAY
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [OAuthCode, accessToken, redirectSource]
  );

  return (
    <>
      {isNewSignUp ? (
        <img src="https://ak.gotrackier.com/pixel?adid=63a198c04a85c91bed412284&txn_id=Lead_ID"></img>
      ) : null}

      <CheckForUnauthorizedAccess check={userCanAddIntegrations}>
        {' '}
        <div className={styles.Wrapper}>
          <AddExchanges />
        </div>
      </CheckForUnauthorizedAccess>
    </>
  );
};

const AddIntegrationsPage = memo(UnMemoizedAddIntegrationsPage);
export default AddIntegrationsPage;
