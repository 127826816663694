import { capitalise } from '@/Utils/capitalise';

export const createSelectOptions = (array) => {
  let options = [];
  array.forEach((obj) => {
    if (typeof obj === 'string') {
      options.push({
        label: capitalise(obj),
        value: obj,
      });
    } else if (typeof obj === 'object') {
      options.push({
        label: capitalise(obj.name),
        value: obj._id,
      });
    }
  });
  return options;
};
