import Enum from './enums';

// TODO: remove unwanted labels after archive table gets data from the api
class ArchiveLabels extends Enum {
  static SPAM = 'spam';
  static DUST = 'dust';
  static SLIPPAGE = 'slippage';
}

export const archiveLabelsEnum = Object.freeze(ArchiveLabels);
