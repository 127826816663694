import { memo } from 'react';
import { useSelector } from 'react-redux';
import { FormData } from 'formdata-node';
import { Button, FileUpload, Text } from '@koinx/xui';
import ExistingWalletPopup from '@/Components/AddExchangesPage/Components/Popups/ExistingWalletPopup';
import useWallet from '@/Hooks/useWallet';
import usePopup from '@/Hooks/usePopup';
import 'react-circular-progressbar/dist/styles.css';
import styles from './FileUploadComponent.module.css';
import {
  KOINX,
  fileUploadComponent,
} from '@/Components/AddExchangesPage/constants';

const {
  SpreadSheetIcon,
  customFileTemplate: { btnText, heading },
  uploadDocsText,
  uploadButtonText,
  KoinXCustomSpreadSheetTemplate,
} = fileUploadComponent;

const UnMemoizedFileUploadComponent = ({
  currentWallet,
  redirectedWallet = null,
  setIsLoading,
  customWalletName,
  uploadedFiles,
  setUploadedFiles,
  handleSetUploadedFiles,
  selectedMethod,
}) => {
  const { wallets: userWallets, loading } = useSelector(
    (state) => state.wallet
  );

  const { handleUploadUserTransactions } = useWallet();
  const { handlePopupCenterComponentRender, handlePopupCenterOpen } =
    usePopup();

  const handleFileChange = (files, fileTitle) => {
    setUploadedFiles((prev) => ({
      ...prev,
      [fileTitle]: files,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('customWalletName', customWalletName);

    Object.entries(uploadedFiles).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((file) => {
          if (file instanceof File) {
            formData.append(key, file);
          }
        });
      }
    });

    formData.append('exchangeName', currentWallet?.name);

    if (redirectedWallet) {
      formData.append('walletId', redirectedWallet._id);
    }

    const existingWallets = userWallets.filter(
      (wallet) =>
        wallet.walletSource === currentWallet?.name &&
        !wallet.isSyncBasedIntegration
    );

    if (!redirectedWallet && existingWallets.length > 0) {
      handlePopupCenterComponentRender(
        <ExistingWalletPopup
          existingWallets={existingWallets}
          customWalletName={customWalletName}
          currentWallet={currentWallet}
          selectedMethod={selectedMethod}
          formData={formData}
          setIsLoading={setIsLoading}
          handleSetUploadedFiles={handleSetUploadedFiles}
        />
      );

      handlePopupCenterOpen(true);
    } else {
      if (currentWallet) {
        handleUploadUserTransactions(
          currentWallet?.name,
          formData,
          currentWallet,
          selectedMethod
        );

        handleSetUploadedFiles();
      }
    }
  };

  const fileInputs = currentWallet?.inputs?.csv?.map((fileData, index) => (
    <FileUpload
      key={index}
      fileState={uploadedFiles[fileData.title] || null}
      setFileState={(files) => handleFileChange(files, fileData.title)}
      heading={fileData.displayName}
      subheading="Click or drag file to this area to upload"
      dragStateClass={styles.dragActive}
      fileInputWrapperClass={styles.fileInputWrapper}
      fileInputClass={styles.fileInput}
      headingClass={styles.heading}
      subheadingClass={styles.subheading}
      fileContentContainerClass={styles.fileContentContainer}
      fileContentWrapperClass={styles.fileContentWrapper}
      fileNameClass={styles.fileName}
    />
  ));

  const handleDownloadTemplate = () => {
    const a = document.createElement('a');
    a.href = KoinXCustomSpreadSheetTemplate;
    a.download = 'KoinX Custom Spreadsheet';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const totalFilesUploaded = Object.values(uploadedFiles)
    .filter(Array.isArray)
    .reduce((total, files) => total + files.length, 0);

  return (
    <div className={styles.Wrapper}>
      {currentWallet?.name === KOINX && (
        <div className={styles.CustomFileTemplateWrapper}>
          <Text variant="heading-5" className={styles.TemplateHeading}>
            {heading}
          </Text>
          <Button
            style={{ minWidth: 'max-content' }}
            buttonType="icon_left"
            variant="subtle"
            icon={<img src={SpreadSheetIcon} alt="Spreadsheet" />}
            onClick={handleDownloadTemplate}
          >
            {btnText}
          </Button>
        </div>
      )}
      <form onSubmit={handleSubmit} data-testid="upload-csv-file">
        {fileInputs}
        <div className={styles.SummaryWrapper}>
          <div className={styles.LeftWrapper}>
            <Text variant="button-1">
              {totalFilesUploaded}{' '}
              {currentWallet?.inputs?.csv?.length === 1
                ? uploadDocsText.replace('s', '')
                : uploadDocsText}
            </Text>
          </div>
          <Button
            variant="primary"
            isLoading={loading}
            disabled={totalFilesUploaded === 0}
            data-heapId="add-wallet-upload-csv-file-button"
          >
            {uploadButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
};

const FileUploadComponent = memo(UnMemoizedFileUploadComponent);
export default FileUploadComponent;
