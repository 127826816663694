import { useSelector } from 'react-redux';
import styles from './AlertSection.module.css';
// import useScreenType from '@src/Hooks/useScreenType';
import { useMemo } from 'react';
import { WarningFilled } from '@koinx/xui';
import { staticData } from './constants';

const { title } = staticData;

const AlertSection = ({ selectedMethod, children }) => {
  // const isMobileScreen = useScreenType();
  const { selectedIntegration, enabledIntegrationMethods } = useSelector(
    (state) => state.sources
  );

  const notesArray = useMemo(() => {
    if (selectedIntegration?.notes?.[selectedMethod]?.length > 0) {
      return selectedIntegration?.notes?.[selectedMethod];
    }
    return null;
  }, [selectedIntegration?.notes, selectedMethod]);

  if (!notesArray) return null;

  return (
    <div
      className={styles.Wrapper}
      style={{
        border:
          // isMobileScreen ? '' :
          '2px solid var(--orange-09)',
        backgroundColor:
          // isMobileScreen ? '' :
          'var(--orange-02)',
      }}>
      {children}
      <ul className={styles.List}>
        {notesArray?.map((note, index) => (
          <li
            key={index}
            dangerouslySetInnerHTML={{ __html: note }}
            className={styles.ListItem}
          />
        ))}
      </ul>
    </div>
  );
};

AlertSection.Title = function Title() {
  return (
    <div className={styles.TitleWrapper}>
      <WarningFilled height="2rem" width="2rem" fill="var(--orange-09)" />
      <span className={styles.Title}>{title}</span>
    </div>
  );
};

export default AlertSection;
