import styles from './VerifyOtpPage.module.css';
import OtpComponent from '@/Components/UserOnboarding/OtpComponent';

const VerifyOtpPage = () => {
  return (
    <section className={styles.Wrapper}>
      <OtpComponent />
    </section>
  );
};

export default VerifyOtpPage;
