import { useEffect, useMemo, useState } from 'react';
import SelectComponent from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/Components/SelectComponent/SelectComponent';
import {
  labels,
  addEditTransactionDrawerStaticContent,
  ADD_NEW_WALLET,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/constants';
import styles from './TransactionDetails.module.css';
import { useSelector } from 'react-redux';
import {
  getSourceOptions,
  getTransactionLabelOptions,
  getTransactionTypeOptions,
  getWalletOptions,
} from '@/Components/Routes/TransactionsPage/AddEditTransactionDrawer/helper';
import { addEditTransactionDrawerTypes } from '@/Utils/enums/addEditTransactionDrawerTypes.js';

const { transactionType, transactionLabel, chooseWallet, selectNewWallet } =
  addEditTransactionDrawerStaticContent;

const TransactionDetails = ({ transaction, handleChange, type }) => {
  const { wallets, walletToNamesMap } = useSelector((state) => state.wallet);
  const { sources } = useSelector((state) => state.sources);

  const [showSources, setShowSources] = useState(false);

  const transactionTypeOptions = useMemo(() => {
    return getTransactionTypeOptions();
  }, []);

  const transactionLabelOptions = useMemo(() => {
    return getTransactionLabelOptions(transaction?.type);
  }, [transaction?.type]);

  const walletOptions = useMemo(() => {
    return getWalletOptions(wallets, walletToNamesMap);
  }, [walletToNamesMap, wallets]);

  const sourceOptions = useMemo(() => {
    return getSourceOptions(sources);
  }, [sources]);

  useEffect(() => {
    if (transaction?.walletId === ADD_NEW_WALLET) {
      setShowSources(true);
    } else {
      setShowSources(false);
    }
  }, [transaction?.walletId]);

  return (
    <div className={styles.Wrapper}>
      {transactionTypeOptions && (
        <SelectComponent
          label={labels.TYPE}
          onChange={handleChange}
          options={transactionTypeOptions}
          title={transactionType}
          disabled={type === addEditTransactionDrawerTypes.EDIT}
          value={transaction?.type}
          data-testid="add-edit-txn-popup-type-selector"
        />
      )}
      {transactionLabelOptions && (
        <SelectComponent
          label={labels.LABEL}
          onChange={handleChange}
          options={transactionLabelOptions}
          title={transactionLabel}
          value={transaction?.label}
          data-testid="add-edit-txn-popup-label-selector"
        />
      )}
      {type === addEditTransactionDrawerTypes.ADD && (
        <>
          {walletOptions && (
            <SelectComponent
              options={walletOptions}
              value={transaction?.walletId}
              onChange={handleChange}
              label={labels.WALLETID}
              title={chooseWallet}
              data-testid="add-edit-txn-popup-wallet-selector"
            />
          )}
          {showSources && sourceOptions && (
            <SelectComponent
              options={sourceOptions}
              value={transaction.sourceId}
              onChange={handleChange}
              label={labels.SOURCEID}
              title={selectNewWallet}
              data-testid="add-edit-txn-popup-add-wallet-selector"
            />
          )}
        </>
      )}
    </div>
  );
};

export default TransactionDetails;
