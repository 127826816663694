import { WarningFilled, CurrencyRupee } from '@koinx/xui';

export const staticContent = {
  defaultTitle: 'Create Account',
  editAccountTitle: 'Edit Account',
  accountType: {
    label: 'Account Type',
    placeholder: 'Select an Account Type',
    warning: {
      icon: WarningFilled,
      text: 'Please select an account type',
    },
  },

  accountName: {
    label: 'Account Name',
    name: 'account_name',
    placeholder: 'Enter Account Name',
    childAccount: {
      checkbox: {
        text: 'Make this a child account',
        name: 'make_child_account_checkbox',
      },
      label: 'Select Parent Account',
      name: 'selected_parent_account',
      placeholder: 'Select a Parent Account',
    },
  },

  description: {
    label: 'Description',
    name: 'description',
    placeholder: 'Add a description',
  },

  openingBalance: {
    label: 'Add an Opening Balance',
    name: 'openingBalance',
    placeholder: 'Add an Opening Balance',
    info: 'Add opening account balance till date for accurate financial tracking.',
  },

  openingTimestamp: {
    label: 'Balance as on',
    name: 'openingTimestamp',
  },

  buttons: {
    left: 'Cancel',
    right: 'Save',
  },
};

