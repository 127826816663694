import { useEffect, useRef } from 'react';

export default function IndeterminateCheckbox(props) {
  const { indeterminate, className = '', ...rest } = props;
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [indeterminate, rest.checked]);

  const handleChange = (event) => {
    event.stopPropagation();
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <input
      type="checkbox"
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      className={`${className} cursor-pointer`}
      onChange={handleChange}
      {...rest}
    />
  );
}
