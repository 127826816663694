import styles from '@/Components/Routes/ChartOfAccounts/Details/Details.module.css';
import { Skeleton } from '@mui/material';
import { ArrowBack } from '@koinx/xui';

const TopDetailsSkeleton = ({ handleGoBack }) => {
  return (
    <div className={styles.MainTopWrapper}>
      <div className={styles.TopWrapper}>
        <ArrowBack onClick={handleGoBack} style={{ cursor: 'pointer' }} />
        <Skeleton width="400px" height="25px" />
      </div>
      <Skeleton width="200px" height="25px" />
      <div className={styles.AccountDetailsWrapper}>
        <Skeleton width="300px" height="25px" />
        <Skeleton width="300px" height="25px" />
        <Skeleton width="300px" height="25px" />
      </div>
      <div className={styles.Description}>
        <Skeleton width="300px" height="25px" />
      </div>
    </div>
  );
};

export default TopDetailsSkeleton;
