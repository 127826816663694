import { Menu } from '@koinx/xui';
import { MenuOptions } from './constants';
import styles from './ChartOfAccountsTable.module.css';

export const processData = (data, handleOptionClick) => {
  const processedData = [];

  const addDepth = (items, depth = 0, parentId = null) => {
    items.forEach((item) => {
      const hasChildren = item.accounts && item.accounts.length > 0;
      processedData.push({
        name: item.name,
        _id: item._id,
        type: item.type,
        parentAccount: item.parentAccount,
        description: item.description,
        depth,
        parentId,
        hasChildren,
        expanded: true,
        menu: (
          <Menu
            disabled={item.level === 0}
            options={MenuOptions}
            buttonClassName={
              item.level === 0 ? styles.DisabledMenuButton : styles.MenuButton
            }
            handleOptionClick={() => handleOptionClick(item)}
          />
        ),
      });
      if (hasChildren) {
        addDepth(item.accounts, depth + 1, item._id);
      }
    });
  };
  addDepth(data?.accounts);
  return processedData;
};
export const handleRowExpansionChange = (
  rowExpanded,
  data,
  setData,
  initialData
) => {
  const rowIndex = rowExpanded[0].dataIndex;
  const rowId = data[rowIndex]._id;

  if (data[rowIndex].expanded) {
    const collapseRows = (parentId) => {
      return data
        .filter((row) => row.parentId === parentId)
        .reduce((acc, row) => {
          return acc.concat(collapseRows(row._id), row);
        }, []);
    };

    const rowsToCollapse = collapseRows(rowId);
    const updatedData = data.filter((row) => !rowsToCollapse.includes(row));
    updatedData[rowIndex] = { ...data[rowIndex], expanded: false };
    setData(updatedData);
  } else {
    const childRows = initialData.filter((row) => row.parentId === rowId);
    const updatedData = [...data];
    let insertIndex = rowIndex + 1;

    childRows.forEach((childRow) => {
      updatedData.splice(insertIndex, 0, { ...childRow, expanded: false });
      insertIndex++;
    });

    updatedData[rowIndex] = { ...data[rowIndex], expanded: true };
    setData(updatedData);
  }
};
