export const staticContent = {
  infoText: ['Already a member?', 'Sign In'],
  title: 'Create Account',
  firstName: {
    text: 'First Name',
    placeholder: 'First Name',
    name: 'firstName',
  },
  LastName: {
    text: 'Last Name',
    placeholder: 'Last Name',
    name: 'lastName',
  },
  emailText: 'Email Address',
  termsOfUse: {
    text: 'By continuing, you agree to our  ',
    terms: ' Terms and Conditions ',
    policy: ' Privacy Policy',
  },
  button: {
    default: 'Proceed',
    loading: 'Please wait',
  },
  notifyText: 'Please fill all necessary details',
};
