import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './AddEditTransactionDrawer.module.css';
import SentSection from './Components/SentSection/SentSection';
import TDSSection from './Components/TDSSection/TDSSection';
import FeesSection from './Components/FeesSection/FeesSection';
import DateAndTimeSection from './Components/DateAndTimeSection/DateAndTimeSection';
import TransactionDetails from './Components/TransactionDetails/TransactionDetails';
import {
  initializeState,
  handleTransactionChange,
  convertTimestampToEpochWithTimeOption,
} from './helper';
import ReceivedSection from './Components/ReceivedSection/ReceivedSection';
import { addEditTransactionDrawerStaticContent, labels } from './constants';
import { Button, Drawer } from '@koinx/xui';
import AccountingSpecificFields from './Components/AccountingSpecificFields';
import useTransaction from '@/Hooks/useTransaction';
import { addEditTransactionDrawerTypes } from '@/Utils/enums/addEditTransactionDrawerTypes';
import { cleanTransaction } from './helper';
import TextFieldComponent from './Components/TextfieldComponent';

const { descriptionPlaceholder, descriptionTitle } =
  addEditTransactionDrawerStaticContent;

const AddEditTransactionDrawer = ({
  type,
  manualWalletId,
  aquisitionAmount,
  userTransaction,
  isOpen,
  handleClose,
  width = '45%',
}) => {
  const { handleAddTransaction, handleEditTransaction } = useTransaction();

  const { wallets } = useSelector((state) => state.wallet);
  const { sources, sourceNameToSourceDataMap } = useSelector(
    (state) => state.sources
  );

  const { isAddTransactionsLoading, isEditTransactionLoading } = useSelector(
    (state) => state.transactions
  );

  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    setTransaction(
      initializeState(
        type,
        userTransaction,
        sources,
        wallets,
        aquisitionAmount,
        sourceNameToSourceDataMap,
        manualWalletId
      )
    );
  }, [
    aquisitionAmount,
    sources,
    userTransaction,
    type,
    wallets,
    sourceNameToSourceDataMap,
    manualWalletId,
  ]);

  const handleChange = useCallback((value, name) => {
    setTransaction((prevTransaction) =>
      handleTransactionChange(prevTransaction, name, value)
    );
  }, []);

  const handleSubmit = useCallback(() => {
    const finalChanges = cleanTransaction(transaction);
    const epoch = convertTimestampToEpochWithTimeOption(
      finalChanges.timestamp,
      finalChanges.timezone,
      false
    );
    finalChanges[labels.TIMESTAMP] = epoch; // set timestamp to new epoch time (created with timezone)
    delete finalChanges[labels.TIMEZONE]; // not sending `timezone` in request body

    if (type === addEditTransactionDrawerTypes.ADD) {
      handleAddTransaction(finalChanges, handleClose);
    } else {
      handleEditTransaction(
        {
          transactionId: userTransaction?._id,
          ...finalChanges,
        },
        handleClose
      );
    }
  }, [
    handleAddTransaction,
    handleClose,
    handleEditTransaction,
    transaction,
    type,
    userTransaction?._id,
  ]);

  return (
    <Drawer
      variant="right_fixed_bottom_content"
      isOpen={isOpen}
      handleClose={handleClose}
      width={width}
      bottomContent={
        <div className={styles.BottomContainer}>
          <Button
            onClick={handleClose}
            className={styles.Button}
            data-testid="categorise-transaction-cancel-button"
            variant="outline">
            {'Cancel'}
          </Button>
          <Button
            isLoading={
              type === addEditTransactionDrawerTypes.ADD
                ? isAddTransactionsLoading
                : isEditTransactionLoading
            }
            onClick={handleSubmit}
            data-testid="categorise-transaction-save-button"
            className={styles.Button}
            variant="primary">
            {'Save'}
          </Button>
        </div>
      }
      data-testid="categorise-transaction-drawer"
      titleClass={styles.DrawerTitle}
      closeIconClass={styles.CloseIcon}
      title={userTransaction ? 'Edit Transaction' : 'Add Transaction'}>
      <div className={styles.Wrapper} data-testid="add-edit-txn-popup">
        <TransactionDetails
          handleChange={handleChange}
          transaction={transaction}
          type={type}
        />
        <ReceivedSection
          handleChange={handleChange}
          transaction={transaction}
        />
        <SentSection handleChange={handleChange} transaction={transaction} />

        {type === addEditTransactionDrawerTypes.EDIT &&
          transaction?.tdsCoin && (
            <TDSSection handleChange={handleChange} transaction={transaction} />
          )}
        {type === addEditTransactionDrawerTypes.ADD && (
          <TDSSection handleChange={handleChange} transaction={transaction} />
        )}

        {type === addEditTransactionDrawerTypes.EDIT &&
          transaction?.feeCoin && (
            <FeesSection
              handleChange={handleChange}
              transaction={transaction}
            />
          )}
        {type === addEditTransactionDrawerTypes.ADD && (
          <FeesSection handleChange={handleChange} transaction={transaction} />
        )}
        <DateAndTimeSection onChange={handleChange} transaction={transaction} />
        <AccountingSpecificFields
          onChange={handleChange}
          transaction={transaction}
        />
        <TextFieldComponent
          label={labels.DESCRIPTION}
          onChange={handleChange}
          placeholder={descriptionPlaceholder}
          title={descriptionTitle}
          value={transaction?.description}
          minRows={3}
          isMultiline={true}
        />
      </div>
    </Drawer>
  );
};

export default AddEditTransactionDrawer;
