import React from 'react';
import { Input } from '@koinx/xui';
import SelectField from './SelectField';
import styles from '@/Components/Routes/TransactionsPage/CategoriseTransactionsDrawer/CategoriseTransactionsDrawer.module.css';
import {
  getCurrencyLogo,
  getMainTransactionLabel,
  roundToTwoDecimals,
} from '@/Components/Routes/TransactionsPage/CategoriseTransactionsDrawer/helper';
import { categorizeSections, mainTransactionLabels } from '@/Components/Routes/TransactionsPage/CategoriseTransactionsDrawer/constants';

const InputGroup = ({
  section,
  categoryOptions,
  accountOptions,
  selectedValues,
  handleSelectChange,
  userBaseCurrencyCode,
  rowData,
  isMultiRow = false,
}) => {
  return (
    <div className={styles.InputWrapper}>
      <div className={styles.SelectMainWrapper}>
        <SelectField
          section={section}
          field="category"
          options={categoryOptions}
          label="Category"
          value={selectedValues[section].category}
          onChange={handleSelectChange}
        />
        <SelectField
          section={section}
          field="account"
          options={accountOptions || []}
          label="Account"
          value={selectedValues[section].account}
          onChange={handleSelectChange}
          disabled={!accountOptions?.length}
        />
      </div>
      <Input
        type="number"
        label={
          section === categorizeSections.mainTransaction
            ? getMainTransactionLabel(rowData, isMultiRow, userBaseCurrencyCode)
            : mainTransactionLabels.DEFAULT_AMOUNT
        }
        value={roundToTwoDecimals(selectedValues[section].amount)}
        onChange={() => { }}
        inputClass={styles.AmountInput}
        adornmentClass={styles.AdornmentClass}
        disabled={true}
        startSelectAdornmentOptions={[
          {
            itemLogo: getCurrencyLogo(userBaseCurrencyCode),
            label: userBaseCurrencyCode,
            value: userBaseCurrencyCode,
          },
        ]}
        startSelectAdornmentValue={userBaseCurrencyCode}
        onSelectChange={() => { }}
        width={'48%'}
      />
    </div>
  );
};

export default InputGroup;
