import Enum from './enums';

class CurrencyOptions extends Enum {
  static INR = 'INR';
  static AUD = 'AUD';
  static JPY = 'JPY';
  static GBP = 'GBP';
  static USD = 'USD';
}

export const Currency = Object.freeze(CurrencyOptions);
