import { JOURNALS_URL } from '@/Utils/constants';

export const fetchJournals = (axiosInstance, params = {}) => {
  const url = new URL(JOURNALS_URL);
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      url.searchParams.append(key, value);
    }
  });

  return axiosInstance.get(url?.toString());
};
