import axios from '@/Services/axios';
import { VALIDATE_OTP_URL } from '@/Utils/constants';

export function validateOTP(otp, email, inviteId) {
  let body = {
    otp,
    email,
    inviteId,
  };

  Object.keys(body).forEach((key) => {
    if (body[key] === undefined || body[key] === null) {
      delete body[key];
    }
  });
  return axios.post(VALIDATE_OTP_URL, body);
}
