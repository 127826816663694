import { UPLOAD_USER_TRANSACTIONS_URL } from '@/Utils/constants';

export const uploadUserTransactions = (fileInputData, axiosInstance) => {
  fileInputData.append('platform', 'accounting');

  return axiosInstance.post(UPLOAD_USER_TRANSACTIONS_URL, fileInputData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};