import { useSelector } from 'react-redux';
import WalletCard from '@/Components/Routes/DataSources/UserIntegrations/Components/WalletCard';
import { formatSyncDate } from '@/Utils/helper/formatSyncDate';

const Wallets = () => {
  const { wallets: walletData } = useSelector((state) => state.wallet);

  if (!walletData) return null;

  return walletData.map((wallet, index) => {
    const lastSync = wallet?.lastUpdated
      ? formatSyncDate(wallet?.lastUpdated)
      : '-';
    const lastTransaction = wallet?.lastTransactionTimestamp
      ? formatSyncDate(wallet?.lastTransactionTimestamp)
      : '-';
    const totalWorth = wallet?.totalWorth || 0;
    const transactionsMade = wallet?.numberOfTransactions || 0;
    const userHasOneIntegration = walletData.length === 1;

    return (
      <WalletCard
        key={wallet._id}
        totalWorth={totalWorth}
        transactionMade={transactionsMade}
        lastSync={lastSync}
        lastTransaction={lastTransaction}
        index={index}
        walletId={wallet._id}
        currWallet={wallet}
        showDesktopCardInMobileView={userHasOneIntegration}
      />
    );
  });
};

export default Wallets;
