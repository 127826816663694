/* eslint-disable path/no-relative-imports */
import BlankCellValue from '@/Components/Ui/BlankCellValue/BlankCellValue';
import TransactionDetail from '../../../TransactionDetail/TransactionDetail';
import styles from '../../CustomTable.module.css';
import DefaultIcon from '@/Assets/Icons/DefaultCoin.svg';
import { useSelector } from 'react-redux';
import { commonHeaders } from '../../constants';
import { getCoinObject } from '@/Utils/helper/getCoinObject';

const SourceCell = ({ getValue, row, column }) => {
  const { transactionCoins } = useSelector((state) => state.transactions);
  const metadata = row.original.metadata;
  const value = getValue();

  const obj =
    column.id === commonHeaders[1].id
      ? getCoinObject(metadata, transactionCoins, value, null)
      : getCoinObject(metadata, transactionCoins, null, value);

  return value && obj?.image !== null ? (
    <TransactionDetail
      avatarTooltipContent={obj?.name}
      fontClassName={styles.TableFont}
      stringValue={value}
      avatar={true}
      image={obj?.image}
    />
  ) : value && obj?.image === null ? (
    <TransactionDetail
      avatarTooltipContent={obj?.name}
      fontClassName={styles.TableFont}
      stringValue={value}
      avatar={true}
      Icon={DefaultIcon}
    />
  ) : (
    <BlankCellValue align="end" />
  );
};

export default SourceCell;
