import React from 'react';
import { useNavigate } from 'react-router';
import styles from '@/Components/Routes/Journals/Journal/JournalTable/JournalTable.module.css';
import { useSelector } from 'react-redux';
import { InfoComponent } from '@koinx/xui';
import { JournaltableInfoCompData } from '@/Components/Routes/Journals/Journal/JournalTable/constants';
import EmptyDataImage from '@/Assets/General/EmptyDataImage.svg';
import { flexRender } from '@tanstack/react-table';
import { routeEnums } from '@/Utils/enums/routes';

const TableBody = ({ rows, columns }) => {
  const navigate = useNavigate();
  const { isJournalsListLoading, journalsList } = useSelector(
    (state) => state.journalTable
  );

  if (!isJournalsListLoading && journalsList.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={columns.length}>
            <InfoComponent
              wrapperClass={styles.InfoWrapper}
              title={JournaltableInfoCompData.title}
              imageAlt={JournaltableInfoCompData.imageAlt}
              image={EmptyDataImage}
              enableButton
              subtitle={JournaltableInfoCompData.subtitle}
              buttonText={JournaltableInfoCompData.btnText}
              buttonClickHandler={() => navigate(routeEnums.ADD_JOURNAL_PAGE)}
            />
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <tbody>
      {rows.map((row) => (
        <tr
          key={row.id}
          onClick={() =>
            navigate(
              routeEnums.JOURNALS_DETAILS_PAGE.replace(
                ':journalId',
                row.original._id
              )
            )
          }
          className={`${styles.TableRow} ${row.original.alert ? styles.AlertRow : ''}`}>
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className={styles.TableCell}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
