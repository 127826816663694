import { WALLET_CONNECT_PROJECT_ID } from '@/Utils/constants';
import notify from '@/Utils/notifyToast';
import { wagmiConfig } from '@/Utils/wagmiConfig';
import { createWeb3Modal, useWeb3Modal } from '@web3modal/wagmi/react';
import useBlockchainIntegration from './useBlockchainIntegration';
import { useCallback, useEffect } from 'react';
import { useAccount, useChainId, useDisconnect } from 'wagmi';

const WALLET_CONNECT = 'WalletConnect';

createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId: WALLET_CONNECT_PROJECT_ID,
});

const useBlockchainWallets = () => {
  const { open, close } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const chainId = useChainId();
  const { disconnect } = useDisconnect();
  const { handleSyncBlockchainWallet } = useBlockchainIntegration();

  useEffect(() => {
    const handleWalletConnectSync = async () => {
      try {
        handleSyncBlockchainWallet(
          '',
          '',
          address,
          true,
          chainId,
          WALLET_CONNECT
        );
        close();
        disconnect();
      } catch {
        notify('Some error occured', 'error');
      }
    };
    if (isConnected) {
      handleWalletConnectSync();
    }
  }, [
    address,
    chainId,
    close,
    disconnect,
    handleSyncBlockchainWallet,
    isConnected,
  ]);

  const handleWeb3WalletClick = useCallback(
    async (wallet) => {
      try {
        switch (wallet?.walletKey) {
          case WALLET_CONNECT:
            open({ view: 'Networks' });
            break;
          default: {
            if (window.ethereum) {
              try {
                const accounts = await window.ethereum.request({
                  method: 'eth_requestAccounts',
                });
                const address = accounts[0];
                const chainId = await window.ethereum.request({
                  method: 'eth_chainId',
                });
                handleSyncBlockchainWallet(
                  '',
                  '',
                  address,
                  true,
                  parseInt(chainId, 16)
                );
              } catch {
                notify(
                  'Please accept connection request in the wallet',
                  'error'
                );
              }
            } else {
              notify('Please install metamask to connect your wallet', 'error');
            }
            break;
          }
        }
      } catch {
        notify('Some error occured', 'error');
      }
    },
    [handleSyncBlockchainWallet, open]
  );

  return { handleWeb3WalletClick };
};

export default useBlockchainWallets;
