import { Popover } from '@mui/material';
import styles from './SidebarTab.module.css';
import SidebarTab from './SidebarTab';

const PopoverContent = ({
  tab,
  handleClose,
  isPopoverOpen,
  anchorEl,
  isCollapsed,
}) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={handleClose}
      open={isPopoverOpen}
      anchorEl={anchorEl}
    >
      <ul className={styles.ExpandableList}>
        {tab.subListItems.map((nestedTab, key) => {
          return (
            <li className={styles.CollapsedNestedListItem} key={key}>
              <SidebarTab
                tab={nestedTab}
                isCollapsed={isCollapsed}
                isNested={true}
                handlePopoverClose={handleClose}
              />
            </li>
          );
        })}
      </ul>
    </Popover>
  );
};

export default PopoverContent;
