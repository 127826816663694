import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    coins: [],
    wallets: [],
    types: [],
    labels: [],
    startDate: null,
    endDate: null,
    errorMessages: false,
    sortBy: '',
    cursor: 0,
  },
  dynamicFilters: {},
  isDynamicFilterLoading: false,
  isEditTransactionLoading: false,
  editTransactionSuccessData: null,
  isAddTransactionsLoading: false,
  addTransactionSuccessData: null,
  error: null,
  selectedTableRows: [],
  isTransactionsLoading: false,
  cursor: 0,
  rowsPerPage: 10,
  transactionsSuccessData: [],
  totalTransactionsCount: 0,
  archiveLabels: [],
  isArchiveLabelsLoading: false,
  isArchiveTransactionsLoading: false,
  archiveTransactionSuccessData: null,
  isCategorizeTransactionsLoading: false,
  categorizeTransactionSuccessData: null,
  categorizeTransactionError: null,
  isCategorisationAllowed: false,
  transactionCoins: null,
  transactionCoinsError: null,
  isTransactionCoinsLoading: false,
  top1000coins: [],
  top1000CoinsLoading: false,
  top1000CoinsError: null,
};

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTotalTransactionsCount: (state, action) => {
      state.totalTransactionsCount = action.payload;
    },
    setTransactionsPageCursorAndRowPerPage: (state, action) => {
      state.cursor = action.payload.cursor;
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    setUpdateFilter: (state, action) => {
      state.filters = action.payload;
    },
    setEditTransaction: (state) => {
      state.isEditTransactionLoading = true;
    },
    setFetchDynamicFilters: (state) => {
      state.isDynamicFilterLoading = true;
    },
    setFetchDynamicFiltersSuccessData: (state, action) => {
      state.isDynamicFilterLoading = false;
      state.dynamicFilters = action.payload;
    },
    setFetchDynamicFiltersError: (state, action) => {
      state.isDynamicFilterLoading = false;
      state.error = action.payload;
    },
    setEditTransactionSuccess: (state, action) => {
      state.isEditTransactionLoading = false;
      state.editTransactionSuccessData = action.payload;
    },
    setEditTransactionError: (state, action) => {
      state.isEditTransactionLoading = false;
      state.error = action.payload;
    },
    setAddTransaction: (state) => {
      state.isAddTransactionsLoading = true;
    },
    setAddTransactionSuccessData: (state, action) => {
      state.isAddTransactionsLoading = false;
      state.addTransactionSuccessData = action.payload;
    },
    setAddTransactionError: (state, action) => {
      state.isAddTransactionsLoading = false;
      state.error = action.payload;
    },
    setSelectedTableRows: (state, action) => {
      state.selectedTableRows = action.payload;
    },
    setFetchTransactions: (state) => {
      state.isTransactionsLoading = true;
    },
    setFetchTransactionsSuccessData: (state, action) => {
      state.transactionsSuccessData = action.payload.transactions;
      state.totalTransactionsCount = action.payload.count;
      state.isTransactionsLoading = false;
      cacheTransactionsInSessionStorage(action.payload.transactions);
    },
    setFetchTransactionsError: (state, action) => {
      state.error = action.payload;
      state.isTransactionsLoading = false;
    },
    setArchiveTransactions: (state) => {
      state.isArchiveTransactionsLoading = true;
    },
    setArchiveTransactionsSuccess: (state, action) => {
      state.isArchiveTransactionsLoading = false;
      state.archiveTransactionSuccessData = action.payload;
    },
    setArchiveTransactionsError: (state, action) => {
      state.isArchiveTransactionsLoading = false;
      state.error = action.payload;
    },
    setArchiveLabels: (state) => {
      state.isArchiveLabelsLoading = true;
    },
    setArchiveLabelsSuccess: (state, action) => {
      state.isArchiveLabelsLoading = false;
      state.archiveLabels = action.payload;
    },
    setArchiveLabelsError: (state, action) => {
      state.isArchiveLabelsLoading = false;
      state.error = action.payload;
    },
    setCategorizeTransactions: (state) => {
      state.isCategorizeTransactionsLoading = true;
    },
    setCategorizeTransactionsSuccess: (state, action) => {
      state.isCategorizeTransactionsLoading = false;
      state.categorizeTransactionSuccessData = action.payload;
    },
    setCategorizeTransactionsError: (state, action) => {
      state.isCategorizeTransactionsLoading = false;
      state.categorizeTransactionError = action.payload;
    },
    setIsCategorisationAllowed: (state, action) => {
      state.isCategorisationAllowed = action.payload;
    },
    setTransactionCoins: (state) => {
      state.isTransactionCoinsLoading = true;
    },
    setTransactionCoinsSuccess: (state, action) => {
      state.isTransactionCoinsLoading = false;
      state.transactionCoins = action.payload;
    },
    setTransactionCoinsError: (state, action) => {
      state.isTransactionCoinsLoading = false;
      state.error = action.payload;
    },
    setResetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFetchTop1000Coins: (state) => {
      state.top1000CoinsLoading = true;
    },
    setFetchTop1000CoinsSuccess: (state, action) => {
      state.top1000CoinsLoading = false;
      state.top1000coins = action.payload;
    },
    setFetchTop1000CoinsError: (state, action) => {
      state.top1000CoinsLoading = false;
      state.top1000CoinsError = action.payload;
    },
  },
});

export const {
  setUpdateFilter,
  setResetFilters,
  setSelectedTableRows,
  setAddTransaction,
  setAddTransactionSuccessData,
  setAddTransactionError,
  setEditTransaction,
  setEditTransactionError,
  setEditTransactionSuccess,
  setFetchTransactions,
  setFetchTransactionsSuccessData,
  setFetchTransactionsError,
  setArchiveTransactions,
  setArchiveTransactionsSuccess,
  setArchiveTransactionsError,
  setArchiveLabels,
  setArchiveLabelsError,
  setArchiveLabelsSuccess,
  setTransactionsPageCursorAndRowPerPage,
  setTotalTransactionsCount,
  setCategorizeTransactions,
  setCategorizeTransactionsSuccess,
  setCategorizeTransactionsError,
  setIsCategorisationAllowed,
  setTransactionCoins,
  setTransactionCoinsSuccess,
  setTransactionCoinsError,
  setFetchDynamicFilters,
  setFetchDynamicFiltersSuccessData,
  setFetchDynamicFiltersError,
  setFetchTop1000Coins,
  setFetchTop1000CoinsSuccess,
  setFetchTop1000CoinsError,
} = transactionSlice.actions;

export default transactionSlice.reducer;

/**
 *
 * @param {object[]} transactions transactions success data
 *
 * @description
 * Cache the transactions in the session storage for later use.
 */
function cacheTransactionsInSessionStorage(transactions) {
  const cachedObj = sessionStorage.getItem('cachedTxns');
  if (cachedObj) {
    const parsedCachedObj = JSON.parse(cachedObj);
    for (let i = 0; i < transactions.length; i++) {
      if (!parsedCachedObj[transactions[i]._id]) {
        parsedCachedObj[transactions[i]._id] = transactions[i];
      }
    }
    sessionStorage.setItem('cachedTxns', JSON.stringify(parsedCachedObj));
  } else
    sessionStorage.setItem(
      'cachedTxns',
      JSON.stringify(convertTxnArrayToObject(transactions))
    );
}

function convertTxnArrayToObject(transactions) {
  let obj = {};
  for (let i = 0; i < transactions.length; i++) {
    obj[transactions[i]._id] = transactions[i];
  }
  return obj;
}
