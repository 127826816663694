import { InfoComponent } from '@koinx/xui';
import styles from './UnauthorizedComponent.module.css';
import Lock from '@/Assets/Unauthorized/Lock.svg';
import { unauthorizedPageData } from './constants';
const UnauthorizedComponent = () => {
  return (
    <div className={styles.MainWrapper}>
      <InfoComponent
        titleClass={styles.Title}
        wrapperClass={styles.InfoWrapper}
        image={Lock}
        enableButton={true}
        buttonClass={styles.Button}
        buttonText={unauthorizedPageData.btnText}
        imageAlt={unauthorizedPageData.btnText}
        subtitle={unauthorizedPageData.subtitle}
        title={unauthorizedPageData.title}
      />
    </div>
  );
};

export default UnauthorizedComponent;
