import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cursor: 0,
  rowsPerPage: 10,
  totalJournalTemplates: null,
  isJournalTemplatesListLoading: false,
  journalTemplatesList: null,
  journalTemplatesListError: null,
  isCreateJournalTemplateLoading: false,
  createJournalTemplateSuccess: null,
  createJournalTemplateError: null,
  isEditJournalTemplateLoading: false,
  editJournalTemplateSuccessData: null,
  editJournalTemplateError: null,
  isDeleteJournalTemplateLoading: false,
  deleteJournalTemplateSuccessData: null,
  deleteJournalTemplateError: null,
};

const journalTemplateSlice = createSlice({
  name: 'journalTemplates',
  initialState,
  reducers: {
    setCursorandRowsPerPageInJournalTemplate: (state, action) => {
      state.cursor = action.payload.cursor;
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    setGetJornalTemplatesList: (state) => {
      state.isJournalTemplatesListLoading = true;
    },
    setGetJornalTemplatesListSuccess: (state, action) => {
      state.isJournalTemplatesListLoading = false;
      state.journalTemplatesList = action.payload.templates;
      state.totalJournalTemplates = action.payload.count;
    },
    setGetJornalTemplatesListError: (state, action) => {
      state.isJournalTemplatesListLoading = false;
      state.journalTemplatesListError = action.payload;
    },
    setCreateJournalTemplate: (state) => {
      state.isCreateJournalTemplateLoading = true;
    },
    setCreateJournalTemplateSuccess: (state, action) => {
      state.isCreateJournalTemplateLoading = false;
      state.createJournalTemplateSuccess = action.payload;
    },
    setCreateJournalTemplateError: (state, action) => {
      state.isCreateJournalTemplateLoading = false;
      state.createJournalTemplateError = action.payload;
    },
    setEditJournalTemplate: (state) => {
      state.isEditJournalTemplateLoading = true;
    },
    setEditJournalTemplateSuccess: (state, action) => {
      state.isEditJournalTemplateLoading = false;
      state.editJournalTemplateSuccessData = action.payload;
    },
    setEditJournalTemplateError: (state, action) => {
      state.isEditJournalTemplateLoading = false;
      state.editJournalTemplateError = action.payload;
    },
    setDeleteJournalTemplate: (state) => {
      state.isDeleteJournalTemplateLoading = true;
    },
    setDeleteJournalTemplateSuccess: (state, action) => {
      state.isDeleteJournalTemplateLoading = false;
      state.deleteJournalTemplateSuccessData = action.payload;
    },
    setDeleteJournalTemplateError: (state, action) => {
      state.isDeleteJournalTemplateLoading = false;
      state.deleteJournalTemplateError = action.payload;
    },
  },
});

export const {
  setCursorandRowsPerPageInJournalTemplate,
  setGetJornalTemplatesList,
  setGetJornalTemplatesListSuccess,
  setGetJornalTemplatesListError,
  setCreateJournalTemplate,
  setCreateJournalTemplateError,
  setCreateJournalTemplateSuccess,
  setDeleteJournalTemplate,
  setDeleteJournalTemplateError,
  setDeleteJournalTemplateSuccess,
  setEditJournalTemplate,
  setEditJournalTemplateError,
  setEditJournalTemplateSuccess,
} = journalTemplateSlice.actions;

export default journalTemplateSlice.reducer;
