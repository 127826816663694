import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import DesktopWalletCard from './DesktopWalletCard';

const WalletCard = ({
  transactionMade,
  lastSync,
  lastTransaction,
  walletId,
  currWallet,
  showDesktopCardInMobileView,
}) => {
  const navigate = useNavigate();

  const walletToNamesMap = useSelector(
    (state) => state.wallet.walletToNamesMap
  );

  const {
    logo: imgSrc,
    displayName: title,
    customName: customWalletName,
  } = useMemo(
    () => walletToNamesMap[walletId] || {},
    [walletToNamesMap, walletId]
  );

  // const handleCardClick = (event) => {
  //   navigate(`${routeEnums.WALLET_DETAILS_PAGE}/${walletId}`);
  //   event?.stopPropagation();
  // };

  return (
    <>
      <DesktopWalletCard
        transactionMade={transactionMade}
        lastSync={lastSync}
        lastTransaction={lastTransaction}
        walletId={walletId}
        currWallet={currWallet}
      />
    </>
  );
};

export default WalletCard;
